import React, { useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import SuccessMobile from "../../../assets/iconsComponents/SuccessMobile";
import { motion, AnimatePresence } from "framer-motion";
import { AiOutlineCopy } from "react-icons/ai";

import { BsChevronLeft } from "react-icons/bs";

import Notification from "../../../components/dashboard-components/Notification";
import EmptyState from "../../../components/base-components/EmptyState";
import {
  useAccActivity,
  useWalletBalance,
} from "../../../sevices/hooks/wallets";
import {
  useGenerateWallet,
  useGetSingleFee,
  useGetWalletDetails,
  useUsers,
} from "../../../sevices/hooks/users";

import ConvertModal from "../../../components/wallet-components/ConvertModal";

import { IoMdClose } from "react-icons/io";

import StableWalletCard from "../../../components/wallet-components/stable-coin-wallet-card";
import WalletAccountTable from "../../../components/wallet-components/WalletAccountTable";
import { extractBalances } from "../../../helper";

const UsdcWallet = ({ currency = "usdc" }: { currency?: string }) => {
  const { data: walletdetails, error, isLoading } = useGetWalletDetails();
  const { data: fees } = useGetSingleFee(currency as string);
  const navigate = useNavigate();
  const { data: xwallet } = useGenerateWallet();
  // console.log(xwallet, "xwallet");

  const [copyStatus, setCopyStatus] = useState("");
  const userActivities = useAccActivity();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [showConvert, setShowConvert] = useState(false);

  //usdc wallet
  const usdcAcc = useWalletBalance("usdc");
  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );

  const copyToClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus("Wallet address copied to clipboard");
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };

  function truncateString(str: string, num: number) {
    if (str?.length <= num) {
      return str;
    }
    return str?.slice(0, num) + "...";
  }

  const usdcTransactions = useMemo(
    () =>
      userActivities?.data?.data?.data?.filter(
        (transaction: { currency: string }) =>
          transaction?.currency?.toUpperCase() === "USDC"
      ),
    [userActivities?.data?.data?.data]
  );

  if (isLoading) {
    return <div>Loading wallet details...</div>;
  }

  //  if (error) {
  //    console.error("Error fetching wallet details:", error);
  //    return <div>Error loading wallet details. Please try again later.</div>;
  //  }

  const details = walletdetails?.data?.data;

  // if (!details) {
  //   console.error("Wallet details are undefined or null");
  //   return <div>No wallet details available.</div>;
  // }

  const usdcDetails = extractBalances(details, "USDC");
  // console.log("USDC Details:", usdcDetails);

  return (
    <div className="bg-[#f7fbfb] h-full w-full md:pt-10 md:px-8 ">
      <div className="flex items-center gap-24 md:gap-4 bg-white md:bg-[#f7fbfb] px-5 py-10 md:py-0 md:px-0 ">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className=" bg-[#ECF6F3] rounded-full p-2 flex transition-all active:scale-90 text-slate-70"
        >
          <BsChevronLeft size={24} />
        </button>
        <p className="text-[#071827] font-semibold">
          {currency?.toUpperCase()} Wallet
        </p>
      </div>
      <StableWalletCard data={usdcDetails} />

      <div className="">
        {/* <Infor currency={currency} fee={fee} type={type} /> */}
      </div>

      <div className="mt-10">
        {usdcTransactions?.length > 0 ? (
          <WalletAccountTable
            // itemsPerPage={10}
            data={usdcTransactions}
            currency={currency}
          />
        ) : (
          <EmptyState />
        )}
      </div>
      <div>
        <AnimatePresence>
          {showConfirmationModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7"
                >
                  <div className="flex items-end justify-end pb-3">
                    <div
                      onClick={() => {
                        setShowConfirmationModal(false);
                      }}
                      className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
                    >
                      <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                        <IoMdClose size={29} className="" />
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center pb-2 absolute top-5">
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmationModal(false);
                        setShowModal(true);
                      }}
                      className="bg-[#f7fbfb] p-2 rounded-full cursor-pointer transition-all active:scale-90"
                    >
                      <BsChevronLeft />
                    </div>

                    <h1 className="font-bold text-xl text-[#071827]">
                      Confirmation
                    </h1>
                  </div>
                  <p className="text-sm text-[#5C6F7F] mb-9">
                    Fund your NGN wallet via direct bank transfer
                  </p>
                  <div className="flex items-center justify-between">
                    <h1 className="text-[#071827] font-bold">
                      You are transferring:
                    </h1>
                    <p className="text-[#0EB683] font-bold text-xl">NGN 0.00</p>
                  </div>
                  <div className="py-5">
                    <Notification
                      text={
                        "Once you have transferred the amount, click confirm below"
                      }
                      info={"To:"}
                    />
                  </div>

                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setShowConfirmationModal(false);
                      setShowSuccess(true);
                    }}
                    className="flex flex-row justify-between md:flex-col mb-4"
                  >
                    <button className="border border-[#0eb683] text-[#0eb683] md:p-3 rounded-md md:mb-4 cursor-pointer transition-all active:scale-90  py-3 px-8 hover:bg-[#0eb683] hover:text-white ">
                      Confirm
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <AnimatePresence>
          {showSuccess && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.1,
                },
              }}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white justify-center  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7"
                >
                  <div className="icon self-center">
                    <SuccessMobile width={"200px"} height={"200px"} />
                  </div>
                  <h1 className="text-3xl font-bold text-[#071827] self-center">
                    Wallet Funded
                  </h1>

                  <p className="text-sm text-[#5C6F7F] self-center">
                    Your wallet has been successfully funded
                  </p>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSuccess(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-28 py-3 self-center transition-all active:scale-90 "
                  >
                    Done
                  </button>
                </motion.div>
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      // scale:1,
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      // scale:10,
                      duration: 0.3,
                    },
                  }}
                  className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white w-full max-w-sm h-[450px] rounded-3xl p-5 "
                >
                  <div className="icon self-center">
                    <SuccessMobile width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-3xl font-bold text-[#071827] self-center">
                    Wallet Funded
                  </h1>

                  <p className="text-sm text-[#5C6F7F] self-center">
                    Your wallet has been successfully funded
                  </p>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSuccess(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90 "
                  >
                    Done
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        {showConvert && (
          <ConvertModal
            closeModal={setShowConvert}
            currency={currency.toUpperCase()}
          />
        )}
      </div>
    </div>
  );

  function information() {
    return (
      <div className="relative xxs:mt-4 md:mt-4">
        <div className="w-full h-48 rounded-md bg-[#F7FBFB] px-3 md:px-5 flex flex-col gap-2 md:gap-3 ">
          <div className="flex flex-col gap-2 mt-3">
            <p className="text-xs font-extrabold ">
              Copy:{" "}
              <span className="text-[#0eb683] font-light">{copyStatus}</span>
            </p>
          </div>

          <div className="mt-1 flex flex-col gap-3 relative">
            <div
              onClick={() =>
                copyToClipBoard(
                  getUserDetails?.data?.data?.stableWallet?.depositAddresses
                    ?.MATIC
                )
              }
              className="flex items-center  gap-1 border border-[#0eb683] w-20 py-1 px-3 rounded-md bg-white transition-all active:scale-90 cursor-pointer "
            >
              <div className="text-[#0eb683]">
                <AiOutlineCopy />
              </div>
              <p className="text-sm text-[#0eb683] font-medium">Copy</p>
            </div>
            <p className="xxs:hidden md:block text-[#5C6F7F] text-sm font-semibold">
              {truncateString(
                getUserDetails?.data?.data?.stableWallet?.depositAddresses
                  ?.MATIC,
                35
              )}
            </p>
            <p className="text-[#5C6F7F] text-sm font-semibold md:hidden">
              {truncateString(
                getUserDetails?.data?.data?.stableWallet?.depositAddresses
                  ?.MATIC,
                30
              )}
            </p>
            <div className="flex flex-col bg-white    p-1 rounded-md   w-full ">
              {/* <InfoIcon weight={"20px"} height={"20px"} /> */}
              <h1 className="font-bold text-[#071827]">Network:</h1>
              <p className="text-xs text-[#E01A00] font-light ">
                {" "}
                <span className="text-[#E01A00]">
                  Ensure you are transferring over the
                </span>{" "}
                Polygon (MATIC) Chain{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="w-1 h-20 bg-red-600 absolute top-10 rounded-full "></div>
      </div>
    );
  }
};

export default UsdcWallet;

export const Infor = ({ currency, fee, type }: { currency: string;  fee: string; type: string}) => {
  return (
    <div className="bg-[#D3FDE9] w-full p-5 rounded-lg">
      <h1 className="font-bold">Fees</h1>
      <p>
        {` Securely make transfers to ${currency.toUpperCase()} wallets with a ${type} fee of a ${fee} % of amount
        transferred`}
      </p>
    </div>
  );
};
