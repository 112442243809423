import React, { useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  FieldValues,
  SubmitErrorHandler,
  DeepMap,
  FieldError,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../../pages/onboarding/comp/Input";
// import { SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useCheckEmail, useRequestAccess } from "../../sevices/hooks/users";
import {  toast } from "sonner";
// import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  referralCode?: string;
};


const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phoneNo: yup.string().required("Phone Number is required"),
  // .matches(/^\+\d{1,3}\d{5,13}$/, "Invalid phone number"),
});

const LandingContact = () => {

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });



  const onError: SubmitErrorHandler<FieldValues> = (
    errors: DeepMap<FieldValues, FieldError>
  ) => {
    // console.error(errors);
  };


    const [loading, setLoading] = useState(false);

    const checkEmail = useCheckEmail();



    const watchAllFields = watch();
    // console.log(errors, ";;");
    // console.log(watchAllFields, ";watchAllFields;");
    const requestAccess = useRequestAccess();
    const navigate = useNavigate();

    useEffect(() => {
      // setSuccess(true);
    }, []);

    const onSubmit: SubmitHandler<FormData> = (data) => {
      // Handle form submission here
      // onRequestAccess(data);
      validateEmail(data);
    };

    const validateEmail = (data: FormData) => {
      setLoading(true);
      checkEmail
        .mutateAsync({ email: watchAllFields.email })
        .then((res) => {
          if (res.data === true) {
              toast("Request Denied, Email Address is already in use");
            setLoading(false);
            toast.warning("User with this email already exist");
          } else if (res.data === false) {
            onRequestAccess(data);
            // setEmailExist(false)
          }
        })
        .catch((e) => {
          setLoading(false);
          // alert("Specified Email is already in use")s
          reset();
        });
    };

    const isButtonDisabled = !(
      watchAllFields.firstName &&
      watchAllFields.lastName &&
      watchAllFields.email &&
      watchAllFields.phoneNo
    );

    const onRequestAccess = (data: FormData) => {
      setLoading(true);
      data["referralCode"] = "5R4YB";
      requestAccess
        .mutateAsync(data)
        .then((res) => {
          toast("Request Submitted, Please Check your email")
          // setSuccess(true);
          // setFailed(false);
          // setModal(true);
          setLoading(false);
          reset();
        })
        .catch((err) => {
          // setSuccess(false);
          // setFailed(true);
          // setModal(true);
          setLoading(false);
        });
    };

  return (
    <div className=" md:px-40 px-5 md:py-12 py-5 bg-[#000]  shadow-md mt-8 rounded-[32px]">
      <h2 className="bold text-[#fff] font-bold text-[20px] leading-[28px]  tracking-tight md:text-center text-left md:text-[73px] md:leading-[90px]">
        Request Access
      </h2>
      <p className="light md:text-[20px] text-[14px] text-[#fff] font-light md:leading-[30px] leading-[19.6px] mt-2 mb-8 md:text-center text-left 3xl:text-[33px] 3xl:leading-[40px] ">
        Discover the extensive capabilities of Trusted Transfers and begin
        enjoying the most <br /> exclusive access to foreign currency liquidity
        for remittances globally.
      </p>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="flex flex-col md:flex-row md:space-x-4">
          {/* First Name */}
          <div className="mb-4 md:w-1/2">
            {/* <label
              htmlFor="firstName"
              className="light block text-sm font-medium text-[#fff]"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              //@ts-ignore
              //   ref={register}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
            {errors?.firstName && (
              <p className="text-red-500 text-sm">{errors.firstName.message}</p>
            )} */}
            <Input
              label="First Name"
              type="text"
              name="firstName"
              required={false}
              bgColor={true}
              // value={""}
              errorMessages={""}
              onChange={(newValue: string) => setValue("firstName", newValue)}
            />
          </div>

          {/* Last Name */}
          <div className="mb-4 md:w-1/2">
            {/* <label
              htmlFor="lastName"
              className="light block text-sm font-medium text-[#fff]"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              //@ts-ignore
              //   ref={register}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
            {errors?.lastName && (
              <p className="text-red-500 text-sm">{errors.lastName.message}</p>
            )} */}
            <Input
              label="Last Name"
              type="text"
              name="lastName"
              required={false}
              bgColor={true}
              // value={""}
              errorMessages={""}
              onChange={(newValue: string) => setValue("lastName", newValue)}
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-4">
          {/* Email */}
          <div className="mb-4 md:w-1/2">
            {/* <label
              htmlFor="email"
              className="light block text-sm font-medium text-[#fff]"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              //@ts-ignore
              //   ref={register}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
            {errors?.email && (
              <p className="text-red-500 text-sm">{errors.email.message}</p>
            )} */}
            <Input
              label="Email"
              type="email"
              name="Email"
              required={false}
              bgColor={true}
              // value={""}
              errorMessages={""}
              onChange={(newValue: string) => setValue("email", newValue)}
            />
          </div>

          {/* Phone Number */}
          <div className="mb-4 md:w-1/2">
            {/* <label
              htmlFor="phoneNumber"
              className="light block text-sm font-medium text-[#fff]"
            >
              Phone Number
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              //@ts-ignore
              //   ref={register}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            />
            {errors?.phoneNumber && (
              <p className="text-red-500 text-sm">
                {errors.phoneNumber.message}
              </p>
            )} */}
            {/* <ReactPhoneInput

              containerStyle={{
                border: `0.5px solid #CBE0D6
                } `,
                padding: "5px",
                backgroundColor: "#fff",
                borderRadius: "4px",
                outline: "#0eb683",
              }}
              buttonStyle={{
                backgroundColor: "transparent",
                border: "none",
                borderRight: "1px solid #0EB683",
                margin: "10px 0px",
                padding: "0px 11px",
              }}
              inputStyle={{
                width: "100%",
                border: "none",
                padding: "0px 0px 0px 72px",
                backgroundColor: "#fff",
                height: 40,
              }}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              enableAreaCodes={true}
              country="ng"
              placeholder="Enter phone number"
              onChange={() => {}}
            /> */}
            <PhoneInput
              disabled={false}
              country={"ng"}
              onChange={(phone: string) => setValue("phoneNo", phone)}
              placeholder={""}
              value={watch("phoneNo")}
              inputProps={{
                autoFocus: false,
              }}
              inputStyle={{
                width: "100%",
                height: "50px",
                //   paddingLeft: "10px",
                //   paddingRight: "2.5px",
                //   marginTop: "0",
                backgroundColor: "#fff",
                border: "1px solid #D1D5DB",
                //   outline: "none",
                //   borderRadius: "4px",
                //   borderColor: "#D1D5DB",
                //   ":placeholder": {
                //     color: "#A0AEC0",
                //   },
                //   ":focus": {
                //     outlineColor: "#0eb683",
                //     boxShadow: "none",
                //     border: "1px solid #0eb683",
                //   },
              }}
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={isButtonDisabled || loading}
          className="light bg-[#0eb683] text-white py-3 px-4 rounded-3xl mt-3 focus:outline-none focus:border-[#0eb683] w-full"
        >
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default LandingContact;
