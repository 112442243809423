type TransactionStatus = "Completed" | "In-progress" | "Initiated" | "Failed";

export interface Transaction {
  transactionType: "Received" | "Transferred" | "Converted to NGN";
  amount: string;
  date: string;
  chain: string;
  transactionReference: string;
  status: TransactionStatus;
  action: string;
}

export const mockData: Transaction[] = [
  {
    transactionType: "Received",
    amount: "670.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Solana Chain",
    transactionReference: "DRAS4490-9187263570813323",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Received",
    amount: "400.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Polygon Chain",
    transactionReference: "DRAS4490-9187263570813323",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Transferred",
    amount: "126.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Tron Chain",
    transactionReference: "DRAS4490-9187263570813323",
    status: "In-progress",
    action: "View more",
  },
  {
    transactionType: "Transferred",
    amount: "70.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Solana Chain",
    transactionReference: "DRAS4490-9187263570813323",
    status: "Initiated",
    action: "View more",
  },
  {
    transactionType: "Received",
    amount: "5,200.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Polygon Chain",
    transactionReference: "DRAS4490-9187263570813323",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Converted to NGN",
    amount: "100.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Polygon Chain",
    transactionReference: "DRAS4490-9187263570813323",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Transferred",
    amount: "1,250.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Solana Chain",
    transactionReference: "DRAS4490-9187263570813323",
    status: "Failed",
    action: "View more",
  },
  {
    transactionType: "Received",
    amount: "890.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Ethereum Chain",
    transactionReference: "DRAS4490-9187263570813324",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Transferred",
    amount: "350.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Binance Smart Chain",
    transactionReference: "DRAS4490-9187263570813325",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Received",
    amount: "2,450.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Tron Chain",
    transactionReference: "DRAS4490-9187263570813326",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Transferred",
    amount: "580.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Polygon Chain",
    transactionReference: "DRAS4490-9187263570813327",
    status: "Failed",
    action: "View more",
  },
  {
    transactionType: "Received",
    amount: "1,780.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Solana Chain",
    transactionReference: "DRAS4490-9187263570813328",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Converted to NGN",
    amount: "120.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Polygon Chain",
    transactionReference: "DRAS4490-9187263570813329",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Transferred",
    amount: "690.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Ethereum Chain",
    transactionReference: "DRAS4490-9187263570813330",
    status: "In-progress",
    action: "View more",
  },
  {
    transactionType: "Received",
    amount: "930.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Binance Smart Chain",
    transactionReference: "DRAS4490-9187263570813331",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Transferred",
    amount: "410.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Tron Chain",
    transactionReference: "DRAS4490-9187263570813332",
    status: "Initiated",
    action: "View more",
  },
  {
    transactionType: "Received",
    amount: "1,200.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Polygon Chain",
    transactionReference: "DRAS4490-9187263570813333",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Converted to NGN",
    amount: "250.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Solana Chain",
    transactionReference: "DRAS4490-9187263570813334",
    status: "Completed",
    action: "View more",
  },
  {
    transactionType: "Transferred",
    amount: "900.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Ethereum Chain",
    transactionReference: "DRAS4490-9187263570813335",
    status: "Failed",
    action: "View more",
  },
  {
    transactionType: "Received",
    amount: "300.00 USDT",
    date: "August 12 2021 3:53pm",
    chain: "Binance Smart Chain",
    transactionReference: "DRAS4490-9187263570813336",
    status: "Completed",
    action: "View more",
  },
];
