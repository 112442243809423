import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useGenerateWallet, useUsers } from "../sevices/hooks/users";
import LoadingScreen from "../pages/Notifications/LoadingScreen2";
import Aside from "../components/dashboard-components/Aside";
import Nav from "../components/dashboard-components/Nav";
import { Toaster } from "sonner";
import { useRateStore, useUserState } from "../store";
import InviteUsers from "../components/base-components/InviteUsers";
import AutoLogout from "../pages/authentication/AutoLogout";
import PopOver from "../components/base-components/PopOver";
import create from "zustand";
import WelcomeScreen from "./WelcomeScreen";
import { useUserContext } from "../context/UserContext";
import { useRatesStore } from "../store/useRateStore";
import { useWalletStore, WalletData } from "../store/useStableCoin";

interface IPopups {
  showProfileDrop: boolean;

  setShowProfileDrop: (shouldShow: boolean) => void;
}

export const usePopup = create<IPopups>((set) => ({
  showProfileDrop: false,

  setShowProfileDrop: (shouldShow: boolean) =>
    set((state: any) => ({ showProfileDrop: shouldShow })),
}));

const DashBoardLayout = () => {
  const showProfileDrop = usePopup((state) => state.showProfileDrop);
  const setWallet = useWalletStore((state) => state.setWallet);
  const { data: walletData, refetch } = useGenerateWallet();
  const setShowProfileDrop = usePopup((state) => state.setShowProfileDrop);
  const rates = useRatesStore((state) => state.rates);
  const setUserDetails = useUserState((state) => state.setUserDetails);
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const { setUser } = useUserContext();

  const usdRate = rates.NGN;
  const eurRate = rates.EUR;
  const gbpRate = rates.GBP;

  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );
  const [open, setOpen] = useState(true);
  console.log(walletData, "walletData");
  useEffect(() => {
    let token = localStorage.getItem("accessToken") || "";
    let user_id = localStorage.getItem("user_id") || "";
    setToken(token);
    setUserId(user_id);
  }, [token, userId]);

  useEffect(() => {
    // console.log(getUserDetails);

    if (getUserDetails.status === "error") {
      navigate("/login");
    }

    if (getUserDetails.isError) {
      navigate("/login");
    }
    localStorage.setItem("user", JSON.stringify(getUserDetails.result?.data));
    setUserDetails(getUserDetails.result?.data);
    setUser(getUserDetails.result?.data);
  }, [
    getUserDetails.isError,
    getUserDetails.result?.data,
    getUserDetails.status,
    navigate,
    setUserDetails,
  ]);

  useEffect(() => {
    if (walletData?.data?.data) {
      const formattedWallet: WalletData = {
        usdcBalance: walletData?.data?.data?.usdcBalance,
        usdtBalance: walletData?.data?.data?.usdtBalance,
        addresses: {
          polygon: {
            usdc: walletData?.data?.data?.polygonUSDC,
            usdt: walletData?.data?.data?.polygonUSDT,
          },
          solana: {
            usdc: walletData?.data?.data?.solUSDC,
            usdt: walletData?.data?.data?.solUSDT,
          },
          tron: {
            usdc: walletData?.data?.data?.tronUSDC,
            usdt: walletData?.data?.data?.tronUSDT,
          },
        },
      };

      setWallet(formattedWallet);
    }
  }, [walletData, setWallet]);

  return (
    <LoadingScreen
      showLoading={
        getUserDetails.status === "loading" ||
        (!getUserDetails.isSuccess && getUserDetails.isError)
      }
    >
      <AutoLogout>
        {getUserDetails.result?.data.kycStatus !== "verified" ? (
          <WelcomeScreen />
        ) : (
          <div className="dashboard overflow-x-hidden h-screen">
            <Toaster position="top-right" richColors />
          <div className="flex flex-1">
                        <Aside
                buyUsd={usdRate?.buyRate}
                buyEur={eurRate?.buyRate}
                buyGbp={gbpRate?.buyRate}
              />
              <div className="grid grid-rows-[auto_1fr] w-full h-screen overflow-auto">
                <Nav
                  requiresLayout={true}
                  open={open}
                  setOpen={setOpen}
                  showProfileDrop={showProfileDrop}
                  //@ts-ignore
                  setShowProfileDrop={setShowProfileDrop}
                />

                <main className="overflow-x-hidden">
                  <Outlet
                    context={[
                      getUserDetails.result?.data,
                      getUserDetails.refetch,
                    ]}
                  />
                </main>
              </div>
            </div>
            <InviteUsers data={getUserDetails.result?.data} />
            {showProfileDrop && (
              <PopOver
                //@ts-ignore
                setShowProfileDrop={setShowProfileDrop}
                showProfileDrop={showProfileDrop}
              />
            )}
          </div>
        )}
      </AutoLogout>
    </LoadingScreen>
  );
};

export default DashBoardLayout;
