import axios from "axios";
import { PaginationParams } from "./hooks/wallets";
import {
  generateAndEncryptData,
  verifyEncryptionSetup,
} from "../utils/encryption/withCrypto";
// import { generateAndEncryptData } from "../utils/encryption";

export const BASEURL =
  process.env.REACT_APP_BASE_URL || "https://dev-backend.ttfcx.com";

export const api = {
  users: {
    getUser: (userId: string) => `/users/${userId}`,
    verify: "/users/login/verify",
    updateUser: (userId: string) => `/users/${userId}`,
    requestAccess: "/users/request-access",
    invitationVerify: "/users/invitations/verify",
    individualKyc: "/users/individual/kyc",
    users: "/users/part",
    businessKyc: "/users/business/kyc",
    businessAcc: "/users/business",
    userLogin: "/users/login",
    recoverPassword: "/users/recover-password",
    BankList: "/users/lbp/local/banks",
    resolveBankName: "/users/lbp/verifyAccountNumber",
    resetPassword: "/users/reset-password",
    usersInvitations: "/users/invitations",
    generateBeneficiaryForm: "/users/forms/beneficiary/generate",
    invitees: (userId: string) => `/users/${userId}/invitees`,
    otpRequest: (userId: string) => `/users/${userId}/request-otp`,
    signUpVerify: "/users/signup/verify",
    updateBank: "/users/update-bank",
    checkEmail: "/users/check-email",
    userDocs: "/users/documents",
    businessCorporate: "/users/business/corporate",
    businessPersons: "/users/business/contact",
    createSmileAccount: "/users/lbp/customer",
    verifySmile: "/users/individual",
    refreshKYC: "/users/individual/refresh",
    businessUserKYC: "/users/business/KYCP/kyc",
    updateUserInfo: (userId: string) => `/users/onboard/${userId}`,
    onboardingRefresh: "/users/individual/refresh",
    onboardingChecks: "/users/onboarding/continue",
    onboardingCompleted: "/users/onboarding/complete",
    businessAccountKYC: "/users/business-user",
    account: "/account",
    convert: (currency: string) => `/wallet/swap/${currency}`,
    preview: (currency: string) => `/wallet/preview-swap/${currency}`,
    generateWallet: "/users/wallet/generate",
    walletDetails: "/users/wallet/details",
    SingleWalletDetail: (currency: string) =>
      `/users/wallet/${currency}/details`,
    singleFee: (currency: string) => `/users/wallet/${currency}/fee`,
    withdraw: (currency: string) => `/users/wallet/${currency}/withdraw`,
  },
  metadata: {
    banks: "/metadata/banks",
    rates: (currency: string) => `/metadata/rates/per/${currency}`,
    ratesUsd: "/metadata/rates/usd",
    shutterScoreBanks: (country: string) =>
      `/metadata/shutterscore/banks/${country}`,
    shutterScoreResolve: "/metadata/shutterscore/banks/resolve",
    getBanks: (country: string) => `/metadata/yc/banks/${country}`,
    resolveBankName: "/metadata/yc/banks_account/resolve",
    getSellReason: "/metadata/get_available/reasons",
  },
  wallet: {
    AccountNgn: "/wallet/accounts/ngn",
    walletAccount: (currency: string) => `/wallet/accounts/${currency}`,
    AccountUsd: "/wallet/accounts/usd",
    AccActivity: "/wallet/accounts/activity/paginate",
    BuyUsd: "/wallet/buy/usd",
    Beneficiaries: "/wallet/beneficiaries",
    getStableBeneficiary: "/stablecoin/beneficiaries",
    getAStableBeneficiary: (userId: string) =>
      `/stablecoin/beneficiaries/${userId}`,
    stablecoinBeneficiary: "/stablecoin",
    deleteStableBeneficiary: (beneficiaryId: string) =>
      `/stablecoin/${beneficiaryId}`,
    ExternalBeneficiaries: "/external/beneficiaries",
    buyCurrency: (currencyCode: string) => `/wallet/buy/${currencyCode}`,
    beneficiaryIdentity: (beneficiaryId: string) =>
      `/wallet/beneficiaries/${beneficiaryId}`,
    transfersUsd: "/wallet/transfer/usd",
    accountWithdrawal: "/wallet/accounts/withdrawal",
    transactionReference: (reference: string) =>
      `/wallet/transactions/${reference}`,
    createNgnBeneficiary: "/wallet/ngn/beneficiaries",
    ngnBeneficiaryIdentity: (beneficiaryId: string) =>
      `/wallet/ngn/beneficiaries/${beneficiaryId}`,
    deleteBeneficiary: (beneficiaryId: string) =>
      `/wallet/beneficiaries/${beneficiaryId}`,
    sellFlow: (currency: string) => `/wallet/sell/${currency}`,
    walletBalance: (currency: string) => `/wallet/accounts/balance/${currency}`,
    activityByDate: "/wallet/transactions/dates",
    activityByRef: (reference: string) =>
      `/wallet/transactions/activity/${reference}`,
  },
  flag: {},
  tags: {
    ratesUsd: "/metadata/rates/usd",
    ratesEur: "/metadata/rates/eur",
    gbpRates: "/metadata/rates/gbp",
    usdcRates: "/metadata/rates/usdc",
    usdtRates: "/metadata/rates/usdt",
    swiftCountries: "/metadata/countries/swift",
    ibanCountries: "/metadata/countries/iban",
    abaCountries: "/metadata/countries/aba",
    states: "/metadata/states",
    abaDistricts: "/metadata/districts/aba",
    countries: "/users/lbp/countries",
    newStates: "/users/lbp/ng/states",
  },
  externals: {
    externalBeneficiary: "/external/beneficiaries",
  },
  admin: {
    fees: "/admin/fees",
    adminSettings: "/admin/settings",
    adminPayouts: (id: string) => `/admin/payouts/${id}`,
  },
  cards: {
    freeze: (id: string) => "/cards/freeze",
    unfreeze: (id: string) => "/cards/unfreeze",
    cardPrivateDetails: (id: string) => `/cards/private-details/${id}`,
    cardBalance: (id: string) => `/cards/balance/${id}`,
    requestCard: "/cards/request-virtual-card",
    cardDetails: (id: string) => `/cards/details/${id}`,
    cards: `/cards`,
    unLoadCard: "/cards/unload-card",
    load: "/cards/load-card",
    deleteCard: (id: string) => `/cards/details/${id}`,
  },
};

// export const makePostRequest = async (
//   data: any,
//   url: string,
//   includeAuthHeader: boolean = true
// ) => {
//   return await axios.post(`${BASEURL}${url}`, data, {
//     headers: {
//       "x-access-token": localStorage.getItem("accessToken") as string,
//     },
//   });
// };

// export const makeDeleteRequest = async <T = any>(
//   url: string,
//   includeAuthHeader: boolean = true
// ) => {
//   return await axios.delete<T>(`${BASEURL}${url}`, {
//     headers: {
//       "x-access-token": localStorage.getItem("accessToken") as string,
//     },
//   });
// };

// export const makePostRequestWithCustomHeaders = async (
//   data: any,
//   url: string,
//   headers?: any
// ) => {
//   const temp = await fetch(`${BASEURL}${url}`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "multipart/form-data",
//       Accept: "application/json",
//       "x-access-token": localStorage.getItem("accessToken") as string,
//       ...headers,
//     },
//     body: JSON.stringify(data),
//   });

//   if (!temp.ok) {
//     throw new Error(await temp.text());
//   }

//   const result = await temp.json();
//   return result;
// };

// export const makePatchRequest = async (
//   data: any,
//   url: string,
//   includeAuthHeader: boolean = true
// ) => {
//   return await axios.patch(`${BASEURL}${url}`, data, {
//     headers: {
//       "x-access-token": localStorage.getItem("accessToken") as string,
//     },
//   });
// };
// export const makePutRequest = async (
//   data: any,
//   url: string,
//   includeAuthHeader: boolean = true
// ) => {
//   return await axios.put(`${BASEURL}${url}`, data, {
//     headers: {
//       "x-access-token": localStorage.getItem("accessToken") as string,
//     },
//   });
// };

// // export const makeGetRequest = async <T = any>(
// //   url: string,
// //   includeAuthHeaders: boolean = true
// // ) => {
// //   const temp = await axios.get<T>(`${BASEURL}${url}`, {
// //     headers: {
// //       "x-access-token": localStorage.getItem("accessToken") as string,
// //     },
// //   });
// //   return temp;
// // };
// export const makeGetRequest = async <T = any>(
//   url: string,
//   params?: PaginationParams,
//   includeAuthHeaders: boolean = true
// ) => {
//   const response = await axios.get<T>(`${BASEURL}${url}`, {
//     params,
//     headers: {
//       "x-access-token": localStorage.getItem("accessToken") as string,
//     },
//   });
//   return response;
// };

// export const makePostRequest = async (
//   data: any,
//   url: string,
//   includeAuthHeader: boolean = true
// ) => {
//   const { dt, ky } = await generateAndEncryptData(
//     JSON.stringify(data)
//   );

//   return await axios.post(
//     `${BASEURL}${url}`,
//     {
//       dt,
//       ky,
//     },
//     {
//       headers: {
//         "x-access-token": localStorage.getItem("accessToken") as string,
//       },
//     }
//   );
// };

export const makePostRequest = async (
  data: any,
  url: string,
  includeAuthHeader: boolean = true
) => {
  try {
    // Verify encryption setup first
    verifyEncryptionSetup();

    const { dt, ky } = await generateAndEncryptData(
      JSON.stringify(data)
    );

    // console.log("Data encrypted successfully");
    // console.log("Making request to:", `${BASEURL}${url}`);

    const response = await axios.post(
      `${BASEURL}${url}`,
      {
        dt,
        ky,
      },
      {
        headers: {
          "Content-Type": "application/json",
          ...(includeAuthHeader && {
            "x-access-token": localStorage.getItem("accessToken") as string,
          }),
        },
      }
    );

    return response;
  } catch (error) {
    // console.error("Request failed:", error);
    //@ts-ignore
    if (error.message.includes("Public key")) {
      throw new Error(
        "Encryption configuration error. Check environment setup."
      );
    }
    throw error;
  }
};

export const makePostRequestWithCustomHeaders = async (
  data: any,
  url: string,
  headers?: any
) => {
  const { dt, ky } = await generateAndEncryptData(
    JSON.stringify(data)
  );

  const temp = await fetch(`${BASEURL}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-access-token": localStorage.getItem("accessToken") as string,
      ...headers,
    },
    body: JSON.stringify({
      dt,
      ky,
    }),
  });

  if (!temp.ok) {
    throw new Error(await temp.text());
  }

  const result = await temp.json();
  return result;
};

export const makePatchRequest = async (
  data: any,
  url: string,
  includeAuthHeader: boolean = true
) => {
  const { dt, ky } = await generateAndEncryptData(
    JSON.stringify(data)
  );

  return await axios.patch(
    `${BASEURL}${url}`,
    {
      dt,
      ky,
    },
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken") as string,
      },
    }
  );
};

export const makePutRequest = async (
  data: any,
  url: string,
  includeAuthHeader: boolean = true
) => {
  const { dt, ky } = await generateAndEncryptData(
    JSON.stringify(data)
  );

  return await axios.put(
    `${BASEURL}${url}`,
    {
      dt,
      ky,
    },
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken") as string,
      },
    }
  );
};

export const makeDeleteRequest = async <T = any>(
  url: string,
  includeAuthHeader: boolean = true
) => {
  // Delete requests typically don't have a body, so no encryption needed
  return await axios.delete<T>(`${BASEURL}${url}`, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
};

export const makeGetRequest = async <T = any>(
  url: string,
  params?: PaginationParams,
  includeAuthHeaders: boolean = true
) => {
  // GET requests don't have a body, so we encrypt the query params if they exist
  let finalUrl = `${BASEURL}${url}`;

  if (params) {
    const { dt, ky } = await generateAndEncryptData(
      JSON.stringify(params)
    );
    // Append encrypted params as query parameters
    finalUrl += `?dt=${encodeURIComponent(
      dt
    )}&ky=${encodeURIComponent(ky)}`;
  }

  const response = await axios.get<T>(finalUrl, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken") as string,
    },
  });
  return response;
};
