import React, { useEffect, useState } from "react";
import ShareIcon from "../../assets/iconsComponents/ShareIcon";
import { TabSelector } from "../widgets/TabSelector";
import { TabPanel, useTabs } from "../widgets/TabWidget";
import tron from "../../assets/coins/tron.png";
import polygon from "../../assets/coins/Polygon.png";
import Solana from "../../assets/coins/solana.png";
import ethereum from "../../assets/coins/eth.png";
import { useCreateStableBeneficiary } from "../../sevices/hooks/wallets";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VscLoading } from "react-icons/vsc";
import { toast } from "sonner";
import { AnimatePresence, motion } from "framer-motion";
import LinkIcon from "../../assets/iconsComponents/LinkIcon";
import { IoMdClose, IoMdCopy } from "react-icons/io";
import ReactLoading from "react-loading";

const AddStableCoinComponent = () => {
    const [shareFormModal, setShareFormModal] = useState(false);
  const [coinTab, setCoinTab] = useTabs(["USDC", "USDT"]);
  const createBeneficiary = useCreateStableBeneficiary();
 const [copyStatus, setCopyStatus] = useState("");
 const [formLink, setFormLink] = useState(false);
 const [loading, setLoading] = useState(false);
 const [formToken, setFormToken] = useState<any>();
  const [userId, setUserId] = useState("");
  useEffect(() => {
    let user_id = localStorage.getItem("user_id") || "";
    setUserId(user_id);
  }, []);
  const [selectedCurrency, setSelectedCurrency] = useTabs([
    "Tron",
    "Polygon",
    "Solana",
    "Ethereum",
  ]);

  const validationSchema = Yup.object().shape({
    beneficiaryAddress: Yup.string().required(
      "Beneficiary USDT Address is required"
    ),
    beneficiaryName: Yup.string().required("Beneficiary Name is required"),
    alias: Yup.string().required("Alias is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    const formData = {
      ...data,
      coin: coinTab,
      chain: selectedCurrency,
      userId: userId,
    };
    createBeneficiary
      .mutateAsync(formData)
      .then((result: any) => {})
      .catch((error: any) => {});
    // console.log(formData);
  };
  const onHandleSubmit = (data: any) => {
    setLoading(true);
    const formData = {
      ...data,
      coin: coinTab,
      chain: selectedCurrency,
      userId: userId,
    };
    createBeneficiary
      .mutateAsync(formData)
      .then((result: any) => {
        reset();
        setLoading(false);
        toast("Beneficiary created successfully");
      })
      .catch((error: any) => {
        toast.warning(error.message);
      });
    // console.log(formData);
  };
 const copyToClipBoard = (token: string) => {
   navigator.clipboard.writeText(token);
   setCopyStatus("Copied to clipbaord");
   let timeout = setTimeout(() => {
     setCopyStatus("");
     clearTimeout(timeout);
   }, 1500);
   setFormLink(false);
   setFormToken(undefined);
 };

//  const generateForm = () => {
//    setLoading(true);
//    beneficiaryForm
//      .mutateAsync({})
//      .then(({ data }) => {
//        setLoading(false);
//        setFormToken(data);
//        setFormLink(true);
//      })
//      .catch((e) => {});
//  };
  return (
    <div>
      <nav className="flex md:gap-32 items-center justify-between md:justify-normal">
        <TabSelector
          className={`cursor-pointer relative bg-transparent ${
            coinTab === "USDC"
              ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
              : ""
          }`}
          isActive={coinTab === "USDC"}
          onClick={() => setCoinTab("USDC")}
        >
          <div className="flex items-center gap-2">
            <div className=" w-7 h-7 rounded-full bg-white border border-[#0eb683] flex items-center "></div>
            <h1
              className={`font-bold xxs:text-sm md:text-base ${
                coinTab === "USDC" ? "text-[#0eb683]" : "text-[#5C6F7F]"
              }`}
            >
              USDC
            </h1>
          </div>
        </TabSelector>
        <TabSelector
          className={`cursor-pointer relative bg-transparent ${
            coinTab === "USDT"
              ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
              : ""
          }`}
          isActive={coinTab === "USDT"}
          onClick={() => setCoinTab("USDT")}
        >
          <div className="flex gap-2 items-center ">
            <div className=" w-7 h-7 rounded-full bg-white border border-[#0eb683] "></div>
            <h1
              className={` font-bold xxs:text-sm md:text-base ${
                coinTab === "USDT" ? "text-[#0eb683]" : "text-[#5C6F7F]"
              }`}
            >
              USDT
            </h1>
          </div>
        </TabSelector>
      </nav>
      <main>
        <TabPanel hidden={coinTab !== "USDC"} className=" mt-8 ">
          <div className="card mt-8 md:bg-white md:max-w-lg md:flex  md:flex-col md:pl-8 md:pb-8 md:mt-12 md:pt-8 rounded-xl">
            <header className="flex items-center justify-between mb-6 ">
              <h1 className="text-[#071827] font-bold xxs:text-base md:text-lg">
                Beneficiary Information
              </h1>
              <div className="text-[#0eb683] hidden md:flex md:justify-center md:gap-1 md:border-[#0eb683] md:p-2 font-medium md:mr-8 md:border rounded-[32px] cursor-pointer transition-all active:scale-90">
                <ShareIcon height="17px" width="17px" />
                <p className="text-sm">Share Form</p>{" "}
              </div>
              <div className="bg-[#0eb683]  md:hidden border border-[#0eb683] p-2 font-medium text-[#fff] cursor-pointer transition-all active:scale-90 rounded-[32px] shadow-md">
                <p className="text-sm">Share Form</p>{" "}
              </div>
            </header>
            <nav className=" flex items-center pb-5 justify-between  pr-5  ">
              <TabSelector
                className={` flex items-center md:gap-2 cursor-pointer relative bg-transparent mr-4 md:mr-0 ${
                  selectedCurrency === "Polygon"
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={selectedCurrency === "Polygon"}
                onClick={() => {
                  setSelectedCurrency("Polygon");
                  // setTransactionAmount("");
                }}
              >
                <div className="flex items-center gap-2">
                  <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                  <img
                    src={polygon}
                    alt="gbpFlag"
                    className="w-7 hidden md:block"
                  />
                  <h1 className="text-[#5C6F7F] md:hidden">Polygon</h1>
                </div>
                <h1 className="text-[#5C6F7F] md:font-bold  hidden md:block">
                  Polygon
                </h1>
              </TabSelector>

              <TabSelector
                className={` flex items-center gap-2 cursor-pointer relative bg-transparent ${
                  selectedCurrency === "Solana"
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={selectedCurrency === "Solana"}
                onClick={() => {
                  setSelectedCurrency("Solana");
                  // setTransactionAmount("");
                }}
              >
                <div className="flex items-center gap-2 ">
                  <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                  <img
                    src={Solana}
                    alt="eurFlag"
                    className="w-7 hidden md:block"
                  />
                  <h1 className="text-[#5C6F7F]  md:hidden">Solana</h1>
                </div>
                <h1 className="text-[#5C6F7F] md:font-bold  hidden md:block">
                  Solana
                </h1>
              </TabSelector>
              <TabSelector
                className={` flex items-center md:gap-2 cursor-pointer relative bg-transparent mr-4 md:mr-0 text-[] ${
                  selectedCurrency === "Ethereum"
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={selectedCurrency === "Ethereum"}
                onClick={() => setSelectedCurrency("Ethereum")}
              >
                <div className="flex items-center gap-2">
                  <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                  <img
                    src={ethereum}
                    alt="usFlag"
                    className="w-7 hidden md:block"
                  />
                  <h1 className="text-[#5C6F7F] md:font-bold md:hidden">
                    Ethereum
                  </h1>
                </div>
                <h1 className="text-[#5C6F7F] md:font-bold hidden md:block">
                  Ethereum
                </h1>
              </TabSelector>
            </nav>
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onHandleSubmit)}
            >
              <div className="mid relative">
                <input
                  type="text"
                  {...register("beneficiaryAddress")}
                  className={`py-3 px-4 w-full bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm ${
                    errors.beneficiaryAddress ? "border-red-500" : ""
                  }`}
                  placeholder="Beneficiary USDT Address"
                />
                {errors.beneficiaryAddress && (
                  <span className="text-red-500 text-sm block">
                    {errors.beneficiaryAddress.message}
                  </span>
                )}
              </div>

              <div className="mid relative">
                <input
                  type="text"
                  {...register("beneficiaryName")}
                  className={`py-3 px-4 w-full bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm ${
                    errors.beneficiaryName ? "border-red-500" : ""
                  }`}
                  onKeyDown={(e) => {
                    if (e.key >= "0" && e.key <= "9") {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Beneficiary Name"
                />
                {errors.beneficiaryName && (
                  <span className="text-red-500 text-sm block">
                    {errors.beneficiaryName.message}
                  </span>
                )}
              </div>

              <div className="mid relative">
                <input
                  type="text"
                  {...register("alias")}
                  className={`py-3 px-4 w-full bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm ${
                    errors.alias ? "border-red-500" : ""
                  }`}
                  placeholder="Alias"
                />
                {errors.alias && (
                  <span className="text-red-500 text-sm block">
                    {errors.alias.message}
                  </span>
                )}
              </div>

              <button
                type="submit"
                className="bg-[#0eb683] text-white p-3 mt-10 max-w-md rounded-[32px] transition-all active:scale-90 flex justify-center items-center"
              >
                {loading ? (
                  <VscLoading size={18} className="animate-spin mr-2 " />
                ) : (
                  "Save Beneficiary"
                )}
              </button>
            </form>
          </div>
        </TabPanel>
        <TabPanel hidden={coinTab !== "USDT"} className=" mt-8 ">
          <div className="card mt-8 md:bg-white md:max-w-lg md:flex  md:flex-col md:pl-8 md:pb-8 md:mt-12 md:pt-8 rounded">
            <header className="flex items-center justify-between mb-6 ">
              <h1 className="text-[#071827] font-bold xxs:text-base md:text-lg">
                Beneficiary Information
              </h1>
              <div className="text-[#0eb683] hidden md:flex md:justify-center md:gap-1 md:border-[#0eb683] md:p-2 font-medium md:mr-8 md:border rounded-[32px] cursor-pointer transition-all active:scale-90">
                <ShareIcon height="17px" width="17px" />
                <p className="text-sm">Share Form</p>{" "}
              </div>
              <div className="bg-[#0eb683]  md:hidden border border-[#0eb683] p-2 font-medium text-[#fff] cursor-pointer transition-all active:scale-90 rounded-[32px] shadow-md">
                <p className="text-sm">Share Form</p>{" "}
              </div>
            </header>
            <nav className=" flex items-center pb-5 justify-between  pr-5  ">
              <TabSelector
                className={` flex items-center md:gap-2 cursor-pointer relative bg-transparent mr-4 md:mr-0 ${
                  selectedCurrency === "Polygon"
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={selectedCurrency === "Polygon"}
                onClick={() => {
                  setSelectedCurrency("Polygon");
                  // setTransactionAmount("");
                }}
              >
                <div className="flex items-center gap-2">
                  <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                  <img
                    src={polygon}
                    alt="gbpFlag"
                    className="w-7 hidden md:block"
                  />
                  <h1 className="text-[#5C6F7F] md:hidden">Polygon</h1>
                </div>
                <h1 className="text-[#5C6F7F] md:font-bold  hidden md:block">
                  Polygon
                </h1>
              </TabSelector>

              <TabSelector
                className={` flex items-center gap-2 cursor-pointer relative bg-transparent ${
                  selectedCurrency === "Solana"
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={selectedCurrency === "Solana"}
                onClick={() => {
                  setSelectedCurrency("Solana");
                  // setTransactionAmount("");
                }}
              >
                <div className="flex items-center gap-2 ">
                  <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                  <img
                    src={Solana}
                    alt="eurFlag"
                    className="w-7 hidden md:block"
                  />
                  <h1 className="text-[#5C6F7F]  md:hidden">Solana</h1>
                </div>
                <h1 className="text-[#5C6F7F] md:font-bold  hidden md:block">
                  Solana
                </h1>
              </TabSelector>
              <TabSelector
                className={` flex items-center md:gap-2 cursor-pointer relative bg-transparent mr-4 md:mr-0 text-[] ${
                  selectedCurrency === "Tron"
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={selectedCurrency === "Tron"}
                onClick={() => setSelectedCurrency("Tron")}
              >
                <div className="flex items-center gap-2">
                  <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                  <img
                    src={tron}
                    alt="usFlag"
                    className="w-7 hidden md:block"
                  />
                  <h1 className="text-[#5C6F7F] md:font-bold md:hidden">
                    Tron
                  </h1>
                </div>
                <h1 className="text-[#5C6F7F] md:font-bold hidden md:block">
                  Tron
                </h1>
              </TabSelector>
            </nav>

            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mid relative">
                <input
                  type="text"
                  {...register("beneficiaryAddress")}
                  className={`py-3 px-4 w-full bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm ${
                    errors.beneficiaryAddress ? "border-red-500" : ""
                  }`}
                  placeholder="Beneficiary USDT Address"
                />
                {errors.beneficiaryAddress && (
                  <span className="text-red-500 text-sm block">
                    {errors.beneficiaryAddress.message}
                  </span>
                )}
              </div>

              <div className="mid relative">
                <input
                  type="text"
                  {...register("beneficiaryName")}
                  className={`py-3 px-4 w-full bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm ${
                    errors.beneficiaryName ? "border-red-500" : ""
                  }`}
                  onKeyDown={(e) => {
                    if (e.key >= "0" && e.key <= "9") {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Beneficiary Name"
                />
                {errors.beneficiaryName && (
                  <span className="text-red-500 text-sm block">
                    {errors.beneficiaryName.message}
                  </span>
                )}
              </div>

              <div className="mid relative">
                <input
                  type="text"
                  {...register("alias")}
                  className={`py-3 px-4 w-full bg-[#F2FAF7] md:max-w-md border border-[#E2E9F0] rounded-md  outline-[#0eb683] placeholder:text-sm ${
                    errors.alias ? "border-red-500" : ""
                  }`}
                  placeholder="Alias"
                />
                {errors.alias && (
                  <span className="text-red-500 text-sm block">
                    {errors.alias.message}
                  </span>
                )}
              </div>

              <button
                type="submit"
                className="bg-[#0eb683] text-white p-3 mt-10 max-w-md rounded-[32px] transition-all active:scale-90 flex justify-center items-center"
              >
                {loading ? (
                  <VscLoading size={18} className="animate-spin mr-2 " />
                ) : (
                  "Save Beneficiary"
                )}
              </button>
            </form>
          </div>
        </TabPanel>

        <AnimatePresence>
          {shareFormModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 "
                >
                  <div className="flex items-end justify-end pb-3">
                    <div
                      onClick={() => {
                        setShareFormModal(false);
                      }}
                      className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
                    >
                      <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                        <IoMdClose size={29} className="" />
                      </span>
                    </div>
                  </div>
                  <div className=" ">
                    <h1 className="font-bold text-xl text-[#071827] pb-3">
                      Share form with an external beneficiary
                    </h1>
                    <p className="text-[#5C6F7F] text-base pb-4">
                      You can either generate a link to share or email the
                      beneficiary to supply their account details
                    </p>

                    {formLink ? (
                      <>
                        <div className="flex items-center gap-4">
                          <div className="flex  items-center justify-center tracking-tighter  rounded-lg text-[#0eb683] border border-[#E2E9F0]] md:w-72 h-11  ">
                            <LinkIcon height="25px" width="30px" />
                            <input
                              type="text"
                              disabled
                              value={` ${window.location.origin}/external/beneficiary-form?ref=${formToken?.ref}&token=${formToken?.token}`}
                              className="w-full  mr-3 outline-none bg-white "
                            />
                          </div>
                          <button
                            disabled={!formToken}
                            onClick={() => {
                              // copyToClipBoard(
                              //   ` ${window.location.origin}/external/beneficiary?ref=${beneficiaryForm?.data?.data?.ref}&token=${beneficiaryForm?.data?.data?.token}`
                              // );
                            }}
                            className="border shadow-md shadow-gray-400/50 select-none tracking-tighter border-[#0eb683] bg-[#0eb683] flex gap-1  items-center rounded-lg transition-all active:scale-90 px-5 py-2"
                          >
                            <IoMdCopy color="#fff" />
                            <p className="text-[#fff] tracking-tighter">copy</p>
                          </button>
                        </div>
                        <button className="flex items-center justify-center rounded-lg border border-[#0eb683] w-full p-3 mt-5">
                          <LinkIcon height="25px" width="30px" />
                          <p className="text-[#0eb683]">Share link</p>
                        </button>
                        <div className="flex items-center gap-4 justify-center my-7">
                          <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                          <p className="text-[#071827] font-semibold">Or</p>
                          <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                        </div>

                        <div className="flex items-center gap-4">
                          <div className="flex  items-center justify-center tracking-tighter  rounded-lg text-slate-800 border border-[#E2E9F0]] md:w-72 h-11  relative">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="w-full pl-2  mr-3 outline-none bg-white "
                              placeholder="Beneficiary Email"
                            />
                            <span className="text-red-600 absolute top-3 left-1">
                              *
                            </span>
                          </div>
                          <button
                            disabled={true}
                            className="border shadow-md shadow-gray-400/50 select-none tracking-tighter border-[#0eb683] bg-[#0eb683] flex gap-1  items-center rounded-lg transition-all active:scale-90 px-5 py-2 disabled:cursor-not-allowed disabled:bg-[#8fc0ad]"
                          >
                            <p className="text-[#fff] tracking-tighter text-sm">
                              share
                            </p>
                          </button>
                        </div>

                        <p className="text-white px-2 bg-green-300 rounded-full absolute top-11">
                          {copyStatus}
                        </p>
                        <div className="flex"></div>
                      </>
                    ) : (
                      <>
                        <div
                          // onClick={generateForm}
                          className="flex items-center justify-center gap-2 rounded-lg text-[#0eb683] border border-[#0eb683] w-full py-3 transition-all active:scale-90 cursor-pointer shadow-md shadow-gray-400/50 select-none tracking-tighter"
                        >
                          <LinkIcon height="25px" width="30px" />
                          <p className="xxs:text-sm md:text-base">
                            {" "}
                            Click here to generate a shareable link!
                          </p>
                          {/* <button className=" ">
                            {formToken
                              ? ` ${window.location.origin}/external/beneficiary-form?ref=${formToken?.ref}&token=${formToken?.token}`
                              : "Generate a shareable link!"
                              ? loading &&
                                // <ReactLoading
                                //   type="bubbles"
                                //   color="#0eb683"
                                //   height="20px"
                                //   width="20px"
                                // />
                                "loading.."
                              : formToken}
                          </button> */}
                          <button className="your-class">
                            {formToken ? (
                              `${window.location.origin}/external/beneficiary-form?ref=${formToken?.ref}&token=${formToken?.token}`
                            ) : loading ? (
                              <ReactLoading
                                type="bubbles"
                                color="#0eb683"
                                height="20px"
                                width="20px"
                              />
                            ) : (
                              "Generate a shareable link!"
                            )}
                          </button>
                        </div>

                        <div className="flex items-center gap-4 justify-center my-7">
                          <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                          <p className="text-[#071827] font-semibold">Or</p>
                          <div className="h-[1px] w-44 bg-[#0eb683]"></div>
                        </div>

                        <div className="flex items-center gap-4">
                          <div className="flex  items-center justify-center tracking-tighter  rounded-lg text-slate-800 border border-[#E2E9F0]] md:w-72 h-11  relative">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="w-full pl-2  mr-3 outline-none bg-white "
                              placeholder="Beneficiary Email"
                            />
                            <span className="text-red-600 absolute top-3 left-1">
                              *
                            </span>
                          </div>
                          <button
                            disabled={true}
                            className="border shadow-md shadow-gray-400/50 select-none tracking-tighter border-[#0eb683] bg-[#0eb683] flex gap-1  items-center rounded-lg transition-all active:scale-90 px-5 py-2 disabled:cursor-not-allowed disabled:bg-[#8fc0ad]"
                          >
                            <p className="text-[#fff] tracking-tighter text-sm">
                              share
                            </p>
                          </button>
                        </div>

                        <p className="text-white px-2 bg-green-300 rounded-full absolute top-11">
                          {copyStatus}
                        </p>
                        <div className="flex"></div>
                      </>
                    )}
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </main>
    </div>
  );
};

export default AddStableCoinComponent;
