import React, { useState, useMemo } from "react";
import { Transaction } from "../transaction-component/TransactionTable";
import { AiOutlineMinus } from "react-icons/ai";
import { MdAdd } from "react-icons/md";
import { FiSend, FiChevronDown, FiChevronUp, FiDownload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "../../pages/dashboard/Withdrawals";
import { capFirst } from "../../helper";
const moment = require("moment");

interface ITrx {
  data: Transaction[];
  currency?: string;
}

const MobileView = ({ data }: { data: Transaction[] }) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const transactionType = (type: string) => {
    let icon;
    switch (type) {
      case "debit":
        icon = (
          <AiOutlineMinus
            size={30}
            color={"#FA0204"}
            className="font-extrabold"
          />
        );
        break;
      case "credit":
        icon = <MdAdd size={30} color={"#0EB683"} className="font-extrabold" />;
        break;
      default:
        icon = (
          <FiSend size={30} color={"#0eb683"} className="font-extrabold" />
        );
        break;
    }
    return icon;
  };

  const currencySymbol = (currency: string, amount: string) => {
    const formatterMap: { [key: string]: string } = {
      NGN: "₦",
      USD: "$",
      EUR: "€",
      GBP: "£",
      USDC: "USDC",
      USDT: "USDT",
    };

    const symbol = formatterMap[currency?.toUpperCase()] || "";
    return symbol ? `${symbol} ${amount}` : `${amount} ${currency}`;
  };

  const mapping = (value: string) => {
    const colorMap = {
      processing: { bg: "#e5e5e5", color: "#5C6F7F" },
      pending: { bg: "#fff2eb", color: "#FF7F36" },
      successful: { bg: "#e2fef0", color: "#0EB683" },
      cancelled: { bg: "#e2fef0", color: "#f51505" },
      failed: { bg: "#f51505", color: "#e2fef0" },
    };

    const status = value?.toLowerCase();
    const { bg, color } = colorMap[status as keyof typeof colorMap] || {};

    const stats = (status: string) => {
      return status.replace(/(^|\s|-)(\w)/g, (match, p1, p2) => {
        return p1 + p2.toUpperCase();
      });
    };

    return (
      <span
        className="inline-block px-3 py-1 rounded-full text-sm font-bold mt-2"
        style={{ backgroundColor: bg, color }}
      >
        {stats(status)}
      </span>
    );
  };

  const toggleExpand = (transactionId: string) => {
    setExpandedRows((prev) =>
      prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId]
    );
  };

  const renderExpandedDetails = (transaction: Transaction) => {
    if (!transaction.transactionPayload) return null;

    const details = [
      { label: "Transaction Ref", value: transaction.transactionRef },
      { label: "Narration", value: transaction.narration },
      { label: "Status", value: transaction.status },
      {
        label: "Amount Sent",
        value: currencySymbol(
          transaction.transactionPayload.fromCurrency,
          numberWithCommas(Number(transaction.transactionPayload.fromAmount))
        ),
      },
      {
        label: "Date",
        value: moment(transaction.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
      },
      {
        label: "Amount Received",
        value: currencySymbol(
          transaction.transactionPayload.toCurrency,
          numberWithCommas(Number(transaction.transactionPayload.toAmount))
        ),
      },
      {
        label: "Transaction Fee",
        value: transaction.transactionPayload.fee
          ? currencySymbol(
              transaction.transactionPayload.fromCurrency,
              numberWithCommas(Number(transaction.transactionPayload.fee))
            )
          : "No fee",
      },
    ];

    return (
      <div className="bg-gray-50 p-4 border-t">
        <div className="space-y-4">
          {details.map((detail, index) => (
            <div key={index} className="flex justify-between items-center">
              <span className="text-gray-500 text-sm font-bold">
                {detail.label}:
              </span>
              <span className="text-gray-900 font-bold text-sm text-right">
                {detail.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const processedTransactions = useMemo(
    () =>
      [...data]
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
        .slice(0, 15),
    [data]
  );

  return (
    <div className="bg-white">
      {processedTransactions.map((trx, index) => {
        const icon = transactionType(trx?.type);
        const amountF = numberWithCommas(Number(trx?.amount));
        const amount = currencySymbol(trx?.currency, amountF);

        const createdDate = new Date(trx?.createdAt);
        const now = new Date();
        const hoursSinceCreation =
          (now.getTime() - createdDate.getTime()) / (1000 * 60 * 60);
        const isNew = hoursSinceCreation <= 70;
        const formatDate = moment(createdDate);
        const Cdate = formatDate.format("lll");

        const isExpanded = expandedRows.includes(trx.id);

        return (
          <div key={index} className="border-b border-slate-100">
            <div className="py-4 px-2 bg-[#fff] w-full flex items-center">
              <div className="flex-1 mr-2">
                <div
                  className={`text-lg h-[3rem] w-[3rem] border rounded-full flex items-center justify-center ${
                    trx?.type === "debit"
                      ? "bg-[#FEECE2] border-[#FEECE2]"
                      : "bg-[#E2FEF0] border-[#E2FEF0]"
                  }`}
                >
                  {icon}
                </div>
              </div>
              <div className="flex-[5]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <span className="text-[#5C6F7F]">
                      {trx?.currency} {capFirst(trx?.type)}
                    </span>
                    {isNew && (
                      <div className="bg-[#E2FEF0] text-xs px-2 rounded-full text-[#0EB683]">
                        New
                      </div>
                    )}
                  </div>
                  <span className=" font-semibold text-[#0eb683]">
                    {amount}
                  </span>
                </div>

                <div className="flex items-center justify-between mt-2">
                  <span
                    className={`
                px-2 py-1 rounded-full text-xs
                ${
                  isNew
                    ? "bg-green-100 text-green-700"
                    : "bg-gray-100 text-gray-600"
                }
              `}
                  >
                    {moment(Cdate).fromNow()}
                  </span>
                  {/* <span className="text-sm text-gray-500">{Cdate}</span> */}
                  <button
                    onClick={() => toggleExpand(trx.id)}
                    className="text-sm text-[#0eb683] flex items-center"
                  >
                    {isExpanded ? (
                      <>
                        Hide <FiChevronUp className="ml-1" />
                      </>
                    ) : (
                      <>
                        View <FiChevronDown className="ml-1" />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
            {isExpanded && renderExpandedDetails(trx)}
          </div>
        );
      })}
    </div>
  );
};

const WalletAccountTable = ({ data, currency }: ITrx) => {
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  // Sort and limit to most recent 15 transactions
  const processedTransactions = useMemo(() => {
    const sortedData = [...data].sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return sortConfig.direction === "desc" ? dateB - dateA : dateA - dateB;
    });

    return sortedData.slice(0, 15);
  }, [data, sortConfig]);

  const toggleRowExpand = (transactionId: string) => {
    setExpandedRows((prev) =>
      prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId]
    );
  };

  console.log(data, "data");

  const currencyFormatter = (currency: string, amount: string) => {
    const formatterMap: { [key: string]: string } = {
      NGN: "₦",
      USD: "$",
      EUR: "€",
      GBP: "£",
      USDC: "USDC",
      USDT: "USDT",
    };

    const symbol = formatterMap[currency?.toUpperCase()] || "";
    return symbol ? `${symbol} ${amount}` : `${amount} ${currency}`;
  };

  const renderTransactionIcon = (type: string) => {
    const iconClasses =
      "w-10 h-10 rounded-full flex items-center justify-center";
    switch (type) {
      case "debit":
        return (
          <div className={`${iconClasses} bg-[#FEECE2] text-[#FA0204]`}>
            <AiOutlineMinus size={25} />
          </div>
        );
      case "credit":
        return (
          <div className={`${iconClasses} bg-[#E2FEF0] text-[#0EB683]`}>
            <MdAdd size={20} />
          </div>
        );
      default:
        return (
          <div className={`${iconClasses} bg-blue-100 text-blue-500`}>
            <FiSend size={20} />
          </div>
        );
    }
  };

  const renderExpandedDetails = (transaction: Transaction) => {
    if (!transaction.transactionPayload) {
      return null;
    }

    const details = [
      { label: "Transaction Ref", value: transaction.transactionRef },
      { label: "Narration", value: transaction.narration },
      { label: "Status", value: transaction.status },
      {
        label: "Amount Sent",
        value: `${currencyFormatter(
          transaction.transactionPayload.fromCurrency,
          numberWithCommas(Number(transaction.transactionPayload.fromAmount))
        )}`,
      },
      {
        label: "Date",
        value: moment(transaction.createdAt).format("MMMM Do YYYY, h:mm:ss a"),
      },
      {
        label: "Amount Received",
        value: `${currencyFormatter(
          transaction.transactionPayload.toCurrency,
          numberWithCommas(Number(transaction.transactionPayload.toAmount))
        )}`,
      },
      {
        label: "Transaction Fee",
        //@ts-ignore
        value: transaction.transactionPayload.fee
          ? `${currencyFormatter(
              transaction.transactionPayload.fromCurrency,
              //@ts-ignore
              numberWithCommas(Number(transaction.transactionPayload.fee))
            )}`
          : "No fee",
      },
    ];

    return (
      <tr>
        <td colSpan={6} className="p-0">
          <div className="transaction-details-wrapper">
            <div className="transaction-details-content bg-gray-50 p-6 rounded-b-xl shadow-inner">
              <div className="grid grid-cols-2 gap-4 text-sm text-gray-700">
                {details.map((detail, index) => (
                  <div key={index} className="space-y-1">
                    <p className="text-gray-500 font-medium">{detail.label}</p>
                    <p className="font-semibold">{detail.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };
  return (
    <>
      {/* Desktop View */}
      <div className="bg-white rounded-xl shadow-lg overflow-hidden hidden md:block">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-bold text-gray-800">
            {`Recent ${currency ? currency.toUpperCase() : ""} Transactions`}
          </h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={() =>
                navigate("/dashboard/transactions", { replace: true })
              }
              className="text-emerald-600 hover:bg-emerald-50 px-3 py-1.5 rounded-md transition flex items-center"
            >
              View All <FiChevronDown className="ml-1" />
            </button>
            <button
              className="text-gray-600 hover:bg-gray-100 p-2 rounded-md transition"
              title="Download Transactions"
            >
              <FiDownload />
            </button>
          </div>
        </div>

        <table className="w-full">
          <thead className="bg-gray-100 text-gray-600">
            <tr>
              <th className="p-4 text-left">Transaction</th>
              <th className="p-4 text-left">Transaction Reference</th>
              <th className="p-4 text-left">Type</th>
              <th className="p-4 text-center">Date</th>
              <th className="p-4 text-center">Status</th>
              <th className="p-4 text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {processedTransactions.map((transaction, index) => {
              const isNew =
                moment().diff(moment(transaction.createdAt), "hours") <= 48;
              const formattedAmount = numberWithCommas(
                Number(transaction.amount)
              );
              const amount = currencyFormatter(
                transaction.currency,
                formattedAmount
              );

              return (
                <React.Fragment key={transaction.id}>
                  <tr
                    className={`
              hover:bg-gray-50
              transition
              cursor-pointer
              relative
              ${
                index < processedTransactions.length - 1
                  ? "border-b border-gray-200"
                  : ""
              }
            `}
                    onClick={() => toggleRowExpand(transaction.id)}
                  >
                    <td className="px-4 py-2 flex items-center space-x-4">
                      <div className="w-10 h-10 bg-blue-50 rounded-full flex items-center justify-center">
                        {renderTransactionIcon(transaction.type)}
                      </div>
                      <div>
                        <p className="text-sm text-[#5C6F7F] font-light capitalize">
                          {transaction.type}
                        </p>
                        <p className="text-[#071827] text-xl font-bold">
                          {amount}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-sm text-[#0eb683] max-w-[5rem]">
                      <div
                        className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
                        title={transaction.transactionRef}
                      >
                        {transaction.transactionRef.slice(0, 20)}
                      </div>
                    </td>

                    <td className="px-4 py-2 text-sm text-gray-600">
                      {transaction.currency.toUpperCase()}{" "}
                      {capFirst(transaction?.transactionType)}
                    </td>
                    <td className="px-4 py-2 text-center text-sm text-gray-600">
                      <span
                        className={`
                px-2 py-1 rounded-full text-xs
                ${
                  isNew
                    ? "bg-green-100 text-green-700"
                    : "bg-gray-100 text-gray-600"
                }
              `}
                      >
                        {moment(transaction.createdAt).fromNow()}
                      </span>
                    </td>
                    <td className="px-4 py-2 text-sm text-center">
                      <span
                        className="inline-block px-3 py-1 rounded-full text-xs font-medium"
                        style={{
                          backgroundColor:
                            colorMap[transaction.status.toLowerCase()]?.bg ||
                            "#e5e5e5",
                          color:
                            colorMap[transaction.status.toLowerCase()]?.color ||
                            "#5C6F7F",
                        }}
                      >
                        {transaction.status.charAt(0).toUpperCase() +
                          transaction.status.slice(1).toLowerCase()}
                      </span>
                    </td>
                    <td className="px-4 py-2 text-right">
                      <div className="flex justify-end items-center">
                        <div className="hover:bg-gray-200 rounded-full p-1 transition-colors duration-200">
                          {expandedRows.includes(transaction.id) ? (
                            <FiChevronUp size={20} className="text-gray-600" />
                          ) : (
                            <FiChevronDown
                              size={20}
                              className="text-gray-600"
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                  {expandedRows.includes(transaction.id) &&
                    renderExpandedDetails(transaction)}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Mobile View */}
      <div className="block md:hidden">
        <MobileView data={data} />
      </div>
    </>
  );
};

export default WalletAccountTable;
const colorMap: { [key: string]: { bg: string; color: string } } = {
  processing: { bg: "#e5e5e5", color: "#5C6F7F" },
  pending: { bg: "#fff2eb", color: "#FF7F36" },
  successful: { bg: "#e2fef0", color: "#0EB683" },
  cancelled: { bg: "#FFE5E5", color: "#F51505" },
  failed: { bg: "#FFE5E5", color: "#F51505" },
};
