import React, { useEffect, useMemo, useState } from "react";
import illustrator from "../../../assets/images/dashboard/Illustrations.png";
import { AnimatePresence } from "framer-motion";
import AddCreditCardModal from "../../../components/cards-component/add-card-modal";
import { useSidebarState } from "../../../store";
import logo from "../../../assets/misc/FaviconAsset_ttfcx.svg";
import hamburger from "../../../assets/misc/hamburger-button.svg";
import cardx from "../../../assets/images/dashboard/Card-2.png";
import { useNavigate } from "react-router-dom";
import {
  useCardBalance,
  useCardPrivateDetails,
  useGetCards,
} from "../../../sevices/hooks/cards";
type Props = {};

const CardPage = (props: Props) => {
  // const cardDetails = useCardPrivateDetails();
  // const { data: getcard, isLoading, refetch, isRefetching } = useGetCards();
  // const [cardId, setCardId] = useState("");
  // const cardBalance = useCardBalance(cardId);
  // const setCreateCardModal = useSidebarState(
  //   (state) => state.setCreateCardModal
  // );
  const {
    data: getcard,
    isLoading: isCardsLoading,
    refetch,
    isRefetching,
  } = useGetCards();
  const [cardBalances, setCardBalances] = useState<any>({});
  const setCreateCardModal = useSidebarState(
    (state) => state.setCreateCardModal
  );
  const navigate = useNavigate();
  const [showInfo, setShowInfo] = useState(false);
  // console.log(cardBalances, "cardBalance");
  // const navigate = useNavigate();
  // const [showInfo, setShowInfo] = useState(false);

  // Extract card IDs once the cards are fetched
  const cardsArr = useMemo(() => {
    return (
      getcard?.data?.data
        ?.slice()
        .sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ) || []
    );
  }, [getcard]);

  const cardIds = useMemo(
    () => cardsArr.map((card: any, index: any) => card.card_id),
    [cardsArr]
  );

  const { data: balances, isLoading: isBalancesLoading } = useCardBalance(
    "510fd005dbd14b0ebc2d5f30da4ddbf6"
  );
  // console.log(getcard?.data?.data, "getcard");
  useEffect(() => {
    // if (balances) {
    //   const balanceMap = balances.reduce((acc: any, balance: any) => {
    //     acc[balance.card_id] = balance.amount;
    //     return acc;
    //   }, {});
    //   setCardBalances(balanceMap);
    // }
  }, [balances]);
  // console.log(cardIds, "cardIds");
  // console.log(balances, "jui");
  const isLoading = isCardsLoading || isBalancesLoading || isRefetching;

  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);

  return (
    <div className="h-screen w-full bg-[#f7fbfb] p-5 md:pr-5 md:pl-8">
      <div className="logo w-16 block md:hidden ml-auto">
        <img src={logo} alt="logo" />
      </div>

      {isLoading || isRefetching ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex justify-center items-center bg-[#131111b9] z-50">
          <div className="flex justify-center items-center flex-col">
            <svg
              className="animate-spin"
              width="160"
              height="160"
              viewBox="0 0 160 160"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160ZM80 146.667C116.819 146.667 146.667 116.819 146.667 80C146.667 43.181 116.819 13.3333 80 13.3333C43.181 13.3333 13.3333 43.181 13.3333 80C13.3333 116.819 43.181 146.667 80 146.667Z"
                fill="url(#paint0_angular_16251_43151)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M151.877 64.0048C155.529 63.5336 158.871 66.1119 159.342 69.7636C159.78 73.1582 160 76.5774 160 80.0001C160 83.682 157.015 86.6668 153.333 86.6668C149.651 86.6668 146.667 83.682 146.667 80.0001C146.667 77.1478 146.484 74.2985 146.119 71.4697C145.647 67.818 148.226 64.4759 151.877 64.0048Z"
                fill="#0EB683"
              />
              <defs>
                <radialGradient
                  id="paint0_angular_16251_43151"
                  cx="10"
                  cy="0"
                  r="0"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(60 80) scale(30)"
                >
                  <stop stopColor="#0EB683" />
                  <stop offset="0.0001" stopColor="#3AE180" stopOpacity="0" />
                  <stop offset="0" stopColor="#3AE180" />
                </radialGradient>
              </defs>
            </svg>
            <span className="text-[#f5fff9] mt-2">Fetching User's Card...</span>
          </div>
        </div>
      ) : (
        <>
          <div>
            <button
              onClick={() => {
                toggleSideBarOpen();
              }}
              className="md:hidden"
            >
              <img src={hamburger} alt="" className="w-10" />
            </button>
            <div className="flex items-center justify-between md:mr-2">
              <h1 className="font-bold text-2xl text-[#071827] md:pb-5">
                Cards
              </h1>
              {cardsArr.length > 0 && (
                <div
                  className="flex items-center text-white font-medium bg-[#0eb683] p-3 rounded-[16px] gap-2 px-8 transition-all active:scale-90 cursor-pointer"
                  onClick={() => setCreateCardModal(true)}
                >
                  <p className="pt-1">Create New Card</p>
                </div>
              )}
            </div>
          </div>

          {showInfo && (
            <div className=" bg-[#D3FDE9] w-full p-5 rounded-lg md:flex items-center justify-between mt-7">
              <div>
                <h1 className="font-bold">Create new virtual card</h1>
                <p>
                  Virtual cards are issued at 1 USD per card. To create your
                  virtual card, your USD balance must have a minimum of 1 USD.
                </p>
              </div>
              <button
                className={`bg-[#fff] text-[#0eb683] border border-[#0eb683] py-2 rounded-[16px] w-24 text-center transition-all active:scale-90 disabled:cursor-not-allowed whitespace-nowrap mt-3 md:mt-0`}
              >
                Buy | Sell
              </button>
            </div>
          )}

          {cardsArr.length > 0 ? (
            <>
              {cardsArr.map((card: any) => {
                const lastFourDigits = card.card_number
                  ? card.card_number.slice(-4)
                  : card.last_4;
                const maskedCardNumber = `**** **** **** ${lastFourDigits}`;
                const createdDate = new Date(card.createdAt); // No need to multiply by 1000
                const now = new Date();
                const hoursSinceCreation =
                  (now.getTime() - createdDate.getTime()) / (1000 * 60 * 60); // Convert milliseconds to hours
                const isNew = hoursSinceCreation <= 70;

                // console.log(card.cardId, "WWW");

                const expiryDate = formatExpiryDate(card.expiry_date);
                return (
                  <div
                    onClick={() => navigate(`/dashboard/cards/${card.cardId}`)}
                    className="box bg-white flex items-center justify-between px-3 md:p-5 mt-4 rounded-[16px] gap-4 shadow-sm hover:shadow-slate-300 cursor-pointer hover:shadow-md"
                  >
                    <div>
                      <div className=" flex items-center gap-4">
                        <div className="py-8">
                          <img src={cardx} alt="card" width={100} height={90} />
                        </div>
                        <div className="">
                          <p className="text-[#071827] font-bold text-sm">
                            {card.card_label}
                          </p>
                          <p className="text-[#071827] font-bold text-sm">
                            {maskedCardNumber}
                          </p>
                          <p className="text-[#5C6F7F] font-normal text-xs">
                            Expires on {expiryDate}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <p className="text-[#5C6F7F] font-normal">
                          Card Balance -{" "}
                          <span className="font-bold text-[#0eb683]">
                            {card.balance} {card.currency}
                          </span>
                        </p>
                      </div>
                    </div>
                    {isNew && (
                      <div className="bg-[#E2FEF0] text-sm px-3 rounded-full text-[#0EB683]">
                        New
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <div className=" mt-24 flex flex-col items-center justify-center">
              <img src={illustrator} alt="" />
              <h1 className="font-bold text-lg md:text-2xl text-[#071827] text-center">
                You have no cards
              </h1>
              <p className=" text-[#5C6F7F] font-normal md:text-sm text-center mt-2">
                You currently do not have any cards. To create a card, click on
                the button below
              </p>
              <div
                className="flex items-center text-white font-medium bg-[#0eb683] p-3 rounded-[16px] gap-2 px-8 transition-all active:scale-90 cursor-pointer mt-2"
                onClick={() => setCreateCardModal(true)}
              >
                <p>Create New Card</p>
              </div>
            </div>
          )}
        </>
      )}
      <AnimatePresence>
        <AddCreditCardModal refetch={refetch} />
      </AnimatePresence>
    </div>
  );
};

export default CardPage;

function formatExpiryDate(expiry_date: string): string {
  const [month, year] = expiry_date.split("/");
  const formattedMonth = month.padStart(2, "0");
  const formattedYear = year.slice(-2);
  return `${formattedMonth}/${formattedYear}`;
}
