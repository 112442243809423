import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { BsChevronLeft } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import cardX from "../../../assets/images/dashboard/Card-2.png";
import frozenCard from "../../../assets/images/dashboard/frozenCard.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useCardState, useSidebarState } from "../../../store";
import DeleteCardModal from "../../../components/cards-component/delete-card-modal";
import { RiLoader2Line } from "react-icons/ri";
import {
  useCardBalance,
  useCardById,
  useCardFreeze,
  useCardPrivateDetails,
  useCardUnFreeze,
  useLoadCard,
  useUnloadCards,
} from "../../../sevices/hooks/cards";
import { numberWithCommas } from "../Withdrawals";
import CardDetails from "../../../components/cards-component/card-details";
import FundCardModal from "../../../components/cards-component/fund-card-modal";
import {
  MdOutlineDeleteForever,
  MdOutlineSevereCold,
  MdPlaylistRemove,
} from "react-icons/md";
import { Button } from "../walletScreens/StableWallet";
import { TbListDetails } from "react-icons/tb";

import { HiOutlineSun, HiPlus } from "react-icons/hi";
import SwapModal from "../../../components/dashboard-components/SwapModal";
import OtpModal from "../../../components/dashboard-components/OtpModal";
import BuyModal from "../../../components/dashboard-components/BuyModal";
import { useRequestOtp } from "../../../sevices/hooks/users";
import { toast } from "sonner";

import { useWalletBalance } from "../../../sevices/hooks/wallets";
import useModalStore, { useFundVerificationStore } from "../../../store/card";

import useVerificationModalStore from "../../../store/cardVerification";
import { FaCheck } from "react-icons/fa";
import Modal from "../../../components/cards-component/modal";

// Define types for your states
type AmountValue = {
  value: number;
  formattedValue: string;
  floatValue: number;
};

type UserDetails = {
  balances: {
    ngnBalance: number;
    usdBalance: number;
  };
};

type Rate = {
  buyRate: number;
  sellRate: number;
};

type Props = {};

const SingleCard = (props: Props) => {
  const { card_id } = useParams();
  const { isVerificationOpen, openVerification, closeVerification } =
    useVerificationModalStore();
  const { isFundVerificationOpen, fundAmount, closeFundVerificationModal } =
    useFundVerificationStore();
  const { openModal } = useModalStore();
  // Define states
  const { fundModal, toggleFundModal } = useCardState();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => setIsModalOpen(false);
  const [NGNAmountValue, setNGNAmountValue] = useState<AmountValue | null>(
    null
  );
  const [usdAmountValue, setUsdAmountValue] = useState<AmountValue | null>(
    null
  );
  const { data: cardD, isLoading } = useCardById(card_id as string);
  const { data: walletBalance } = useWalletBalance("USD");

  const { data: cardBalance } = useCardBalance(card_id as string);
  const getOtp = useRequestOtp(localStorage.getItem("user_id") as string);
  const getDetails = useCardPrivateDetails(card_id as string);
  const load = useLoadCard();
  const unLoad = useUnloadCards();
  const [showVerification, setShowVerification] = useState(false);
  const [amountErr, setAmountErr] = useState<string | null>(null);
  const [tab, setTab] = useState<"buy" | "sell">("buy");
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [usdRate, setUsdRate] = useState<Rate | null>(null);
  const current_card_balance = cardBalance?.data?.data?.balance;
  const currenct_wallet_balance = walletBalance?.data;
  const fundNgn = () => {
    // Function to fund NGN
  };

  const refetch = () => {
    // Function to refetch data
  };
  ///states end
  const setDeleteCard = useSidebarState((state) => state.setDeleteCard);
  const setDetails = useSidebarState((state) => state.setShowDetails);
  const setFundModal = useSidebarState((state) => state.setShowFund);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  // console.log(card_id, "ukiffd");

  const cardId = "";

  // console.log(cardD, "cardD");
  const card = cardD?.data?.data;
  const [otpLoading, setOtpLoading] = useState(false);
  const unfreeze = useCardUnFreeze(card_id as string);
  const freeze = useCardFreeze(card_id as string);
  const [showAmount, setShowAmount] = useState(true);
  const [freezeCard, setFreezeCard] = useState(card?.is_active);
  const navigate = useNavigate();

  // console.log(cardBalance, "cardBalance ");
  // console.log(walletBalance, "walletBalancewalletBalance ");

  const toggleShowAmount = () => {
    setShowAmount((prevState) => !prevState);
  };

  const getVerification = () => {
    getOtp
      .mutateAsync({})
      .then(() => {
        setShowVerification(true);
        setDetails(true);
      })
      .catch(() => {});
  };

  const getPrivateDetails = async (verificationCode: string) => {
    if (otpLoading) return; // Prevent multiple submissions

    setOtpLoading(true);
    try {
      const res = await getDetails.mutateAsync({
        verificationCode: verificationCode,
      });
      setOtpLoading(false);
      toast(res?.data?.message);
      // console.log(res, "response");
      closeVerification();
    } catch (error) {
      setOtpLoading(false);
      toast.error("Verification failed");
    }
  };
  const LoadCard = async (verificationCode: string) => {
    if (otpLoading) return; // Prevent multiple submissions

    setOtpLoading(true);
    try {
      const res = await load.mutateAsync({
        pin: verificationCode,
        card_label: card?.card_label,
        amount: fundAmount as number,
        card_id: card_id as string,
      });
      setOtpLoading(false);
      toast(res?.data?.message);
      // console.log(res, "response");
      closeFundVerificationModal();
    } catch (error) {
      setOtpLoading(false);
      toast.error("Verification failed");
    }
  };
  const UnloadCard = async (verificationCode: string) => {
    if (otpLoading) return; // Prevent multiple submissions

    setOtpLoading(true);
    try {
      const res = await unLoad.mutateAsync({
        pin: verificationCode,
        card_label: card?.card_label,
        amount: current_card_balance,
        card_id: card_id as string,
      });

      setOtpLoading(false);

      const message = res?.data?.message || "";

      if (
        message
          .toLowerCase()
          .includes(
            "this card doesn't have sufficient funds perform this operation"
          )
      ) {
        toast.warning(message);
        closeVerification();
      } else {
        toast.success(message);
        // console.log(res, "response");
        closeVerification();
        openModal();
      }
    } catch (error) {
      setOtpLoading(false);
      alert("Verification failed");
    }
  };
  const toogleDetails = () => {
    setDetails(false);

    setTimeout(() => {
      setShowVerification(false);
    }, 2000);
  };
  const handleFreeze = () => {
    setLoading(true);
    freezeCard
      ? unfreeze.mutateAsync({}).then((res) => {
          setFreezeCard(false);

          setLoading(false);
        })
      : freeze.mutateAsync({}).then((res) => {
          setFreezeCard(true);

          setLoading(false);
        });
  };
  if (isLoading) {
    return <SkeletonLoader />;
  }
  // console.log(card, "card");
  return (
    <div className="md:bg-[#f7fbfb] h-full w-full px-5 py-5 md:p-5 md:pl-8 ">
      <div className="flex items-center gap-24 md:gap-4">
        <button
          className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
          onClick={() => {
            navigate(-1);
          }}
        >
          <BsChevronLeft />
        </button>
        <p className="font-semibold">Card</p>
      </div>
      <div className="box bg-white flex flex-col md:p-5 mt-7 rounded-[16px]">
        <div className="flex flex-col  md:items-start gap-3  md:flex-row md:justify-between w-full">
          <div className="flex flex-col ">
            <div className="flex gap-3 ">
              <div className="relative">
                <img
                  src={freezeCard ? frozenCard : cardX}
                  alt="card"
                  width={175}
                />

                {loading && (
                  <span className="absolute top-10 left-20 ">
                    <RiLoader2Line
                      size={34}
                      className=" animate-spin text-[#ffffff]"
                    />
                  </span>
                )}
              </div>
              <div className="flex flex-col justify-between">
                <div className="flex items-center xs:justify-center md:justify-start gap-6 ">
                  <h4 className="text-[#5C6F7F] ">Card Balance</h4>
                  <button
                    className="text-[#0eb683]  focus:outline-none text-xl block md:hidden"
                    onClick={toggleShowAmount}
                  >
                    {showAmount ? <FiEye /> : <FiEyeOff />}
                  </button>
                </div>

                <div className="flex space-x-2  ">
                  <span className="text-[#OOO] text-2xl">
                    {showAmount ? (
                      <p className="text-[#OOO] text-lg md:text-3xl font-bold">
                        {" "}
                        {numberWithCommas(card?.balance)} {card?.card_currency}
                      </p>
                    ) : (
                      <>
                        <p className="text-2xl md:text-4xl font-bold md:block hidden">{`*** *** *****`}</p>
                        <p className="text-2xl md:text-4xl font-bold md:hidden block">{`*** ***`}</p>
                      </>
                    )}
                  </span>
                  <button
                    className="text-[#0eb683]  focus:outline-none text-xl md:block hidden"
                    onClick={toggleShowAmount}
                  >
                    {showAmount ? <FiEye /> : <FiEyeOff />}
                  </button>
                </div>
              </div>
            </div>

            <div className="button gap-4 mt-6 hidden md:flex flex-wrap justify-center md:justify-start">
              <button
                onClick={() => setFundModal(true)}
                className="border border-[#0eb683] text-center text-[#0eb683] w-full md:w-auto px-4 py-3 flex items-center justify-center whitespace-nowrap bg-white rounded-[16px] transition-all active:scale-90 cursor-pointer disabled:cursor-not-allowed"
              >
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5 12H19.5M12.5 5V19V5Z"
                    stroke="#0EB683"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ml-2 hidden lg:block">Fund card</span>
                <span className="ml-2 lg:hidden">Fund</span>
              </button>

              <Link
                to={"#"}
                onClick={getVerification}
                className="bg-[#fff] w-full md:w-auto px-4 py-3 text-center border border-[#0eb683] text-[#0eb683] rounded-[16px] transition-all active:scale-90 cursor-pointer flex items-center justify-center"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12"
                    stroke="#0EB683"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12"
                    stroke="#0EB683"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                    stroke="#0EB683"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ml-2 hidden lg:block">View card details</span>
                <span className="ml-2 lg:hidden">Details</span>
              </Link>

              <Link
                to={"#"}
                onClick={handleFreeze}
                className="bg-[#fff] w-full md:w-auto px-4 py-3 text-center border border-[#0eb683] text-[#0eb683] rounded-[16px] transition-all active:scale-90 cursor-pointer flex items-center justify-center"
              >
                {!freezeCard ? (
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 6L5 8M5 8L10.5 11M5 8L1.5 9M5 8L4.5 4.5M19.5 16L16 14M16 14L10.5 11M16 14L16.5 17.5M16 14L19.5 13M10.5 11L5 14M10.5 11V4M10.5 11V17.5M10.5 11L16 8M5 14L1.5 16M5 14L1.5 13M5 14L4.5 17.5M10.5 4V1M10.5 4L8 3M10.5 4L13 3M10.5 17.5V21M10.5 17.5L13 19M10.5 17.5L8 19M16 8L19.5 6M16 8L19.5 9M16 8L16.5 4.5"
                      stroke="#0EB683"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 16.5L18 18L16.5 16.5ZM19 11H21H19ZM5.5 5.5L4 4L5.5 5.5ZM16.5 5.5L18 4L16.5 5.5ZM5.5 16.5L4 18L5.5 16.5ZM1 11H3H1ZM11 1V3V1ZM11 19V21V19ZM15 11C15 13.2091 13.2091 15 11 15C8.79086 15 7 13.2091 7 11C7 8.79086 8.79086 7 11 7C13.2091 7 15 8.79086 15 11Z"
                      stroke="#0EB683"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                <span className="ml-2 hidden lg:block">
                  {!freezeCard ? "Freeze Card" : "Unfreeze card"}
                </span>
                <span className="ml-2 lg:hidden">
                  {!freezeCard ? "Freeze" : "Unfreeze"}
                </span>
              </Link>

              <div
                onClick={() => setDeleteCard(true)}
                className="w-full md:w-auto px-4 py-3 text-center text-[#E01A00] transition-all active:scale-90 cursor-pointer flex items-center justify-center"
              >
                <span className="ml-2 hidden lg:block">Delete card</span>
                <span className="ml-2 lg:hidden">Delete</span>
              </div>
            </div>

            <div className=" w-full md:hidden block">
              <div className="grid grid-cols-4  pt-4 gap-4">
                <Button
                  label={`Fund`}
                  icon={HiPlus}
                  onClick={() => {
                    setFundModal(true);
                  }}
                  bgColor="#fff"
                  borderColor="#0eb683"
                />
                <Button
                  label={`Details`}
                  icon={TbListDetails}
                  onClick={() => {
                    setDetails(true);
                  }}
                  bgColor="#fff"
                  borderColor="#0eb683"
                />
                <Button
                  label={`${freezeCard ? "UnFreeze" : "Freeze"}
                  `}
                  icon={freezeCard ? HiOutlineSun : MdOutlineSevereCold}
                  onClick={handleFreeze}
                  bgColor="#fff"
                  borderColor="#0eb683"
                />
                <Button
                  label={`Delete`}
                  icon={MdPlaylistRemove}
                  onClick={() => {
                    setDeleteCard(true);
                  }}
                  bgColor="#fff"
                  borderColor={"#f51515"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-5">
        <Infor />
      </div>
      <div>
        {/* BUY USD MODAL */}
        <AnimatePresence>
          {fundModal && (
            <BuyModal
              baseCurrency={card?.card_currency}
              baseCurrencySymbol={"$ "}
              currency={"ngn"}
              currencySymbol={"₦ "}
              rate={usdRate?.buyRate}
              confirmation={showConfirmation}
              setConfirmation={setShowConfirmation}
              inputValue={
                tab === "buy" ? NGNAmountValue?.value : usdAmountValue?.value
              }
              inputValueFormatted={NGNAmountValue?.formattedValue as string}
              inputValueFloat={NGNAmountValue?.floatValue}
              setInputValue={setNGNAmountValue}
              openModal={fundModal}
              loading={loading}
              setLoading={setLoading}
              setOpenModal={() => toggleFundModal(false)}
              balance={userDetails?.balances?.ngnBalance}
              baseBalance={userDetails?.balances?.usdBalance}
              computedValue={
                tab === "buy" ? usdAmountValue?.value : NGNAmountValue?.value
              }
              sellRate={usdRate?.sellRate}
              computedValueFloat={usdAmountValue?.floatValue}
              computedValueFormatted={usdAmountValue?.formattedValue}
              setComputedValue={setUsdAmountValue}
              errorMessage={amountErr}
              success={showSuccessModal}
              setSuccess={setShowSuccessModal}
              refetch={() => refetch()}
              setFundModal={fundNgn}
            />
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        <DeleteCardModal
          cardBalance={current_card_balance}
          walletBalance={currenct_wallet_balance}
        />
      </AnimatePresence>
      <AnimatePresence>
        <CardDetails />
      </AnimatePresence>
      <AnimatePresence>
        <Modal />
      </AnimatePresence>
      <AnimatePresence>
        <CardFundedModal isOpen={isModalOpen} onClose={handleClose} />
      </AnimatePresence>
      <AnimatePresence>
        <FundCardModal currency={card?.currency} />
      </AnimatePresence>
      {/* details */}
      <AnimatePresence>
        {showVerification && (
          <OtpModal
            loading={otpLoading}
            setVerification={setShowVerification}
            verify={showVerification}
            setOtp={setOtp}
            withdraw={getPrivateDetails}
            onClose={toogleDetails}
          />
        )}
      </AnimatePresence>
      {/* unload */}
      <AnimatePresence>
        {isVerificationOpen && (
          <OtpModal
            loading={otpLoading}
            verify={isVerificationOpen}
            setOtp={setOtp}
            withdraw={UnloadCard}
            onClose={toogleDetails}
          />
        )}
      </AnimatePresence>
      {/* load */}
      <AnimatePresence>
        {isFundVerificationOpen && (
          <OtpModal
            loading={otpLoading}
            verify={isFundVerificationOpen}
            setOtp={setOtp}
            withdraw={LoadCard}
            onClose={toogleDetails}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default SingleCard;
export const Infor = () => {
  return (
    <div className="bg-[#D3FDE9] w-full p-5 rounded-lg">
      <h1 className="font-bold">Fees</h1>
      <p>
        {` There is 1.5% of transaction value fee for all USD transactions with this card and an additional 1.5% if transaction is not in USD.`}
      </p>
    </div>
  );
};

const SkeletonLoader = () => {
  return (
    <div className="md:bg-[#f7fbfb] h-full w-full px-5 py-5 md:p-5 md:pl-8">
      <div className="flex items-center gap-24 md:gap-4">
        <div className="skeleton-loader rounded-full bg-[#ECF6F3] p-2 flex transition-all"></div>
        <div className="skeleton-loader w-32 h-6 bg-gray-200"></div>
      </div>
      <div className="box bg-white flex flex-col md:p-5 mt-7 rounded-[16px]">
        <div className="flex flex-col md:items-start gap-3 md:flex-row md:justify-between w-full">
          <div className="flex flex-col">
            <div className="flex gap-8">
              <div className="skeleton-loader w-64 h-40 bg-gray-200 rounded-lg"></div>
              <div className="flex flex-col justify-between">
                <div className="skeleton-loader w-28 h-4 bg-gray-200 mb-2"></div>
                <div className="flex space-x-2">
                  <div className="skeleton-loader w-32 h-8 bg-gray-200"></div>
                  <div className="skeleton-loader w-8 h-8 bg-gray-200 rounded-full"></div>
                </div>
              </div>
            </div>
            <div className="flex gap-4 mt-6 hidden md:flex">
              <div className="skeleton-loader w-64 h-10 bg-gray-200 rounded-lg"></div>
              <div className="skeleton-loader w-64 h-10 bg-gray-200 rounded-lg"></div>
              <div className="skeleton-loader w-64 h-10 bg-gray-200 rounded-lg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CardFundedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CardFundedModal: React.FC<CardFundedModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30 px-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.3 }}
        className="bg-white rounded-2xl p-8 w-full max-w-sm shadow-lg"
      >
        <h2 className="text-2xl font-bold text-center text-gray-900">
          Card funded
        </h2>

        <div className="mt-6 flex items-start space-x-3 bg-green-50 p-4 rounded-md">
          <FaCheck className="text-green-500 w-6 h-6" />
          <div className="text-gray-600">
            <p>Your card funding is in progress</p>
            <p>Please refresh your card</p>
          </div>
        </div>

        <div className="mt-8">
          <button
            onClick={onClose}
            className="w-full py-2 text-white bg-[#0eb683]hover:bg-[#0eb670] rounded-lg text-lg"
          >
            Done
          </button>
        </div>
      </motion.div>
    </div>
  );
};
