import React, { useState, useEffect } from "react";

import { TabSelector } from "../../components/widgets/TabSelector";
import { TabPanel } from "../../components/widgets/TabWidget";
import OnboardingFooter from "../../components/base-components/OnboardingFooter";
import Input from "./comp/Input";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DragDropComponent from "../../components/base-components/DragDropComponent";

import { AiFillPlusCircle, AiOutlineClose } from "react-icons/ai";
import CustomSelect from "../../components/base-components/CustomSelect";
import { GenderData, IDOptionType } from "./IndividualUser";
import { useGetCountry, useGetState } from "../../sevices/hooks/tags";
import {
  useBusinessCorporate,
  useBusinessIndividual,
} from "../../sevices/hooks/users";
import LoadingScreen from "../Notifications/LoadingScreen2";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "./refresh-initiative/Controller";
import { useBusinessOwnerStep } from "../../store";

export const IDTypeOptions = [
  {
    id: 1,
    label: "International Passport",
    slug: "international-passport",
  },
  {
    id: 2,
    label: "National Identity Slip",
    slug: "NIN_SLIP",
  },
  {
    id: 3,
    label: "National Identification Card",
    slug: "NIN_V2",
  },
  {
    id: 4,
    label: "Driver's License",
    slug: "DRIVERS_LICENSE",
  },
  {
    id: 5,
    label: "Voter's Card",
    slug: "VOTER_ID",
  },
];
const BusinessOwner = ({
  getDetails,
  email,
  backFn,
  nextFn,
  userDetail,
  setIsSummary,
}: {
  getDetails: (data: any, isValid: boolean) => void;
  email?: string;
  backFn: () => void;
  nextFn: () => void;
  userDetail: any;
  setIsSummary: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [formInstances, setFormInstances] = useState<number>(1);
  const [formData, setFormData] = useState<any>();
  const [selectedType, setSelectedType] = useState<string[]>(["Individual"]);

  const createBusinessIndividual = useBusinessIndividual();

  const [loading, setLoading] = useState(false);
  const [IDType, setIDType] = useState<IDOptionType>(null);
  const { isSubmitted, setIsSubmitted } = useBusinessOwnerStep();

  const handleMember = (action: string, index: number) => {
    if (action === "add") {
      setFormInstances((prevInstances) => prevInstances + 1);
      setSelectedType((prevCurrencies) => [...prevCurrencies, "Individual"]);
    } else if (action === "remove" && formInstances > 1) {
      setFormInstances((prevInstances) => prevInstances - 1);
      setSelectedType((prevCurrencies) => {
        const newCurrencies = [...prevCurrencies];
        newCurrencies.splice(index, 1);
        return newCurrencies;
      });
    }
  };

  const updateFormData = (index: number, data: any) => {
    setFormData((prevData: any) => {
      let newData = [...prevData];
      newData[index] = data;
      return newData;
    });

    createBusinessIndividual
      .mutateAsync(formData)
      .then((res) => {})
      .catch((err) => {});
  };

  const handleRemoveMember = (index: number) => {
    handleMember("remove", index);
  };

  return (
    <LoadingScreen
      showLoading={loading}
      msg="Validating your details..."
      bg="#f2faf799"
    >
      <div className=" md:pl-5 md:mx-0   mx-4">
        {/* {!showSummary ? ( */}
        {Array.from({ length: formInstances }).map((_, index) => (
          <div key={index} className={` ${formInstances > 1 ? "mb-8" : ""}`}>
            <div className=" px-5 bg-[#F2FAF7]  md:max-w-lg md:bg-white md:mt-10 ">
              <div className=" py-7 flex xxs:flex-col md:flex-row md:justify-between ">
                <h1 className="text-[#071827] text-[16px] leading-[22px] font-medium  ">
                  Business Owner 1
                </h1>
                {index > 0 && (
                  <div
                    onClick={() => handleRemoveMember(index)}
                    className="cursor-pointer"
                  >
                    <AiOutlineClose />
                  </div>
                )}
              </div>
              {!isSubmitted && (
                <nav className=" flex items-center  justify-between  pb-5  ">
                  <TabSelector
                    className={` flex items-center md:gap-2 cursor-pointer relative bg-transparent mr-4 md:mr-0 text-[] ${
                      selectedType[index] === "Individual"
                        ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                        : ""
                    }`}
                    isActive={selectedType[index] === "Individual"}
                    onClick={() =>
                      setSelectedType((prevCurrencies) => {
                        const newCurrencies = [...prevCurrencies];
                        newCurrencies[index] = "Individual";
                        return newCurrencies;
                      })
                    }
                  >
                    <div className="flex items-center gap-2">
                      <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>

                      <h1 className="text-[#5C6F7F] md:font-bold md:hidden">
                        Individual
                      </h1>
                    </div>
                    <h1 className="text-[#5C6F7F] md:font-bold hidden md:block">
                      Individual
                    </h1>
                  </TabSelector>

                  <TabSelector
                    className={` flex items-center md:gap-2 cursor-pointer relative bg-transparent mr-4 md:mr-0 ${
                      selectedType[index] === "Corporate"
                        ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                        : ""
                    }`}
                    isActive={selectedType[index] === "Corporate"}
                    onClick={() =>
                      setSelectedType((prevCurrencies) => {
                        const newCurrencies = [...prevCurrencies];
                        newCurrencies[index] = "Corporate";
                        return newCurrencies;
                      })
                    }
                  >
                    <div className="flex items-center gap-2">
                      <div className=" bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>

                      <h1 className="text-[#5C6F7F] md:hidden">Corporate</h1>
                    </div>
                    <h1 className="text-[#5C6F7F] md:font-bold  hidden md:block">
                      Corporate
                    </h1>
                  </TabSelector>
                </nav>
              )}

              <main className="panel p-0 flex flex-col ">
                {isSubmitted ? (
                  <div className="flex flex-col items-center w-full justify-center h-80">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="103"
                      height="101"
                      viewBox="0 0 93 91"
                      fill="none"
                    >
                      <path
                        d="M22.3563 44.5678L43.28 58.2678C45.0508 59.4273 47.4183 59.0065 48.6812 57.3079L87.1334 5.58691"
                        stroke="#0EB683"
                        stroke-width="10"
                        stroke-linecap="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M63.9659 10.2674C65.398 8.29235 64.7779 5.49402 62.5314 4.54281C57.1436 2.26159 51.2191 1 45 1C20.1472 1 0 21.1472 0 46C0 70.8528 20.1472 91 45 91C69.8528 91 90 70.8528 90 46C90 44.7512 89.9491 43.5143 89.8494 42.2913C89.5457 38.5687 85.0013 37.6928 82.8088 40.7165C82.158 41.614 81.8731 42.7197 81.9372 43.8266C81.9789 44.5457 82 45.2704 82 46C82 66.4345 65.4345 83 45 83C24.5655 83 8 66.4345 8 46C8 25.5655 24.5655 9 45 9C49.8605 9 54.5022 9.93722 58.7541 11.6408C60.6138 12.3859 62.7898 11.8893 63.9659 10.2674Z"
                        fill="#00432F"
                      />
                    </svg>
                    <p className="mt-5">
                      Information has been successfully added
                    </p>
                    <p>Click on save and proceed to continue</p>
                  </div>
                ) : (
                  <>
                    <TabPanel
                      hidden={selectedType[index] !== "Individual"}
                      className=" "
                    >
                      <div className="  px-0 md:pb-5 ">
                        <IndividualForm
                          handleMember={handleMember}
                          index={index}
                          updateFormData={updateFormData}
                          getDetails={getDetails}
                          userDetail={userDetail}
                          email={email}
                          backFn={backFn}
                          nextFn={() => {
                            nextFn();
                          }}
                          setLoading={setLoading}
                        />{" "}
                      </div>
                    </TabPanel>
                    <TabPanel hidden={selectedType[index] !== "Corporate"}>
                      <div className=" px-0 md:pb-5 ">
                        <div className="card">
                          <CorporateForm
                            handleMember={handleMember}
                            index={index}
                            updateFormData={updateFormData}
                            getDetails={getDetails}
                            userDetail={userDetail}
                            backFn={backFn}
                            nextFn={nextFn}
                            setLoading={setLoading}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  </>
                )}
              </main>
            </div>
            {isSubmitted && (
              <OnboardingFooter
                backFn={
                  backFn
                  // updateStep("choose", false);
                  // setCurrentFlow("individual");
                  // setSelectedTab("choose");
                }
                nextFn={() => {
                  setIsSummary(true);
                }}
                // nextDisabled={!isValid}
              />
            )}
          </div>
        ))}
      </div>
    </LoadingScreen>
  );
};

export default BusinessOwner;

type IndividualFormProps = {
  handleMember: (action: string, index: number) => void;
  index: number;
  updateFormData: (index: number, data: any) => void;
  getDetails: (data: any, isValid: boolean) => void;
  userDetail: any;
  email?: string;
  backFn: () => void;
  nextFn: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const IndividualForm: React.FC<IndividualFormProps> = ({
  handleMember,
  index,
  updateFormData,
  getDetails,
  userDetail,
  backFn,
  nextFn,
  setLoading,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [IDType, setIDType] = useState<IDOptionType>(null);
  const [gender, setGender] = useState<IDOptionType>(null);
  const [fullName, setFullName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [email, setEmail] = useState("");
  const [postCode, setPostcode] = useState("");
  const [percentage, setPercentage] = useState("");
  const [phone, setPhone] = useState("");
  const [file1, setFile1] = useState<string | Blob>("");
  const handleAddMember = () => {
    handleMember("add", index);
  };
  const { isSubmitted, setIsSubmitted } = useBusinessOwnerStep();
  const [country, setCountry] = useState<IDOptionType>(null);
  const [state, setState] = useState<IDOptionType>(null);
  const createBusinessIndividual = useBusinessIndividual();
  const navigate = useNavigate();
  const handleRemoveMember = () => {
    handleMember("remove", index);
  };

  const getDocs = (files: any) => {
    setFile1(files);
  };

  useEffect(() => {
    getDetails(
      {
        IDType,
        gender,
        fullName,
        address1,
        address2,
        email,
        postCode,
        percentage,
        phone,
        file1,
        country,
        state,
      },
      true
    );
  }, [
    IDType,
    gender,
    fullName,
    address1,
    address2,
    email,
    postCode,
    percentage,
    phone,
    file1,
    country,
    state,
  ]);

  const handleSubmit = async (index: number) => {
    setLoading(true);

    try {
      if (userDetail) {
        const {
          accountType,
          IDType,
          gender,
          fullName,
          address1,
          address2,
          email,
          postCode,
          percentage,
          phone,
          file1,
          country,
          state,
          businessId,
        } = userDetail;

        const formData = new FormData();
        formData.append("businessId", businessId);
        formData.append("idType", IDType?.slug);
        formData.append("gender", gender.slug === "M" ? "Male" : "Female");
        formData.append("fullName", fullName);
        formData.append("addressLine1", address1);
        formData.append("addressLine2", address2);
        formData.append("email", email);
        formData.append("postCode", postCode);
        formData.append("shareholding", `${percentage}%`);
        formData.append("phoneNumber", phone);
        if (file1) {
          formData.append("idDoc", file1);
        }
        formData.append("country", country?.slug);
        formData.append("state", state?.slug);

        const res = await createBusinessIndividual.mutateAsync(formData);
        // console.log(res);

        setLoading(false);

        setIsSubmitted(true);
      }
    } catch (err) {
      setLoading(false);
      // Handle the error here if needed
    }
  };

  const Icountry = useGetCountry();
  const states = useGetState();

  const countryOptions =
    Icountry?.data?.data.data.map(
      (
        {
          countryCode,
          name,
          index,
        }: { countryCode: string; name: string; index: number },
        id: number
      ) => ({
        label: name,
        slug: countryCode,
        id,
      })
    ) || [];

  const stateOptions =
    states?.data?.data.data.map(
      ({ name, index }: { name: string; index: number }, id: number) => ({
        label: name,
        slug: name,
        id,
      })
    ) || [];

  useEffect(() => {
    // Function to handle window resize and update mobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={` px-0 `}>
      <div className="bg-none md:bg-white flex flex-col gap-3 p-0  md:w-full md:max-w-lg">
        <div className="relative ">
          <Input
            label="Full Name"
            name="fullName"
            type="text"
            required={true}
            // value={firstname}
            onChange={(evt) => setFullName(evt)}
          />
        </div>

        <div className="relative w-full">
          <small className="text-[#0B0C0E] text-sm font-normal ">
            <span className="text-[#E01A00]">*</span>
            Gender{" "}
          </small>
          <CustomSelect
            selectedOption={gender}
            setSelectOption={setGender}
            placeholder={"Choose gender"}
            options={GenderData || []}
          />
          {/* <span className="text-[#E01A00] absolute left-2 top-9">*</span> */}
        </div>

        <div className="relative ">
          <Input
            label="Email Address"
            name="emailAddress"
            type="text"
            required={true}
            // value={firstname}
            onChange={(evt) => setEmail(evt)}
          />
        </div>

        <ReactPhoneInput
          containerStyle={{
            border: "0.5px solid #CBE0D6",
            padding: "5px",
            backgroundColor: isMobile ? "#fff" : "#F2FAF7",
            borderRadius: "4px",
          }}
          buttonStyle={{
            backgroundColor: "transparent",
            border: "none",
            borderRight: "1px solid #0EB683",
            margin: "10px 0px",
            padding: "0px 11px",
          }}
          inputStyle={{
            width: "100%",
            border: "none",
            padding: "0px 0px 0px 72px",
            backgroundColor: isMobile ? "#fff" : "#F2FAF7",
            height: 40,
          }}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          enableAreaCodes={true}
          country="ng"
          placeholder="Enter the best mobile number to reach you"
          onChange={(data) => setPhone(data)}
          // {...props}
        />
        <small className="text-[11px] text-[#7991a4] ">
          You will be required to confirm this via OTP
        </small>

        <div className="relative ">
          <Input
            label="Address Line 1"
            name="address1"
            type="text"
            required={true}
            // value={firstname}
            onChange={(evt) => setAddress1(evt)}
          />
        </div>
        <div className="relative ">
          <Input
            label="Address Line 2"
            name="address2"
            type="text"
            required={false}
            // value={firstname}
            onChange={(evt) => setAddress2(evt)}
          />
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="mb-3 w-[50%] relative">
            <label
              className=" text-[#0B0C0E] text-[14px] block leading-[16px] font-normal  pl-2"
              htmlFor="country"
            >
              Select Country
            </label>

            <CustomSelect
              selectedOption={country?.slug}
              setSelectOption={setCountry}
              placeholder={"select country"}
              options={countryOptions || []}
            />
            <span className="text-[#E01A00] absolute top-[0px]">*</span>
          </div>
          <div className="mb-3 w-[50%] relative">
            <label
              className=" text-[#0B0C0E] text-[14px] block leading-[21px] font-normal  pl-2"
              htmlFor="state"
            >
              State
            </label>

            <CustomSelect
              selectedOption={state?.slug}
              setSelectOption={setState}
              placeholder={"select city"}
              options={stateOptions || []}
            />
            <span className="text-[#E01A00] absolute top-[0px]">*</span>
          </div>
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="relative w-full">
            <Input
              label="Post Code"
              name="businessName"
              type="text"
              required={false}
              // value={firstname}
              onChange={(evt) => setPostcode(evt)}
            />
          </div>
          <div className="relative w-full">
            <Input
              label="% Shareholding"
              name="businessName"
              type="text"
              required={true}
              // value={firstname}
              onChange={(evt) => setPercentage(evt)}
            />
          </div>
        </div>
        <div className="relative w-full">
          <small className="text-[#0B0C0E] text-sm font-normal ">
            <span className="text-red-600">*</span>
            Select means of identification{" "}
          </small>
          <CustomSelect
            selectedOption={IDType}
            setSelectOption={setIDType}
            placeholder={"IDType"}
            options={IDTypeOptions || []}
          />
          <span className="text-red-600 absolute left-2 top-9">*</span>
        </div>
        <div>
          <small className="text-[#0B0C0E] text-sm font-normal ">
            <span className="text-red-600">*</span>
            Upload <span className="font-bold text-[#0eb683] ">
              selected
            </span>{" "}
            means of identification{" "}
          </small>
          <DragDropComponent inputId="dkytytyke" getFiles={getDocs} />
          <small className="text-[11px] leading-[18px] text-[#7991a4] tracking-tight ">
            These include International Passport, NIN ID, or Driver’s License.
            Supported file types .pdf, .docx, .jpeg, .jpg, .png, .txt
          </small>
        </div>
        {index === 0 && (
          <div
            onClick={handleAddMember}
            className="text-[#0eb683] flex items-center gap-2 text-[16px] leading-[24px] cursor-pointer"
          >
            <AiFillPlusCircle />
            <span>Add another owner</span>
          </div>
        )}
        <OnboardingFooter
          nextBtnLabel="Submit"
          color={"md:bg-[#fff]"}
          backFn={
            backFn
            // updateStep("choose", false);
            // setCurrentFlow("individual");
            // setSelectedTab("choose");
          }
          nextFn={() => handleSubmit(index)}
          // nextDisabled={!isValid}
        />
      </div>
    </div>
  );
};

const CorporateForm: React.FC<IndividualFormProps> = ({
  handleMember,
  index,
  updateFormData,
  getDetails,
  userDetail,
  backFn,
  nextFn,
  setLoading,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState("");
  const [incorNum, setIncorNum] = useState("");
  const [country, setCountry] = useState<IDOptionType>(null);
  const [businessState, setBusinessState] = useState<IDOptionType>(null);
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [file2, setFile2] = useState<string | Blob>("");
  const [businessPercentage, setBusinessPercentage] = useState("");
  const createBusinessCorporate = useBusinessCorporate();
  const { isSubmitted, setIsSubmitted } = useBusinessOwnerStep();
  const Icountry = useGetCountry();
  const states = useGetState();
  const navigate = useNavigate();
  const getMeansOfId = (files: any) => {
    setFile2(files);
  };
  useEffect(() => {
    getDetails(
      {
        //  IDType,
        //  gender,
        businessName,
        incorNum,
        businessAddress,
        businessEmail,
        //  postCode,
        businessPercentage,
        businessPhone,
        file2,
        country,
        businessState,
      },
      true
    );
  }, [
    //  IDType,
    //  gender,
    businessName,
    incorNum,
    businessAddress,
    businessEmail,
    //  postCode,
    businessPercentage,
    businessPhone,
    file2,
    country,
    businessState,
  ]);

  const countryOptions =
    Icountry?.data?.data.data.map(
      (
        {
          countryCode,
          name,
          index,
        }: { countryCode: string; name: string; index: number },
        id: number
      ) => ({
        label: name,
        slug: countryCode,
        id,
      })
    ) || [];

  const stateOptions =
    states?.data?.data.data.map(
      ({ name, index }: { name: string; index: number }, id: number) => ({
        label: name,
        slug: name,
        id,
      })
    ) || [];

  const handleSubmit = async (index: number) => {
    setLoading(true);

    try {
      if (userDetail) {
        const {
          businessName,
          incorNum,
          businessAddress,
          businessEmail,
          businessPercentage,
          businessPhone,
          file2,
          country,
          businessId,
        } = userDetail;

        const formData = new FormData();
        formData.append("businessId", businessId);
        formData.append("businessName", businessName);
        formData.append("registrationNumber", incorNum);
        formData.append("businessAddress", businessAddress);
        formData.append("businessEmail", businessEmail);
        formData.append(
          "businessPhoneNumber",
          formatPhoneNumber(businessPhone)
        );
        formData.append("shareholding", `${businessPercentage}%`);
        if (file2) {
          formData.append("registrationDocument", file2);
        }
        if (country) {
          formData.append("countryOfRegistration", country.slug);
        }

        const res = await createBusinessCorporate.mutateAsync(formData);
        // console.log(res);
        setLoading(false);

        setIsSubmitted(true);
      }
    } catch (err) {
      setLoading(false);
      // Handle the error here if needed
    }
  };

  useEffect(() => {
    // Function to handle window resize and update mobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAddMember = () => {
    handleMember("add", index);
  };

  return (
    <div className=" px-0 ">
      <div className="bg-none md:bg-white flex flex-col gap-3 p-0  md:w-full md:max-w-l mb-3">
        <div className="relative ">
          <Input
            label="Business Name"
            name="businessName"
            type="text"
            required={true}
            // value={firstname}
            onChange={(evt) => setBusinessName(evt)}
          />
          {/* {firstname.trim().length > 1 && (
            <BsCheckCircleFill
              size={20}
              className="text-[#0eb683] absolute top-[50%] -translate-y-1/2 right-5"
            />
          )} */}
        </div>

        <div className="relative ">
          <Input
            label="Registration Number"
            name="incorNum"
            type="text"
            pattern="\d*"
            required={true}
            // value={firstname}
            onChange={(evt) => setIncorNum(evt)}
          />
          {/* {firstname.trim().length > 1 && (
            <BsCheckCircleFill
              size={20}
              className="text-[#0eb683] absolute top-[50%] -translate-y-1/2 right-5"
            />
          )} */}
        </div>
        <div className="relative ">
          <CustomSelect
            selectedOption={country?.slug}
            setSelectOption={setCountry}
            placeholder={"select country of registration"}
            options={countryOptions || []}
          />
          {/* <span className="text-[#E01A00] absolute top-[0px]">*</span> */}
        </div>
        <div>
          <small className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal ">
            Upload incorporation documents{" "}
            <span className="text-red-600">*</span>
          </small>
          <DragDropComponent inputId="dkrereke" getFiles={getMeansOfId} />
          <small className="text-[11px] text-[#7991a4] ">
            These include CAC incorporation certificates, etc.
          </small>
        </div>

        <div className="relative ">
          <Input
            label="Business Address"
            name="businessAdress"
            type="text"
            required={true}
            // value={firstname}
            onChange={(evt) => setBusinessAddress(evt)}
          />
          {/* {firstname.trim().length > 1 && (
            <BsCheckCircleFill
              size={20}
              className="text-[#0eb683] absolute top-[50%] -translate-y-1/2 right-5"
            />
          )} */}
        </div>
        <div className="relative ">
          <Input
            label="Business Email"
            name="businessEmail"
            type="text"
            required={true}
            // value={firstname}
            onChange={(evt) => setBusinessEmail(evt)}
          />
          {/* {firstname.trim().length > 1 && (
            <BsCheckCircleFill
              size={20}
              className="text-[#0eb683] absolute top-[50%] -translate-y-1/2 right-5"
            />
          )} */}
        </div>

        <ReactPhoneInput
          containerStyle={{
            border: "0.5px solid #CBE0D6",
            padding: "5px",
            backgroundColor: isMobile ? "#fff" : "#F2FAF7",
            borderRadius: "4px",
          }}
          buttonStyle={{
            backgroundColor: "transparent",
            border: "none",
            borderRight: "1px solid #0EB683",
            margin: "10px 0px",
            padding: "0px 11px",
          }}
          inputStyle={{
            width: "100%",
            border: "none",
            padding: "0px 0px 0px 72px",
            backgroundColor: isMobile ? "#fff" : "#F2FAF7",
            height: 40,
          }}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          enableAreaCodes={true}
          country="ng"
          placeholder="Enter the best mobile number to reach you"
          onChange={(data) => setBusinessPhone(data)}
          // {...props}
        />
        <small className="text-[11px] text-[#7991a4] ">
          You will be required to confirm this via OTP
        </small>

        <div className="relative ">
          <Input
            label="% Shareholding"
            name="businessPercentage"
            type="text"
            required={true}
            // value={firstname}
            onChange={(evt) => setBusinessPercentage(evt)}
          />
          {/* {firstname.trim().length > 1 && (
            <BsCheckCircleFill
              size={20}
              className="text-[#0eb683] absolute top-[50%] -translate-y-1/2 right-5"
            />
          )} */}
        </div>
      </div>
      {index === 0 && (
        <div
          onClick={handleAddMember}
          className="text-[#0eb683] flex items-center gap-2 text-[16px] leading-[24px] cursor-pointer "
        >
          <AiFillPlusCircle />
          <span>Add another owner</span>
        </div>
      )}
      <OnboardingFooter
        nextBtnLabel="Submit"
        color="md:bg-[#fff]"
        backFn={
          backFn
          // updateStep("choose", false);
          // setCurrentFlow("individual");
          // setSelectedTab("choose");
        }
        nextFn={() => handleSubmit(index)}
        // nextDisabled={!isValid}
      />
    </div>
  );
};
