import useQueryAction from "../../../lib/useQueryAction";
import useQueryMutation from "../../../lib/useQueryMutation";
import { StableCoinBeneficiary } from "../../../types/Users";
import {
  api,
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
} from "../../api";

export const useCardPrivateDetails = (id: string) => {
  return useQueryMutation({
    mutationFn: (data: { verificationCode: string }) =>
      makePostRequest(data, api.cards.cardPrivateDetails(id)),
  });
};
export const useCardRequest = () => {
  return useQueryMutation({
    mutationFn: (data: { pin: string; card_label: string }) =>
      makePostRequest(data, api.cards?.requestCard),
  });
};
export const useCardFreeze = (id: string) => {
  return useQueryMutation({
    mutationFn: () => makeGetRequest(api.cards.freeze(id)),
  });
};
export const useCardUnFreeze = (id: string) => {
  return useQueryMutation({
    mutationFn: () => makeGetRequest(api.cards.unfreeze(id)),
  });
};

export const useRequestOtp = (userId: string) => {
  return useQueryMutation({
    mutationFn: () => makeGetRequest(api.users.otpRequest(userId)),
  });
};
export const useCardById = (id: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.cards.cardDetails(id)),
    queryKey: ["cardid", id],
  });
};
export const useCardBalance = (id: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.cards.cardBalance(id)),
    queryKey: ["cardBalance", id],
  });
};
export const useGetCards = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.cards.cards),
    queryKey: ["cards"],
  });
};

export const useUnloadCards = () => {
  return useQueryMutation({
    mutationFn: (data: {
      pin: string;
      card_label: string;
      amount: number;
      card_id: string;
    }) => makePostRequest(data, api.cards.unLoadCard),
  });
};

export const useDeleteCard = (id: string) => {
  return useQueryMutation({
    mutationFn: () => makeDeleteRequest(api.cards.deleteCard(id)),
  });
};

export const useLoadCard = () => {
  return useQueryMutation({
    mutationFn: (data: {
      pin: string;
      card_label: string;
      amount: number;
      card_id: string;
    }) => makePostRequest(data, api.cards.load),
  });
};
