import React, { useCallback, useState } from "react";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import hamburger from "../../assets/misc/hamburger-button.svg";
import EmptyState from "../../components/base-components/EmptyState";
import { useAccActivity } from "../../sevices/hooks/wallets";
import { useSidebarState } from "../../store";
import { useRecieptState } from "../../store/useReciept";
import TransactionRecieptModal from "./transaction/TransactionRecieptModal";
import TransactionTable from "../../components/transaction-component/TransactionTable";
import Pagination from "../../components/transaction-component/paginate";

export const capitalize = (word: string) => {
  if (!word) return;
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const Transactions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);
  const showAllTransactionReciept = useRecieptState(
    (state) => state.showTransactionReciept
  );

  const {
    data: userActivities,
    isLoading,

  } = useAccActivity({
    page_size: pageSize,
    page: currentPage,
    currency: selectedCurrency,
  });

  const totalPages = Math.ceil((userActivities?.data?.total || 0) / pageSize);


  const handlePageChange = useCallback(
    (page: number) => {

      if (page === currentPage) return;
      setCurrentPage(page);
    },
    [currentPage]
  );

  const handlePageSizeChange = useCallback((newSize: number) => {
    // console.log("Page size changed to:", newSize);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page when changing page size
  }, []);

  const handleCurrencyChange = useCallback((currency: string) => {
    // console.log("Currency changed to:", currency);
    setSelectedCurrency(currency);
    setCurrentPage(1); // Reset to first page when changing currency
  }, []);

  // Prevent unnecessary re-renders during loading
  const paginationElement =
    userActivities?.data?.total > 0 ? (
      <div className="mt-4">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    ) : null;

  return (
    <div className="bg-[#fff] h-full w-full p-5 md:pl-8">
      {/* Logo for smaller screens */}
      <div className="w-16 ml-auto block md:hidden">
        <img src={logo} alt="Logo" />
      </div>

      {/* Header Section */}
      <div className="hamburger_menu flex flex-col gap-2 md:gap-2 items-start">
        <button
          className="md:hidden py-1 hover:bg-slate-200 rounded-md transition-all active:scale-90"
          onClick={() => toggleSideBarOpen()}
        >
          <img src={hamburger} alt="Menu" className="w-10" />
        </button>
        <h1 className="font-bold text-2xl text-[#071827]">Transactions</h1>
      </div>

      {/* Content Section */}
      {isLoading ? (
        <Spinner />
      ) : userActivities?.data?.total ? (
        <>
          <TransactionTable
            tableData={userActivities.data}
            tabs={["All", "NGN", "USD", "GBP", "EUR", "USDC", "USDT"]}
            onCurrencyChange={handleCurrencyChange}
          />
          {paginationElement}
        </>
      ) : (
        <EmptyState />
      )}

      {/* Transaction Receipt Modal */}
      {showAllTransactionReciept && <TransactionRecieptModal />}
    </div>
  );
};

export default Transactions;

const Spinner = () => (
  <div className="flex items-center justify-center h-64">
    <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-green-500"></div>
  </div>
);
