import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import { useSidebarState } from "../../store";
import ReactLoading from "react-loading";
import FiatWalletCard from "../dashboard-components/FiatWalletCard";
import { numberWithCommas } from "../../pages/dashboard/Withdrawals";
import { BsChevronLeft } from "react-icons/bs";
import useModalStore from "../../store/card";
import useVerificationModalStore from "../../store/cardVerification";
import { useRequestOtp } from "../../sevices/hooks/cards";
type Props = {
  cardBalance: any;
  walletBalance: any;
};

const DeleteCardModal = (props: Props) => {
  const { isVerificationOpen, openVerification, closeVerification } =
    useVerificationModalStore();
  const { openModal } = useModalStore();
  const getOtp = useRequestOtp(localStorage.getItem("user_id") as string);
  const deleteCard = useSidebarState((state) => state.deleteCard);
  const [check, setCheck] = useState(false);
  const setDeleteCard = useSidebarState((state) => state.setDeleteCard);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleInputChange = (name: string) => (newValue: string) => {};
  const handleInputFocus = (evt: { target: { name: any } }) => {};

  const [loading, setLoading] = useState(false);

  const inviteUser = (data: {
    email: string;
    lastName: string;
    firstName: string;
  }) => {};

  const getVerification = () => {
    setLoading(true);
    getOtp
      .mutateAsync({})
      .then(() => {
        setShowConfirmation(false);
        closeModal();

        openVerification();
      })
      .catch(() => {});
  };

  const closeModal = () => {
    setDeleteCard(false);
    setShowConfirmation(false);
  };
  return (
    <>
      <AnimatePresence>
        {deleteCard && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="modal fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
          >
            <motion.div
              animate={{
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="modal_overlay flex xxs:justify-center xxs:p-5 md:p-0 md:justify-end h-full cursor-pointer bg-[#13111173]"
            >
              {!showConfirmation ? (
                <motion.div
                  initial={{
                    x: 100,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 0,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white w-full max-w-sm md:max-w-sm max-h-full md:h-full relative xxs:rounded-md md:rounded-l-md p-5 md:px-7"
                >
                  <div className="flex items-center justify-between ">
                    <div>
                      <h2 className="text-xl font-semibold">Empty card</h2>
                    </div>
                    <div
                      onClick={closeModal}
                      className="close flex items-center justify-center h-10 w-10 rounded-full hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center self-end"
                    >
                      <span className="hover:rotate-90 hover:transform transition duration-150 ease-in-out">
                        <IoMdClose size={29} className="" />
                      </span>
                    </div>
                  </div>
                  <div className="mb-10">
                    <p>
                      You are required to first empty your card before deleting
                    </p>
                  </div>

                  <div className="flex flex-col items-center w-full gap-3  h-full">
                    <div className="flex flex-col w-full gap-3 mt-5 flex-grow">
                      <div className="w-full md:w-fit">
                        <p className="mb-2">Emptying from:</p>
                        <div>
                          <FiatWalletCard
                            text={"Card Balance"}
                            currency={"$ "}
                            refr={true}
                            amount={props.cardBalance}
                            mdHeight="100px"
                          />
                        </div>
                      </div>
                      <div className="w-full md:w-fit">
                        <p className="mb-2">Emptying to:</p>
                        <FiatWalletCard
                          text={"USD Balance"}
                          currency={"$ "}
                          refr={true}
                          amount={props.walletBalance}
                          mdHeight="100px"
                        />
                      </div>
                      <div>
                        <div className="flex justify-between">
                          <div>
                            <p className="text-gray-500 text-sm">Fee</p>
                          </div>
                          <div className="flex gap-2">
                            <p className="text-gray-800 font-medium">
                              {"Free"}
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div>
                            <p className="text-gray-500 text-sm">Total</p>
                          </div>
                          <div className="flex gap-2">
                            <p className="text-gray-800 font-medium">
                              $ {numberWithCommas(Number(props.cardBalance))}
                            </p>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        onClick={() => {
                          setShowConfirmation(true);
                        }}
                        className="text-white w-full bg-[#0eb683] rounded-[16px] px-10 py-3.5 mt-auto transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 item-center justify-center flex disabled"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  initial={{
                    x: 100,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 0,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white w-full max-w-sm md:max-w-sm max-h-full md:h-full relative xxs:rounded-md md:rounded-l-md p-5 md:px-5"
                >
                  <div className="flex items-end justify-end ">
                    <div
                      onClick={closeModal}
                      className="close  flex items-center justify-center h-10 w-10  rounded-full
                  hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center self-end"
                    >
                      <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                        <IoMdClose size={29} className="" />
                      </span>
                    </div>
                  </div>

                  <div>
                    <div className="absolute top-6 flex items-center gap-4 ">
                      <button
                        className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
                        onClick={() => {
                          setShowConfirmation(false);
                        }}
                      >
                        <BsChevronLeft />
                      </button>
                      <h1 className="  text-lg font-bold text-[#071827]">
                        Confirmation
                      </h1>
                    </div>
                    <p className="text-[#5C6F7F] pt-3">
                      Confirm the following details for your transaction
                    </p>
                  </div>
                  <div className="mt-10">
                    <p className="text-lg font-medium text-[#071827]">
                      You are funding your USD balance with
                    </p>
                    <p className="text-lg font-bold text-[#0eb683] mt-3">
                      {" "}
                      {`${numberWithCommas(Number(props.cardBalance))} USD`}
                    </p>
                  </div>
                  <div className="my-6">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-gray-500 text-sm">Fee</p>
                      </div>
                      <div className="flex gap-2">
                        <p className="text-gray-800 font-medium">{"Free"}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-[#071827] text-sm">
                      By clicking the button below, I agree my USD card would be
                      debited of the amount above and my USD wallet funded
                      immediately.
                    </p>
                  </div>
                  <button
                    className="text-white w-full bg-[#0eb683] rounded-[16px] px-10 py-3.5 mt-auto transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 item-center justify-center flex disabled"
                    onClick={() => {
                      getVerification();
                    }}
                  >
                    {loading ? (
                      <div className="mx-auto">
                        <ReactLoading
                          type="spinningBubbles"
                          color="#fff"
                          height="27px"
                          width="27px"
                        />
                      </div>
                    ) : (
                      "Continue"
                    )}
                  </button>
                </motion.div>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default DeleteCardModal;
