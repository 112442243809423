import create from "zustand";

interface IUserState {
  userDetails: any;
  setUserDetails: (value: any) => void;
}

interface CardState {
  fundModal: boolean;
  toggleFundModal: (value?: boolean) => void;
}

interface ISideBarState {
  sideBarOpen: boolean;
  invitationModalOpen: boolean;
  createCardModal: boolean;
  deleteCard: boolean;
  showDetails: boolean;
  showFunds: boolean; // Updated from showFund to showFunds

  setCreateCardModal: (value: boolean) => void;
  toggleSidebar: (value?: boolean) => void;
  setInvitationModalOpen: (value: boolean) => void;
  setDeleteCard: (value: boolean) => void;
  setShowDetails: (value: boolean) => void;
  setShowFund: (value: boolean) => void;
}

interface IRateState {
  usdRate: { buyRate: number; sellRate: number };
  eurRate: { buyRate: number; sellRate: number; eurConversion: string };
  gbpRate: { buyRate: number; sellRate: number; gbpConversion: string };
  setUsdRate(newUsdRate: { buyRate: number; sellRate: number }): void;
  setEurRate(newEurRate: {
    buyRate: number;
    sellRate: number;
    eurConversion: string;
  }): void;
  setGbpRate(newGbpRate: {
    buyRate: number;
    sellRate: number;
    gbpConversion: string;
  }): void;
}

// interface IAccountsState {
//   ngnAccount: number
//   usdAccount: number
//   setNgnAccount: (newAccount: number) => void
//   setUsdAccount: (newAccount: number) => void
// }

type Rate = {
  buyRate: number;
  sellRate: number;
  eurConversion?: string;
};

export interface RateStore {
  usdRate: Rate;
  eurRate: Rate;
  gbpRate: Rate;
  setUsdRate: (newUsdRate: Rate) => void;
  setEurRate: (newEurRate: Rate) => void;
  setGbpRate: (newGbpRate: Rate) => void;
}

export const useUserState = create<IUserState>((set) => ({
  userDetails: [],
  setUserDetails: (value: any) => set((state) => ({ userDetails: value })),
}));

export const useCardState = create<CardState>((set) => ({
  fundModal: false,
  toggleFundModal: (value?: boolean) =>
    set((state) => ({
      fundModal: value !== undefined ? value : !state.fundModal,
    })),
}));
export const useSidebarState = create<ISideBarState>((set) => ({
  sideBarOpen: false,
  invitationModalOpen: false,
  createCardModal: false,
  deleteCard: false,
  showDetails: false,
  showFunds: false,

  setCreateCardModal: (isOpen: boolean) =>
    set((state) => ({ createCardModal: isOpen })),
  toggleSidebar: (value?: boolean) =>
    set((state) => ({ sideBarOpen: value ?? !state.sideBarOpen })),
  setInvitationModalOpen: (isOpen: boolean) =>
    set((state) => ({ invitationModalOpen: isOpen })),
  setDeleteCard: (isOpen: boolean) => set((state) => ({ deleteCard: isOpen })),
  setShowDetails: (isOpen: boolean) =>
    set((state) => ({ showDetails: isOpen })),
  setShowFund: (isOpen: boolean) => set((state) => ({ showFunds: isOpen })),
}));

export const useRateStore = create<RateStore>((set) => ({
  usdRate: { buyRate: 0, sellRate: 0 },
  eurRate: { buyRate: 0, sellRate: 0, eurConversion: "" },
  gbpRate: { buyRate: 0, sellRate: 0, gbpConversion: "" },
  setUsdRate: (newUsdRate: Rate) => set((state) => ({ usdRate: newUsdRate })),
  setEurRate: (newEurRate: Rate) => set((state) => ({ eurRate: newEurRate })),
  setGbpRate: (newGbpRate: Rate) => set((state) => ({ gbpRate: newGbpRate })),
}));
export const useStore = create((set) => ({
  count: 0,
}));

interface IOnboardingStepC {
  isSubmitted: boolean;
  setIsSubmitted: (isSubmitted: boolean) => void;
}

export const useBusinessOwnerStep = create<IOnboardingStepC>((set) => ({
  isSubmitted: false,
  setIsSubmitted: (isSubmitted) => set({ isSubmitted }),
}));
