/* eslint-disable use-isnan */
import React, { useEffect, useState } from "react";
import { useTabs } from "../widgets/TabWidget";
import { TabSelector } from "../widgets/TabSelector";
import { TabPanel } from "../widgets/TabWidget";
import usFlag from "../../assets/flags/usd.png";
import gbpFlag from "../../assets/flags/pound.png";
import euFlag from "../../assets/flags/euro.png";
import AppSelectInput from "../base-components/AppSelectInput";
import DragDropComponent, { IFile } from "../base-components/DragDropComponent";
import RefreshIcon from "../../assets/iconsComponents/RefreshIcon";
import { motion, AnimatePresence } from "framer-motion";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import Notification from "../dashboard-components/Notification";
import { BsChevronLeft } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
// import { useGbpRates, useRates, useRatesEur } from "../../sevices/hooks/tags";
import {
  useAccountNgn,
  useAccountUsd,
  useBuyCurrency,
  useWalletBalance,
} from "../../sevices/hooks/wallets";
import AppNumericInput from "../widgets/AppNumericInput";
import { numberWithCommas } from "../../pages/dashboard/Withdrawals";
import SendMultiRadioInput from "../base-components/SendMultiRadio";
import SendRadioInput from "../base-components/SendRadioInput";
// import Summary from "./Summary";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Summary from "../wallet-components/Summary";
import BeneficiarySummary from "./BeneficiarySummary";
import { purposeOfPayment } from "../../types/PurposeOfPayment";
import SwapModal from "../dashboard-components/SwapModal";
import FundModal from "../dashboard-components/FundModal";
import { AxiosResponse } from "axios";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from "react-query";
import { useGetRates } from "../../sevices/hooks/meta-data";

const BeneficaryTransfer = () => {
  const [selectedCurrency, setSelectedCurrency] = useTabs([
    "USD",
    "GBP",
    "EUR",
  ]);
  const [fields, setFields] = useState({
    amount: "",
  });
  const currencyOptions = [
    {
      currency: "USD",
      flagSrc: usFlag,
      text: "USD ($)",
    },
    {
      currency: "GBP",
      flagSrc: gbpFlag,
      text: "GBP (£)",
    },
    {
      currency: "EUR",
      flagSrc: euFlag,
      text: "EUR (€)",
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleClick = (index: number) => {
    setSelectedIndex(index);
  };

  const { beneficiaryId, currency } = useParams();
  const [showBuyCurrency, setShowBuyCurrency] = useState(false);
  const [usdAmountValue, setUsdAmountValue] = useState<any>();
  const [fundedSuccess, setfundedSuccess] = useState(false);
  const [currentAssetTab, setCurrentAssetTab] = useState("buy");
  const [transferConfirm, setTransferConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  //hooks for Gbp operations

  const [showModal, setShowModal] = useState(false);
  const [currentGbpTab, setCurrentGbpTab] = useState("buy");
  const [gbpAmountValue, setGbpAmountValue] = useState<any>();
  const [showBuyGbp, setShowBuyGbp] = useState(false);
  const [confirmBuyGbp, setConfirmBuyGbp] = useState(false);
  const [gbpBuySuccess, setGbpBuySuccess] = useState(false);
  const [confirmSellGbp, setConfirmSellGbp] = useState(false);
  const [gbpSellSuccess, setGbpSellSuccess] = useState(false);

  const [choice, setChoice] = useTabs(["No", "Yes"]);

  //hooks for EUR operations
  const [currentEurTab, setCurrentEurTab] = useState("buy");
  const [showBuyEur, setShowBuyEur] = useState(false);
  const [eurAmountValue, setEurAmountValue] = useState<any>();
  const [confirmBuyEur, setConfirmBuyEur] = useState(false);
  const [eurBuySuccess, setEurBuySuccess] = useState(false);
  const [confirmSellEur, setConfirmSellEur] = useState(false);
  const [eurSellSuccess, setEurSellSuccess] = useState(false);

  //fund
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFundModal, setShowFundModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [amountValue, setAmountValue] = useState<any>();

  //rates
  const rate = useGetRates(selectedCurrency as string);
  // const rate = useGetRates("EUR");
  // const rate = useGetRates("GBP");
  // const rate = useRates();

  // console.log(selectedCurrency, "hhh");

  //BuyUsd
  // const [usdAmountValue, setUsdAmountValue] = useState<any>();
  const [NGNAmountValue, setNGNAmountValue] = useState<any>();
  const [appUSDRate, setappUSDRate] = useState(rate?.data?.data);
  const [sourceOfFunds, setSourceOfFunds] = useState("");
  //payment option:
  const [selectedReason, setSelectedReason] = useState("");
  const [sendersAmountInclusive, setSendersAmountInclusive] = useState(true);
  const [usdBalance, setUsdBalance] = useState(0);
  const [transactionAmount, setTransactionAmount] = useState<any>();

  const [showSummary, setShowSummary] = useState(false);
  const [debittedAmount, setDebittedAmount] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [sourceofFundsDocs, setSourceofFundsDocs] = useState("SOURCE OF FUNDS");
  const [invoice, setInvoice] = useState("Payment Invoice");
  const [SOFdocsUrl, setSOFDocsUrl] = useState<FormData>();
  const [payInvoiceDocsUrl, setPayInvoiceDocsUrl] = useState<FormData>();
  //balances
  const accountNgn = useWalletBalance("ngn");
  const accountUsd = useWalletBalance("usd");
  // const buyCurr

  const { currency: params } = useParams();
  // const navigate = useNavigate();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    //@ts-ignore
    params.set("currency", selectedCurrency);
    navigate({ search: params.toString() });
  }, [selectedCurrency, location.search, navigate]);

  const onfieldChange = ({ name, value }: { value: any; name: any }) => {
    setFields({ ...fields, [name]: value });
  };
  const confirmation = () => {
    setShowConfirmationModal(true);
  };

  const getPaymentsInvoice = (files: any) => {
    const formData = new FormData();
    files.forEach((file: string | Blob, index: any) => {
      formData.append(`documents`, file);
    });
    formData.append("invoice", invoice);
    setPayInvoiceDocsUrl(formData);
  };

  const getSOFDocs = (files: any) => {
    const formData = new FormData();
    files.forEach((file: string | Blob, index: any) => {
      formData.append(`documents`, file);
    });
    formData.append("sourceofFundsDocs", sourceofFundsDocs);
    setSOFDocsUrl(formData);
  };

  const openModal = () => {
    setShowModal(true);
    // confirmation()
  };
  const closeModal = () => {
    setShowModal(false);
    setShowConfirmationModal(false);
    setShowSuccess(true);
  };

  function commas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const transferAmount = sendersAmountInclusive
    ? numberWithCommas(transactionAmount?.value)
    : numberWithCommas(
        (
          Number(transactionAmount?.value) +
          (Number(transactionAmount?.value) * 0.4) / 100 +
          50
        ).toString()
      );

  const debit = sendersAmountInclusive
    ? numberWithCommas(
        (
          transactionAmount?.value -
          (Number(transactionAmount?.value) * 0.4) / 100 -
          50
        ).toString()
      )
    : numberWithCommas(transactionAmount?.value);

  const serviceFee = numberWithCommas(
    ((transactionAmount?.value * 0.4) / 100).toString()
  );

  const credit = sendersAmountInclusive
    ? numberWithCommas(
        (
          transactionAmount?.value -
          (Number(transactionAmount?.value) * 0.4) / 100 -
          50
        ).toString()
      )
    : numberWithCommas(transactionAmount?.value);
  const [summaryData, setSummaryData] = useState({});

  const handleSummary = (
    boolean: boolean | ((prevState: boolean) => boolean)
  ) => {
    setShowSummary(boolean);
  };

  useEffect(() => {}, [accountUsd]);

  let regEx =
    /\b([1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9][0-9][0-9])\b/;

  const amountErr = `Minimum amount is NGN ${commas(
    Number(rate?.data?.data?.buyRate) * Number(100)
  )} `;
  const GbpErr = `Minimum amount is NGN ${commas(
    Number(rate?.data?.data?.buyRate * 100).toFixed()
  )}`;
  const EurErr = `Minimum amount is NGN ${commas(
    Number(rate?.data?.data?.buyRate * 100).toFixed()
  )}`;

  return (
    <div className="bg-[#F2FAF7] md:pl-5 md:pt-5">
      {!showSummary ? (
        <div className="bg-[#F2FAF7]  md:max-w-lg md:bg-white ">
          <div className="px-5 flex xxs:flex-col md:flex-row md:justify-between pt-5">
            <h1 className="text-[#071827] text-[20px] leading-[30px] font-medium  ">
              Payment Instructions
            </h1>
            <div className="flex flex-col gap-1  pb-3 xxs:my-5 md:my-0">
              <small className="text-[16px] leading-[24px] text-[#5C6F7F] font-normal  ">
                Current Rate
              </small>
              <div className="flex items-center gap-2">
                {selectedCurrency === "USD" && (
                  <>
                    <img src={usFlag} alt="usflag" className="w-5 h-5" />
                    <h1 className="text-[#0EB683] font-bold text-[20px] leading-[120%]">
                      $1 / N{rate?.data?.data?.buyRate}
                    </h1>
                  </>
                )}
                {selectedCurrency === "EUR" && (
                  <>
                    <img src={euFlag} alt="usflag" className="w-5 h-5" />
                    <h1 className="text-[#0EB683] font-bold text-[20px] leading-[120%]">
                      <span>&#8364;</span>1 / N{rate?.data?.data?.buyRate}
                    </h1>
                  </>
                )}{" "}
                {selectedCurrency === "GBP" && (
                  <>
                    <img src={gbpFlag} alt="usflag" className="w-5 h-5" />
                    <h1 className="text-[#0EB683] font-bold text-[20px] leading-[120%]">
                      <span>&#163;</span>1 / N{rate?.data?.data?.buyRate}
                    </h1>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="px-5 ">
            <span className="text-[14px] leading-[21px] font-normal text-[#0B0C0E]">
              Select Currency
            </span>
            <span className="text-[#E01A00] ">*</span>
          </div>
          <nav className=" flex items-center g px-5 justify-between    ">
            {currencyOptions.map((option) => (
              <TabSelector
                key={option.currency}
                className={`flex items-center md:gap-2 cursor-pointer relative bg-transparent mr-4 md:mr-0 ${
                  selectedCurrency === option.currency
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={selectedCurrency === option.currency}
                onClick={() => {
                  //@ts-ignore
                  setSelectedCurrency(option.currency);
                  setTransactionAmount("");
                }}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                  <img
                    src={option.flagSrc}
                    alt={option.currency + "Flag"}
                    className="w-7 hidden md:block"
                  />
                  <h1 className="text-[#5C6F7F] md:hidden">{option.text}</h1>
                </div>
                <h1 className="text-[#5C6F7F] md:font-bold  hidden md:block">
                  {option.text}
                </h1>
              </TabSelector>
            ))}
          </nav>
          <main className="panel p-5 md:p-0 flex flex-col mt-5">
            <TabPanel hidden={selectedCurrency !== "USD"} className=" ">
              <div className="md:px-5 md:pb-5 ">
                <div className="card ">
                  <div className="flex flex-col  gap-4">
                    <div className="mid relative flex flex-col">
                      <label className="">
                        <span className="text-[14px] leading-[21px] text-[#0B0C0E]">
                          Source of funds
                        </span>
                        <span className="text-[#ED0000]">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="py-3 px-4 border w-full md:bg-[#F2FAF7] xxs:bg-[#fff] rounded-sm placeholder:text-[14px] placeholder:leading-[16px] placeholder-[#5C6F7F]"
                        placeholder="Enter Source of funds"
                        value={sourceOfFunds}
                        onChange={(e) => setSourceOfFunds(e.target.value)}
                      />
                    </div>
                    <div className="mid ">
                      <p className="text-[14px] leading-[21px] text-[#0B0C0E]">
                        Upload Proof of source of funds
                        <span className="text-[#ED0000]">*</span>{" "}
                      </p>
                      <DragDropComponent getFiles={getSOFDocs} inputId="bbb" />
                      <p className="text-[#5C6F7F] text-[10px] leading-[16px]">
                        These include payslips, transfer instruction, evidential
                        letters, contract agreements etc. Supported file types
                        .pdf, .docx, .jpeg, .jpg, .png, .txt
                      </p>
                    </div>
                    <div className="mid  relative">
                      <AppSelectInput
                        options={purposeOfPayment || []}
                        label=""
                        className="bg-#000"
                        placeholder="Select purpose of payment"
                        handleChange={(inputValue) => {
                          inputValue?.value &&
                            setSelectedReason(inputValue?.value);
                        }}
                      />
                      <span className="text-[#ED0000] absolute left-2 bottom-4">
                        *
                      </span>
                    </div>
                    <div className="mid ">
                      <p className="text-[14px] leading-[21px] text-[#0B0C0E]">
                        Upload payment invoice
                        <span className="text-[#ED0000]">*</span>{" "}
                      </p>
                      <DragDropComponent
                        getFiles={getPaymentsInvoice}
                        inputId="yyy"
                      />
                      <p className="text-[#5C6F7F] text-[10px] leading-[16px]">
                        These include invoices, evidential letters, contract
                        agreements etc. Supported file types .pdf, .docx, .jpeg,
                        .jpg, .png, .txt
                      </p>
                    </div>
                    <div className="mid ">
                      <p className="text-[14px] leading-[21px] text-[#0B0C0E]">
                        Current Balance
                      </p>

                      <div className="flex items-center justify-between">
                        <h1 className="text-[#0eb683] text-[20px] leading-[30px] font-semibold">
                          ${" "}
                          {numberWithCommas(accountUsd?.data?.data) === "NaN"
                            ? "0.00"
                            : numberWithCommas(accountUsd?.data?.data)}
                        </h1>

                        <button
                          // disabled={true}
                          onClick={() => {
                            setShowBuyCurrency(true);
                          }}
                          disabled
                          className="hidden md:flex px-7 py-2  cursor-pointer transition-all active:scale-90 border bg-[#0eb683] text-[#fff] border-[#0eb683] shadow-sm rounded-md ease-in-out "
                        >
                          Buy
                        </button>
                      </div>
                      {showBuyCurrency && (
                        <SwapModal
                          baseCurrency={"usd"}
                          baseCurrencySymbol={"$ "}
                          currency={"ngn"}
                          currencySymbol={"₦ "}
                          rate={rate?.data?.data?.buyRate}
                          sellRate={rate?.data?.data?.sellRate}
                          confirmation={showConfirmation}
                          setConfirmation={setShowConfirmation}
                          inputValue={NGNAmountValue?.value}
                          inputValueFormatted={NGNAmountValue?.formattedValue}
                          inputValueFloat={NGNAmountValue?.floatValue}
                          setInputValue={setNGNAmountValue}
                          openModal={showBuyCurrency}
                          loading={loading}
                          setLoading={setLoading}
                          setOpenModal={setShowBuyCurrency}
                          balance={
                            accountNgn?.data?.data?.available_balance?.formatted
                          }
                          baseBalance={accountUsd?.data?.data}
                          computedValue={usdAmountValue?.value}
                          computedValueFloat={usdAmountValue?.floatValue}
                          computedValueFormatted={
                            usdAmountValue?.formattedValue
                          }
                          setComputedValue={setUsdAmountValue}
                          errorMessage={amountErr}
                          success={showSuccessModal}
                          setSuccess={setShowSuccessModal}
                          // initiateBuy={purchaseUsd}
                          setFundModal={setShowFundModal}
                          refetchRates={function <TPageData>(
                            options?:
                              | (RefetchOptions &
                                  RefetchQueryFilters<TPageData>)
                              | undefined
                          ): Promise<
                            QueryObserverResult<
                              AxiosResponse<any, any>,
                              unknown
                            >
                          > {
                            throw new Error("Function not implemented.");
                          }}
                        />
                      )}

                      <AnimatePresence>
                        {showSuccessModal && (
                          <div>
                            <motion.div
                              initial={{
                                opacity: 0,
                              }}
                              animate={{
                                opacity: 1,
                                transition: {
                                  duration: 0.3,
                                },
                              }}
                              exit={{
                                opacity: 0,
                                transition: {
                                  // delay: 0.3,
                                  duration: 0.1,
                                },
                              }}
                              className="confirmation_modal backdrop-blur-sm
                          bg-[#13111137] fixed w-full top-0   left-0 justify-center h-full items-center z-50"
                            >
                              <motion.div
                                initial={{
                                  x: 600,
                                  scale: 0,
                                }}
                                animate={{
                                  x: 0,
                                  scale: 1,
                                  transition: {
                                    duration: 0.3,
                                  },
                                }}
                                exit={{
                                  x: 600,
                                  scale: 0,
                                  transition: {
                                    delay: 0.3,
                                  },
                                }}
                                className="flex h-screen items-center justify-center md:justify-end "
                              >
                                <motion.div
                                  initial={{
                                    x: 600,
                                    opacity: 0,
                                  }}
                                  animate={{
                                    x: 0,
                                    opacity: 1,
                                    transition: {
                                      delay: 0.3,
                                      duration: 0.3,
                                    },
                                  }}
                                  exit={{
                                    x: 100,
                                    opacity: 1,
                                    transition: {
                                      duration: 0.3,
                                    },
                                  }}
                                  className="desktop-card md:flex hidden justify-center flex-col gap-8 bg-white md:h-full w-full max-w-md h-[450px] rounded-l-3xl p-5 "
                                >
                                  <div className="icon self-center">
                                    <SuccessMobile
                                      width={"100px"}
                                      height={"100px"}
                                    />
                                  </div>
                                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                                    Order placed
                                  </h1>

                                  <p className="text-sm text-[#5C6F7F] self-center text-center">
                                    Your purchase is in progress. Please check
                                    your FCY wallet
                                  </p>

                                  <button
                                    onClick={() => setShowSuccessModal(false)}
                                    className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                                  >
                                    Done
                                  </button>
                                </motion.div>
                                {/* Mobile */}

                                <motion.div
                                  initial={{
                                    x: 600,
                                    opacity: 0,
                                  }}
                                  animate={{
                                    x: 0,
                                    opacity: 1,
                                    transition: {
                                      delay: 0.3,
                                      duration: 0.3,
                                    },
                                  }}
                                  exit={{
                                    x: 100,
                                    opacity: 1,
                                    transition: {
                                      duration: 0.3,
                                    },
                                  }}
                                  className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white shadow-2xl w-full max-w-sm h-[450px] <rounded-3></rounded-3>xl p-5 "
                                >
                                  <div className="icon self-center">
                                    <SuccessMobile
                                      width={"100px"}
                                      height={"100px"}
                                    />
                                  </div>
                                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                                    Order placed
                                  </h1>

                                  <p className="text-sm text-[#5C6F7F] self-center text-center">
                                    Your purchase is in progress. Please check
                                    your FCY wallet
                                  </p>

                                  <button
                                    onClick={() => setShowSuccessModal(false)}
                                    className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90"
                                  >
                                    Done
                                  </button>
                                </motion.div>
                              </motion.div>
                            </motion.div>
                          </div>
                        )}
                      </AnimatePresence>
                    </div>
                    <button
                      onClick={() => {
                        setShowBuyCurrency(true);
                      }}
                      className="w-full md:hidden p-4 text-[#0eb683] bg-white border border-[#0eb683] rounded-md transition-all active:scale-90"
                    >
                      Buy
                    </button>

                    <AppNumericInput
                      value={numberWithCommas(transactionAmount?.value)}
                      onValueChange={(value: any) => {
                        setTransactionAmount(value);
                      }}
                      thousandSeparator={true}
                      prefix="$ "
                      style={{ borderColor: "#0EB683" }}
                      labelStyle={{
                        color: "#000",
                        fontWeight: "bold",
                        // margin: "20px 0px 10px",
                      }}
                      rightLabel={`Minimum transferable amount is $ 100`}
                      rightLabelStyle={{
                        fontStyle: "bold",
                        fontSize: "13px",
                        color: "#ED0000",
                      }}
                      // label="Amount you wish to swap (USD)"
                      placeholder="Amount you want to send in USD ($)"
                      // isAllowed={(nValue: any) => nValue?.value?.length <= 11}
                      isAllowed={(nValue: any) =>
                        nValue?.value?.length <= 11 &&
                        nValue?.value?.indexOf("-") === -1
                      }
                      isInsufficient={
                        accountUsd?.data?.data <
                        parseFloat(transactionAmount?.value)
                      }
                      insufficientLabel="Insufficient balance"
                    />
                    <div>
                      <span className="text-[14px] leading-[21px] text-[#5C6F7F] font-normal">
                        Should service fee be debited from total amount?
                      </span>{" "}
                      <span className="text-[#ED0000]">*</span>
                    </div>
                    <div className="flex  ">
                      <SendRadioInput
                        label=""
                        getValue={(value: boolean) => {
                          setSendersAmountInclusive(value);
                        }}
                        isRequired={false}
                        defaultValue={true}
                        positiveText="YES"
                        negativeText="NO"
                        revPos={true}
                      />
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Service Fee @0.25%
                      </p>
                      <h1 className="text-[#071827] font-bold text-[20px] leading-[30px]">
                        {" "}
                        {transactionAmount?.value === "" ||
                        transactionAmount?.value === undefined
                          ? "0.00"
                          : "$ " +
                            numberWithCommas(
                              (
                                (transactionAmount?.value * 0.4) /
                                100
                              ).toString() || "0.00"
                            )}
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Offshore Charges
                      </p>
                      <h1 className="text-[#071827] font-bold text-[20px] leading-[30px]">
                        $ 50.00
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Total Amount to be debited
                      </p>
                      <h1 className="text-[#0eb683] font-bold text-[20px] leading-[30px]">
                        {" "}
                        {transactionAmount?.value === "" ||
                        transactionAmount?.value === undefined
                          ? "0.00"
                          : `$ ${
                              sendersAmountInclusive
                                ? numberWithCommas(transactionAmount?.value)
                                : numberWithCommas(
                                    (
                                      Number(transactionAmount?.value) +
                                      (Number(transactionAmount?.value) * 0.4) /
                                        100 +
                                      50
                                    ).toString()
                                  )
                            }`}
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Beneficiary receives
                      </p>
                      <h1 className="text-[#071827] font-bold text-[20px] leading-[30px]">
                        {" "}
                        {transactionAmount?.value === "" ||
                        transactionAmount?.value === undefined
                          ? "0.00"
                          : `$ ${
                              sendersAmountInclusive
                                ? numberWithCommas(
                                    (
                                      transactionAmount?.value -
                                      (Number(transactionAmount?.value) * 0.4) /
                                        100 -
                                      50
                                    ).toString()
                                  )
                                : numberWithCommas(transactionAmount?.value)
                            }`}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between md:bg-[#F2FAF7] pb-20 md:pb-6 ">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="text-[#0eb683] bg-white border border-[#0eb683] p-3 px-6 mt-10     md:max-w-lg rounded-md transition-all active:scale-90"
                >
                  Previous
                </button>

                <button
                  disabled={
                    !transactionAmount?.value ||
                    !selectedReason ||
                    accountUsd?.data?.data <
                      parseFloat(transactionAmount?.value) ||
                    !regEx.test(transactionAmount?.value)
                  }
                  onClick={() => setShowSummary(true)}
                  className="bg-[#0eb683] text-white px-6 p-3 mt-10     md:max-w-lg rounded-md transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                >
                  Continue
                </button>
              </div>
            </TabPanel>
            <TabPanel hidden={selectedCurrency !== "GBP"}>
              {/* <div className="flex flex-col gap-2 md:px-5 pb-3">
                <small className="text-sm text-[#5C6F7F] md:pt-8 ">
                  Current Rate
                </small>
                <div className="flex items-center gap-2">
                  <img src={gbpFlag} alt="usflag" className="w-7" />
                  <h1 className="text-[#0EB683] font-bold text-xl">
                    <span>&#163;</span>1 / N{rate?.data?.data?.buyRate}
                  </h1>
                </div>
              </div> */}
              <div className="md:px-5 md:pb-5 ">
                <div className="card ">
                  <div className="flex flex-col  gap-4">
                    <div className="mid relative flex flex-col">
                      <label className="">
                        <span className="text-[14px] leading-[21px] text-[#0B0C0E]">
                          Source of funds
                        </span>
                        <span className="text-[#ED0000]">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="py-3 px-4 border w-full md:bg-[#F2FAF7] xxs:bg-[#fff] rounded-sm placeholder:text-[14px] placeholder:leading-[16px] placeholder-[#5C6F7F]"
                        placeholder="Enter Source of funds"
                        value={sourceOfFunds}
                        onChange={(e) => setSourceOfFunds(e.target.value)}
                      />
                    </div>
                    <div className="mid ">
                      <p className="text-[14px] leading-[21px] text-[#0B0C0E]">
                        Upload Proof of source of funds
                        <span className="text-[#ED0000]">*</span>{" "}
                      </p>
                      <DragDropComponent getFiles={getSOFDocs} inputId="bbb" />
                      <p className="text-[#5C6F7F] text-[10px] leading-[16px]">
                        These include payslips, transfer instruction, evidential
                        letters, contract agreements etc. Supported file types
                        .pdf, .docx, .jpeg, .jpg, .png, .txt
                      </p>
                    </div>
                    <div className="mid  relative">
                      <AppSelectInput
                        options={purposeOfPayment || []}
                        label=""
                        placeholder="Select purpose of payment"
                        handleChange={() => {}}
                      />
                      <span className="text-[#ED0000] absolute left-2 bottom-4">
                        *
                      </span>
                    </div>
                    <div className="mid ">
                      <p className="text-[14px] leading-[21px] text-[#0B0C0E]">
                        Upload payment invoice
                        <span className="text-[#ED0000]">*</span>{" "}
                      </p>
                      <DragDropComponent
                        getFiles={getPaymentsInvoice}
                        inputId="heyy"
                      />
                      <p className="text-[#5C6F7F] text-[10px] leading-[16px]">
                        These include invoices, evidential letters, contract
                        agreements etc. Supported file types .pdf, .docx, .jpeg,
                        .jpg, .png, .txt
                      </p>
                    </div>
                    <div className="mid ">
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px]">
                        Current Balance
                      </p>
                      <div className="flex items-center justify-between">
                        <h1 className="text-[#0eb683] text-[20px] leading-[30px] font-semibold">
                          <span>&#163;</span> 0.00
                        </h1>

                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setShowBuyGbp(true);
                          }}
                          disabled
                          className="hidden md:flex px-7 py-2  cursor-pointer transition-all active:scale-90 border bg-[#0eb683] text-[#fff] border-[#0eb683] shadow-sm rounded-md ease-in-out "
                        >
                          Buy
                        </button>
                      </div>
                    </div>

                    {showBuyGbp && (
                      <SwapModal
                        baseCurrency={"gbp"}
                        baseCurrencySymbol={"£ "}
                        currency={"ngn"}
                        currencySymbol={"₦ "}
                        rate={rate?.data?.data?.buyRate}
                        sellRate={rate?.data?.data?.sellRate}
                        confirmation={confirmSellGbp}
                        setConfirmation={setConfirmSellGbp}
                        inputValue={NGNAmountValue?.value}
                        inputValueFormatted={NGNAmountValue?.formattedValue}
                        inputValueFloat={NGNAmountValue?.floatValue}
                        setInputValue={setNGNAmountValue}
                        openModal={showBuyGbp}
                        loading={loading}
                        setLoading={setLoading}
                        setOpenModal={setShowBuyGbp}
                        balance={
                          accountNgn?.data?.data?.available_balance?.formatted
                        }
                        baseBalance={"0"}
                        computedValue={gbpAmountValue?.value}
                        computedValueFloat={gbpAmountValue?.floatValue}
                        computedValueFormatted={gbpAmountValue?.formattedValue}
                        setComputedValue={setGbpAmountValue}
                        errorMessage={GbpErr}
                        success={gbpBuySuccess}
                        setSuccess={setGbpBuySuccess}
                        // initiateBuy={() => {}}
                        setFundModal={setShowFundModal}
                        refetchRates={function <TPageData>(
                          options?:
                            | (RefetchOptions & RefetchQueryFilters<TPageData>)
                            | undefined
                        ): Promise<
                          QueryObserverResult<AxiosResponse<any, any>, unknown>
                        > {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    )}

                    <AnimatePresence>
                      {gbpBuySuccess && (
                        <motion.div
                          initial={{
                            opacity: 0,
                          }}
                          animate={{
                            opacity: 1,
                            transition: {
                              duration: 0.3,
                            },
                          }}
                          exit={{
                            opacity: 0,
                            transition: {
                              // delay: 0.3,
                              duration: 0.1,
                            },
                          }}
                          className="confirmation_modal backdrop-blur-sm
                    bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
                        >
                          <motion.div
                            initial={{
                              x: 600,
                              scale: 0,
                            }}
                            animate={{
                              x: 0,
                              scale: 1,
                              transition: {
                                duration: 0.3,
                              },
                            }}
                            exit={{
                              x: 600,
                              scale: 0,
                              transition: {
                                delay: 0.3,
                              },
                            }}
                            className="flex h-screen items-center justify-center md:justify-end "
                          >
                            <motion.div
                              initial={{
                                x: 600,
                                opacity: 0,
                              }}
                              animate={{
                                x: 0,
                                opacity: 1,
                                transition: {
                                  delay: 0.3,
                                  duration: 0.3,
                                },
                              }}
                              exit={{
                                x: 100,
                                opacity: 1,
                                transition: {
                                  duration: 0.3,
                                },
                              }}
                              className="desktop-card md:flex hidden justify-center flex-col gap-8 bg-white h-full w-full md:w-[448px] rounded-l-3xl p-5 "
                            >
                              <div className="icon self-center">
                                <SuccessMobile
                                  width={"100px"}
                                  height={"100px"}
                                />
                              </div>
                              <h1 className="text-2xl font-bold text-[#071827] self-center">
                                Order placed
                              </h1>

                              <p className="text-sm text-[#5C6F7F] self-center text-center">
                                Your purchase is in progress. Please check your
                                FCY wallet
                              </p>

                              <button
                                onClick={() => setGbpBuySuccess(false)}
                                className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                              >
                                Done
                              </button>
                            </motion.div>
                            {/* Mobile */}

                            <motion.div
                              initial={{
                                x: 600,
                                opacity: 0,
                              }}
                              animate={{
                                x: 0,
                                opacity: 1,
                                transition: {
                                  delay: 0.3,
                                  duration: 0.3,
                                },
                              }}
                              exit={{
                                x: 100,
                                opacity: 1,
                                transition: {
                                  duration: 0.3,
                                },
                              }}
                              className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white shadow-2xl w-full max-w-sm h-[450px] <rounded-3></rounded-3>xl p-5 "
                            >
                              <div className="icon self-center">
                                <SuccessMobile
                                  width={"100px"}
                                  height={"100px"}
                                />
                              </div>
                              <h1 className="text-2xl font-bold text-[#071827] self-center">
                                Order placed
                              </h1>

                              <p className="text-sm text-[#5C6F7F] self-center text-center">
                                Your purchase is in progress. Please check your
                                FCY wallet
                              </p>

                              <button
                                onClick={() => {
                                  setGbpBuySuccess(false);
                                }}
                                className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90"
                              >
                                Done
                              </button>
                            </motion.div>
                          </motion.div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowBuyGbp(true);
                      }}
                      className="w-full md:hidden p-4 text-[#0eb683] bg-white border border-[#0eb683] rounded-md transition-all active:scale-90"
                    >
                      Buy
                    </button>
                    <div className="mid ">
                      <AppNumericInput
                        value={""}
                        onValueChange={() => {}}
                        thousandSeparator={true}
                        prefix="$ "
                        style={{ borderColor: "#0EB683" }}
                        labelStyle={{
                          color: "#000",
                          fontWeight: "bold",
                          // margin: "20px 0px 10px",
                        }}
                        isAllowed={(nValue: any) =>
                          nValue.value.length <= 11 &&
                          nValue.value.indexOf("-") === -1
                        }
                        rightLabel={`Minimum transferable amount is £ 100`}
                        rightLabelStyle={{
                          fontStyle: "bold",
                          fontSize: "13px",
                          color: "#ED0000",
                        }}
                        // label="Amount you wish to swap (USD)"
                        placeholder="Amount you want to send in GBP (£)"
                        // isAllowed={(nValue: any) => nValue?.value?.length <= 11}
                        // isInsufficient={
                        //   accountUsd?.data?.data <
                        //   parseFloat(transactionAmount?.value)
                        // }
                        insufficientLabel="Insufficient balance"
                      />
                      <span className="text-[#ED0000] absolute left-2 bottom-4">
                        *
                      </span>
                    </div>
                    <div className="">
                      <span className="text-[14px] leading-[21px] text-[#5C6F7F] font-normal">
                        Should service fee be debited from total amount?
                      </span>{" "}
                      <span className="text-[#ED0000]">*</span>
                    </div>
                    <div className="flex  ">
                      <SendRadioInput
                        label=""
                        getValue={(value: boolean) => {
                          setSendersAmountInclusive(value);
                        }}
                        isRequired={false}
                        defaultValue={true}
                        positiveText="YES"
                        negativeText="NO"
                        revPos={true}
                      />
                    </div>

                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Service Fee @0.25%
                      </p>
                      <h1 className="text-[#071827] font-bold text-[20px] leading-[30px]">
                        £ 0.00
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Offshore Charges
                      </p>
                      <h1 className="text-[#071827] font-bold text-[20px] leading-[30px]">
                        £ 50.00
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Total Amount to be debited
                      </p>
                      <h1 className="text-[#0eb683] font-bold text-[20px] leading-[30px]">
                        {" "}
                        £ 0.00
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Beneficiary receives
                      </p>
                      <h1 className="text-[#071827] font-bold text-[20px] leading-[30px]">
                        £ 0.00
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between md:bg-[#f7fbfb] pb-20 md:pb-6">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="text-[#0eb683] bg-white border border-[#0eb683] p-3 px-6 mt-10     md:max-w-lg rounded-md transition-all active:scale-90"
                >
                  Previous
                </button>
                <button
                  disabled={true}
                  onClick={() => setShowSummary(true)}
                  className="bg-[#0eb683] text-white px-6 p-3 mt-10     md:max-w-lg rounded-md transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                >
                  Continue
                </button>
              </div>
            </TabPanel>
            <TabPanel hidden={selectedCurrency !== "EUR"}>
              {/* <div className="flex flex-col gap-2 md:px-5 pb-3">
                <small className="text-sm text-[#5C6F7F] md:pt-8 ">
                  Current Rate
                </small>
                <div className="flex items-center gap-2">
                  <img src={euFlag} alt="usflag" className="w-7" />
                  <h1 className="text-[#0EB683] font-bold text-xl">
                    <span>&#8364;</span>1 / N{rate?.data?.data?.buyRate}
                  </h1>
                </div>
              </div> */}

              <div className="md:px-5 md:pb-5 ">
                <div className="card ">
                  <div className="flex flex-col  gap-4">
                    <div className="mid relative flex flex-col">
                      <label className="">
                        <span className="text-[14px] leading-[21px] text-[#0B0C0E]">
                          Source of funds
                        </span>
                        <span className="text-[#ED0000]">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="py-3 px-4 border w-full md:bg-[#F2FAF7] xxs:bg-[#fff] rounded-sm placeholder:text-[14px] placeholder:leading-[16px] placeholder-[#5C6F7F]"
                        placeholder="Enter Source of funds"
                        value={sourceOfFunds}
                        onChange={(e) => setSourceOfFunds(e.target.value)}
                      />
                    </div>
                    <div className="mid ">
                      <p className="text-[14px] leading-[21px] text-[#0B0C0E]">
                        Upload Proof of source of funds
                        <span className="text-[#ED0000]">*</span>{" "}
                      </p>
                      <DragDropComponent getFiles={getSOFDocs} inputId="bbb" />
                      <p className="text-[#5C6F7F] text-[10px] leading-[16px]">
                        These include payslips, transfer instruction, evidential
                        letters, contract agreements etc. Supported file types
                        .pdf, .docx, .jpeg, .jpg, .png, .txt
                      </p>
                    </div>
                    <div className="mid  relative">
                      <AppSelectInput
                        options={purposeOfPayment || []}
                        label=""
                        placeholder="Select purpose of payment"
                        handleChange={() => {}}
                      />
                      <span className="text-[#ED0000] absolute left-2 bottom-4">
                        *
                      </span>
                    </div>
                    <div className="mid ">
                      <p className="text-[14px] leading-[21px] text-[#0B0C0E]">
                        Upload payment invoice
                        <span className="text-[#ED0000]">*</span>{" "}
                      </p>
                      <DragDropComponent
                        getFiles={getPaymentsInvoice}
                        inputId="heyy"
                      />
                      <p className="text-[#5C6F7F] text-[10px] leading-[16px]">
                        These include invoices, evidential letters, contract
                        agreements etc. Supported file types .pdf, .docx, .jpeg,
                        .jpg, .png, .txt
                      </p>
                    </div>
                    <div className="mid ">
                      <p className="text-[14px] leading-[21px] text-[#0B0C0E]">
                        Current Balance
                      </p>
                      <div className="flex items-center justify-between">
                        <h1 className="text-[#0eb683] text-[20px] leading-[30px] font-semibold">
                          € 0.00
                        </h1>

                        <button
                          // disabled={true}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowBuyEur(true);
                          }}
                          disabled
                          className="hidden md:flex px-7 py-2  cursor-pointer transition-all active:scale-90 border bg-[#0eb683] text-[#fff] border-[#0eb683] shadow-sm rounded-md ease-in-out "
                        >
                          Buy
                        </button>
                      </div>
                    </div>

                    {showBuyEur && (
                      <SwapModal
                        baseCurrency={"eur"}
                        baseCurrencySymbol={"€ "}
                        currency={"ngn"}
                        currencySymbol={"₦ "}
                        rate={rate?.data?.data?.buyRate}
                        sellRate={rate?.data?.data?.sellRate}
                        confirmation={confirmSellEur}
                        setConfirmation={setConfirmSellEur}
                        inputValue={NGNAmountValue?.value}
                        inputValueFormatted={NGNAmountValue?.formattedValue}
                        inputValueFloat={NGNAmountValue?.floatValue}
                        setInputValue={setNGNAmountValue}
                        openModal={showBuyEur}
                        loading={loading}
                        setLoading={setLoading}
                        setOpenModal={setShowBuyEur}
                        balance={
                          accountNgn?.data?.data?.available_balance?.formatted
                        }
                        baseBalance={"0"}
                        computedValue={eurAmountValue?.value}
                        computedValueFloat={eurAmountValue?.floatValue}
                        computedValueFormatted={eurAmountValue?.formattedValue}
                        setComputedValue={setEurAmountValue}
                        errorMessage={EurErr}
                        success={eurSellSuccess}
                        setSuccess={setEurSellSuccess}
                        // initiateBuy={() => {}}
                        setFundModal={setShowFundModal}
                        refetchRates={function <TPageData>(
                          options?:
                            | (RefetchOptions & RefetchQueryFilters<TPageData>)
                            | undefined
                        ): Promise<
                          QueryObserverResult<AxiosResponse<any, any>, unknown>
                        > {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    )}

                    <AnimatePresence>
                      {eurBuySuccess && (
                        <motion.div
                          initial={{
                            opacity: 0,
                          }}
                          animate={{
                            opacity: 1,
                            transition: {
                              duration: 0.3,
                            },
                          }}
                          exit={{
                            opacity: 0,
                            transition: {
                              // delay: 0.3,
                              duration: 0.1,
                            },
                          }}
                          className="confirmation_modal backdrop-blur-sm
                    bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
                        >
                          <motion.div
                            initial={{
                              x: 600,
                              scale: 0,
                            }}
                            animate={{
                              x: 0,
                              scale: 1,
                              transition: {
                                duration: 0.3,
                              },
                            }}
                            exit={{
                              x: 600,
                              scale: 0,
                              transition: {
                                delay: 0.3,
                              },
                            }}
                            className="flex h-screen items-center justify-center md:justify-end "
                          >
                            <motion.div
                              initial={{
                                x: 600,
                                opacity: 0,
                              }}
                              animate={{
                                x: 0,
                                opacity: 1,
                                transition: {
                                  delay: 0.3,
                                  duration: 0.3,
                                },
                              }}
                              exit={{
                                x: 100,
                                opacity: 1,
                                transition: {
                                  duration: 0.3,
                                },
                              }}
                              className="desktop-card md:flex hidden justify-center flex-col gap-8 bg-white h-full w-full md:w-[448px] rounded-l-3xl p-5 "
                            >
                              <div className="icon self-center">
                                <SuccessMobile
                                  width={"100px"}
                                  height={"100px"}
                                />
                              </div>
                              <h1 className="text-2xl font-bold text-[#071827] self-center">
                                Order placed
                              </h1>

                              <p className="text-sm text-[#5C6F7F] self-center text-center">
                                Your purchase is in progress. Please check your
                                NGN wallet
                              </p>

                              <button
                                onClick={() => setEurBuySuccess(false)}
                                className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                              >
                                Done
                              </button>
                            </motion.div>
                            {/* Mobile */}

                            <motion.div
                              initial={{
                                x: 600,
                                opacity: 0,
                              }}
                              animate={{
                                x: 0,
                                opacity: 1,
                                transition: {
                                  delay: 0.3,
                                  duration: 0.3,
                                },
                              }}
                              exit={{
                                x: 100,
                                opacity: 1,
                                transition: {
                                  duration: 0.3,
                                },
                              }}
                              className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white shadow-2xl w-full max-w-sm h-[450px] <rounded-3></rounded-3>xl p-5 "
                            >
                              <div className="icon self-center">
                                <SuccessMobile
                                  width={"100px"}
                                  height={"100px"}
                                />
                              </div>
                              <h1 className="text-2xl font-bold text-[#071827] self-center">
                                Order placed
                              </h1>

                              <p className="text-sm text-[#5C6F7F] self-center text-center">
                                Your purchase is in progress. Please check your
                                NGN wallet
                              </p>

                              <button
                                onClick={() => {
                                  setEurBuySuccess(false);
                                }}
                                className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90"
                              >
                                Done
                              </button>
                            </motion.div>
                          </motion.div>
                        </motion.div>
                      )}
                    </AnimatePresence>

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setShowBuyEur(true);
                      }}
                      className="w-full md:hidden p-4 text-[#0eb683] bg-white border border-[#0eb683] rounded-md transition-all active:scale-90"
                    >
                      Buy
                    </button>
                    <div className="mid ">
                      <AppNumericInput
                        value={""}
                        onValueChange={() => {}}
                        thousandSeparator={true}
                        prefix="$ "
                        style={{ borderColor: "#0EB683" }}
                        labelStyle={{
                          color: "#000",
                          fontWeight: "bold",
                          // margin: "20px 0px 10px",
                        }}
                        isAllowed={(nValue: any) =>
                          nValue.value.length <= 11 &&
                          nValue.value.indexOf("-") === -1
                        }
                        rightLabel={`Minimum transferable amount is € 100`}
                        rightLabelStyle={{
                          fontStyle: "bold",
                          fontSize: "13px",
                          color: "#ED0000",
                        }}
                        // label="Amount you wish to swap (USD)"
                        placeholder="Amount you want to send in EUR (€)"
                        // isAllowed={(nValue: any) => nValue?.value?.length <= 11}
                        // isInsufficient={
                        //   accountUsd?.data?.data <
                        //   parseFloat(transactionAmount?.value)
                        // }
                        insufficientLabel="Insufficient balance"
                      />
                      <span className="text-[#ED0000] absolute left-2 bottom-4">
                        *
                      </span>
                    </div>
                    <div>
                      <span className="text-[14px] leading-[21px] text-[#5C6F7F] font-normal">
                        Should service fee be debited from total amount?
                      </span>{" "}
                      <span className="text-[#ED0000]">*</span>
                    </div>

                    <div className="flex  ">
                      <SendRadioInput
                        label=""
                        getValue={(value: boolean) => {
                          setSendersAmountInclusive(value);
                        }}
                        isRequired={false}
                        defaultValue={true}
                        positiveText="YES"
                        negativeText="NO"
                        revPos={true}
                      />
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Service Fee @0.25%
                      </p>
                      <h1 className="text-[#071827] font-bold text-[20px] leading-[30px]">
                        € 0.00
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Offshore Charges
                      </p>
                      <h1 className="text-[#071827] font-bold text-[20px] leading-[30px]">
                        € 50.00
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Total Amount to be debited
                      </p>
                      <h1 className="text-[#0eb683] font-bold text-[20px] leading-[30px]">
                        € 0.00
                      </h1>
                    </div>
                    <div>
                      <p className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal">
                        Beneficiary receives
                      </p>
                      <h1 className="text-[#071827] font-bold text-[20px] leading-[30px]">
                        € 0.00
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between md:bg-[#f7fbfb] pb-20 md:pb-6 ">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="text-[#0eb683] bg-white border border-[#0eb683] p-3 px-6 mt-10     md:max-w-lg rounded-md transition-all active:scale-90"
                >
                  Previous
                </button>
                <button
                  disabled={true}
                  onClick={() => setShowSummary(true)}
                  className="bg-[#0eb683] text-white px-6 p-3 mt-10     md:max-w-lg rounded-md transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                >
                  Continue
                </button>
              </div>
            </TabPanel>
          </main>

          <div className="fund-modal">
            <AnimatePresence>
              {showFundModal && (
                <FundModal
                  fundModal={showFundModal}
                  confirm={showConfirmationModal}
                  setConfirm={setShowConfirmationModal}
                  loading={loading}
                  success={showSuccess}
                  setValue={setAmountValue}
                  setSuccess={setShowSuccess}
                  value={amountValue?.value}
                  currency={"ngn"}
                  valueFormatted={amountValue?.formattedValue}
                  setFund={setShowFundModal}
                />
              )}
            </AnimatePresence>
          </div>
        </div>
      ) : (
        <BeneficiarySummary
          selectedReason={selectedReason}
          transferAmount={transferAmount}
          serviceFee={serviceFee}
          debit={debit}
          credit={credit}
          sourceOfFunds={sourceOfFunds}
          setData={handleSummary}
          PayInvoice={payInvoiceDocsUrl}
          SoF={SOFdocsUrl}
        />
      )}
    </div>
  );
};

export default BeneficaryTransfer;
