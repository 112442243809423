import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../widgets/styles/standard.css";

const CustomSelectComponent = ({
  label,
  info,
  handleChange,
  options,
  defaultValue,
  placeholder,
  className,
}: {
  label: string;
  info?: string;
  options?: any;
  onChange?: (inputValue: any, actionMeta: any) => void;
  handleChange: (inputValue: any, actionMeta: any) => void;
  defaultValue?: any;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  error?: any;
  name?: string;
}) => {
  const [selectSettings] = useState({
    isClearable: true,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
  });

  const { isClearable, isSearchable, isDisabled, isLoading, isRtl } =
    selectSettings;

  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setSelectOptions(
        options.map((option: { label: any; value: any; image: any }) => ({
          label: option.label,
          value: option.value,
          image: option.image,
        }))
      );
    }

    return () => {
      mounted = false;
    };
  }, [options]);

  const customStyles = {
    placeholder: (provided: any) => ({
      ...provided,
      color: "#5C6F7F", // Customize the color of the placeholder
      fontSize: "14px",
      lineHeight: "16px",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      padding: "8px 12px",
      backgroundColor: state.isSelected ? "#f2f2f2" : "white",
      "&:hover": {
        backgroundColor: "#f2f2f2",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      //   marginTop: "8px",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      border: state.isFocused ? "1px solid #2684FF" : "1px solid #ccc",
      boxShadow: state.isFocused ? "0 0 0 1px #2684FF" : null,
      "&:hover": {
        borderColor: state.isFocused ? "#2684FF" : "#ccc",
      },
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      display: state.isFocused ? "block" : "none",
    }),
    image: {
      margin: "8px",

      width: "24px",
      height: "24px",
    },
  };

  return (
    <div className={`app-special-input w-full ${className}`}>
      {label && (
        <label className="text-[#071827] font-bold text-xl">{label}</label>
      )}
      <Select
        className="app-select"
        classNamePrefix="select"
        defaultValue={
          defaultValue
            ? {
                label: defaultValue.label,
                value: defaultValue.value,
                image: defaultValue.image,
              }
            : null
        }
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        placeholder={placeholder || "Select a bank"}
        options={selectOptions}
        onChange={handleChange}
        styles={customStyles}
        components={{
          Option: (props) => (
            <div
              {...props.innerProps}
              style={{ display: "flex", alignItems: "center" }}
            >
              {props.data.image && (
                <img
                  src={props.data.image}
                  alt={props.data.label}
                  style={customStyles.image}
                />
              )}
              <div>{props.data.label}</div>
            </div>
          ),
          SingleValue: (props) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              {props.data.image && (
                <img
                  src={props.data.image}
                  alt={props.data.label}
                  style={customStyles.image}
                />
              )}
              <div>{props.data.label}</div>
            </div>
          ),
        }}
      />
      {/* {info && <p className="info">{info}</p>} */}
    </div>
  );
};

export default CustomSelectComponent;
