import React, { useEffect, useMemo, useState, useContext } from "react";
import { IoIosArrowBack, IoMdClose } from "react-icons/io";
// import { numberWithCommas } from "../../pages/dashboard/Withdrawals";
import { SwapModalProps } from "../../types/Swap";
import AppNumericInput from "../widgets/AppNumericInput";
import ReactLoading from "react-loading";
import usFlag from "../../assets/flags/usd.png";
import ukFlag from "../../assets/flags/pound.png";
import euFlag from "../../assets/flags/euro.png";
import ngFlag from "../../assets/flags/ng.png";
import usdc from "../../assets/coins/usdc.png";
import usdt from "../../assets/coins/usdt.png";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import { Tooltip } from "../base-components/Tooltip";
import AppSelectInput from "../base-components/AppSelectInput";
import _ from "lodash";
import {
  useGetBanks,
  useGetReasons,
  useLpResolve,
} from "../../sevices/hooks/meta-data";
import AuthContext from "../../store/context/AuthProvider";
import { useBuyCurrency, useSwap } from "../../sevices/hooks/wallets";
import TabContext from "../../store/context/TabProvider";
import { useOutletContext } from "react-router-dom";
import { toast } from "sonner";
import { useBankList, useResolveBankName } from "../../sevices/hooks/users";
import { IBank } from "../../pages/dashboard/withdrawals_screen/Withdraw";
interface IProps {
  code: string;
  country: string;
  name: string;
  slug: string;
}

const SwapModal: React.FC<SwapModalProps> = ({
  balance,
  baseCurrency,
  baseCurrencySymbol,
  computedValue,
  currencySymbol,
  currency,
  inputValue,
  openModal,
  rate,
  sellRate,
  inputValueFloat,
  inputValueFormatted,
  errorMessage,
  confirmation,
  baseBalance,
  success,
  loading,
  computedValueFloat,
  computedValueFormatted,
  setComputedValue,
  setInputValue,
  setOpenModal,
  setConfirmation,
  setSuccess,
  refetchRates,
  // initiateBuy,
  refetch,
  setLoading,
  setFundModal,
}) => {
  const [currentTab, setCurrentTab] = useState("buy");
  const overLayRef = React.useRef(null);
  const [timerId, setTimerId] = useState<any>();
  const [timer, setTimer] = useState<number>(60);
  const [userDetails] = useOutletContext<any>();
  const [sellAcc, setSellAcc] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [validating, setValidating] = useState(false);
  const [email, setEmail] = useState<any>(null);
  const swapJob = useSwap(baseCurrency);
  const resolveName = useResolveBankName();
  const getReasons = useGetReasons();
  //bank
  const [bankName, setBankName] = useState<{
    label?: string;
    value?: string;
    bank?: IProps;
  }>({});
  // Define a mapping object for currency icons
  const currencyIcons: Record<string, string> = {
    USDT: usdt,
    USDC: usdc,
    NGN: ngFlag,
    USD: usFlag,
    GBP: ukFlag,
    EUR: euFlag,
  };
  // console.log(bankName, "bankName");
  const getCurrencyIcon = (currency: string): string =>
    currencyIcons[currency.toUpperCase()] || usFlag;

  interface Currency {
    value: string;
    label: string;
    image: string;
  }

  const currencies = useMemo(
    () => [
      {
        value: currency.toUpperCase(),
        label: currency.toUpperCase(),
        image: getCurrencyIcon(currency),
      },
      {
        value: baseCurrency.toUpperCase(),
        label: baseCurrency.toUpperCase(),
        image: getCurrencyIcon(baseCurrency),
      },
    ],
    [currency, baseCurrency]
  );

  var currentCurrency =
    currentTab === "buy" ? baseCurrency.toUpperCase() : currency.toUpperCase();
  const [accNumber, setAccNumber] = useState("");
  const [error, setError] = useState<string | undefined>("");
  const [err, setErr] = useState<string>("");
  const resolveAcc = useLpResolve();
  const [code, setCode] = useState("");
  // const [xcurr, setXcurr] = useState(currency.toUpperCase());
  const [selectedCurrency, setSelectedCurrency] = useState(currentCurrency);
  const banks = useGetBanks("NG");
  const getBanks = useBankList();
  const [reasons, setReasons] = useState("");
  // Check if currency is a stablecoin
  const isStablecoin = useMemo(() => {
    const stablecoins = ["USDT", "USDC"];
    return (
      stablecoins.includes(currency.toUpperCase()) ||
      stablecoins.includes(baseCurrency.toUpperCase())
    );
  }, [currency, baseCurrency]);

  const [phoneNumber, setPhoneNumber] = useState<any>(null);
  //@ts-ignore
  const { auth } = useContext(AuthContext);
  //@ts-ignore
  const { setTab, tab } = useContext(TabContext);
  const buyUsd = useBuyCurrency(baseCurrency as string);

  const bankOptions = useMemo(
    () =>
      banks.data?.data?.[0].map((bank: any) => ({
        label: bank?.name,
        value: bank?.code,
        bank,
      })),
    [banks.data]
  );

  const handleReasons = (reason: any) => {
    reason.value && setReasons(reason.value);
  };

  const reasonsOptions = useMemo(
    () =>
      getReasons?.data?.data.map((reason: string) => ({
        label: _.capitalize(reason),
        value: reason,
      })),
    [getReasons?.data?.data]
  );

  // useEffect(() => {
  //   if (accNumber.length === 10 && bankName?.bank) {
  //     // console.log(bankName, "cth");
  //     setValidating(true);
  //     const code = bankName?.value || "";
  //     //@ts-ignore
  //     const networkId = bankName?.bank?.id || "";
  //     const requestData = { accountNumber: accNumber, code, networkId };
  //     resolveAcc
  //       .mutateAsync(requestData)
  //       .then((response) => {
  //         console.log(response);

  //         setAccountName(response?.data?.accountName);
  //         setEmail(auth?.res?.data?.data?.email);
  //         setPhoneNumber(auth?.res?.data?.data?.phoneNumber);
  //       })
  //       .catch((error) => {
  //         setError(error);
  //       })
  //       .finally(() => {
  //         setValidating(false);
  //       });
  //   }
  // }, [accNumber, bankName]);

  useEffect(() => {
    // Prevent unnecessary calls
    if (accNumber.length !== 10 || !bankName?.bank) return;

    // Create a flag to track if the effect is still mounted
    let isMounted = true;

    // Extract stable values before the async operation
    const code = bankName?.value || "";
    //@ts-ignore
    const networkId = bankName?.bank?.id || "";
    const requestData = {
      accountNumber: accNumber,
      code,
      networkId,
    };

    // Set validating to true
    setValidating(true);

    // Wrap the async operation
    const resolveAccount = async () => {
      try {
        const response = await resolveAcc.mutateAsync(requestData);

        // Check if still mounted before updating state
        if (isMounted) {
          if (response?.data) {
            setAccountName(response.data.accountName || "");
            toast.success("Account details resolved successfully");
          } else {
            toast.error("Unable to resolve account details");
          }

          // Set email and phone number
          setEmail(auth?.res?.data?.data?.email || "");
          setPhoneNumber(auth?.res?.data?.data?.phoneNumber || "");
        }
      } catch (error) {
        // Check if still mounted before showing error
        if (isMounted) {
          //@ts-ignore
          if (error.response) {
            toast.error(
              //@ts-ignore
              error.response.data.message || "Account resolution failed"
            );
            //@ts-ignore
          } else if (error.request) {
            toast.error("No response received from server");
          } else {
            toast.error("Error resolving account details");
          }

          // console.error("Account resolution error:", error);
        }
      } finally {
        // Check if still mounted before setting validating
        if (isMounted) {
          setValidating(false);
        }
      }
    };

    // Call the async function
    resolveAccount();

    // Cleanup function to prevent state updates if component unmounts
    return () => {
      isMounted = false;
    };
  }, [
    accNumber,
    //@ts-ignore
    bankName?.bank?.id,
    bankName?.value,
    resolveAcc.mutateAsync,
    auth?.res?.data?.data?.email,
    auth?.res?.data?.data?.phoneNumber,
  ]);

  const onStop = () => {
    clearInterval(timerId);
  };

  useEffect(() => {
    return () => clearInterval(timerId);
  }, [timerId]);
  useEffect(() => {
    if (timer === 0) {
      setTimer(60);
    }
  }, [timer]);

  const handleSuccess = () => {
    setLoading(false);
    setSuccess(true);
    setConfirmation(false);
    refetch?.();
  };

  const handleError = (error: any) => {
    setLoading(false);
    toast.error(error?.response?.data);
    startTimer();
  };

  const startTimer = () => {
    const id = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    setTimerId(id);

    setTimeout(() => {
      refetch?.();
    }, 60000);
  };

  const swapCurrency = async () => {
    setError(undefined);
    setLoading(true);

    try {
      const response = await swapJob.mutateAsync({
        benDetails: {
          email: email ?? localStorage.getItem("user_email"),
          phone: phoneNumber ?? localStorage.getItem("user_phone"),
          bank: bankName?.value as string,
          account_name: accountName,
          account_number: accNumber,
        },
        trxDetails: {
          from_currency: baseCurrency.toUpperCase(),
          to_currency: currency.toUpperCase(),
          fiat_from_amount: Number(computedValue),
          from_amount: Number(inputValue),
          transaction_type: "swap",
          channel: "WEB",
          reason: reasons,
        },
      });
      // console.log(response);
      handleSuccess();
    } catch (error: any) {
      handleError(error);
    }
  };

  const purchaseUsd = () => {
    setErr("");
    onStop();
    setLoading(true);

    buyUsd
      .mutateAsync({
        from_currency: currency.toUpperCase(),
        to_currency: baseCurrency.toUpperCase(),
        from_amount: Number(inputValueFloat),
        fcy_from_amount: Number(computedValueFloat),
        transaction_type: "swap",
        channel: "WEB",
      })
      .then((res: any) => {
        setLoading(false);
        setSuccess(true);
        // setOpenModal(false);
        setConfirmation(false);
        refetch && refetch();
      })
      .catch((e: any) => {
        setLoading(false);
        setErr(e?.response?.data);
        onStart();
      });
  };

  const onStart = () => {
    // Use setTimeout() to schedule the triggerFunction() to be executed after 60 seconds
    setTimeout(() => {
      refetch && refetch();
    }, 60 * 1000);
    setTimerId(
      setInterval(() => {
        setTimer((state: number) => state - 1);
      }, 1000)
    );
  };

  const initiateSwap = () => {
    setLoading(true);
    switch (tab) {
      case "buy":
        purchaseUsd();
        onStop();
        break;
      case "swap":
        swapCurrency();
        onStop();
        break;
      default:
        // code to handle unexpected values of currentTab
        break;
    }
  };

  useEffect(() => {
    setTab(currentTab);
  }, [currentTab]);

  const closeModal = () => {
    setOpenModal(false);
    setSuccess(false);
    setTimer(60);
    setConfirmation(false);
    setInputValue("");
    setComputedValue("");
    setSellAcc(false);
  };

  const flag =
    baseCurrency === "eur"
      ? euFlag
      : baseCurrency === "usd"
      ? usFlag
      : baseCurrency === "gbp"
      ? ukFlag
      : baseCurrency === "usdc"
      ? usdc
      : baseCurrency === "usdt"
      ? usdt
      : "";

  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.target === overLayRef.current && closeModal();
  };

  const handleCurrencyChange = (selectedOption: any) => {
    setSelectedCurrency(selectedOption.value);
  };

  let isInsufficient;

  if (selectedCurrency === baseCurrency.toUpperCase()) {
    isInsufficient = parseFloat(inputValue) > parseFloat(baseBalance);
  } else {
    isInsufficient = parseFloat(inputValue) > parseFloat(baseBalance);
  }

  return (
    <>
      <div
        onClick={handleOverLayClick}
        className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50 "
      >
        <div
          ref={overLayRef}
          className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
        >
          <div className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 overflow-auto ">
            {!success ? (
              <>
                <div className="flex items-end justify-end pb-3">
                  <Tooltip message="close">
                    <div
                      onClick={closeModal}
                      className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
                    >
                      <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                        <IoMdClose size={29} className="" />
                      </span>
                    </div>
                  </Tooltip>
                </div>
                {!confirmation ? (
                  <div>
                    {sellAcc ? (
                      ""
                    ) : (
                      <div className="toggle w-full h-12 md:h-12 flex border border-[#0eb683] mb-6 md:mb-4 self-center justify-between rounded-[32px] cursor-pointer p-1">
                        <div
                          className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-[32px] select-none tracking-wider font-semibold ${
                            currentTab === "buy"
                              ? "bg-[#0eb683] text-white"
                              : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentTab("buy");
                            onStop();
                          }}
                        >
                          Buy
                        </div>
                        <div
                          className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-[32px] select-none tracking-wider font-semibold ${
                            currentTab === "swap"
                              ? "bg-[#0eb683] text-white"
                              : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentTab("swap");
                            onStop();
                          }}
                        >
                          Sell
                        </div>
                      </div>
                    )}

                    <div>
                      {currentTab === "buy" ? (
                        <div className=" flex flex-col gap-3">
                          <h1 className="text-[#071827] text-2xl font-bold self-center select-none">
                            Buy {baseCurrency.toUpperCase()}
                          </h1>
                          <div className="flex flex-col gap-2 select-none mt-5">
                            <p className="text-[#5C6F7F] text-[16px] leading-[24px]  self-center select-none">
                              Current Rate
                            </p>
                            <h2 className="text-[#0EB683] text-3xl font-bold self-center select-none">
                              {currency.toUpperCase()} {rate}
                            </h2>
                            <p className="text-[#071827] text-sm font-bold self-center select-none">
                              <span className="">{baseCurrencySymbol} </span> 1
                              {!baseCurrencySymbol && baseCurrency} is currently{" "}
                              <span className="">{currencySymbol}</span>
                              {rate}
                            </p>
                          </div>

                          <div className="mt-6">
                            <AppNumericInput
                              handleCurrencyChange={handleCurrencyChange}
                              setSelectedCurrency={setSelectedCurrency}
                              selectedCurrency={selectedCurrency}
                              currencies={currencies}
                              value={
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? computedValueFormatted
                                  : inputValueFormatted
                              }
                              onValueChange={(value: any) => {
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? setComputedValue(value)
                                  : setInputValue(value);
                              }}
                              thousandSeparator={true}
                              prefix={
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? baseCurrencySymbol
                                  : currencySymbol
                              }
                              style={{
                                borderColor: "#a10",
                              }}
                              labelStyle={{
                                color: "#071827",
                                fontWeight: "normal",
                                fontSize: "14px",
                                lineHeight: "24px",
                              }}
                              rightLabel={
                                inputValue !== "" &&
                                inputValue !== null &&
                                inputValue !== undefined
                              }
                              rightLabelStyle={{
                                color: "red",
                                padding: 0,
                              }}
                              label={`Enter amount you wish to buy (${baseCurrency.toUpperCase()}) or the ${currency.toUpperCase()} equivalent`}
                              placeholderStyle={{
                                color: "gray",
                                // fontStyle: "italic",
                                fontSize: "14px",
                              }}
                              placeholder={
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? `Enter amount you wish to buy (${baseCurrency.toUpperCase()})`
                                  : `Enter ${currency.toUpperCase()} equivalent`
                              }
                              isAllowed={(nValue: any) =>
                                nValue.value.length <= 11
                              }
                              isInsufficient={
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? inputValue > balance
                                  : inputValue > balance
                              }
                              insufficientLabel="Insufficient balance"
                            />
                          </div>
                          <div className="mt-5">
                            <AppNumericInput
                              value={
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? (computedValueFloat * Number(rate)).toFixed(
                                      2
                                    )
                                  : (inputValueFloat / Number(rate)).toFixed(2)
                              }
                              onValueChange={(value: any) => {
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? setInputValue(value)
                                  : setComputedValue(value);
                              }}
                              placeholderStyle={{
                                color: "gray",
                                // fontStyle: "italic",
                                fontSize: "14px",
                              }}
                              disabled={true}
                              thousandSeparator={true}
                              prefix={
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? currencySymbol
                                  : baseCurrencySymbol
                              }
                              style={{
                                borderColor: "#EAEAEA",
                                backgroundColor: "#EAEAEA",
                              }}
                              labelStyle={{
                                color: "#071827",
                                fontWeight: "normal",
                                fontSize: "14px",
                                lineHeight: "24px",
                              }}
                              label={
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? `Amount you need (${currency.toUpperCase()})`
                                  : `Amount you will receive (${baseCurrency.toUpperCase()})`
                              }
                              placeholder={`Amount computed in (${
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? currency.toUpperCase()
                                  : baseCurrency.toUpperCase()
                              })`}
                              isAllowed={(nValue: any) =>
                                nValue.value.length <= 11
                              }
                              info={
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? `Current Balance ${baseCurrency.toUpperCase()} ${baseBalance}`
                                  : `Current Balance ${currency.toUpperCase()} ${balance}`
                              }
                              infoStyle={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                textAlign: "end",
                                fontWeight: "bold",
                                color: "#071827",
                                padding: 0,
                              }}
                            />
                          </div>

                          <div className="flex flex-col  justify-end  h-32 w-full">
                            <div className="flex items-center justify-center">
                              <small className="text-sm self-center md:mb-1 text-[#071827]">
                                Insufficient balance? click{" "}
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setFundModal(true);
                                    closeModal();
                                  }}
                                  className="text-[#0eb683] font-bold transition-all active:scale-90"
                                >
                                  {" "}
                                  Here
                                </button>{" "}
                                to fund{" "}
                              </small>
                            </div>

                            <button
                              disabled={
                                selectedCurrency === baseCurrency.toUpperCase()
                                  ? !inputValue ||
                                    parseFloat(inputValue) >
                                      parseFloat(balance) ||
                                    parseFloat(computedValue) * Number(rate) <
                                      50
                                  : !computedValue ||
                                    parseFloat(inputValue) >
                                      parseFloat(balance) ||
                                    parseFloat(inputValue) < Number(sellRate)
                              }
                              onClick={() => {
                                setConfirmation(true);
                                onStart();
                              }}
                              className="bg-[#0eb683] text-white p-3 rounded-[16px] transition-all active:scale-90  disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 "
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          {!sellAcc ? (
                            <div className=" flex flex-col gap-3">
                              <h1 className="text-[#071827] text-2xl font-bold self-center">
                                Sell {baseCurrency.toUpperCase()}
                              </h1>
                              <div className="flex flex-col gap-2 mt-5">
                                <p className="text-[#5C6F7F] text-[16px] leading-[24px] self-center">
                                  Current Rate
                                </p>
                                <h2 className=" text-3xl font-bold self-center text-[#0eb683]">
                                  {currency.toUpperCase()} {sellRate}
                                </h2>

                                <p className="text-[#071827] text-sm font-semibold self-center">
                                  <span className="">{currencySymbol}</span>
                                  {sellRate} is currently{" "}
                                  <span className="">{baseCurrencySymbol}</span>{" "}
                                  1 {!baseCurrencySymbol && baseCurrency}
                                </p>
                              </div>
                              <div className="mt-6">
                                <AppNumericInput
                                  handleCurrencyChange={handleCurrencyChange}
                                  setSelectedCurrency={setSelectedCurrency}
                                  selectedCurrency={selectedCurrency}
                                  currencies={currencies}
                                  value={
                                    selectedCurrency === currency.toUpperCase()
                                      ? inputValueFormatted
                                      : computedValueFormatted
                                  }
                                  onValueChange={(value: any) => {
                                    selectedCurrency === currency.toUpperCase()
                                      ? setInputValue(value)
                                      : setComputedValue(value);
                                  }}
                                  thousandSeparator={true}
                                  prefix={
                                    selectedCurrency === currency.toUpperCase()
                                      ? currencySymbol
                                      : baseCurrencySymbol
                                  }
                                  style={{
                                    borderColor: "#a10",
                                  }}
                                  labelStyle={{
                                    color: "#071827",
                                    fontWeight: "normal",
                                    fontSize: "14px",
                                    lineHeight: "24px",
                                  }}
                                  label={`Enter amount you wish to sell (${baseCurrency.toUpperCase()}) or the ${currency.toUpperCase()} equivalent`}
                                  placeholderStyle={{
                                    color: "gray",
                                    // fontStyle: "italic",
                                    fontSize: "14px",
                                  }}
                                  placeholder={
                                    selectedCurrency === currency.toUpperCase()
                                      ? `Enter ${currency.toUpperCase()} equivalent`
                                      : `Enter amount you wish to sell (${baseCurrency.toUpperCase()})`
                                  }
                                  isAllowed={(nValue: any) =>
                                    nValue?.value?.length <= 11
                                  }
                                  //@ts-ignore
                                  rightLabel={
                                    inputValue !== "" &&
                                    inputValue !== null &&
                                    inputValue !== undefined
                                    // ? inputValue <
                                    //   (Number(sellRate) * Number(10)) /
                                    //     Number(sellRate)
                                    //   ? errorMessage
                                    //   : ""
                                    // : ""
                                  }
                                  rightLabelStyle={{
                                    color: "red",
                                    paddingTop: "-10px",
                                  }}
                                  isInsufficient={
                                    selectedCurrency ===
                                    baseCurrency.toUpperCase()
                                      ? inputValue > baseBalance
                                      : inputValue > baseBalance
                                  }
                                  insufficientLabel="Insufficient balance"
                                />
                              </div>
                              <div className="mt-5">
                                <AppNumericInput
                                  value={
                                    selectedCurrency === currency.toUpperCase()
                                      ? (
                                          inputValueFloat / Number(sellRate)
                                        ).toFixed(2)
                                      : (
                                          computedValueFloat * Number(sellRate)
                                        ).toFixed(2)
                                  }
                                  onValueChange={(value: any) => {
                                    selectedCurrency === currency.toUpperCase()
                                      ? setComputedValue(value)
                                      : setInputValue(value);
                                  }}
                                  placeholderStyle={{
                                    color: "gray",
                                    // fontStyle: "italic",
                                    fontSize: "14px",
                                  }}
                                  disabled={true}
                                  thousandSeparator={true}
                                  prefix={
                                    selectedCurrency === currency.toUpperCase()
                                      ? baseCurrencySymbol
                                      : currencySymbol
                                  }
                                  // suffix={Number.isInteger(NGNAmountValue?.floatValue) ? '' : '.00'}
                                  style={{
                                    borderColor: "#EAEAEA",
                                    backgroundColor: "#EAEAEA",
                                  }}
                                  labelStyle={{
                                    color: "#071827",
                                    fontWeight: "normal",
                                    fontSize: "14px",
                                    lineHeight: "24px",
                                  }}
                                  infoStyle={{
                                    fontSize: "14px",
                                    lineHeight: "24px",
                                    textAlign: "end",
                                    fontWeight: "bold",
                                    color: "#071827",
                                    padding: 0,
                                  }}
                                  // label={`Amount you will receive (${currency.toUpperCase()})`}

                                  label={
                                    selectedCurrency !== currency.toUpperCase()
                                      ? `Amount you will receive (${currency.toUpperCase()})`
                                      : `Amount you need (${baseCurrency.toUpperCase()})`
                                  }
                                  placeholder="Amount will be computed"
                                  isAllowed={(nValue: any) =>
                                    nValue.value.length <= 11
                                  }
                                  info={
                                    selectedCurrency === currency.toUpperCase()
                                      ? `Current Balance ${currency.toUpperCase()} ${balance}`
                                      : `Current Balance ${baseCurrency.toUpperCase()} ${baseBalance}`
                                  }
                                />
                              </div>

                              <div className="flex flex-col  justify-end  h-32 w-full  ">
                                <div className="flex items-center justify-center">
                                  <small className="text-sm text-center md:mb-1 text-[#071827]">
                                    Insufficient balance? click{" "}
                                    <button
                                      onClick={() => {
                                        setFundModal(true);
                                        closeModal();
                                      }}
                                      className="text-[#0eb683] font-bold"
                                    >
                                      {" "}
                                      Here
                                    </button>{" "}
                                    to fund{" "}
                                  </small>
                                </div>

                                <button
                                  disabled={
                                    selectedCurrency ===
                                    baseCurrency.toUpperCase()
                                      ? !inputValue ||
                                        parseFloat(inputValue) >
                                          parseFloat(baseBalance) ||
                                        parseFloat(inputValue) <= 0
                                      : !computedValue ||
                                        parseFloat(inputValue) >
                                          parseFloat(baseBalance) ||
                                        parseFloat(inputValue) < 10
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setSellAcc(true);
                                    // setTransferConfirm(true);
                                    // setShowFcyModal(false);
                                  }}
                                  // onClick={() => {
                                  //   setConfirmation(true);
                                  //   // setTab(currentTab);
                                  //   onStart();
                                  // }}
                                  className="bg-[#0eb683] text-white p-3 rounded-[16px] transition-all active:scale-90  disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                                >
                                  Continue
                                </button>
                                {/* <Button
                                  disable={false}
                                  onClick={() => {
                                    setSellAcc(true);
                                  }}
                                >
                                  Continue
                                </Button> */}
                              </div>
                            </div>
                          ) : (
                            <div className="h-screen ">
                              <div>
                                <div className="flex items-center absolute top-6 gap-3">
                                  <div
                                    className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer overflow-hidden h-10 w-10 justify-center items-center"
                                    onClick={() => {
                                      setSellAcc(false);
                                      setError("");
                                    }}
                                  >
                                    <IoIosArrowBack size={27} />
                                  </div>
                                  <h1 className="font-bold text-xl text-[#071827]  ">
                                    Bank Details
                                  </h1>
                                </div>
                                <p className="text-[#5C6F7F] font-medium pt-10 xxs:pt-5 text-left text-base whitespace-nowrap">
                                  Provide your account details
                                </p>
                              </div>
                              <div className="flex flex-col gap-2 mt-7">
                                {/* {error ? (
                                  <div className="text-[#ee0000] truncate w-38">
                                    {error}
                                  </div>
                                ) : (
                                  ""
                                )} */}
                                <div className="relative">
                                  <AppSelectInput
                                    options={bankOptions || []}
                                    label=""
                                    placeholder="Bank"
                                    handleChange={(bank: any) => {
                                      setBankName(bank);
                                      setCode(bank?.value);
                                      setError("");
                                    }}
                                  />
                                  <div className="text-[#ee0000] font-bold absolute top-3 left-3 ">
                                    *
                                  </div>
                                </div>
                                <div className="relative ">
                                  <input
                                    type="text"
                                    pattern="\d*"
                                    name="accNumber"
                                    id="accNumber"
                                    placeholder="Account Number"
                                    className="p-4 rounded-md bg-[#F2FAF7] border w-full outline-[#0eb683] "
                                    required
                                    value={accNumber}
                                    onChange={(evt) => {
                                      setAccNumber(evt.target.value);
                                      setError("");
                                    }}
                                    maxLength={10}
                                  />

                                  <div className="font-bold text-lg mt-3 ">
                                    {validating
                                      ? "Validating details.."
                                      : accountName && accNumber.length === 10
                                      ? accountName
                                      : error
                                      ? ""
                                      : ""}
                                  </div>
                                  <div className="text-[#ee0000] font-bold absolute top-3 left-2 ">
                                    *
                                  </div>
                                </div>
                                {error && (
                                  <div className="font-bold text-lg mt-3 text-[#ee0000] ">
                                    {error}
                                  </div>
                                )}

                                <div className="relative">
                                  <AppSelectInput
                                    options={reasonsOptions || []}
                                    label=""
                                    placeholder="Select Reasons"
                                    handleChange={(reason: any) => {
                                      handleReasons(reason);
                                    }}
                                  />
                                  <div className="text-[#ee0000] font-bold absolute top-3 left-3 ">
                                    *
                                  </div>
                                </div>
                              </div>

                              <button
                                onClick={() => {
                                  setConfirmation(true);
                                  // setTab(currentTab);
                                  onStart();
                                }}
                                disabled={
                                  !bankName ||
                                  !accNumber ||
                                  !accountName ||
                                  !reasons
                                }
                                className="bg-[#0eb683] rounded-[16px] px-10 p-3 xxs:mt-20  transition-all active:scale-90 w-full  text-white  disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 select-none tracking-wider"
                              >
                                Continue
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex items-center gap-4 absolute top-6">
                      <div
                        className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer overflow-hidden h-10 w-10 justify-center items-center"
                        onClick={() => {
                          onStop();
                          setConfirmation(false);
                          // setShowConfirmation(false);
                          // setShowBuyCurrency("usd");
                        }}
                      >
                        <IoIosArrowBack size={27} />
                      </div>
                      <h1 className="select-none text-lg font-bold text-[#071827]">
                        Confirmation
                      </h1>
                    </div>
                    {/* {err && (
                      <>
                        <div className="text-[#e10] my-2 truncate bg-red-200 p-2 relative">
                          Error:<span className="ml-1">{err}</span>{" "}
                          <p
                            onClick={() => setErr("")}
                            className="absolute text-[#000] inline-block items-center top-[-4px] right-1 cursor-pointer"
                          >
                            x
                          </p>
                        </div>
                      </>
                    )} */}
                    <div className="flex flex-col">
                      <h1 className="text-[#0eb683] text-xl font-bold self-center pb-4 select-none">
                        Conversion Details
                      </h1>

                      <div className="border border-[#0eb683] flex flex-col items-center justify-center w-full h-24 rounded-[16px] ">
                        <p className="text-[#5C6F7F] ">Current Rate</p>
                        <div className="flex items-center gap-4">
                          <h1 className="text-xl text-[#071827] font-bold">
                            {currency.toUpperCase()}{" "}
                            {tab === "buy" ? rate : sellRate}
                          </h1>
                        </div>
                        <p className="select-none  text-[#5C6F7F]">
                          Current rate changes in{" "}
                          <span className="font-extrabold text-[#0eb683] ">
                            ({timer})
                          </span>{" "}
                          secs
                        </p>
                      </div>
                    </div>
                    <div className=" flex flex-col gap-4 mt-6">
                      {currentTab === "buy" ? (
                        <>
                          <div className="flex justify-between">
                            <h1 className="text-sm text-[#071827] select-none tracking-wider font-bold">
                              You are Swapping:
                            </h1>
                            <div className="flex items-center gap-1">
                              <img src={ngFlag} alt=" FLAG" className="w-4 " />
                              <p className="text-sm text-[#0eb683] select-none tracking-wider font-bold">
                                {inputValueFormatted}
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <h1 className="text-sm text-[#071827] select-none tracking-wider font-bold">
                              You will receive:
                            </h1>
                            <div className="flex items-center gap-1">
                              <img src={flag} alt="US FLAG" className="w-4 " />
                              <p className="text-sm text-[#0eb683] select-none tracking-wider font-bold">
                                {baseCurrencySymbol}{" "}
                                {(inputValue / Number(rate)).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex justify-between">
                            <h1 className="text-sm text-[#071827] select-none tracking-wider font-bold">
                              You are Swapping:
                            </h1>
                            <div className="flex items-center gap-1">
                              <img src={flag} alt=" FLAG" className="w-4 " />
                              <p className="text-sm text-[#0eb683] select-none tracking-wider font-bold">
                                {baseCurrencySymbol} {inputValue}
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <h1 className="text-sm text-[#071827] select-none tracking-wider font-bold">
                              You will receive:
                            </h1>
                            <div className="flex items-center gap-1">
                              <img
                                src={ngFlag}
                                alt="US FLAG"
                                className="w-4 "
                              />
                              <p className="text-sm text-[#0eb683] select-none tracking-wider font-bold">
                                {inputValueFormatted}
                              </p>
                            </div>
                          </div>
                        </>
                      )}

                      {currentTab === "buy" ? (
                        <p className="text-base mt-7 text-[#071827] font-bold text-center break-words whitespace-nowrap">
                          <span className="text-[#0eb683]">
                            {inputValueFormatted}
                          </span>{" "}
                          <span className="text-sm relative bottom-[2px]">
                            converts to{" "}
                          </span>
                          <span className="text-[#0eb683]">
                            {" "}
                            {baseCurrencySymbol}{" "}
                            {(inputValue / Number(rate)).toFixed(2)}
                          </span>
                        </p>
                      ) : (
                        <>
                          <div>
                            <h1 className="font-bold text-2xl text-[#33333]">
                              To:
                            </h1>

                            <div className="mt-4">
                              <div className="flex items-center justify-between">
                                <p className="text-sm text-[#33333] font-bold text-left ">
                                  Bank:
                                </p>
                                {/* <p className="text-sm text-[#33333] text-right">
                                  Providus Bank
                                </p> */}
                                <p className="text-sm text-[#33333] text-right">
                                  {bankName?.label}{" "}
                                </p>
                              </div>
                              <div className="flex items-center justify-between mt-2">
                                <p className="text-sm text-[#33333] font-bold text-left ">
                                  Account No.:
                                </p>
                                {/* <p className="text-sm text-[#33333] text-right">
                                  {
                                    userDetails?.lcyAccount
                                      ?.ngnLBPSubAccountNumber
                                  }
                                </p> */}
                                <p className="text-sm text-[#33333] text-right">
                                  {accNumber}{" "}
                                </p>
                              </div>
                              <div className="flex items-center justify-between mt-2">
                                <p className="text-sm text-[#33333] font-bold text-left ">
                                  Beneficiary
                                </p>
                                {/* <p className="text-sm text-[#33333] text-right truncate md:w-60 xxs:w-44">
                                  {userDetails?.lcyAccount?.ngnLBPAccountName}
                                </p> */}
                                <p className="text-sm text-[#33333] text-right truncate md:w-60 xxs:w-44">
                                  {accountName}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                          <p className="text-[#071827] text-base mt-7 font-bold self-center break-normal whitespace-nowrap">
                            <span className="text-[#0eb683] ">
                              {baseCurrencySymbol} {inputValue}
                            </span>{" "}
                            <span className="text-sm relative bottom-[2px]">
                              converts to{" "}
                            </span>
                            <span className="text-[#0eb683] ">
                              {inputValueFormatted}
                            </span>
                          </p>
                        </>
                      )}

                      <div className="flex flex-col  justify-end  ">
                        <div className="mb-5">
                          {currentTab === "buy" ? (
                            <h3 className="flex items-end justify-center text-sm text-center text-[#5C6F7F] select-none tracking-wider font-bold">
                              By clicking the button below,
                              <br />I agree my {currency.toUpperCase()} account
                              would be debited <br /> and a bid placed to swap
                              for {baseCurrency.toUpperCase()} immediately
                            </h3>
                          ) : (
                            <h3 className="flex items-end justify-center text-sm text-center text-[#5C6F7F] select-none tracking-wider font-bold">
                              By clicking the button below,
                              <br />I agree my {baseCurrency.toUpperCase()}{" "}
                              balance would be debited <br /> and a bid placed
                              to swap for {currency.toUpperCase()} immediately
                            </h3>
                          )}
                        </div>
                        <button
                          className="bg-[#0eb683] rounded-[16px] px-10 p-3 xxs:mt-20 md:mb-auto transition-all active:scale-90 w-full  text-white  disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 select-none tracking-wider"
                          onClick={initiateSwap}
                        >
                          {loading ? (
                            <div className="flex items-center justify-center">
                              <ReactLoading
                                type="spinningBubbles"
                                color="#fff"
                                height="27px"
                                width="27px"
                              />
                            </div>
                          ) : (
                            ` Initiate Swap (${timer})`
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className=" flex items-center flex-col justify-center h-full gap-3">
                <div className="icon self-center">
                  <SuccessMobile width={"100px"} height={"100px"} />
                </div>
                <h1 className="text-2xl font-bold text-[#071827] self-center">
                  Order placed
                </h1>
                {currentTab === "buy" ? (
                  <p className="text-sm text-[#5C6F7F] self-center text-center">
                    Your Swap is in progress <br /> Please check your{" "}
                    {baseCurrency.toUpperCase()} balance
                  </p>
                ) : (
                  <p className="text-sm text-[#5C6F7F] self-center text-center">
                    Your Swap is in progress <br /> Please check your{" "}
                    {currency.toUpperCase()} balance
                  </p>
                )}

                <button
                  // onClick={() => {
                  //   setOpenModal(false);
                  //   setInputValue("");
                  //   setComputedValue("");
                  // }}

                  onClick={closeModal}
                  className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                >
                  Done
                </button>
              </div>
            )}
            {/* confirmation page */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SwapModal;
