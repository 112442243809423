import React, { useContext, useMemo, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import usFlag from "../../assets/flags/usd.png";
import ukFlag from "../../assets/flags/pound.png";
import euFlag from "../../assets/flags/euro.png";
import ngFlag from "../../assets/flags/ng.png";
import usdt from "../../assets/coins/usdt.png";
import usdc from "../../assets/coins/usdc.png";
import hamburger from "../../assets/misc/hamburger-button.svg";
import { useAccActivity, useWalletBalance } from "../../sevices/hooks/wallets";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import { AnimatePresence } from "framer-motion";
import { numberWithCommas } from "./Withdrawals";
import DashBoardEmptyState from "../../components/dashboard-components/DashBoardEmptyState";
import LoadingScreen from "../Notifications/LoadingScreen2";

import TransactionRecieptModal from "./transaction/TransactionRecieptModal";
import { useRecieptState } from "../../store/useReciept";

import { IoIosSend } from "react-icons/io";

import SwapModal from "../../components/dashboard-components/SwapModal";
import FundModal from "../../components/dashboard-components/FundModal";
import TabContext from "../../store/context/TabProvider";
import DashboardTable from "../../components/dashboard-components/DashboardTable";
import { useRatesStore } from "../../store/useRateStore";
import WalletScroll from "../../components/dashboard-components/widgetScroll";
import { useSidebarState } from "../../store";
import { useWalletStore } from "../../store/useStableCoin";
import TransactionTable from "../../components/transaction-component/TransactionTable";

interface IPayload {
  benDetails: {
    email: string;
    phone: string;
    bank: string;
    account_name: string;
    account_number: string;
  };
  trxDetails: {
    from_currency: string;
    to_currency: string;
    from_amount: number;
    transaction_type: string;
  };
}

const DashboardPage = () => {
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);
  const [userDetails, refetch] = useOutletContext<any>();
  const wallet = useWalletStore((state) => state.wallet);
  const rates = useRatesStore((state) => state.rates);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showBuyCurrency, setShowBuyCurrency] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  //BuyUsd
  const [usdAmountValue, setUsdAmountValue] = useState<any>();
  const [NGNAmountValue, setNGNAmountValue] = useState<any>();
  // const [appUSDRate, setappUSDRate] = useState(usdRate);

  //hooks for Gbp operations
  const [showBuyGbp, setShowBuyGbp] = useState(false);

  const [gbpBuySuccess, setGbpBuySuccess] = useState(false);
  const [confirmSellGbp, setConfirmSellGbp] = useState(false);

  // buyGBP
  const [gbpAmountValue, setGdpAmountValue] = useState<any>();
  const [NGNGBPAmountValue, setNGNGBPAmountValue] = useState<any>();

  //buyEUR
  const [eurAmountValue, setEurAmountValue] = useState<any>();

  //hooks for EUR operations
  const [showBuyEur, setShowBuyEur] = useState(false);

  const [confirmSellEur, setConfirmSellEur] = useState(false);
  const [eurSellSuccess, setEurSellSuccess] = useState(false);

  //fund
  const [fundModal, setFundModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [countdown, setCountdown] = useState(60);

  // const currentRate = useRates();
  // const eurRates = useRatesEur();
  // const gbpRates = useGbpRates();

  const usdRate = rates.NGN;
  const eurRate = rates.EUR;
  const gbpRate = rates.GBP;
  const {data: userActivities} = useAccActivity({
    page_size: 10,
    page: 1,
    currency: "",
  });

  // console.log(userActivities?.data?.total, "userActivities");
  // console.log(wallet, "wallet");

  const transactions = useMemo(
    () => userActivities?.data?.data,
    [userActivities?.data?.data]
  );

  const showAllTransactionReciept = useRecieptState(
    (state) => state.showTransactionReciept
  );

  const [amountValue, setAmountValue] = useState<any>();
  //@ts-ignore
  const { setTab, tab } = useContext(TabContext);

  function commas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const amountErr =
    tab === "buy"
      ? `Minimum amount is NGN ${commas(
          Number(usdRate?.buyRate) * Number(500)
        )} `
      : `Minimum amount is USD ${commas(
          (Number(usdRate?.sellRate) * Number(10)) / Number(usdRate?.sellRate)
        )} `;
  const GbpErr = `Minimum amount is NGN ${commas(
    Number(gbpRate?.buyRate * 100).toFixed()
  )}`;
  const EurErr = `Minimum amount is NGN ${commas(
    Number(eurRate?.buyRate * 100).toFixed()
  )}`;

  const fundNgn = () => setFundModal((prevState) => !prevState);
  const initiateBuy = () => setShowBuyCurrency(true);
  const initiateBuyEur = () => setShowBuyEur(true);
  const initiateBuyGBP = () => setShowBuyGbp((prevState) => !prevState);

  const widgetsData = [
    {
      color: "#FFF",
      currency: "NGN",
      symbol: "₦‎",
      buttonColor: "#0eb683",
      amount:
        numberWithCommas(userDetails?.balances?.ngnBalance) === "NaN"
          ? "0.00"
          : ` ${numberWithCommas(userDetails?.balances?.ngnBalance)}`,
      border: "2px solid #0eb683",
      action: fundNgn,
      button: "Fund",
      textColor: "#fff",
      flag: ngFlag,
      navigate: "/dashboard/wallet/ngn",
      disable: false,
    },
    {
      color: "#fff",
      currency: "USD",
      symbol: "$",
      buttonColor: "#fff",
      textColor: "#0eb683",
      amount:
        numberWithCommas(userDetails?.balances?.usdBalance) === "NaN"
          ? "0.00"
          : ` ${numberWithCommas(userDetails?.balances?.usdBalance)}`,
      border: "2px solid #fff",
      action: initiateBuy,
      button: "Buy | Sell",
      flag: usFlag,
      navigate: "/dashboard/wallet/usd",
      disable: false,
    },
    {
      color: "#fff",
      currency: "GBP",
      symbol: "£",
      buttonColor: "#fff",
      textColor: "#0eb683",
      amount:
        numberWithCommas(userDetails?.balances?.gbpBalance) === "NaN"
          ? "0.00"
          : ` ${numberWithCommas(userDetails?.balances?.gbpBalance)}`,
      border: "2px solid #fff",
      action: initiateBuyGBP,
      button: "Buy | Sell",
      flag: ukFlag,
      disable: true,
      navigate: "#",
      // navigate: "/dashboard/wallet/gbp",
    },
    {
      color: "#fff",
      currency: "EUR",
      symbol: "€",
      buttonColor: "#fff",
      textColor: "#0eb683",
      amount:
        numberWithCommas(userDetails?.balances?.eurBalance) === "NaN"
          ? "0.00"
          : ` ${numberWithCommas(userDetails?.balances?.eurBalance)}`,
      border: "2px solid #fff",
      action: initiateBuyEur,
      button: "Buy | Sell",
      flag: euFlag,
      navigate: "#",
      // navigate: "/dashboard/wallet/eur",
      disable: true,
    },
    {
      color: "#fff",
      currency: "USDC",
      symbol: "",
      buttonColor: "#fff",
      textColor: "#0eb683",
      amount: numberWithCommas(wallet?.usdcBalance),
      border: "2px solid #fff",
      action: initiateBuyEur,
      button: "Buy | Sell",
      flag: usdc,

      navigate: "/dashboard/wallet/usdc/stablecoin",
      disable: false,
    },
    {
      color: "#fff",
      currency: "USDT",
      symbol: "",
      buttonColor: "#fff",
      textColor: "#0eb683",
      amount: numberWithCommas(wallet?.usdtBalance),
      border: "2px solid #fff",
      action: initiateBuyEur,
      button: "Buy | Sell",
      flag: usdt,
      navigate: "/dashboard/wallet/usdt/stablecoin",
      disable: false,
    },
  ];

  return (
    <LoadingScreen showLoading={false} msg="Fetching Current Rates..">
      <div className="bg-[#F8F8F8] h-full w-full p-5  md:pl-8 md:pr-9 overflow-auto">
        <div className="w-16 ml-auto block md:hidden ">
          <img src={logo} alt="" />
        </div>

        <div className="flex flex-wrap items-center justify-between -z-0 ">
          <div className="flex">
            <div className="flex flex-col">
              <div className="top flex flex-col items-start gap-2">
                <button
                  className="py-1 md:hidden w-16"
                  onClick={() => toggleSideBarOpen()}
                >
                  <img src={hamburger} alt="" className="w-10" />
                </button>
                <div className="info ">
                  {userDetails?.accountType !== "business" ? (
                    <h1 className="text-2xl  text-[#071827] pb-2 ">
                      Hello{" "}
                      <span className=" text-2xl font-bold text-[#071827]">
                        {userDetails.firstName.charAt(0).toUpperCase() +
                          userDetails.firstName.slice(1)}
                        ,
                      </span>
                    </h1>
                  ) : (
                    <h1 className="text-2xl  text-[#071827] pb-2 ">
                      Welcome{" "}
                      <span className=" text-2xl font-bold text-[#071827]">
                        {userDetails?.businessName?.charAt(0).toUpperCase() +
                          userDetails?.businessName?.slice(1)}
                        ,
                      </span>
                    </h1>
                  )}

                  <p className=" xxs:pb-0 md:pb-5 text-[#5C6F7F] text-lg">
                    {` Here’s an overview of your ${
                      transactions?.length > 0 ? "activities" : "activity"
                    }`}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className=" hidden items-center justify-center gap-2 md:gap-5 py-5 md:flex ">
            <div className=" flex  items-center border-[#0eb683] border-r-2 gap-3 pr-2 md:pr-4  ">
              <figure className="w-5 md:w-10 ">
                <img src={usFlag} alt="" className="" />
              </figure>
              <p className="text-xs md:text-lg font-semibold">{`$1/N${
                usdRate?.buyRate || "0.00"
              }`}</p>
            </div>
            <div className=" flex  items-center  border-[#0eb683] border-r-2 gap-3 pr-2 md:pr-4">
              <figure className="w-5 md:w-10 ">
                <img src={ukFlag} alt="" className="" />
              </figure>
              <p className="text-xs md:text-lg font-semibold">{`£1/N${
                gbpRate?.buyRate || "0.00"
              }`}</p>
            </div>
            <div className=" flex  items-center gap-3">
              <figure className="w-5 md:w-10 ">
                <img src={euFlag} alt="" className="" />
              </figure>
              <p className="text-xs md:text-lg font-semibold">{`€1/N${
                eurRate?.buyRate || "0.00"
              }`}</p>
            </div>
          </div>

          <button
            onClick={() => {
              navigate("/dashboard/beneficiaries", { replace: true });
            }}
            className="hidden  md:flex items-center text-base text-white font-medium bg-[#0eb683] p-3 rounded-[16px] gap-2 px-8 transition-all active:scale-90 cursor-pointer md:mb-3.5 "
          >
            <IoIosSend size={25} />
            <span className="text-base pt-1">Initiate Transfer</span>
          </button>
        </div>

        {/*Mobile Rate */}

        <div className=" md:hidden items-center justify-center gap-2 md:gap-5 py-5 flex overflow-x-auto px-5 scrollbar-hide">
          <div className=" flex  items-center border-[#0eb683] border-r-2 gap-1 pr-2 md:pr-4  ">
            <figure className="w-5 md:w-10 ">
              <img src={usFlag} alt="" className="" />
            </figure>
            <p className="text-base md:text-lg font-semibold text-[#333333]">{`$1/N${
              usdRate?.buyRate || "0.00"
            }`}</p>
          </div>
          <div className=" flex  items-center  border-[#0eb683] border-r-2 gap-1 pr-2 md:pr-4">
            <figure className="w-5 md:w-10 ">
              <img src={ukFlag} alt="" className="" />
            </figure>
            <p className="text-base md:text-lg font-semibold text-[#333333]">{`£1/N${
              gbpRate?.buyRate || "0.00"
            }`}</p>
          </div>
          <div className=" flex  items-center gap-1">
            <figure className="w-5 md:w-10 ">
              <img src={euFlag} alt="" className="" />
            </figure>
            <p className="text-base md:text-lg font-semibold text-[#333333]">{`€1/N${
              eurRate?.buyRate === null ? "0.00" : eurRate?.buyRate
            }`}</p>
          </div>
        </div>

        <WalletScroll widgets={widgetsData} />
        <div>
          <div className="">
            {showBuyGbp && (
              <SwapModal
                baseCurrency={"gbp"}
                baseCurrencySymbol={"£ "}
                // refetchRates={refetch}
                // refetch={refetchRate}
                currency={"ngn"}
                currencySymbol={"₦ "}
                sellRate={gbpRate?.sellRate}
                rate={gbpRate?.buyRate}
                confirmation={confirmSellGbp}
                setConfirmation={setConfirmSellGbp}
                inputValue={
                  tab === "buy"
                    ? NGNGBPAmountValue?.value
                    : gbpAmountValue?.value
                }
                inputValueFormatted={NGNGBPAmountValue?.formattedValue}
                inputValueFloat={NGNGBPAmountValue?.floatValue}
                setInputValue={setNGNGBPAmountValue}
                openModal={showBuyGbp}
                loading={loading}
                setLoading={setLoading}
                setOpenModal={setShowBuyGbp}
                balance={userDetails?.balances?.ngnBalance}
                baseBalance={userDetails?.balances?.gbpBalance}
                computedValue={
                  tab === "buy"
                    ? gbpAmountValue?.value
                    : NGNGBPAmountValue?.value
                }
                computedValueFloat={gbpAmountValue?.floatValue}
                computedValueFormatted={gbpAmountValue?.formattedValue}
                setComputedValue={setGdpAmountValue}
                errorMessage={GbpErr}
                success={gbpBuySuccess}
                setSuccess={setGbpBuySuccess}
                // initiateBuy={purchaseUsd}
                setFundModal={fundNgn}
              />
            )}
          </div>
          <div className="xxs:mb-10 md:mb-0">
            {userActivities?.data?.total > 0 ? (
         
              <TransactionTable
                tableData={userActivities?.data}
                limit={15}

              />
            ) : (
              <>
                <DashBoardEmptyState />
              </>
            )}
          </div>
        </div>

        {showBuyEur && (
          <SwapModal
            baseCurrency={"eur"}
            baseCurrencySymbol={"€ "}
            currency={"ngn"}
            currencySymbol={"₦ "}
            sellRate={eurRate?.sellRate}
            rate={eurRate?.buyRate}
            confirmation={confirmSellEur}
            setConfirmation={setConfirmSellEur}
            inputValue={NGNAmountValue?.value}
            inputValueFormatted={NGNAmountValue?.formattedValue}
            inputValueFloat={NGNAmountValue?.floatValue}
            setInputValue={setNGNAmountValue}
            openModal={showBuyEur}
            loading={loading}
            setLoading={setLoading}
            setOpenModal={setShowBuyEur}
            balance={userDetails?.balances?.ngnBalance}
            baseBalance={userDetails?.balances?.eurBalance}
            computedValue={eurAmountValue?.value}
            computedValueFloat={eurAmountValue?.floatValue}
            computedValueFormatted={eurAmountValue?.formattedValue}
            setComputedValue={setEurAmountValue}
            errorMessage={EurErr}
            success={eurSellSuccess}
            setSuccess={setEurSellSuccess}
            // refetchRates={refetch}
            // refetch={refetchRate}
            // initiateBuy={purchaseUsd}
            setFundModal={fundNgn}
          />
        )}

        {/* fund modal NgN */}

        {fundModal && (
          <FundModal
            fundModal={fundModal}
            confirm={showConfirmationModal}
            setConfirm={setShowConfirmationModal}
            loading={loading}
            success={showSuccess}
            setValue={setAmountValue}
            setSuccess={setShowSuccess}
            value={amountValue?.value}
            currency={"ngn"}
            valueFormatted={amountValue?.formattedValue}
            setFund={fundNgn}
          />
        )}

        <div
          onClick={(e) => {
            e.preventDefault();
          }}
        ></div>
        <div>
          {/*BUY USD MODAL */}
          <AnimatePresence>
            {showBuyCurrency && (
              <SwapModal
                baseCurrency={"usd"}
                baseCurrencySymbol={"$ "}
                currency={"ngn"}
                currencySymbol={"₦ "}
                rate={usdRate?.buyRate}
                confirmation={showConfirmation}
                setConfirmation={setShowConfirmation}
                inputValue={
                  tab === "buy" ? NGNAmountValue?.value : usdAmountValue?.value
                }
                inputValueFormatted={NGNAmountValue?.formattedValue}
                inputValueFloat={NGNAmountValue?.floatValue}
                setInputValue={setNGNAmountValue}
                openModal={showBuyCurrency}
                loading={loading}
                setLoading={setLoading}
                setOpenModal={setShowBuyCurrency}
                balance={userDetails?.balances?.ngnBalance}
                baseBalance={userDetails?.balances?.usdBalance}
                computedValue={
                  tab === "buy" ? usdAmountValue?.value : NGNAmountValue?.value
                }
                sellRate={usdRate?.sellRate}
                computedValueFloat={usdAmountValue?.floatValue}
                computedValueFormatted={usdAmountValue?.formattedValue}
                setComputedValue={setUsdAmountValue}
                errorMessage={amountErr}
                success={showSuccessModal}
                setSuccess={setShowSuccessModal}
                // refetchRates={refetch}
                refetch={() => refetch()}
                // initiateBuy={purchaseUsd}
                setFundModal={fundNgn}
              />
            )}
          </AnimatePresence>
        </div>
        {showAllTransactionReciept && <TransactionRecieptModal />}
      </div>
    </LoadingScreen>
  );
};

export default DashboardPage;
