import useQueryAction from "../../../lib/useQueryAction";
import useQueryMutation from "../../../lib/useQueryMutation";
import { StableCoinBeneficiary } from "../../../types/Users";
import {
  api,
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
} from "../../api";

export interface PaginationParams {
  page_size?: number;
  page?: number;
  currency?: string;
}

export const useAccountNgn = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.wallet.AccountNgn),
    queryKey: ["ngn"],
  });
};

export const useWalletAccount = (currency: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.wallet.walletAccount(currency)),
    queryKey: ["wallet", currency],
  });
};

export const useAccountUsd = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.wallet.AccountUsd),
    queryKey: ["usd"],
  });
};

export const useAccActivity = (params?: PaginationParams) => {
  const queryKey = [
    "activity",
    params?.page,
    params?.page_size,
    params?.currency,
  ];

  return useQueryAction({
    queryFn: () => makeGetRequest(api.wallet.AccActivity, params),
    queryKey,

    cacheTime: 5 * 60 * 1000,
    enabled: !!params,
  });
};
export const useBuyUsd = () => {
  return useQueryMutation({
    mutationFn: (data: { units: number }) =>
      makePostRequest(data, api.wallet.BuyUsd),
  });
};

export const useGetTransactionsByDates = () => {
  return useQueryMutation({
    mutationFn: (data: { startDate: string; endDate: string }) =>
      makePostRequest(data, api.wallet.activityByDate),
  });
};

export const useGetTransactionsByRef = (ref: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.wallet.activityByRef(ref)),
    queryKey: ["ref", ref],
  });
};

export const useWalletBalance = (currency: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.wallet.walletBalance(currency)),
    queryKey: ["balance", currency],
  });
};
export const useBuyCurrency = (currencyCode: string) => {
  return useQueryMutation({
    mutationFn: (data: {
      from_currency: string;
      to_currency: string;
      from_amount: number;
      fcy_from_amount: number;
      transaction_type: string;
      channel: string;
    }) => makePostRequest(data, api.wallet.buyCurrency(currencyCode)),
  });
};
export const useConvert = (currency: string) => {
  return useQueryMutation({
    mutationFn: (data: {
      from_currency: string;
      to_currency: string;
      from_amount: number;
      to_amount: number;
    }) => makePostRequest(data, api.users.convert(currency)),
  });
};

export const usePreview = (currency: string) => {
return useQueryMutation({
  mutationFn: (data: {
    from_currency: string;
    to_currency: string;
    from_amount: number;
    to_amount: number;
  }) => makePostRequest(data, api.users.preview(currency)),
});
};
export const useBeneficiaryIdentity = (beneficiaryId: string) => {
  return useQueryAction({
    queryFn: () =>
      makeGetRequest(api.wallet.beneficiaryIdentity(beneficiaryId)),
    queryKey: ["beneficiaryId"],
  });
};

export const useNgnBeneficiaryIdentity = (beneficiaryId: string) => {
  return useQueryAction({
    queryFn: () =>
      makeGetRequest(api.wallet.ngnBeneficiaryIdentity(beneficiaryId)),
    queryKey: ["ngnBeneficiaryId"],
  });
};

export const useDeleteNgnBeneficiary = (beneficiaryId: string) => {
  return useQueryMutation({
    mutationFn: () =>
      makeDeleteRequest(api.wallet.ngnBeneficiaryIdentity(beneficiaryId)),
  });
};

export const useDeleteBeneficiary = (beneficiaryId: string) => {
  return useQueryMutation({
    mutationFn: () =>
      makeDeleteRequest(api.wallet.deleteBeneficiary(beneficiaryId)),
  });
};
export const useDeleteStableBeneficiary = (beneficiaryId: string) => {
  return useQueryMutation({
    mutationFn: () =>
      makeDeleteRequest(api.wallet.deleteStableBeneficiary(beneficiaryId)),
  });
};
export const useTransactionReference = (reference: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.wallet.transactionReference(reference)),
    queryKey: ["reference"],
  });
};

export const useCreateBeneficiary = () => {
  return useQueryMutation({
    mutationFn: (data: {
      beneficiaryName: string;
      billingDetails: {
        line1: string;
        line2: string;
        district: string;
        city: string;
        country: string;
        postalCode: string;
        name: string;
      };
      bankAddress: {
        bankName: string;
        city: string;
        country: string;
      };
      destinationType: string;
      accountNumber: string;
      routingNumber: string;
      iban?: string;
    }) => makePostRequest(data, api.wallet.Beneficiaries),
  });
};

export const useCreateStableBeneficiary = () => {
  return useQueryMutation({
    mutationFn: (data: {
      userId: string;
      chain: string;
      coin: string;
      beneficiaryName: string;
      beneficiaryAddress: string;
      alias: string;
    }) => makePostRequest(data, api.wallet.stablecoinBeneficiary),
  });
};

export const useExternalBeneficiary = () => {
  return useQueryMutation({
    mutationFn: (data: {
      beneficiaryName: string;
      billingDetails: {
        line1: string;
        line2: string;
        district: string;
        city: string;
        country: string;
        postalCode: string;
        name: string;
      };
      bankAddress: {
        bankName: string;
        city: string;
        country: string;
      };
      destinationType: string;
      accountNumber: string;
      routingNumber: string;
      iban?: string;
    }) => makePostRequest(data, api.wallet.ExternalBeneficiaries),
  });
};

export const useTransferUsd = () => {
  return useQueryMutation({
    mutationFn: (data: { units: number; beneficiaryId: string; otp: string }) =>
      makePostRequest(data, api.wallet.transfersUsd),
  });
};

export const useAccountWithdrawal = () => {
  return useQueryMutation({
    mutationFn: (data: {
      amount: number;
      accountName: string;
      accountNumber: string;
      narration: string;
      bankId: string;
      bankCode: string;
      channel: string;
      currency: string;
      otp: string;
      cot: number;
    }) => makePostRequest(data, api.wallet.accountWithdrawal),
  });
};

export const useCreateNgnBeneficiary = () => {
  return useQueryMutation({
    mutationFn: (data: {
      bankName: string | undefined;
      accountNumber: string;
      accountName: string;
      bankId: string;
    }) => makePostRequest(data, api.wallet.createNgnBeneficiary),
  });
};

export type BeneficiaryType = {
  id: string;
  updatedAt: string;
  deletedAt: string;
  user: {
    id: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role: {
      id: string;
      createdAt: string;
      updatedAt: string;
      deletedAt: string;
      name: string;
      code: string;
      description: string;
      users: [string];
      permissions: [
        {
          id: string;
          createdAt: string;
          updatedAt: string;
          deletedAt: string;
          name: string;
          description: string;
        }
      ];
    };
    bank: string;
    accountNumber: string;
    status: string;
    verificationCode: string;
    phoneNumber: string;
    invitationCode: string;
    refereeCode: string;
    accountType: string;
    alias: string;
    subAccountId: string;
    limitOrders: [
      {
        id: string;
        createdAt: string;
        updatedAt: string;
        deletedAt: string;
        originalReference: string;
        unit: number;
        rate: number;
        amount: number;
        currency: string;
        currencyType: string;
        user: string;
        resourceId: string;
        status: string;
      }
    ];
    fiatLedger: [
      {
        id: string;
        createdAt: string;
        updatedAt: string;
        deletedAt: string;
        originalReference: string;
        amount: number;
        subAccountId: string;
        title: string;
        description: string;
        destinationNuban: string;
        destinationAccountName: string;
        destinationBank: string;
        destinationId: string;
        transferFees: number;
        externalId: string;
        timestamp: string;
        status: string;
        user: string;
      }
    ];
    usdcTransfers: [
      {
        id: string;
        createdAt: string;
        updatedAt: string;
        deletedAt: string;
        amount: number;
        status: string;
        transactionHash: string;
        fee: number;
        chainId: string;
        transactionId: string;
        user: string;
        originalReference: string;
      }
    ];
    beneficiaries: [string];
    documents: [
      {
        id: string;
        createdAt: string;
        updatedAt: string;
        deletedAt: string;
        name: string;
        extension: string;
        originalFileName: string;
        type: string;
        user: string;
      }
    ];
  };
  name: string;
  address: {
    id: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    line1: string;
    line2: string;
    district: string;
    city: string;
    country: string;
    postalCode: string;
  };
  bank: string;
  destinationType: string;
  accountNumber: string;
  routingNumber: string;
  iban: string;
  bankAddress: {
    city: string;
    country: string;
  };
  beneficiaryId: string;
  status: string;
};

export const useBeneficiaries = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest<BeneficiaryType[]>(api.wallet.Beneficiaries),
    queryKey: ["beneficiaries"],
  });
};
export const useGetStableBeneficiaries = () => {
  return useQueryAction({
    queryFn: () =>
      makeGetRequest<{ data: StableCoinBeneficiary[] }>(
        api.wallet.getStableBeneficiary
      ),
    queryKey: ["getStableBeneficiary"],
  });
};
export const useGetSingleStableBeneficiary = (id: string) => {
  return useQueryAction({
    queryFn: () =>
      makeGetRequest<{ data: StableCoinBeneficiary[] }>(
        api.wallet.getAStableBeneficiary(id)
      ),
    queryKey: ["getAStableBeneficiary"],
  });
};

export const useNgnBeneficiary = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.wallet.createNgnBeneficiary),
    queryKey: ["ngnBeneficiary"],
  });
};

export const useSwap = (currency: string) => {
  return useQueryMutation({
    mutationFn: (data: {
      benDetails: {
        email?: any;
        phone?: any;
        bank: string;
        account_name: string;
        account_number: string;
      };
      trxDetails: {
        from_currency: string;
        to_currency: string;
        from_amount: number;
        fiat_from_amount: number;
        transaction_type: string;
        channel: string;
        reason: string;
      };
    }) => makePostRequest(data, api.wallet.sellFlow(currency)),
  });
};

// export const useTransferUsd = () => {
//   return useQueryMutation({
//     mutationFn: (data: { units: number; beneficiaryId: string }) =>
//       makePostRequest(),
//   });
// };
