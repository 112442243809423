import React, { useEffect } from "react";
import { useRatesStore } from "../store/useRateStore";
import { appCurrencies } from "../utils";
import { useGetRates } from "../sevices/hooks/meta-data";
import { useQueries } from "react-query";

import { api, makeGetRequest } from "../sevices/api";

const CurrencyRatesComponent: React.FC = () => {
  const setRate = useRatesStore((state) => state.setRate);



  const queries = useQueries(
    appCurrencies.map((currency) => ({
      queryKey: ["rates", currency],
      queryFn: () => makeGetRequest(api.metadata.rates(currency)),
      onSuccess: (data: any) => {
        // console.log(data.data);
        setRate(currency, data.data);
      },
      //   staleTime: 1000 * 60 * 5,
    }))
  );

  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);
  const error = queries.find((query) => query.error)?.error;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // if (isError) {
  //   return <div>Error: {"an error occurred"}</div>;
  // }

  return null;
};

export default CurrencyRatesComponent;
