import { useEffect, useMemo, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate, useOutletContext } from "react-router-dom";
import logo from "../../../assets/misc/FaviconAsset_ttfcx.svg";
import WithdrawalCard from "../../../components/dashboard-components/WithdrawalCard";
// import {
//   BankType,
//   useBanks,
//   useResolveBankName,
// } from "../../../sevices/hooks/meta-data";
// import { useBankList,  } from "../../sevices/hooks/users";
import {
  useBankList,
  useRequestOtp,
  useUsers,
  useResolveBankName,
} from "../../../sevices/hooks/users";
import {
  useAccountNgn,
  useAccountWithdrawal,
  useCreateNgnBeneficiary,
  useDeleteNgnBeneficiary,
  useNgnBeneficiary,
  useWalletBalance,
} from "../../../sevices/hooks/wallets";
import TransactionsIcon from "../../../assets/iconsComponents/TransactionsIcon";
import NgnBeneficiaryCard from "../../../components/dashboard-components/NgnBeneficiaryCard";
import { useNgnBeneficiaryState } from "../../../store/useNgnBeneficiary";
import NgnBeneficiaryModal from "./NgnBeneficiaryModal";
import WithdrawalModal from "../walletScreens/WithdrawalModal";
import NgnWithdrawalModal from "../walletScreens/NgnWithdrawalModal";
import OtpModal from "../../../components/dashboard-components/OtpModal";
import CustomOtpModal from "../../../components/dashboard-components/newCustomOTP";
import { toast } from "sonner";
import VerificationModal from "../../../components/base-components/VerificationComponent";
// import { IBank } from "../../onboarding/ChooseBank";

export type IBank = {
  anchorId: string;
  bankName: string;
  cbnCode: string;
  createdAt: string;
  deletedAt: null | string;
  id: string;
  nipCode: string;
  updatedAt: string;
};

interface WithdrawalPayload {
  amount: number;
  accountName: string;
  accountNumber: string;
  otp: string;
  narration: string;
  bankId: string;
  bankCode: string;
  channel: "WEB";
  currency: string;
  cot: number;
}

const Withdraw = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  // const [reference, setReference] = useState("");
  const [bank, setBank] = useState<{
    label?: string;
    value?: string;
    bank?: IBank;
  }>({});
  const [accountName, setAccountName] = useState("");
  const [amountValue, setAmountValue] = useState<any>({ value: 0 });
  const [referenceValue, setReferenceValue] = useState("");
  const [otp, setOtp] = useState("");
  const getBanks = useBankList();
  const resolveName = useResolveBankName();
  const [loading1, setLoading1] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [withdrwalModal, setWithdrwalModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [failedMsg, setFailedMsg] = useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  // const accountNgn = useWalletBalance("ngn");
  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );
  const [beneficiary, setBeneficiary] = useState(false);
  const getOtp = useRequestOtp(
    //@ts-ignore
    localStorage.getItem("user_id")
  );
  const selectedNgnBeneficiaryId = useNgnBeneficiaryState(
    (state) => state.selectedBeneficiaryId
  );
  const setBeneficiaries = useNgnBeneficiaryState(
    (state) => state.setBeneficiaries
  );
  const showNgnBeneficiaryDetails = useNgnBeneficiaryState(
    (state) => state.showBeneficiaryDetails
  );
  const ngnBeneficiary = useNgnBeneficiary();
  const createBeneficiary = useCreateNgnBeneficiary();
  const deleteBeneficiary = useDeleteNgnBeneficiary(selectedNgnBeneficiaryId);
  const [userDetails] = useOutletContext<any>();
  const withdrwal = useAccountWithdrawal();

  const bankOptions = useMemo(
    () =>
      getBanks?.data?.data?.map((bank: IBank) => ({
        label: bank.bankName,
        value: bank.nipCode,
        bank,
      })),
    [getBanks.data]
  );
  // const bankOptions: never[] = [];

  // console.log(ngnBeneficiary, "ngnBeneficiary");

  useEffect(() => {
    if (accountNumber.length === 10 && bank?.bank) {
      const id = bank.bank?.nipCode || "";
      resolveName
        .mutateAsync({
          bankCode: id,
          accountNumber: accountNumber,
        })
        .then((data: any) => {
          if (data) {
            setAccountName(data?.data?.data?.attributes?.accountName);
            setErrorMessage("");
            // if(accountName === "empty")
            toast.success("Account resolved successfully!");
          }
          // toast.error("Account Verification failed!");
        })
        .catch((error: any) => {
          setErrorMessage("Invalid account details");
          toast.error(`Failed to resolve account: ${error.message}`);
        });
    }
  }, [accountNumber, bank]);

  const addBeneficiary = () => {
    createBeneficiary
      .mutateAsync({
        bankName: bank?.label,
        accountName,
        accountNumber,
        bankId: bank?.bank?.anchorId || "",
      })

      .then((res) => {
        setAccountName("");
        setBank({});
        setAccountNumber("");
      })
      .catch((e) => {});
  };
  const bankName = (bnk: any) => {};
  const cot = 50 + (0.5 / 1000) * amountValue?.floatValue;

  const withdrawNgn = (e: any) => {
    // console.log("withdrawNgn called", {
    //   amountValue,
    //   accountName,
    //   otp,
    //   bank,
    //   cot,
    // });
    setLoading(true);
    if (beneficiary === true) {
      addBeneficiary();
    }
    withdrwal
      .mutateAsync({
        amount: amountValue?.floatValue + cot,
        accountName,
        accountNumber: accountNumber,
        otp,
        narration: referenceValue,
        bankId: bank?.bank?.anchorId as string,
        bankCode: bank?.bank?.nipCode as string,
        channel: "WEB",
        currency: "NGN",
        cot,
      })
      .then((data) => {
        setLoading(false);
        setShowVerification(false);
        setShowModal(true);
        setShowSuccess(true);
        setAccountName("");
        setBank({});
        setAccountNumber("");
        setAmountValue("");
        setReferenceValue("");
        ngnBeneficiary.refetch();
      })
      .catch((e: any) => {
        toast.error(e?.response?.data);
        setShowErr(true);
        setLoading(false);
        setShowVerification(false);
        setConfirmationModal(false);
        setFailedMsg(
          "Sorry! We are having issues processing this withdrawal request. Please check your details and try again"
        );
        setAccountName("");
        setBank({});
        setAccountNumber("");
        setAmountValue("");
        setReferenceValue("");
      });
  };

  // Helper function to reset form
  const resetForm = () => {
    setAccountName("");
    setBank({});
    setAccountNumber("");
    setAmountValue({ value: 0, floatValue: 0, formattedValue: "" });
    setReferenceValue("");
  };
  // const withdrawNgn = async (e: React.FormEvent) => {

  //   try {
  //     // Prevent default form behavior if it's a form event
  //     if (e) {
  //       e.preventDefault();
  //     }

  //     // Validate required fields before proceeding
  //     if (!amountValue?.floatValue || !accountName || !otp) {
  //       setShowErr(true);
  //       toast("Please fill in all required fields");
  //       return;
  //     }

  //     setLoading(true);

  //     // Add beneficiary first if option is selected
  //     if (beneficiary) {
  //       await addBeneficiary();
  //     }

  //     // Prepare withdrawal payload
  //     const withdrawalPayload: WithdrawalPayload = {
  //       amount: amountValue.floatValue + cot,
  //       accountName,
  //       accountNumber:
  //         accountNumber ,
  //       otp,
  //       narration: referenceValue,
  //       bankId: bank?.bank?.anchorId as string,
  //       bankCode: bank?.bank?.nipCode as string,
  //       channel: "WEB",
  //       currency: "NGN",
  //       cot,
  //     };

  //     // Execute withdrawal
  //     await withdrwal.mutateAsync(withdrawalPayload);

  //     // Handle success
  //     setLoading(false);
  //     setShowVerification(false);
  //     setShowModal(true);
  //     setShowSuccess(true);

  //     // Clear form
  //     resetForm();

  //     // Refresh beneficiary list
  //     ngnBeneficiary.refetch();
  //   } catch (error: any) {
  //     // Handle error
  //     setShowErr(true);
  //     setLoading(false);
  //     setShowVerification(false);
  //     setConfirmationModal(false);
  //     setFailedMsg(
  //       error?.message ||
  //         "Sorry! We are having issues processing this withdrawal request. Please check your details and try again"
  //     );

  //     // Clear form
  //     resetForm();
  //   }
  // };
  useEffect(() => {
    setBeneficiaries(ngnBeneficiary?.data?.data?.data || []);
  }, [ngnBeneficiary?.data?.data?.data]);

  const getVerification = () => {
    getOtp.mutateAsync({});
    setShowVerification(true);
    // setConfirmationModal(false)
    // setWithdrwalModal(false);
  };
  const handleDeleteClick = () => {
    setIsDeleteModalVisible(true);
  };

  const handleConfirmDelete = () => {
    setLoading1(true);
    deleteBeneficiary
      .mutateAsync({})
      .then((res) => {
        ngnBeneficiary.refetch();
        setLoading1(false);
        setIsDeleteModalVisible(false);
      })
      .catch((err) => {
        setLoading1(false);
      });
  };

  const handleCloseModal = () => {
    setIsDeleteModalVisible(false);
  };
  return (
    <div className=" h-full  bg-[#f7fbfb]  md:pt-10 md:px-8 ">
      <div className="bg-white md:bg-[#f7fbfb] pb-5 ">
        <div className="w-20 pt-5 pr-5 ml-auto block md:hidden bg-white">
          <img src={logo} alt="" />
        </div>
        <div className="flex items-center xxs:ml-5  md:justify-start md:ml-0  pt-5 md:pt-0 gap-4">
          <button
            onClick={() => {
              navigate("/dashboard/withdrawals", { replace: true });
            }}
            className=" bg-[#E5F2EA] rounded-full p-3 xs:mr-6 flex transition-all active:scale-90 "
          >
            <BsChevronLeft />
          </button>

          <p className="text-[#071827] text-lg xxs:text-sm">
            Where would you like to withdraw to?
          </p>
        </div>
      </div>
      {showModal && (
        <WithdrawalModal
          openModal={showModal}
          setModal={setShowModal}
          setAccName={setAccountName}
          //@ts-ignore
          banksOptions={bankOptions}
          bank={bank}
          setValues={setAmountValue}
          setBank={setBank}
          accountNumber={accountNumber}
          setAccount={setAccountNumber}
          setError={setErrorMessage}
          resolveName={resolveName.isLoading}
          accountName={accountName}
          error={errorMessage}
          value={amountValue?.value}
          valueFormatted={amountValue?.formattedValue}
          valueFloat={amountValue.floatValue}
          setValue={setAmountValue}
          balance={userDetails?.balances?.ngnBalance}
          refValue={referenceValue}
          setRefValue={setReferenceValue}
          bankName={bankName}
          confirmation={confirmationModal}
          setConfirmation={setConfirmationModal}
          userDetails={getUserDetails}
          beneficiary={setBeneficiary}
          verify={getVerification}
          setVerify={setShowVerification}
          // otp={OTP}
          setOtp={setOtp}
          withdraw={withdrawNgn}
          loading={loading}
          setLoading={setLoading}
          success={showSuccess}
          setSuccess={setShowSuccess}
          failed={failedMsg}
          setFailed={setFailedMsg}
          withdrawalModal={withdrwalModal}
          setWithdrawalModal={setWithdrwalModal}
          setAccNumber={setAccountNumber}
          setShowErr={setShowErr}
          showErr={showErr}
          firstName={getUserDetails?.data?.data?.firstName}
          lastName={getUserDetails?.data?.data?.lastName}
          accNumber={getUserDetails?.data?.data?.accountNumber}
          accName={getUserDetails?.data?.data?.bankName}
        />
      )}
      <div className="grid grid-cols-[repeat(auto-fill,_minmax(15rem,_1fr))] gap-3 mt-10 xxs:px-5">
        <div
          className="border border-[#0eb683] gap-4 bg-white w-full   rounded-[16px] flex md:flex-col items-center h-28  md:h-48 p-5 transition-all active:scale-90 cursor-pointer md:justify-center "
          onClick={() => {
            setShowModal(true);
          }}
        >
          <div className="bg-[#0eb683] p-3 text-white font-bold rounded-full md:mb-1 ">
            <TransactionsIcon stroke="#fff" />
          </div>
          <p className="text-[#071827] md:pb-5 font-semibold">
            Transfer to others
          </p>
        </div>

        {ngnBeneficiary?.data?.data?.data.map(
          (beneficiary: any, index: any) => {
            return (
              <NgnBeneficiaryCard b={beneficiary} delete={handleDeleteClick} />
            );
          }
        )}

        {showVerification && (
          <VerificationModal
            showVerification={showVerification}
            setShowVerification={setShowVerification}
            setAmountValue={setAmountValue}
            setOtp={setOtp}
            setConfirmationModal={setConfirmationModal}
            withdrawNgn={withdrawNgn}
            loading={loading}
          />
        )}
      </div>
      {isDeleteModalVisible && (
        <DeleteModal
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          loading={loading1}
        />
      )}
      {showNgnBeneficiaryDetails && (
        <NgnBeneficiaryModal delete={handleDeleteClick} />
      )}
    </div>
  );
};

export default Withdraw;

type ModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
};

const DeleteModal: React.FC<ModalProps> = ({ onClose, onConfirm, loading }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleConfirm = () => {
    onConfirm();
    setIsVisible(false);
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            onClick={onClose}
            className="absolute inset-0 bg-black opacity-50"
          ></div>

          <div className="bg-white p-4 md:p-8  rounded-lg z-10 w-full md:w-96 lg:w-[400px]">
            <h2 className="text-lg md:text-xl lg:text-2xl font-semibold mb-4">
              Are you sure you want to delete this beneficiary permanently?
            </h2>
            <p className="text-gray-600 mb-6">
              This action is irreversible and cannot be undone. Except you add
              again.
            </p>

            <div className="flex flex-col gap-4 md:gap-0 md:flex-row justify-between">
              <button
                onClick={handleCancel}
                className="w-full md:w-auto px-4 py-2 border border-gray-300 rounded-[16px]"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirm}
                className="w-full md:w-auto px-4 py-2 bg-red-500 text-white rounded-[16px] mb-2 md:mb-0 "
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      fill="white"
                    />
                    <path
                      d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  "Yes, Continue"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
