import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerInputProps {
  label: string;
  name: string;
  required?: boolean;
  onChange?: (date: Date | null) => void;
  className?: string;
  placeholder?: string;
  backgroundColor?: string;
}

const DatePickerInput: React.FC<DatePickerInputProps> = ({
  label,
  name,
  required = false,
  onChange,
  className,
  placeholder = "Select a date",
  backgroundColor = "bg-gray-100",
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);

    if (onChange) {
      onChange(date);
    }
  };
  // Calculate the range of years
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100; // 100 years in the past
  const endYear = currentYear + 20; // 20 years in the future

  return (
    <div className="w-full">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 mb-1"
      >
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      <div className="relative w-full">
        <DatePicker
          id={name}
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          className={`
            w-full
            px-3
            py-3
            ${backgroundColor}
          outline-[#0eb683]
placeholder-gray-400
         border-gray-300
            focus:outline-none
           focus:ring-0 focus:border-[#0eb683]
            border
            // border-transparent
            hover:border-gray-350
            transition-all
            duration-300
            rounded-[4px]
            ${className}
          `}
          placeholderText={placeholder}
          isClearable
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}
          minDate={new Date(startYear, 0, 1)}
          maxDate={new Date(endYear, 11, 31)}
          required={required}
          wrapperClassName="w-full"
          calendarClassName="custom-calendar"
          popperClassName="custom-popper"
        />
        {/* Custom dropdown icon */}
        <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center pr-3">
          <svg
            className="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default DatePickerInput;
