import React from 'react'

const Solana = () => {
  return (
    <div>
      <svg
        width="61"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.333252"
          width="60"
          height="60"
          rx="30"
          fill="url(#paint0_linear_15879_44265)"
        />
        <path
          d="M19.5311 17.984C19.7322 17.7918 19.9977 17.6797 20.2713 17.6797H45.8102C46.2768 17.6797 46.5102 18.2402 46.1803 18.5685L41.1353 23.5894C40.9422 23.7816 40.6766 23.8937 40.395 23.8937H14.8562C14.3895 23.8937 14.1561 23.3331 14.486 23.0048L19.5311 17.984Z"
          fill="white"
        />
        <path
          d="M41.1353 27.1988C40.9422 27.0066 40.6766 26.8945 40.395 26.8945H14.8562C14.3895 26.8945 14.1561 27.4551 14.486 27.7834L19.5311 32.8042C19.7242 32.9964 19.9897 33.1085 20.2713 33.1085H45.8102C46.2768 33.1085 46.5102 32.548 46.1803 32.2197L41.1353 27.1988Z"
          fill="white"
        />
        <path
          d="M19.5311 36.4098C19.7242 36.2176 19.9897 36.1055 20.2713 36.1055H45.8102C46.2768 36.1055 46.5102 36.666 46.1803 36.9943L41.1353 42.0151C40.9422 42.2073 40.6766 42.3194 40.395 42.3194H14.8562C14.3895 42.3194 14.1561 41.7589 14.486 41.4306L19.5311 36.4098Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_15879_44265"
            x1="46.8332"
            y1="-2.16588e-06"
            x2="5.83325"
            y2="67"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00FFA3" />
            <stop offset="1" stop-color="#DC1FFF" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default Solana