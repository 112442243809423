import React, { useEffect, useRef, useState } from "react";
import { GridPattern } from "../GridPattern";
import {
  type MotionValue,
  motion,
  useMotionTemplate,
  useMotionValue,
} from "framer-motion";
import { Link, useParams } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
// import arbitrumIcon from "../../assets/icons/Arbitrum";
import TronIcon from "../../assets/icons/Tron";
import Solana from "../../assets/icons/Solana";
import Polygon from "../../assets/icons/Polygon";
import _ from "lodash";
import { numberWithCommas } from "../../pages/dashboard/Withdrawals";
import {
  useGenerateWallet,
  useGetSingleWallet,
  useGetWalletDetails,
} from "../../sevices/hooks/users";
import { StableWalletCardProps } from "../../helper";

// type StableWalletCardProps = {
//   data: Array<{
//     href: string;
//     name: string;
//     currency: string;
//     amount: number;
//     icon: React.ReactNode;
//     pattern: { y: number; squares: number[][] };
//   }>;
// };

export interface Props {
  href: string;
  name: string;
  currency: string;
  amount: number;
  icon?: React.ComponentType<{ className?: string }>;
  pattern: Omit<
    React.ComponentPropsWithoutRef<typeof GridPattern>,
    "width" | "height" | "x"
  >;
}

export function WalletIcon({ icon: Icon }: { icon: Props["icon"] }) {
  return (
    <div className="flex h-14 w-14 items-center justify-center rounded-full bg-zinc-900/5 ring-1 ring-zinc-900/25 backdrop-blur-[2px] transition duration-300 group-hover:bg-white/50 group-hover:ring-zinc-900/25 dark:bg-white/7.5 dark:ring-white/15 dark:group-hover:bg-indigo-300/10 dark:group-hover:ring-indigo-400">
      {Icon && (
        <Icon className="h-5 w-5 fill-zinc-700/10 stroke-zinc-700 transition-colors duration-300 group-hover:stroke-zinc-900 dark:fill-white/10 dark:stroke-zinc-400 dark:group-hover:fill-indigo-300/10 dark:group-hover:stroke-indigo-400" />
      )}
    </div>
  );
}

function WalletPattern({
  mouseX,
  mouseY,
  ...gridProps
}: Props["pattern"] & {
  mouseX: MotionValue<number>;
  mouseY: MotionValue<number>;
}) {
  let maskImage = useMotionTemplate`radial-gradient(90px at ${mouseX}px ${mouseY}px, white, transparent)`;
  let style = { maskImage, WebkitMaskImage: maskImage };

  return (
    <div className="pointer-events-none">
      <div className="absolute inset-0 rounded-2xl transition duration-300 [mask-image:linear-gradient(white,transparent)] group-hover:opacity-50">
        <GridPattern
          width={72}
          height={56}
          x="50%"
          className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-[#e8f0ee] stroke-black/5 dark:fill-white/1 dark:stroke-white/2.5"
          {...gridProps}
        />
      </div>
      <motion.div
        className="absolute inset-0 rounded-2xl bg-gradient-to-r from-[#d5dbd9] to-[#e6eeeb] opacity-0 transition duration-300 group-hover:opacity-20 dark:from-[#bec9c4] dark:to-[#01454a]"
        style={style}
      />
      <motion.div
        className="absolute inset-0 rounded-2xl opacity-0 mix-blend-overlay transition duration-300 group-hover:opacity-100"
        style={style}
      >
        <GridPattern
          width={72}
          height={56}
          x="50%"
          className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-[#d3dedb] stroke-black/70 dark:fill-white/2.5 dark:stroke-white/10"
          {...gridProps}
        />
      </motion.div>
    </div>
  );
}

export function StableCoin({ data }: { data: Props }) {
  let mouseX = useMotionValue(0);
  let mouseY = useMotionValue(0);
  const { currency } = useParams();
  function onMouseMove({
    currentTarget,
    clientX,
    clientY,
  }: React.MouseEvent<HTMLDivElement>) {
    let { left, top } = currentTarget.getBoundingClientRect();
    mouseX.set(clientX - left);
    mouseY.set(clientY - top);
  }
  const formattedAmount = isNaN(Number(data?.amount))
    ? ""
    : numberWithCommas(Number(data?.amount));

  return (
    <div
      key={data.name}
      onMouseMove={onMouseMove}
      className="group relative flex rounded-2xl bg-white transition-shadow hover:shadow-md hover:shadow-zinc-900/5 dark:bg-white/2.5 dark:hover:shadow-black/5 border h-36"
    >
      <WalletPattern {...data.pattern} mouseX={mouseX} mouseY={mouseY} />
      <div className=" flex items-center w-full h-full gap-6 md:gap-3  rounded-2xl ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-zinc-900/10 dark:ring-white/10 dark:group-hover:ring-white/20">
        <div className=" rounded-2xl w-[20%] pl-4">
          <WalletIcon icon={data.icon} />
        </div>
        <div className="w-[70%]">
          <h3 className=" text-sm  leading-7 text-[#5C6F7F] dark:text-zinc-400 ">
            {_.capitalize(data.name)}
          </h3>
          <p className="mt-1  text-[#071827] font-semibold">
            {formattedAmount} {currency?.toUpperCase()}
          </p>
          <Link
            to={`/dashboard/wallet/${currency}/${data.name}`}
            className="flex text-sm items-center text-[#0eb683] mt-1 hover:underline gap-2"
          >
            <GoArrowUpRight color="#0eb683" size={20} />
            View more
          </Link>
        </div>
      </div>
    </div>
  );
}



const StableWalletCard: React.FC<StableWalletCardProps> = ({ data }) => {
  const { currency } = useParams();
  const { data: wallet } = useGenerateWallet();
  const { data: walletdetails } = useGetWalletDetails();
  const { data: singleWallet } = useGetSingleWallet(currency as string);

  // console.log(wallet, "wallet");
  // console.log(walletdetails, "walletdetails");
  // console.log(singleWallet, "singleWallet");

  // console.log(currency, "StableWalletCard");
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (sliderRef.current) {
      const index = Math.round(
        sliderRef.current.scrollLeft / sliderRef.current.clientWidth
      );
      setCurrentSlide(index);
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("scroll", handleScroll);
      return () => {
        slider.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  // console.log(data, "data");

  return (
    <div className="xl:max-w-none px-5 md:px-0">
      <div className="not-prose mt-4 gap-8 border-t border-zinc-900/5 dark:border-white/5">
        <div
          className="flex md:grid md:grid-cols-3 overflow-x-scroll gap-3 overflow-y-hidden scroll-snap-x mandatory"
          ref={sliderRef}
        >
          {data.map((resource: any, index: any) => (
            <div
              className="scroll-snap-start flex-shrink-0 w-[90%] md:w-auto "
              key={index}
            >
              <StableCoin data={resource} />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4 md:hidden ">
          {data.map((_: any, index: any) => (
            <span
              className={`h-2 w-2 mx-1 bg-[#0eb683] rounded-full cursor-pointer ${
                currentSlide === index ? "bg-[#757676]" : ""
              }`}
              key={index}
              onClick={() => {
                if (sliderRef.current) {
                  sliderRef.current.scrollTo({
                    left: index * sliderRef.current.clientWidth * 0.9,
                    behavior: "smooth",
                  });
                }
              }}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StableWalletCard;
