import React, { useState } from "react";
import InputField from "../comp/InputField";
import CustomSelect from "../../../components/base-components/CustomSelect";
import { docType, industries } from "../../../utils";
import TextArea from "../comp/TextArea";
import AppSelectInput from "../../../components/base-components/AppSelectInput";
import { businessOptions, companyOptions } from "../BusinessUser";
import { useGetCountry, useGetState } from "../../../sevices/hooks/tags";
import DragDropComponent from "../../../components/base-components/DragDropComponent";
import Controller from "./Controller";
import { IOnboardingUser } from "./UserDetails";
import { IFormData, useForm } from "../../../context/OnboardingFormContext";

const BusinessDetails = ({
  handleClick,
  currentStep,
  steps,
  setErrMsg,
}: IOnboardingUser) => {
  const Icountry = useGetCountry();
  const states = useGetState();

  const [formData, setFormData] = useState({
    businessName: "",
    businessEmail: "",
    businessphoneNumber: "",
    industry: null,
    registration: "",
    description: "",
    addressLine_1: "",
    ContactRegisteredCountry: null,
    businessTypeSlug: "",
    ContactRegisteredState: null,
    certificate: null,
    postalCode: "",
    businessCity: "",
    registrationEvidence: "",
    registrationEvidenceDoc: "",
    dateOfRegistration: "",
    website: "",
    companyType: "",
    businessIncorNum: "",
    // selfieData: [],
  });

  const { state, dispatch } = useForm();

  const countryOptions =
    Icountry?.data?.data.data.map(
      (
        {
          countryCode,
          name,
          index,
        }: { countryCode: string; name: string; index: number },
        id: number
      ) => ({
        label: name,
        slug: countryCode,
        id,
      })
    ) || [];

  const stateOptions =
    states?.data?.data.data.map(
      ({ name, index }: { name: string; index: number }, id: number) => ({
        label: name,
        slug: name,
        id,
      })
    ) || [];

  const handleInputChange = (name: string, value: string) => {
    setErrMsg("");
    dispatch({ type: "UPDATE_FIELD", field: name as keyof IFormData, value });

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    // Handle form submission using formData
  };

  const handleBusiness = (
    business: {
      value: string;
      label: string;
      slug: string;
    } | null
  ) => {
    if (business) {
      handleInputChange("businessType", business.value);
      handleInputChange("businessTypeSlug", business.slug);
    }
  };

  return (
    <div>
      <div className=" pb-4 pt-10">
        <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827] ">
          Business Details
        </h1>
        <p className=" text-[#5C6F7F] text-[16px] font-normal leading-[24px]">
          Complete basic details about your business:
        </p>
      </div>
      <div className="md:w-full md:max-w-lg">
        <form
          onSubmit={handleFormSubmit}
          className="bg-none md:bg-white flex flex-col gap-3 p-0 md:p-5 "
        >
          <div className="relative ">
            <InputField
              label="Business Name"
              name="businessName"
              type="text"
              required={true}
              value={state.businessName}
              onChange={(value: string) =>
                handleInputChange("businessName", value)
              }
            />
          </div>
          <div className="mb-3  relative">
            <small className="text-[#0B0C0E] text-sm font-normal ">
              <span className="text-[#E01A00]">*</span>
              Select Industry{" "}
            </small>
            <CustomSelect
              //@ts-ignore
              selectedOption={state.industry}
              setSelectOption={(industry) =>
                handleInputChange("industry", industry.slug)
              }
              placeholder={"select Industry"}
              options={industries || []}
              // errors={errorMessages}
              name={"industry"}
            />
            {/* <div className="text-red-500 text-xs">{errorMessages.country}</div> */}
          </div>
          <div>
            <TextArea
              label="Business Nature (Description)"
              name="description"
              dvalue={state.description}
              rows={2}
              onChange={(evt) =>
                handleInputChange("description", evt.target.value)
              }
            />
          </div>
          <div>
            <AppSelectInput
              placeholder="Business Registration Type"
              options={businessOptions || []}
              label=""
              handleChange={(business) => {
                handleBusiness(business);
              }}
            />
          </div>
          {state.businessType === "co" && (
            <div>
              <AppSelectInput
                placeholder="Company Registration Type"
                options={companyOptions || []}
                label=""
                handleChange={(coy) =>
                  handleInputChange("companyType", coy?.value)
                }
              />
            </div>
          )}
          <div className="relative ">
            <InputField
              label="Business Registration Number"
              name="businessIncorNum"
              type="text"
              pattern="\d*"
              required={true}
              value={state.businessIncorNum}
              onChange={(value: string) =>
                handleInputChange("businessIncorNum", value)
              }
            />
            <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]">
              Business registration number (numbers only without the 2 letter
              suffix) e.g. RC 0000000 should be submitted as 0000000
            </small>
          </div>
          <div className="relative ">
            <InputField
              label="Business Registration Date"
              name="dateOfRegistration"
              type="date"
              required={true}
              value={state.dateOfRegistration}
              onChange={(value: string) =>
                handleInputChange("dateOfRegistration", value)
              }
            />
          </div>
          <div className="flex items-center justify-between gap-4">
            <div className="mb-3 w-full relative">
              <small className="text-[#0B0C0E] text-sm font-normal ">
                <span className="text-[#E01A00]">*</span>
                Country{" "}
              </small>
              <CustomSelect
                //@ts-ignore
                selectedOption={state.BasicCountry}
                setSelectOption={(country) =>
                  handleInputChange("BasicCountry", country.slug)
                }
                placeholder={"Select Country of Registration"}
                options={countryOptions || []}
              />
              <span className="text-[#E01A00] absolute left-2 top-9">*</span>
            </div>
          </div>
          <div className="flex items-center justify-between gap-4">
            <div className="mb-3 w-full relative">
              <small className="text-[#0B0C0E] text-sm font-normal ">
                <span className="text-[#E01A00]">*</span>
                State{" "}
              </small>
              <CustomSelect
                //@ts-ignore
                selectedOption={state.ContactRegisteredState}
                setSelectOption={(state) =>
                  handleInputChange("ContactRegisteredState", state.slug)
                }
                placeholder={"Select State of Registration"}
                options={stateOptions || []}
              />
              <span className="text-[#E01A00] absolute left-2 top-9">*</span>
            </div>
          </div>
          <div className="relative ">
            <InputField
              label="Business City"
              name="businessCity"
              type="text"
              required={true}
              value={state.businessCity}
              onChange={(value: string) =>
                handleInputChange("businessCity", value)
              }
            />
          </div>
          <div>
            <small className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal ">
              <span className="text-[#E01A00]">*</span>
              Upload Incorporation Certificate
            </small>
            <DragDropComponent
              inputId="dkke"
              getFiles={(cert) => handleInputChange("certificate", cert)}
            />
            <small className="text-[11px] text-[#7991a4] ">
              These include CAC incorporation certificates, etc. Supported file
              types .pdf, .docx, .jpeg, .jpg, .png, .txt
            </small>
          </div>
          <div>
            <AppSelectInput
              placeholder="Select Registration Evidence"
              options={docType || []}
              label=""
              handleChange={(doc) =>
                handleInputChange("registrationEvidence", doc?.slug)
              }
            />
          </div>
          <div>
            <small className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal ">
              <span className="text-[#E01A00]">*</span>
              Upload Selected Document
            </small>
            <DragDropComponent
              inputId="ddockke"
              getFiles={(documents) =>
                handleInputChange("registrationEvidenceDoc", documents)
              }
            />
            <small className="text-[11px] text-[#7991a4] ">
              Supported file types .pdf, .docx, .jpeg, .jpg, .png, .txt
            </small>
          </div>
          <div className="relative ">
            <InputField
              label="Business Address"
              name="addressLine_1"
              type="text"
              required={true}
              value={state.addressLine_1}
              onChange={(value: string) =>
                handleInputChange("addressLine_1", value)
              }
            />
          </div>
          <div className="relative ">
            <InputField
              label="Business Phone Number"
              name="businessphoneNumber"
              type="tel"
              required={true}
              value={state.businessphoneNumber}
              onChange={(value: string) =>
                handleInputChange("businessphoneNumber", value)
              }
            />
            <div className="flex items-center gap-2  ">
              <small className="text-[10px] tracking-[0.01em] leading-[16px]  text-[#5C6F7F]  ">
                This should be the number linked to your Business
              </small>
            </div>
          </div>
          <div className="relative ">
            <InputField
              label="Business Email"
              name="businessEmail"
              type="text"
              value={state.businessEmail}
              required={true}
              onChange={(value: string) =>
                handleInputChange("businessEmail", value)
              }
            />
          </div>
          <div className="relative ">
            <InputField
              label="Business Website, if applicable"
              name="website"
              type="text"
              value={state.website}
              onChange={(value: string) => handleInputChange("website", value)}
            />
          </div>
        </form>
        <Controller
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
          setErrMsg={setErrMsg}
        />
      </div>
    </div>
  );
};

export default BusinessDetails;
