import React, { useEffect, useMemo, useState } from "react";
import RefreshIcon from "../../assets/iconsComponents/RefreshIcon";
import { TabSelector } from "../widgets/TabSelector";
import { useTabs } from "../widgets/TabWidget";
import QrCode from "qrcode.react";
import {
  useGetSingleFee,
  useGetWalletDetails,
} from "../../sevices/hooks/users";
import Warning from "./Warning";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useStepperStore } from "../../store/useStableCoin";
import { numberWithCommas } from "../../pages/dashboard/Withdrawals";
import AppNumericInput from "../widgets/AppNumericInput";
import { StableCoinBeneficiary } from "../../types/Users";
import { useGetStableBeneficiaries } from "../../sevices/hooks/wallets";

const UsdcTransactionDetails = ({
  prev,
  onClick,
  currency,
  onSaveBeneficiary,
}: {
  onClick: () => void;
  currency: string;
  prev: () => void;
  onSaveBeneficiary: (details: any) => void;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { beneficiaryDetails, setCurrentStep } = useStepperStore();
  //  const {  } = useStepperStore();
  const Stablebeneficiaries = useGetStableBeneficiaries();
  const beneficiaryId = searchParams.get("q");
  const [choice, setChoice] = useTabs(["No", "Yes"]);
  const { data: walletdetails, refetch } = useGetWalletDetails();
  const { data: fees } = useGetSingleFee(currency as string);
  const { isLoading: StableisLoading, data: Stabledata } = Stablebeneficiaries;
  const filteredBeneficiary = useMemo<StableCoinBeneficiary | null>(() => {
    if (beneficiaryId && Stabledata?.data?.data) {
      return (
        Stabledata?.data?.data.find(
          (beneficiary) => beneficiary.id === beneficiaryId
        ) || null
      );
    }
    return null;
  }, [beneficiaryId, Stabledata]);

  const [inputValue, setInputValue] = useState<any>();
  const [walletBalance, setWalletBalance] = useState<string | undefined>("0.0");
  const walletData = walletdetails?.data?.data;

  useEffect(() => {
    setWalletBalance(
      currency === "USDC" ? walletData?.usdcBalance : walletData?.usdtBalance
    );
  }, []);

  // console.log(beneficiaryDetails, "beneficiaryDetails");
  // console.log(walletBalance, "walletBalance");
  // console.log(inputValue, "inputValue");

  const handleClick = () => {
    onSaveBeneficiary({
      ...beneficiaryDetails,
      amount: inputValue?.value,
      formattedAmount: inputValue?.formattedValue,
    });
    onClick();
  };

  useEffect(() => {
    return () => {
      if (performance.navigation.type !== 1) {
        useStepperStore.getState().resetStore();
      }
    };
  }, []);

  const handleBack = () => {
    if (beneficiaryId) {
      navigate("/dashboard/beneficiaries?tab=Stablecoins", { replace: true });
      setCurrentStep(1);
    } else {
      prev();
    }
  };

  return (
    <div className="bg-[#f7fbfb] h-full w-full p-5 pt-8 pb-10">
      <div className="relative">
        {/* Warning Component - Stacked on mobile, popup on desktop */}
        <div className="mb-6 relative sm:absolute sm:top-2 sm:right-4 sm:max-w-md sm:z-10">
          <Warning
            currency={currency}
            chain={
              filteredBeneficiary
                ? filteredBeneficiary.chain
                : beneficiaryDetails?.selectedNetwork
            }
          />
        </div>

        {/* Main transaction form */}
        <div className="max-w-2xl relative sm:mt-16">
          <div className="sm:bg-white sm:p-5 sm:rounded-lg">
            <h1 className="text-[#071827] font-bold text-xl pt-5">
              {currency} Transaction Details
            </h1>

            {/* Current Balance Section */}
            <div className="pt-8">
              <p className="text-[#0B0C0E] text-base">Current Balance</p>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <h1 className="text-[#0eb683] font-bold text-xl">
                    {numberWithCommas(walletBalance)} {currency}
                  </h1>
                  <button className="bg-white rounded-full p-1 border border-[#0eb683] transition-all active:scale-90">
                    <RefreshIcon />
                  </button>
                </div>
                <button className="text-[#0eb683] border border-[#0eb683] py-2 px-6 rounded-xl">
                  Receive
                </button>
              </div>
            </div>

            {/* Amount Input */}
            <div className="mid pt-4 relative">
              <AppNumericInput
                value={inputValue?.formattedValue}
                onValueChange={(value: any) => {
                  setInputValue(value);
                }}
                thousandSeparator={true}
                prefix={`${currency} `}
                style={{
                  borderColor: "#a10",
                }}
                labelStyle={{
                  color: "#071827",
                  fontWeight: "normal",
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
                rightLabel={
                  inputValue !== "" &&
                  inputValue !== null &&
                  inputValue !== undefined
                }
                rightLabelStyle={{
                  color: "red",
                  padding: 0,
                }}
                label={`Enter Amount you want to send in ${currency}`}
                placeholderStyle={{
                  color: "gray",
                  fontSize: "14px",
                }}
                placeholder={`Amount you want to send in ${currency}`}
                isAllowed={(nValue: any) => nValue.value.length <= 11}
                //@ts-ignore
                isInsufficient={inputValue?.value > +walletBalance}
                insufficientLabel="Insufficient balance"
              />
            </div>

            {/* Service Fee Selection */}
            <p className="text-sm text-slate-500 pt-5">
              <span className="text-[#FA0204]">*</span> Should service fee be
              debited from total amount?
            </p>
            <div className="flex gap-6 pt-2">
              <TabSelector
                className={`cursor-pointer relative bg-transparent mr-4 md:mr-0 ${
                  choice === "No"
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={choice === "No"}
                onClick={() => setChoice("No")}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                  <h1 className="text-[#5C6F7F]">No</h1>
                </div>
              </TabSelector>

              <TabSelector
                className={`cursor-pointer relative bg-transparent ${
                  choice === "Yes"
                    ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
                    : ""
                }`}
                isActive={choice === "Yes"}
                onClick={() => setChoice("Yes")}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
                  <h1 className="text-[#5C6F7F]">Yes</h1>
                </div>
              </TabSelector>
            </div>

            {/* Transaction Details */}
            <div className="space-y-5 pt-5">
              <div>
                <p className="text-[#0B0C0E] text-sm">
                  Service Fee @ {fees?.data?.data?.fee}% ({currency})
                </p>
                <h1 className="text-xl font-bold text-[#0b0c0e]">
                  {currency} 0.00
                </h1>
              </div>

              <div>
                <p className="text-[#0B0C0E] text-sm">Blockchain Network</p>
                <h1 className="text-xl font-bold text-[#0b0c0e]">
                  {filteredBeneficiary
                    ? filteredBeneficiary.chain
                    : beneficiaryDetails?.selectedNetwork}
                </h1>
              </div>

              <div>
                <p className="text-[#0B0C0E] text-sm">
                  Total Amount to be debited
                </p>
                <h1 className="text-xl font-bold text-[#0b0c0e]">
                  {inputValue?.value === ""
                    ? `${currency} 0.00`
                    : inputValue?.formattedValue}
                </h1>
              </div>

              <div>
                <p className="text-[#0B0C0E] text-sm">
                  Beneficiary receives ({currency})
                </p>
                <h1 className="text-xl font-bold text-[#0b0c0e]">
                  {inputValue?.value === ""
                    ? `${currency} 0.00`
                    : inputValue?.formattedValue}
                </h1>
              </div>
            </div>

            {/* Navigation Buttons */}
            <div className="flex items-center justify-between pt-5">
              <button
                onClick={handleBack}
                className="bg-white text-[#0eb683] p-3 border border-[#0eb683] rounded-xl px-6 transition-all active:scale-90"
              >
                Back
              </button>
              {/* <button
                disabled={
                  //@ts-ignore
                  inputValue?.value > +walletBalance || inputValue?.value === ""
                }
                onClick={handleClick}
                className="text-white bg-[#0eb683] border border-[#0eb683] p-3 rounded-xl px-6 transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none"
              >
                Proceed
              </button> */}
              <button
                disabled={
                  inputValue?.value === "" ||
                  inputValue?.value === undefined ||
                  //@ts-ignore
                  inputValue?.value > +walletBalance
                }
                onClick={handleClick}
                className="text-white bg-[#0eb683] border border-[#0eb683] p-3 rounded-xl px-6 transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsdcTransactionDetails;
