import React from "react";
import { motion } from "framer-motion";
import { FaCheckCircle } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai"; // Loading spinner icon
import { useParams, useNavigate } from "react-router-dom";
import useModalStore from "../../store/card";
import { useDeleteCard } from "../../sevices/hooks/cards";


const Modal: React.FC = () => {
  const { card_id } = useParams();
  const { isOpen, closeModal } = useModalStore();
  const navigate = useNavigate(); // Initialize navigation hook
  const deleteCard = useDeleteCard(card_id as string);

  const handleDelete = async () => {
    try {
      await deleteCard.mutateAsync({}).then(() => {
        alert("Card deleted!");
        closeModal();
        navigate("/dashboard/cards"); // Navigate to dashboard after deletion
      });
    } catch (e) {
      // console.error("Failed to delete the card:", e);
    }
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      className="confirmation_modal backdrop-blur-md bg-[#13111137] fixed w-full h-full top-0 left-0 justify-center md:justify-end items-center z-50"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.3 }}
        className="bg-white rounded-2xl p-6 w-full max-w-md shadow-xl"
      >
        <h2 className="text-2xl font-semibold text-center text-gray-900">
          Card emptied
        </h2>

        <div className="mt-4 flex items-start space-x-3 bg-[#F2FAF7] p-4 rounded-md">
          <FaCheckCircle className="text-green-500 w-6 h-6" />
          <div className="text-[#5C6F7F] space-y-2">
            <p>Your card has been emptied.</p>
            <p>
              You can proceed to delete the card by clicking the button below.
            </p>
            <p>Do you still want to delete your card?</p>
            <p>
              This action is permanent and all associated data will be deleted
              too.
            </p>
          </div>
        </div>

        <div className="mt-6 flex justify-between">
          <button
            onClick={closeModal}
            className="px-4 py-2 text-green-700 border border-green-500 rounded-lg hover:bg-green-50"
          >
            No, cancel
          </button>

          <button
            onClick={handleDelete}
            disabled={deleteCard.isLoading}
            className={`px-4 py-2 flex items-center justify-center gap-2 text-red-700 border border-red-500 rounded-lg hover:bg-red-50 ${
              deleteCard.isLoading ? "opacity-70 cursor-not-allowed" : ""
            }`}
          >
            {deleteCard.isLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin w-5 h-5" />
            ) : (
              "Yes, delete card"
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Modal;
