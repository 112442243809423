import React, { useEffect, useMemo, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";

import { useNavigate, useOutletContext } from "react-router-dom";
import WalletCompartment from "../../../components/dashboard-components/WalletCompartment";
import { AiOutlineClose } from "react-icons/ai";
import Notification from "../../../components/dashboard-components/Notification";

import SuccessMobile from "../../../assets/iconsComponents/SuccessMobile";
import { motion, AnimatePresence } from "framer-motion";
import InfoIcon from "../../../assets/iconsComponents/InfoIcon";
import OTPInput from "../../../components/widgets/OTPInput";
import AppSelectInput from "../../../components/base-components/AppSelectInput";
import {
  useAccActivity,
  useAccountWithdrawal,
  useWalletAccount,
  useWalletBalance,
} from "../../../sevices/hooks/wallets";
import { numberWithCommas } from "../Withdrawals";
import { FaRegCopy, FaMoneyBillWave } from "react-icons/fa";
import {
  useBankList,
  useRequestOtp,
  useResolveBankName,
  useUsers,
} from "../../../sevices/hooks/users";
import AppCheckBox from "../../../components/widgets/AppCheckBox";
import FailedIcon from "../../../assets/iconsComponents/FailedIcon";
import ReactLoading from "react-loading";
import moment from "moment";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { RiBankFill } from "react-icons/ri";
import FiatWalletCard from "../../../components/dashboard-components/FiatWalletCard";
import FundModal from "../../../components/dashboard-components/FundModal";
import WalletAccountTable from "../../../components/wallet-components/WalletAccountTable";
import { IBank } from "../../onboarding/ChooseBank";
import EmptyState from "../../../components/base-components/EmptyState";

const NairaWallet = ({ currency = "ngn" }: { currency?: string }) => {
  const [userDetails] = useOutletContext<any>();
  const [copyStatus, setCopyStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bank, setBank] = useState<{
    label?: string;
    value?: string;
    bank?: IBank;
  }>({});
  const [accountName, setAccountName] = useState("");
  const [amountValue, setAmountValue] = useState<any>({ value: 0 });
  const [accountNumber, setAccountNumber] = useState("");
  const [showWithdrawalSuccess, setShowWithdrawalSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [failureMsg, setFailureMsg] = useState("");
  const [spin, setSpin] = useState(false);
  const resolveName = useResolveBankName();
  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );
  const getOtp = useRequestOtp(
    //@ts-ignore
    localStorage.getItem("user_id")
  );
  const withdrwal = useAccountWithdrawal();
  const [otp, setOtp] = useState("");
  const getBanks = useBankList();
  // const [withdrawalModal, setWithdrawalModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  const [referenceValue, setReferenceValue] = useState("");
  const accountNgn = useWalletBalance("ngn");
  // const [userDetails] = useOutletContext<any>();

  const copyToBankClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus(userDetails?.lcyAccount?.ngnLBPBankName);
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };
  const copyToNameClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus(userDetails?.lcyAccount?.ngnLBPAccountName);
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };
  const copyToNumberClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus(userDetails?.lcyAccount?.ngnLBPSubAccountNumber);
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };

  const navigate = useNavigate();

  const confirmation = () => {
    setShowConfirmationModal(true);
  };

  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (accountNumber.length === 10 && bank.bank) {
      const id = bank.bank?.nipCode || "";
      resolveName
        .mutateAsync({
          bankCode: id,
          accountNumber: accountNumber,
        })
        .then((data: any) => {
          // getDetails({ bank:bank.bank, accountNumber }, true);
          setAccountName(data?.data?.account_name);
          setErrorMessage("");
        })
        .catch((error: any) => {
          setErrorMessage(error.response.data);
        });
    }
  }, [accountNumber, bank]);

  const withdrawNgn = (e: any) => {
    setSpin(true);
    e.preventDefault();
    setLoading(true);
    withdrwal
      .mutateAsync({
        amount: amountValue?.floatValue,
        accountName: accountName,
        accountNumber: accountNumber,
        otp: otp,
        narration: referenceValue,
        // @ts-ignore
        bankId: bank?.bank?.id,
      })
      .then((data) => {
        setSpin(false);
        setLoading(false);
        setShowVerification(false);
        setShowWithdrawalSuccess(true);
        setAccountName("");
        setBank({});
        setAccountNumber("");
        setAmountValue("");
        setReferenceValue("");
      })

      .catch((e: any) => {
        setSpin(false);
        setLoading(false);
        setFailureMsg(
          "Validation error. Please check your inputs and try again"
        );
        setFailed(true);
        setAccountName("");
        setBank({});
        setAccountNumber("");
        setAmountValue("");
        setReferenceValue("");
      });
  };

  const userActivities = useAccActivity({
    page_size: 10,
    page: 1,
    currency: "NGN",
  });

  const ngnTransactions = useMemo(
    () => userActivities?.data?.data?.data,
    [userActivities?.data?.data?.data]
  );

  // console.log(transactions, "transactions");

  const bankName = (bnk: any) => {};

  const { data } = accountNgn;
  const hasData = !!data?.data?.available_balance?.formatted;

  const getVerification = () => {
    getOtp.mutateAsync({});
    setConfirmationModal(false);
    setShowWithdrawModal(false);
    setShowModal(false);
    setShowVerification(true);
  };
  // console.log(ngnTransactions, "ngnTransactions");

  return (
    <div className="md:bg-[#f7fbfb] h-full w-full px-5 py-5 md:p-5 md:pl-8 md:pr-10 ">
      <div className="flex items-center gap-24 md:gap-4">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className=" bg-[#ECF6F3] rounded-full p-2 flex transition-all active:scale-90  "
        >
          <BsChevronLeft />
        </button>
        <p className="font-semibold">Naira Wallet</p>
      </div>
      <div className="box bg-white flex flex-col  md:p-5 mt-7 rounded-lg">
        <div className="flex flex-col items-start gap-3 mb-5 md:flex-row md:justify-between ">
          <div className="w-full ">
            <FiatWalletCard
              text="Naira Balance"
              currency={"₦ "}
              props={accountNgn}
              amount={
                numberWithCommas(userDetails?.balances?.ngnBalance) === "NaN"
                  ? "0.00"
                  : ` ${numberWithCommas(userDetails?.balances?.ngnBalance)}`
              }
            />
          </div>
          <div className="hidden md:flex xxs:flex-col sm:flex-col md:items-end ">
            <div className="flex gap-3 w-full md:items-center">
              <div
                // className="flex"
                onClick={openModal}
                className="border border-[#0eb683] text-center  text-[#0eb683] w-full p-3 md:w-40 whitespace-nowrap bg-white rounded-[16px] transition-all active:scale-90 cursor-pointer flex items-center justify-center gap-2"
              >
                <RiBankFill size={20} />
                <p className="">Fund</p>
              </div>
              <div
                // className="flex"
                onClick={() => {
                  // setShowWithdrawModal(true);
                  navigate("/dashboard/withdrawals/withdraw", {
                    replace: true,
                  });
                }}
                className="bg-[#0eb683] flex w-full md:w-64 p-3 text-center border border-[#0eb683] text-white rounded-[16px] transition-all active:scale-90 cursor-pointer self-end items-center justify-center gap-2"
              >
                <FaMoneyBillWave size={25} />
                Withdraw
              </div>
            </div>
          </div>

          {/* Mobile */}

          <div className="flex md:hidden gap-3 w-full md:items-center">
            <div
              onClick={openModal}
              className="border border-[#0eb683] text-center  text-[#0eb683] w-full p-3 md:w-28 whitespace-nowrap bg-white rounded-[16px] transition-all active:scale-90 cursor-pointer "
            >
              Fund Wallet
            </div>
            <div
              onClick={() => {
                // setShowWithdrawModal(true);
                navigate("/dashboard/withdrawals/withdraw", {
                  replace: true,
                });
              }}
              className="bg-[#0eb683] w-full md:w-40 p-3 text-center border border-[#0eb683] text-white rounded-[16px] transition-all active:scale-90 cursor-pointer self-end"
            >
              Withdraw
            </div>
          </div>

          {confirmationModal && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="modal bg-[#13111173] fixed w-full h-full top-0 left-0 justify-center items-center z-10"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex h-screen items-center justify-center md:justify-end"
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="card flex flex-col bg-white  w-full max-w-sm max-h-full rounded-l-3xl p-8 md:h-full md:max-w-md "
                  >
                    <button
                      onClick={() => {
                        setConfirmationModal(false);
                        setAccountName("");
                        setBank({});
                        setAccountNumber("");
                        setAmountValue("");
                        setReferenceValue("");
                      }}
                      className="hover:bg-[#f2faf7] rounded-full p-2 self-end"
                    >
                      <AiOutlineClose size={28} />
                    </button>

                    <div>
                      <div className="flex items-center gap-4 ">
                        <button
                          className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
                          onClick={() => {
                            setConfirmationModal(false);
                          }}
                        >
                          <BsChevronLeft />
                        </button>
                        <h1 className="text-lg font-bold text-[#071827]">
                          Confirmation
                        </h1>
                      </div>
                      <p className="text-[#5C6F7F] pt-3">
                        Confirm the following details for your withdrawal
                      </p>
                    </div>
                    <div className="flex justify-between mt-5">
                      <p className="text-[#071827] font-semibold">
                        You are transferring
                      </p>
                      <h1 className="text-[#0eb683] text-lg font-bold">
                        NGN {numberWithCommas(amountValue?.value)}
                      </h1>
                    </div>
                    <div className=" mt-4">
                      <h1 className="text-[#071827] font-extrabold">To:</h1>
                      <div className="flex flex-col gap-5 mt-5">
                        <div className="flex items-center justify-between">
                          <h1 className="text-[#5C6F7F]">Bank</h1>
                          <h2 className="text-[#071827] font-medium">
                            {bank?.label ||
                              getUserDetails?.data?.data?.bankName}
                          </h2>
                        </div>
                        <div className="flex items-center justify-between">
                          <h1 className="text-[#5C6F7F]">Account Number</h1>
                          <h2 className="text-[#071827] font-medium">
                            {accountNumber ||
                              getUserDetails?.data?.data?.accountNumber}
                          </h2>
                        </div>
                        <div className="flex items-center justify-between">
                          <h1 className="text-[#5C6F7F]">Beneficiary</h1>
                          <h2 className="text-[#071827] font-medium">
                            {accountName ||
                              getUserDetails?.data?.data?.firstName}
                          </h2>
                          {/* {getUserDetails?.data?.data?.lastName} */}
                        </div>
                        <div className="flex items-center justify-between">
                          <h1 className="text-[#5C6F7F]">
                            Total Amount Debited{" "}
                          </h1>
                          <h2 className="text-[#0eb683] text-lg font-bold">
                            NGN {numberWithCommas(amountValue?.value * 1 + 100)}
                          </h2>
                        </div>
                        <div className="flex items-center justify-between">
                          <h1 className="text-[#5C6F7F]">
                            Save Beneficiary Details:
                          </h1>
                          <AppCheckBox getChecked={(value: boolean) => {}} />
                        </div>
                      </div>
                      <p className=" text-center pt-10">
                        By clicking the button below, I agree my NGN wallet
                        would be debited of the amount above immediately
                      </p>
                    </div>
                    <button
                      className=" bg-[#0eb683] rounded-md px-10 p-4 transition-all active:scale-90 mt-28 text-white "
                      onClick={getVerification}
                    >
                      Continue
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
          {showVerification && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="confirmation_modal backdrop-blur-sm bg-[#13111137] fixed w-full h-full top-0 left-0 justify-center md:justify-end items-center z-10"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex h-screen items-end md:items-center  md:justify-center "
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="card flex flex-col bg-white h-[350px] w-full max-w-md md:max-w-md md:max-h-full rounded-t-3xl md:rounded-3xl p-10 "
                  >
                    <div className="flex flex-col gap-3 my-auto relative">
                      <button
                        onClick={() => {
                          setShowVerification(false);
                          setAccountName("");
                          setBank({});
                          setAccountNumber("");
                          setAmountValue("");
                          setReferenceValue("");
                        }}
                        className="text-right hover:bg-red-200 hover:text-red-600 rounded-full hover:rounded-full flex items-end justify-end md:text-slate-600 absolute xxs:top-[-45px] xxs:right-[-30px] md:bg-transparent xxs:bg-red-200 xxs:text-red-600 md:top-[-61px] md:right-[-33px] p-2 hover:rotate-90 hover:transform  transition duration-150 ease-in-out  "
                      >
                        <AiOutlineClose size={28} />
                      </button>

                      <div className=" ">
                        <h1 className="text-3xl text-[#0eb683] font-extrabold self-center text-center ">
                          Verification
                        </h1>
                      </div>
                      <div className=" mt-1 md:mt-0">
                        <p className="text-[#5C6F7F] text-sm text-center ">
                          Enter the 5-digit code sent to your email
                        </p>
                        <OTPInput
                          autoFocus
                          length={5}
                          className="otpContainer flex justify-between  w-full py-3 mt-4 md:mt-3 "
                          inputClassName="otpInput w-12 h-12 md:w-[50px] md:h-[50px] bg-white border border-[#0EB683] outline-[#0EB683] text-[#0EB683] text-2xl text-center font-bold rounded-lg"
                          autoCapitalize={true}
                          onChangeOTP={(OTP) => {
                            setOtp(OTP.toUpperCase());
                          }}
                          onPaste={(OTP) => {
                            setOtp(OTP.toUpperCase());
                          }}
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <button
                          onClick={() => {
                            setShowVerification(false);
                            setConfirmationModal(true);
                          }}
                          className="text-[#0eb683] active:scale-90 border border-[#0eb683] md:p-3 rounded-md transition-all md:w-40 xxs:w-32 xxs:p-[17px] flex items-center justify-center  "
                        >
                          {" "}
                          <span>
                            <MdOutlineKeyboardBackspace />
                          </span>{" "}
                          <span className="w-14">Back</span>{" "}
                        </button>
                        <button
                          // disabled={}
                          onClick={withdrawNgn}
                          className="bg-[#0eb683]  self-center justify-center md:w-48 border border-[#0eb683]  text-white md:p-3 rounded-md transition-all active:scale-90  md:mt-0 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 xxs:w-40 xxs:p-[17px] "
                        >
                          {loading ? (
                            <div className="xxs:ml-14 md:ml-[68px]">
                              <ReactLoading
                                type="spinningBubbles"
                                color="#fff"
                                height="27px"
                                width="27px"
                              />
                            </div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
          <AnimatePresence>
            {showWithdrawalSuccess && (
              <div>
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      // delay: 0.3,
                      duration: 0.1,
                    },
                  }}
                  className="confirmation_modal bg-[#13111173] backdrop-blur-sm fixed w-full top-0 left-0 justify-center md:justify-end h-full items-center z-50"
                >
                  <motion.div
                    initial={{
                      x: 600,
                      scale: 0,
                    }}
                    animate={{
                      x: 0,
                      scale: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 600,
                      scale: 0,
                      transition: {
                        delay: 0.3,
                      },
                    }}
                    className="flex h-screen items-center justify-center md:justify-end"
                  >
                    <motion.div
                      initial={{
                        x: 600,
                        opacity: 0,
                      }}
                      animate={{
                        x: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        x: 100,
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      className="desktop-card md:flex hidden justify-center flex-col gap-6 bg-white  w-full max-w-md h-[450px] md:h-full rounded-l-3xl p-5 "
                    >
                      <div className="icon self-center">
                        <SuccessMobile width={"200px"} height={"200px"} />
                      </div>
                      <h1 className="text-2xl font-bold text-[#071827] self-center">
                        Withdrawal successful
                      </h1>

                      <p className="text-sm text-[#5C6F7F] self-center text-center">
                        Your recipient will receive value shortly
                      </p>

                      <button
                        onClick={() => {
                          setShowWithdrawalSuccess(false);
                        }}
                        className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                      >
                        Done
                      </button>
                    </motion.div>
                    {/* Mobile */}

                    <motion.div
                      initial={{
                        x: 600,
                        opacity: 0,
                      }}
                      animate={{
                        x: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        x: 100,
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white  w-full max-w-sm h-[450px] rounded-3xl p-5 "
                    >
                      <div className="icon self-center">
                        <SuccessMobile width={"100px"} height={"100px"} />
                      </div>
                      <h1 className="text-2xl font-bold text-[#071827] self-center">
                        Withdrawal successful
                      </h1>

                      <p className="text-sm text-[#5C6F7F] self-center text-center">
                        Your recipient will receive value shortly
                      </p>

                      <button
                        onClick={() => {
                          setShowWithdrawalSuccess(false);
                        }}
                        className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90"
                      >
                        Done
                      </button>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </div>
            )}
          </AnimatePresence>

          {/* Failed */}
          <AnimatePresence>
            {failed && (
              <div>
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      // delay: 0.3,
                      duration: 0.1,
                    },
                  }}
                  className="confirmation_modal backdrop-blur-sm
           bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
                >
                  <motion.div
                    initial={{
                      x: 600,
                      scale: 0,
                    }}
                    animate={{
                      x: 0,
                      scale: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 600,
                      scale: 0,
                      transition: {
                        delay: 0.3,
                      },
                    }}
                    className="flex h-screen items-center justify-center md:justify-end "
                  >
                    <motion.div
                      initial={{
                        x: 600,
                        opacity: 0,
                      }}
                      animate={{
                        x: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        x: 100,
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5 "
                    >
                      <div className="icon self-center">
                        <FailedIcon width={"100px"} height={"100px"} />
                      </div>
                      <h1 className="text-2xl font-bold text-[#071827] self-center">
                        Unsuccessful
                      </h1>

                      <p className="  text-sm text-[#5C6F7F] self-center text-center">
                        {failureMsg}
                      </p>

                      <button
                        onClick={() => {
                          setShowVerification(false);
                          setFailed(false);
                          // setShowInviteUserModal(true);
                        }}
                        className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                      >
                        Retry
                      </button>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </div>
            )}
          </AnimatePresence>

          {/* Fund modal */}

          {showModal && (
            <FundModal
              fundModal={showModal}
              confirm={showConfirmationModal}
              setConfirm={setShowConfirmationModal}
              loading={loading}
              success={showSuccess}
              setValue={setAmountValue}
              setSuccess={setShowSuccess}
              value={amountValue?.value}
              currency={"ngn"}
              valueFormatted={amountValue?.formattedValue}
              setFund={setShowModal}
            />
          )}
        </div>

        <div className="bg-[#f7fbfb] p-3 rounded-lg  py-4 relative ">
          <div className="flex items-center  pb-3 text-[#0eb683] ">
            <InfoIcon size={30} />
            <p className="font-extrabold text-black text-md pl-3  leading-tight">
              To fund your Naira wallet, transfer to:
            </p>
          </div>
          <div className="flex   pl-9  relative ">
            <div className="pb-3">
              <div className="flex gap-3 items-center">
                <button
                  onClick={() =>
                    copyToBankClipBoard(userDetails?.lcyAccount?.ngnLBPBankName)
                  }
                  className="  text-[#0eb683]  transition-all active:scale-90 "
                >
                  <FaRegCopy />
                </button>
                <h2 className="font-extrabold text-black text-md">
                  {userDetails?.lcyAccount?.ngnLBPBankName}
                </h2>
              </div>
              <div className="flex gap-3 items-center">
                <button
                  onClick={() =>
                    copyToNumberClipBoard(
                      userDetails?.lcyAccount?.ngnLBPSubAccountNumber
                    )
                  }
                  className=" text-[#0eb683] transition-all active:scale-90 "
                >
                  <FaRegCopy />
                </button>
                <h1 className="font-medium text-[#0eb683] break-all">
                  {" "}
                  {userDetails?.lcyAccount?.ngnLBPSubAccountNumber}
                </h1>
              </div>
              <div className="flex gap-3 items-center">
                <button
                  onClick={() =>
                    copyToNameClipBoard(
                      userDetails?.lcyAccount?.ngnLBPAccountName
                    )
                  }
                  className="  text-[#0eb683] transition-all active:scale-90 "
                >
                  <FaRegCopy />
                </button>
                <p> {userDetails?.lcyAccount?.ngnLBPAccountName}</p>
              </div>
            </div>

            <p className="bg-slate-200 rounded-md text-slate-600 text-sm font-bold absolute top-[-17px]">
              {copyStatus}
            </p>
          </div>
          <div className="pl-9 leading-tight pr-8 ">
            <small className="text-[#5C6F7F] word-wrap">
              Note transfers may typically take up to 10 minutes to reflect,
              also note that withdrawals can not be made from this wallet at a
              physical bank
            </small>
          </div>
          <div className="absolute bg-[#FF705E] h-16 w-1 top-20 md:top-14 left-0"></div>
        </div>
      </div>


      <div className="mt-10">
        {ngnTransactions?.length > 0 ? (
          <WalletAccountTable
            // itemsPerPage={10}
            data={ngnTransactions}
            currency="NGN"
          />
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

export default NairaWallet;
