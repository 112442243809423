import React from "react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    const ellipsis = -1; // Use -1 to represent ellipsis

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);

      if (currentPage > 4) {
        pageNumbers.push(ellipsis);
      }

      const start = Math.max(2, currentPage - 2);
      const end = Math.min(totalPages - 1, currentPage + 2);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - 3) {
        pageNumbers.push(ellipsis);
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const buttonClass =
    "relative inline-flex items-center px-3 py-1.5 rounded-lg text-sm font-medium transition-colors duration-150 ease-in-out focus:outline-none";
  const activeClass = "z-10 bg-[#0eb683] text-white hover:bg-[#75a496]";
  const inactiveClass =
    "text-gray-700 bg-[#F8F8F8] hover:bg-gray-50 hover:bg-opacity-1 bg-op";
  const disabledClass = "opacity-50 cursor-not-allowed hover:bg-none";

  return (
    <nav className="flex items-center justify-between px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => onPageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
          className={`${buttonClass} ${inactiveClass} rounded-md ${
            currentPage === 1 ? disabledClass : ""
          }`}
        >
          Previous
        </button>
        <button
          onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
          className={`${buttonClass} ${inactiveClass} rounded-md ml-3 ${
            currentPage === totalPages ? disabledClass : ""
          }`}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing page <span className="font-medium">{currentPage}</span> of{" "}
            <span className="font-medium">{totalPages}</span>
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex space-x-[10px] rounded-md "
            aria-label="Pagination"
          >
            <button
              onClick={() => onPageChange(1)}
              disabled={currentPage === 1}
              className={`${buttonClass} ${inactiveClass} rounded-l-md ${
                currentPage === 1 ? disabledClass : ""
              }`}
            >
              <span className="sr-only">First</span>
              <FiChevronsLeft
                className="h-3.5 w-3.5 text-[#5C6F7F]"
                aria-hidden="true"
              />
            </button>
            <button
              onClick={() => onPageChange(Math.max(1, currentPage - 1))}
              disabled={currentPage === 1}
              className={`${buttonClass} ${inactiveClass} ${
                currentPage === 1 ? disabledClass : ""
              }`}
            >
              <span className="sr-only">Previous</span>
              <FiChevronLeft
                className="h-3.5 w-3.5 text-[#5C6F7F]"
                aria-hidden="true"
              />
            </button>
            {getPageNumbers().map((number, index) =>
              number === -1 ? (
                <span
                  key={`ellipsis-${index}`}
                  className={`${buttonClass} ${inactiveClass}`}
                >
                  ...
                </span>
              ) : (
                <button
                  key={number}
                  onClick={() => onPageChange(number)}
                  className={`${buttonClass} ${
                    number === currentPage ? activeClass : inactiveClass
                  }`}
                >
                  {number}
                </button>
              )
            )}
            <button
              onClick={() =>
                onPageChange(Math.min(totalPages, currentPage + 1))
              }
              disabled={currentPage === totalPages}
              className={`${buttonClass} ${inactiveClass} ${
                currentPage === totalPages
                  ? disabledClass
                  : "text-[#0D9A6F] bg-[#D8D8D8]"
              }`}
            >
              <span className="sr-only">Next</span>
              <FiChevronRight
                className="h-3.5 w-3 text-[#5C6F7F] bg=[#E2FEF0]"
                aria-hidden="true"
              />
            </button>
            <button
              onClick={() => onPageChange(totalPages)}
              disabled={currentPage === totalPages}
              className={`${buttonClass} ${inactiveClass} rounded-r-md ${
                currentPage === totalPages
                  ? disabledClass
                  : "text-[#0D9A6F] bg-[#D8D8D8]"
              }`}
            >
              <span className="sr-only">Last</span>
              <FiChevronsRight
                className="h-3.5 w-3.5 text-[#5C6F7F]"
                aria-hidden="true"
              />
            </button>
          </nav>
        </div>
      </div>
    </nav>
  );
};

export default Pagination;