import React, { UIEvent, useState } from "react";
import { Link } from "react-router-dom";

//@ts-ignore
interface MouseEvent<T = Element, E = NativeMouseEvent> extends UIEvent<T, E> {
  altKey: boolean;
  button: number;
  buttons: number;
  clientX: number;
  clientY: number;
  ctrlKey: boolean;
  /**
   * See [DOM Level 3 Events spec](https://www.w3.org/TR/uievents-key/#keys-modifier). for a list of valid (case-sensitive) arguments to this method.
   */
  getModifierState(key: string): boolean;
  metaKey: boolean;
  movementX: number;
  movementY: number;
  pageX: number;
  pageY: number;
  relatedTarget: EventTarget | null;
  screenX: number;
  screenY: number;
  shiftKey: boolean;
}
export interface Ixprops {
  color: string;
  currency: string;
  symbol: string;
  border?: string;
  action: () => void;
  button: string;
  flag: string;
  amount: string;
  buttonColor: string;
  textColor: string;
  navigate: string;
  disable: boolean;
}

const WalletWidget = ({
  color,
  currency,
  symbol,
  border,
  action,
  button,
  flag,
  amount,
  buttonColor,
  textColor,
  navigate,
  disable,
}: Ixprops) => {
  const [notify, setNotify] = useState(false);
  const openModal = (e: any) => {
    e.preventDefault();
    action();
  };

  const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // Do something
    setNotify((prev) => !prev);
  };

  const style = {
    backgroundColor: color,
    border: border ? `2px solid ${border}` : undefined,
  };

  return (
    <Link
      to={navigate}
      className={`relative flex flex-auto justify-between flex-col p-3
        xxs:w-[310px] sm:w-[310px] h-52 rounded-[16px] shadow-sm
        xxs:flex-shrink-0 md:flex-shrink overflow-hidden -z-0 ${
          disable && "cursor-not-allowed"
        }`}
      style={style}
    >
      {/* Currency Info */}
      <div className="flex items-center gap-3">
        <figure className="w-10">
          <img src={flag} alt={currency} />
        </figure>
        <div className="text-[#5C6F7F] text-base font-medium">{currency}</div>
      </div>

      {/* Amount and Symbol */}
      <div className="text-[#071827] font-bold text-xl">
        <span>{symbol}</span> <span>{amount}</span>{" "}
        {(currency === "USDC" || currency === "USDT") && (
          <span>{currency}</span>
        )}
      </div>

      {/* "Coming Soon" Ribbon */}
      {disable && (
        <div className="absolute right-[-8px] top-[125px]">
          <div
            className="bg-slate-400 text-[#ffffff] transform rotate-45 origin-bottom-right
              absolute bottom-0 right-0 py-1 px-2 whitespace-nowrap w-44 text-center
              font-semibold shadow-xl transition-all duration-300

             "
          >
            Coming Soon
          </div>
        </div>
      )}

      {/* Action Button */}
      {currency !== "USDC" && currency !== "USDT" && (
        <div className="flex items-center justify-between">
          <button
            disabled={disable}
            onClick={openModal}
            className={`bg-[${buttonColor}] text-[${textColor}] font-normal py-2 rounded-[9px]
              w-24 text-center transition-all active:scale-90
              disabled:cursor-not-allowed border-[#0eb683] border hover:opacity-80`}
          >
            {button}
          </button>
        </div>
      )}
    </Link>
  );
};

export default WalletWidget;
