import { QueryObserverResult, UseQueryOptions, useQuery } from "react-query";

// Modify the useQueryAction to handle pagination better
const useQueryAction = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  queries: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  const res = useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true, // Add this to maintain data during loading
    staleTime: 2000, // Add a reasonable stale time
    ...queries,
    queryKey: queries.queryKey || [queries],
    onError: (e) => {
      // console.error('Query error:', e);
      return e;
    },
  } as UseQueryOptions<unknown, unknown, unknown>) as QueryObserverResult<
    TQueryFnData,
    TError
  >;

  return {
    ...res,
    //@ts-ignore
    result: res?.data,
    isPreviousData: res.isPreviousData,
  };
};

export default useQueryAction;