import { AxiosResponse } from "axios";
import useQueryAction from "../../../lib/useQueryAction";
import useQueryMutation from "../../../lib/useQueryMutation";
import { api, makeGetRequest, makePostRequest } from "../../api";

export type BankType = {
  code: string;
  created_at: string;
  deleted_at: string;
  embeds: string[];
  id: string;
  is_trashed: boolean;
  is_visible: boolean;
  name: string;
  type: string;
  updated_at: string;
};

export type ResolveBankType = {
  accountNumber: string;
  accountName: string;
  accountBank: string;
};

export const useBanks = () => {
  return useQueryAction({
    queryFn: (): Promise<AxiosResponse<BankType[]>> =>
      makeGetRequest(api.metadata.banks),
    queryKey: ["banks"],
  });
};

export const useGetRates = (currency: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.metadata.rates(currency)),
    queryKey: ["rates", "USDT"],
  });
};

export const useRates = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.metadata.ratesUsd),
    queryKey: ["rates", "usd"],
  });
};

export const useSwiftCountries = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.tags.swiftCountries),
    queryKey: ["swift"],
  });
};

export const useIbanCountries = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.tags.ibanCountries),
    queryKey: ["iban"],
  });
};

export const useAbaCountries = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.tags.abaCountries),
    queryKey: ["aba"],
  });
};

export const useAbaDistricts = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.tags.abaDistricts),
    queryKey: ["abaDistricts"],
  });
};

export const useGetBanks = (country: string) => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.metadata.getBanks(country)),
    queryKey: ["shutterscore_bnk"],
  });
};

export const useGetReasons = () => {
  return useQueryAction({
    queryFn: () => makeGetRequest(api.metadata.getSellReason),
    queryKey: ["reason"],
  });
};

export const useLpResolve = () => {
  return useQueryMutation({
    mutationFn: (data: {
      accountNumber: string;
      code: string;
      networkId: string;
    }): Promise<AxiosResponse<ResolveBankType>> =>
      makePostRequest(data, api.metadata.resolveBankName),
  });
};
