import React, { useEffect, useState } from "react";
import Logo from "../../assets/misc/logo-on-light.svg";
import AppCheckBox from "../../components/widgets/AppCheckBox";
import { useNavigate } from "react-router-dom";
import { useCompleteOnboarding } from "../../sevices/hooks/users";

const UserSummary = ({
  setIsSummary,
  getDetails,
  userDetail,
  nextOption,
}: {
  setIsSummary: React.Dispatch<React.SetStateAction<boolean>>;
  getDetails: (data: any, isValid: boolean) => void;
  userDetail: any;
  nextOption: (step: string) => void;
}) => {
  const [check, setCheck] = useState(true);
  const [loading, setLoading] = useState(false);
  const createUserAccount = useCompleteOnboarding();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  function formatPhoneNumber(phoneNumber: string) {
    const cleanedNumber = phoneNumber.replace(/^234/, "");

    const formattedNumber = `0${cleanedNumber}`;

    return formattedNumber;
  }
  useEffect(() => {
    const data = localStorage.getItem("user_data");
    if (data !== null) {
      try {
        const userData = JSON.parse(data);
        setUserData(userData);
      } catch (error) {

      }
    }
  }, []);


  if (userDetail) {
    var {
      accountName,
      accountNumber,
      accountType,
      address1,
      address2,
      bank,
      bvn,
      NIN,
      city,
      country,
      dob,
      email,
      firstname,
      gender,
      refereeCode,
      lastname,
      phone,
      postCode,
      password,
      bankId,
      bankCode,
      state,
      id_type,
      idDocUrl,
      selfieUrl,
    } = userDetail;
  }

  const createAccount = () => {
    setLoading(true);
    createUserAccount
      .mutateAsync({
        //@ts-ignore
        email: email || userData?.email,
      })
      .then((res) => {

        setLoading(false);
        navigate("/success");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="bg-[#ECF6F3] c">
      <div className=" bg-white flex flex-col gap-3   md:w-full  ">
        <div className="py-10 ">
          <div className="logo pl-4 lg:pl-9  block">
            <img src={Logo} alt="" className=" w-36" />
          </div>
          <div className="logo px-4 lg:px-9 mt-10   block">
            <h1 className="lg:text-[30px] lg:leading-[42px] text-[20px] leading-[32px] font-medium text-[#071827]">
              Individual Onboarding
            </h1>
            <h3 className="text-[#5C6F7F] text-[14px] leading-[22px] lg:text-[18px] lg:leading-[26px] font-normal mt-2">
              Please confirm your information is accurate before you submit your
              Onboarding Request.
            </h3>
          </div>
          <div className=" px-4 lg:px-9 mt-8">
            <div className="  block bg-[#EAEAEA4D] p-4">
              <h1 className="text-[14px] leading-[21px] lg:text-[18px] lg:leading-[25px] font-medium text-[#071827]">
                Application Summary
              </h1>
            </div>
          </div>
          <div className=" px-4 lg:px-9 mt-5">
            <div className="  flex items-center justify-between bg-[#fff] py-4 lg:py-4">
              <h1 className=" text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                User Details
              </h1>
              {/* <h1
                onClick={() => {
                  nextOption("individual");
                  setIsSummary(false);
                }}
                className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] cursor-pointer  font-bold text-[#0EB683]"
              >
                Click here to edit this section
              </h1> */}
            </div>
            <div className="w-[100%] space-y-4 mt-6">
              {/* <div className="flex items-center justify-between"> */}
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    First Name:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {firstname || userData?.firstName}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Last Name:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {lastname || userData?.lastName}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Gender:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {gender || userData?.gender}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Date of Birth:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {dob || userData?.dob}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Phone number:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {phone || userData?.phoneNumber}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Email:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {email || userData?.email}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    BVN:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {bvn || userData?.bvn}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    NIN:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {NIN || userData?.NIN}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Address 1:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {address1 || userData?.address1}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Address 2:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {address2 || userData?.address2}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Country:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {country || userData?.country}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    State:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {state || userData?.state}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    City:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {city || userData?.city}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Post Code:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {/* @ts-ignore */}
                    {postCode || userData?.postCode || "-"}
                  </h1>
                </div>
              </div>
              {/* <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Means of Identification:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {id_type || ""}
                  </h1>
                </div>
              </div> */}
              {/* <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    File:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {idFile}
                  </h1>
                </div>
              </div> */}
            </div>
            <div className="  flex items-center justify-between bg-[#fff] py-4 lg:py-6">
              <h1 className=" text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] font-semibold text-[#071827]">
                Bank Details
              </h1>
              <h1
                onClick={() => {
                  nextOption("bank");
                  setIsSummary(false);
                }}
                className="text-[14px] leading-[21px] lg:text-[16px] lg:leading-[25px] cursor-pointer  font-bold text-[#0EB683]"
              >
                Click here to edit this section
              </h1>
            </div>
            <div className="w-[100%] space-y-4 mt-5">
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Bank:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {bank || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Account Name:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {accountName || ""}
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-4 ">
                <div className="w-[40%] ">
                  <h1 className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] font-normal text-[#5C6F7F] ">
                    Account Number:
                  </h1>
                </div>
                <div className="w-full ">
                  <h1
                    className="text-[14px] leading-[19px] lg:text-[16px] lg:leading-[21px] text-left font-bold text-[#000f]"
                    style={{ textAlign: "left" }}
                  >
                    {accountNumber || ""}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="flex  md:items-center xxs:gap-1  md:gap-3 mt-6 px-4 lg:px-9">
            <AppCheckBox
              getChecked={(value: boolean) => {
                setCheck(value);
              }}
            />
            <small className=" xxs:text-left  md:text-sm ">
              I agree to the website's{" "}
              <button
                onClick={() =>
                  navigate("/onboarding/Terms-Of-Use", { replace: true })
                }
                className="text-[#000f] transition-all active:scale-90"
              >
                terms of use
              </button>{" "}
              and{" "}
              <button
                onClick={() =>
                  navigate("/onboarding/terms-Of-Service", { replace: true })
                }
                className="text-[#000f] transition-all active:scale-90"
              >
                terms of service
              </button>{" "}
            </small>
          </div>
          <div className="flex justify-between px-4 lg:px-9 mt-6">
            <button
              onClick={() => {
                nextOption("password");
                setIsSummary(false);
              }}
              className="bg-[rgb(255, 255, 255)] hover:[#def8f0] text-[#0eb683] shadow-sm font-normal py-3 px-8 rounded-lg text-[16px] border border-[#0eb683] leading-[24px]"
            >
              Back
            </button>
            <button
              disabled={!check}
              onClick={createAccount}
              className="bg-[#0eb683] hover:bg-[##11ffbb text-white font-normal shadow-sm py-3 px-12 rounded-lg text-[16px] border leading-[24px] disabled:bg-opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3 text-white"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    fill="white"
                  />
                  <path
                    d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
                    fill="green"
                  />
                </svg>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSummary;
