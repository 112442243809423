import React, { SVGProps } from 'react'

const USDCIcon:  React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <div>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_15881_54808)">
          <path
            d="M30 58C45.5168 58 58 45.5168 58 30C58 14.4832 45.5168 2 30 2C14.4832 2 2 14.4832 2 30C2 45.5168 14.4832 58 30 58Z"
            fill="white"
          />
          <path
            d="M37.7 34.4344C37.7 30.3511 35.25 28.9511 30.35 28.3679C26.85 27.9011 26.15 26.9679 26.15 25.3344C26.15 23.7009 27.3168 22.6511 29.65 22.6511C31.75 22.6511 32.9168 23.3511 33.5 25.1011C33.6168 25.4511 33.9668 25.6844 34.3168 25.6844H36.1833C36.65 25.6844 37 25.3344 37 24.8679V24.7511C36.5333 22.1844 34.4333 20.2011 31.75 19.9679V17.1679C31.75 16.7011 31.4 16.3511 30.8168 16.2344H29.0668C28.6 16.2344 28.25 16.5844 28.1333 17.1679V19.8511C24.6333 20.3179 22.4168 22.6511 22.4168 25.5679C22.4168 29.4179 24.75 30.9344 29.65 31.5179C32.9168 32.1011 33.9668 32.8011 33.9668 34.6679C33.9668 36.5347 32.3333 37.8179 30.1168 37.8179C27.0833 37.8179 26.0333 36.5344 25.6833 34.7844C25.5668 34.3179 25.2168 34.0844 24.8668 34.0844H22.8833C22.4168 34.0844 22.0668 34.4344 22.0668 34.9011V35.0179C22.5333 37.9344 24.4 40.0344 28.25 40.6179V43.4179C28.25 43.8844 28.6 44.2344 29.1833 44.3511H30.9333C31.4 44.3511 31.75 44.0011 31.8668 43.4179V40.6179C35.3668 40.0344 37.7 37.5844 37.7 34.4344Z"
            fill="#2775CA"
          />
          <path
            d="M24.05 46.6844C14.95 43.418 10.2833 33.268 13.6668 24.2844C15.4168 19.3844 19.2668 15.6512 24.05 13.9012C24.5168 13.668 24.75 13.318 24.75 12.7344V11.1012C24.75 10.6344 24.5168 10.2844 24.05 10.168C23.9333 10.168 23.7 10.168 23.5833 10.2844C12.5 13.7844 6.43326 25.568 9.93326 36.6512C12.0333 43.1844 17.05 48.2012 23.5833 50.3012C24.05 50.5345 24.5168 50.3012 24.6333 49.8344C24.75 49.718 24.75 49.6012 24.75 49.368V47.7344C24.75 47.3844 24.4 46.918 24.05 46.6844ZM36.4168 10.2844C35.95 10.0512 35.4833 10.2844 35.3668 10.7512C35.25 10.868 35.25 10.9844 35.25 11.218V12.8512C35.25 13.318 35.6 13.7844 35.95 14.018C45.05 17.2844 49.7168 27.4345 46.3333 36.418C44.5833 41.318 40.7333 45.0512 35.95 46.8012C35.4833 47.0345 35.25 47.3844 35.25 47.968V49.6012C35.25 50.068 35.4833 50.418 35.95 50.5344C36.0668 50.5344 36.3 50.5345 36.4168 50.418C47.5 46.918 53.5668 35.1344 50.0668 24.0512C47.9668 17.4012 42.8333 12.3844 36.4168 10.2844Z"
            fill="#2775CA"
          />
        </g>
        <defs>
          <clipPath id="clip0_15881_54808">
            <rect
              width="56"
              height="56"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default USDCIcon