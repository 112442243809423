import React, { useState, useEffect, useContext } from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DragDropComponent from "../../components/base-components/DragDropComponent";
import OnboardingFooter from "../../components/base-components/OnboardingFooter";
import {
  useBusinessAcc,
  useBusinessAccountKYC,
  // useBusinessKyc,
  useBusinessUserKyc,
  useUpdateUserOnboardingInfo,
  useUser,
  useUsersDocs,
} from "../../sevices/hooks/users";
import AppSelectInput from "../../components/base-components/AppSelectInput";
import TabContext from "../../store/context/TabProvider";
import Input from "./comp/Input";
import TextArea from "./comp/TextArea";
import { docType, industries } from "../../utils";
import { useGetCountry, useGetState } from "../../sevices/hooks/tags";
import { IDOptionType } from "./IndividualUser";
import CustomSelect from "../../components/base-components/CustomSelect";
import LoadingScreen from "../Notifications/LoadingScreen2";
import { formatPhoneNumber } from "./refresh-initiative/Controller";
import DatePickerInput from "./datepicket";

export const businessOptions = [
  {
    value: "co",
    label: "Company",
    slug: "Company",
  },
  {
    value: "bn",
    label: "Business Name",
    slug: "Business_Name",
  },
  {
    value: "it",
    label: "Incorporated Trustees",
    slug: "Incorporated_Trustees",
  },
];

export const companyOptions = [
  {
    value: "Public_Incorporated",
    label: "Public Incorporated ",
  },
  {
    value: "Private_Incorporated",
    label: "Private Incorporated",
  },
];

const BusinessUser = ({
  getDetails,
  email,
  backFn,
  nextFn,
  userDetail,
}: {
  getDetails: (data: any, isValid: boolean) => void;
  email?: string;
  backFn: () => void;
  nextFn: () => void;
  userDetail: any;
}) => {
  const [incorNum, setIncorNum] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [industry, setIndustry] = useState<IDOptionType>(null);
  const [businessType, setBusinessType] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [website, setWebsite] = useState("");
  const [regDate, setRegDate] = useState<Date | null>();
  const [businessCity, setBusinessCity] = useState("");
  const [countryOfRegistration, setCountryOfRegistation] =
    useState<IDOptionType>(null);
  const [businessState, setBusinessState] = useState<IDOptionType>(null);
  const [regEvidence, setRegEvidence] = useState<IDOptionType>(null);
  const [loading, setLoading] = useState(false);
  const [businessSlug, setBusinessSlug] = useState("");
  const [file1, setFile1] = useState<any>();
  const [file2, setFile2] = useState<any>();
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [errmsg, setErrMsg] = useState("");
  const [loadingMsg, setLoadingMsg] = useState("Verifying your Details");
  const [UserId, setUserId] = useState("");
  const createBusinessAccount = useBusinessAccountKYC();
  const newBusiness = useBusinessAcc();
  const Icountry = useGetCountry();
  const states = useGetState();
  const updateInfo = useUpdateUserOnboardingInfo(UserId);

  // console.log(userDetail, "userDetail");

  const getDocs = (files: any) => {
    setFile1(files);
  };

  const getCertificate = (files: any) => {
    setFile2(files);
  };

  useEffect(() => {
    getDetails(
      {
        businessPhone,
        businessEmail,
        businessName,
        industry,
        businessType,
        businessNature,
        businessAddress,
        companyType,
        website,
        regDate,
        regEvidence,
        countryOfRegistration,
        businessState,
        loading,
        businessSlug,
        file1,
        file2,
        incorNum,
        businessCity,
      },
      true
    );
  }, [
    businessPhone,
    businessEmail,
    businessName,
    industry,
    businessType,
    businessNature,
    businessAddress,
    companyType,
    website,
    regDate,
    regEvidence,
    countryOfRegistration,
    businessState,
    loading,
    businessSlug,
    file1,
    file2,
    incorNum,
    businessCity,
  ]);

  const validateFields = () => {
    const errors: Record<string, string> = {};

    switch (true) {
      case !businessName?.trim():
        errors.businessName = "Business Name is required";
        break;
      case !industry:
        errors.industry = "Industry is required";
        break;
      case !businessType?.trim():
        errors.businessType = "Business registration type is required";
        break;
      case !incorNum?.trim():
        errors.incorNum = "Registration number is required";
        break;
      //  case !gender:
      //    errors.gender = "Gender is required";
      //    break;
      //  case !address1?.trim():
      //    errors.address1 = "Address Line 1 is required";
      //    break;
      // case !country:
      //   errors.country = "Country is required";
      //   break;
      // case !certificate:
      //   errors.certificate = "Incorporation certificate is required";
      //   break;
      // case !regEvidence?.trim():
      //   errors.regEvidence = "Registration evidence is required";
      //   break;
      case !businessAddress?.trim():
        errors.businessAddress = "Business address is required";
        break;
      // case !docsUrl:
      //   errors.docsUrl = "ID Type is required";
      //   break;
      case !businessPhone?.trim():
        errors.businessPhone = "Phone Number is required";
        break;
      case !businessEmail?.trim():
        errors.businessEmail = "Business email is required";
        break;
      //  case !website.trim():
      //    errors.website = "Business email is required";
      //    break;
      //  case selfieData.length < 1:
      //    errors.selfie = "Selfie is required";
      //    break;
      default:
        // No errors
        break;
    }

    return errors;
  };

  const countryOptions =
    Icountry?.data?.data.data.map(
      (
        {
          countryCode,
          name,
          index,
        }: { countryCode: string; name: string; index: number },
        id: number
      ) => ({
        label: name,
        slug: countryCode,
        id,
      })
    ) || [];

  const stateOptions =
    states?.data?.data.data.map(
      ({ name, index }: { name: string; index: number }, id: number) => ({
        label: name,
        slug: name,
        id,
      })
    ) || [];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const createFormDataBusiness = async (id: string, businessKYCUrl: string) => {
    setLoadingMsg("Confirming Account status...");

    const formData = new FormData();
    if (id) {
      const { countryOfRegistration } = userDetail;
      formData.append("businessEmail", businessEmail);
      formData.append("businessName", businessName);
      formData.append("businessNumber", incorNum);
      formData.append("businessNature", businessNature);
      formData.append("businessAddress", businessAddress);
      formData.append("businessPhone", businessPhone);
      formData.append("countryOfRegistration", countryOfRegistration?.slug);
      formData.append("businessKYCUrl", businessKYCUrl);
      // formData.append("registrationEvidence", regEvidence?.slug);
      formData.append("website", website);
      formData.append("userId", id);
      formData.append("docType", regEvidence?.slug);
      if (file1) {
        formData.append("registrationDoc", file1);
      }
      if (file2) {
        formData.append("registrationDoc", file2);
      }

      try {
        const res = await newBusiness.mutateAsync(formData);

        if (res.data.id) {
          const businessId = res.data.id;
          getDetails(
            {
              businessId: businessId,
            },
            true
          );
          appendSelfieImg();
          // setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };
  const appendSelfieImg = async () => {
    setLoadingMsg("Saving your entries...");
    var { selfieData } = userDetail;
    updateInfo
      .mutateAsync({ images: selfieData })
      .then((res) => {
        nextFn();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const createAccount = async () => {
    setLoading(true);
    setLoadingMsg("Creating your Business Account...");
    if (userDetail) {
      var {
        accountNumber,
        accountType,
        address1,
        address2,
        bank,
        bvn,
        city,
        country,
        dob,
        email,
        firstName,
        lastName,
        middleName,
        gender,
        refereeCode,
        bankId,
        industry,
        bankCode,
        businessCity,
        countryOfRegistration,
        phone,
        postCode,
        password,
        state,
        NIN,
        businessState,
        id_type,
        idDocUrl,
        selfieUrl,
        businessType,
        companyType,
        busiType,
        industry,
        idNumber,
        idType,
      } = userDetail;
    }

    try {
      const accountResponse = await createBusinessAccount.mutateAsync({
        firstName: firstName,
        lastName: lastName,
        email: email,
        refereeCode: refereeCode,
        accountType: accountType,
        userBankCode: bankCode,
        phoneNumber: formatPhoneNumber(phone),
        dob: dob,
        bvn: bvn,
        nin: NIN,
        address1: address1,
        address2: address1,
        country: country,
        state: state,
        postCode: postCode,
        gender: gender === "M" ? "Male" : "Female",
        city: city,
        middleName: middleName,
        idNumber: idNumber,
        idType: idType,
        password: password,
        business: {
          country: countryOfRegistration.slug,
          state: businessState.slug,
          industry: industry.slug,
          registration: businessType === "co" ? companyType : businessType,
          BasicCountry: country,
          businessName: businessName,
          bvn: bvn,
          //@ts-ignore
          dateOfRegistration: regDate,
          description: businessNature,
          ContactEmail: businessEmail,
          ContactCountry: country,
          ContactState: state,
          addressLine_1: businessAddress,
          city: businessCity,
          postalCode: postCode,
          ContactRegisteredCountry: country,
          ContactRegisteredState: state,
          phoneNumber: formatPhoneNumber(businessPhone),
          type: "BusinessCustomer",
        },
      });

      if (accountResponse) {
        const userId = accountResponse.data.data.id;
        const businessKYCUrl =
          accountResponse.data.data.kycRequest.responseKYCDoc;
        // console.log(accountResponse, "accountResponse");
        getDetails(
          {
            userId: userId,
            businessKYCUrl,
          },
          true
        );

        setUserId(userId);

        createFormDataBusiness(userId, businessKYCUrl);
      }
    } catch (err: any) {
      setErrMsg(err.response.data);
    } finally {
      setLoading(false);
    }
  };

  // console.log(formatPhoneNumber("23774828992"));

  return (
    <LoadingScreen showLoading={loading} msg={`${loadingMsg}`} bg="#f2faf799">
      <div className=" px-[5%] md:px-0 pt-10">
        <div className="text pb-4">
          <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827] ">
            Business Details
          </h1>
          <p className=" text-[#5C6F7F] text-[16px] font-normal leading-[24px]">
            Complete basic details about your business:
          </p>
        </div>

        <div className="bg-none md:bg-white flex flex-col gap-3 p-0 md:p-5 md:w-full md:max-w-lg ">
          {errmsg ? (
            <div className="error text-[#e01a00] text-sm text-center bg-rose-200 border-dashed border-2 border-[#a10] rounded-md py-2">
              {errmsg}
            </div>
          ) : null}

          <div className="relative ">
            <Input
              label="Business Name"
              name="businessName"
              type="text"
              required={true}
              value={businessName}
              onChange={(evt) => setBusinessName(evt)}
            />
            {validationErrors.businessName && (
              <div className="text-red-500 text-sm">
                {validationErrors.businessName}
              </div>
            )}
          </div>
          <div>
            {/* <AppSelectInput
            placeholder="Select Industry"
            options={industries || []}
            label=""
            handleChange={(ind) => setIndustry(ind?.value)}
          /> */}

            <CustomSelect
              selectedOption={industry?.slug}
              setSelectOption={setIndustry}
              placeholder="Select Industry"
              name={"industry"}
              // errors={errorMessages}
              options={industries || []}
            />

            {validationErrors.industry && (
              <div className="text-red-500 text-sm">
                {validationErrors.industry}
              </div>
            )}
          </div>

          <div>
            <TextArea
              label="Business Nature (Description)"
              name="businessNature"
              rows={2}
              value={businessNature}
              onChange={(evt) => setBusinessNature(evt.target.value)}
            />
            {validationErrors.businessNature && (
              <div className="text-red-500 text-sm">
                {validationErrors.businessNature}
              </div>
            )}
          </div>
          <div>
            <AppSelectInput
              placeholder="Business Registration Type"
              options={businessOptions || []}
              label=""
              handleChange={(business) => {
                setBusinessType(business?.value);
                setBusinessSlug(business.slug);
              }}
            />
            {validationErrors.businessType && (
              <div className="text-red-500 text-sm">
                {validationErrors.businessType}
              </div>
            )}
          </div>
          {businessType === "co" && (
            <div>
              <AppSelectInput
                placeholder="Company Registration Type"
                options={companyOptions || []}
                label=""
                handleChange={(coy) => setCompanyType(coy?.value)}
              />
              {validationErrors.businessType && (
                <div className="text-red-500 text-sm">
                  {validationErrors.businessType}
                </div>
              )}
            </div>
          )}

          <div className="relative ">
            <Input
              label="Registration Number (0000000)"
              name="incorNum"
              type="text"
              pattern="\d*"
              required={true}
              value={incorNum}
              onChange={(evt) => setIncorNum(evt)}
            />
            <small className="text-[11px] text-[#7991a4] leading-[1]">
              Business registration number (Numbers only{" "}
              <b className="text-[#0eb683]">without</b> the 2 letter suffix)
              e.g. RC 0000000 should be submitted as 0000000
            </small>
          </div>
          {/* <div className="relative ">
            <Input
              label="Registration Date"
              name="registrationDate"
              type="date"
              required={true}
              // value={firstname}
              onChange={(evt) => setRegDate(evt)}
            />

          </div> */}

          <div className="relative">
            <DatePickerInput
              label="Registration Date"
              name="registrationDate"
              required={true}
              onChange={(date) => setRegDate(date)}
              backgroundColor="bg-[#F2FAF7]"
              // minDate={minDate}
              // maxDate={maxDate}
            />
          </div>

          <div className="flex items-center justify-between gap-4">
            <div className="mb-3 w-full relative">
              <small className="text-[#0B0C0E] text-sm font-normal ">
                <span className="text-[#E01A00]">*</span>
                Country{" "}
              </small>
              <CustomSelect
                selectedOption={countryOfRegistration?.slug}
                setSelectOption={setCountryOfRegistation}
                placeholder={"Select Country of Registration"}
                options={countryOptions || []}
              />
              <span className="text-[#E01A00] absolute left-2 top-9">*</span>
            </div>
          </div>
          <div className="flex items-center justify-between gap-4">
            <div className="mb-3 w-full relative">
              <small className="text-[#0B0C0E] text-sm font-normal ">
                <span className="text-[#E01A00]">*</span>
                State{" "}
              </small>
              <CustomSelect
                selectedOption={businessState?.slug}
                setSelectOption={setBusinessState}
                placeholder={"Select State of Registration"}
                options={stateOptions || []}
              />
              <span className="text-[#E01A00] absolute left-2 top-9">*</span>
            </div>
          </div>
          <div className="relative w-full">
            <Input
              label="City"
              type="text"
              name="businessCity"
              required={true}
              value={businessCity}
              onChange={(evt) => setBusinessCity(evt)}
            />
          </div>
          <div>
            <small className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal ">
              <span className="text-[#E01A00]">*</span>
              Upload Incorporation Certificate
            </small>
            <DragDropComponent inputId="dkke" getFiles={getCertificate} />
            <small className="text-[11px] text-[#7991a4] ">
              These include CAC incorporation certificates, etc. Supported file
              types .pdf, .docx, .jpeg, .jpg, .png, .txt
            </small>
          </div>

          <CustomSelect
            selectedOption={regEvidence?.slug}
            setSelectOption={setRegEvidence}
            placeholder={"Select Registration Evidence"}
            options={docType || []}
          />
          <div>
            <small className="text-[#0B0C0E] text-[14px] leading-[21px] font-normal ">
              <span className="text-[#E01A00]">*</span>
              Upload Selected Document
            </small>
            <DragDropComponent inputId="ddockke" getFiles={getDocs} />
            <small className="text-[11px] text-[#7991a4] ">
              Supported file types .pdf, .docx, .jpeg, .jpg, .png, .txt
            </small>
          </div>

          <div className="relative ">
            <Input
              label="Business Address"
              name="businessAddress"
              type="text"
              required={true}
              value={businessAddress}
              onChange={(evt) => setBusinessAddress(evt)}
            />
          </div>
          <ReactPhoneInput
            containerStyle={{
              border: "0.5px solid #CBE0D6",
              padding: "5px",
              backgroundColor: isMobile ? "#fff" : "#F2FAF7",
              borderRadius: "4px",
            }}
            buttonStyle={{
              backgroundColor: "transparent",
              border: "none",
              borderRight: "1px solid #0EB683",
              margin: "10px 0px",
              padding: "0px 11px",
            }}
            inputStyle={{
              width: "100%",
              border: "none",
              padding: "0px 0px 0px 72px",
              backgroundColor: isMobile ? "#fff" : "#F2FAF7",
              height: 40,
            }}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            enableAreaCodes={true}
            country="ng"
            placeholder="Enter the best mobile number to reach you"
            onChange={(data) => setBusinessPhone(data)}
            // {...props}
          />
          <small className="text-[11px] text-[#7991a4] ">
            You will be required to confirm this via OTP
          </small>
          <div className="relative ">
            <Input
              label="Business Email"
              name="businessEmail"
              type="text"
              required={true}
              value={businessEmail}
              onChange={(evt) => setBusinessEmail(evt)}
            />
          </div>
          <small className="text-[11px] text-[#7991a4] ">
            Ensure email address is active
          </small>

          <div className="relative ">
            <Input
              label="Website, if applicable"
              name="website"
              type="text"
              required={false}
              value={website}
              onChange={(evt) => setWebsite(evt)}
            />
          </div>
        </div>
        <OnboardingFooter
          backFn={
            backFn
            // updateStep("choose", false);
            // setCurrentFlow("individual");
            // setSelectedTab("choose");
          }
          // nextFn={nextFn}
          nextFn={createAccount}
          // nextDisabled={}
        />
      </div>
    </LoadingScreen>
  );
};

export default BusinessUser;
