import React, { useState } from "react";
import Hamburger from "../../assets/misc/hamburger-button.svg";
import usFlag from "../../assets/flags/usd.png";
import ukFlag from "../../assets/flags/pound.png";
import eurFlag from "../../assets/flags/euro.png";
import { useSidebarState } from "../../store";
import { RiArrowDropDownLine } from "react-icons/ri";
import usdt from "../../assets/coins/usdt.png";
import usdc from "../../assets/coins/usdc.png";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import ReactLoading from "react-loading";
// import {
//   useGbpRates,
//   useRates,
//   useRatesEur,
//   useUsdcRates,
//   useUsdtRates,
// } from "../../sevices/hooks/tags";
import { useRatesStore } from "../../store/useRateStore";

const Rates = () => {
  const [currentTab, setCurrentTab] = useState("fiat");
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);
  const rates = useRatesStore((state) => state.rates);
  //FIAT
  const usdRate = rates.NGN;
  const eurRate = rates.EUR;
  const gbpRate = rates.GBP;

  // Check if rates data is available
  const isLoading = !rates || Object.keys(rates).length === 0;

  //STABLECOINS
  // const usdcRates = useUsdcRates();
  // const usdtRates = useUsdtRates();
  const usdcRate = rates.USDC;
  const usdtRate = rates.USDT;



  return (
    <>
      {!isLoading ? (
        <div className=" bg-[#f7fbfb] h-full w-full p-5  md:pl-9">
          <div className="w-16 ml-auto block md:hidden">
            <img src={logo} alt="" />
          </div>
          <div className="flex flex-col md:flex-row md:justify-between md:mb-4 md:items-center md:mr-3">
            <div className="hamburger_menu flex flex-col  gap-2 md:gap-2 items-start ">
              <button
                className="md:hidden py-1 hover:bg-slate-200 rounded-md transition-all active:scale-90"
                onClick={() => toggleSideBarOpen()}
              >
                <img src={Hamburger} alt="menu" className="w-10" />
              </button>
              <h1 className="  font-bold text-2xl text-[#071827]">Rates</h1>
              <p className=" pb-5  text-[#5C6F7F] text-lg">
                {" "}
                Toggle between fiat and stablecoin rates
              </p>
            </div>

            <div className="toggle md:max-w-[240px] w-full h-12 md:h-10 flex border border-[#0eb683] xxs:mb-6 self-center bg-white justify-between rounded-[32px] cursor-pointer p-1 md:mb-8">
              <div
                className={`px-2 flex-1 flex items-center justify-center transition-all duration-500 rounded-[32px] ${
                  currentTab === "fiat" ? "bg-[#0eb683] text-white" : ""
                }`}
                onClick={() => setCurrentTab("fiat")}
              >
                Fiat
              </div>
              <div
                className={`px-2 flex-1 flex items-center justify-center transition-all duration-500 rounded-[32px] ${
                  currentTab === "stable" ? "bg-[#0eb683] text-white" : ""
                }`}
                onClick={() => setCurrentTab("stable")}
              >
                Stablecoin
              </div>
            </div>
          </div>

          {currentTab === "fiat" ? (
            <div className="">
              <div className=" hidden transition-transform duration-500 w-full h-full md:block   bg-[#f7fbfb]">
                <table className="table-auto w-full rounded-[16px]  bg-white ">
                  <thead>
                    <tr className="border-b border-slate-50">
                      <th className="px-4 py-2 text-left  font-bold">
                        Currency
                      </th>
                      <th className="px-4 py-2 text-center font-bold">
                        We Sell{" "}
                      </th>
                      <th className="px-4 py-2 text-center font-bold">
                        We Buy
                      </th>
                      <th className="px-4 py-2 text-center font-bold">
                        24hrs Change
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b border-slate-50">
                      <td className=" px-4 py-2 text-left flex items-center gap-2 ">
                        <img src={usFlag} alt="" className="w-10" />
                        <span className=" text-[#5C6F7F] font-bold text-lg">
                          USD
                        </span>
                      </td>
                      <td className=" px-4 py-2 text-center  text-[#5C6F7F]">
                        {usdRate?.buyRate}
                      </td>
                      <td className=" px-4 py-2 text-center  text-[#5C6F7F]">
                        {usdRate?.sellRate}
                      </td>
                      <td className=" px-4 py-2 text-center text-[#00D215]">
                        +5%
                      </td>
                    </tr>
                    <tr className="">
                      <td className=" px-4 py-2 text-left flex items-center gap-2  text-[#5C6F7F] text-lg">
                        <img src={ukFlag} alt="" className="w-10 " />

                        <span className=" text-[#5C6F7F] font-bold text-lg">
                          GBP
                        </span>
                      </td>
                      <td className=" px-4 py-2 text-center  text-[#5C6F7F]">
                        {gbpRate?.buyRate}
                      </td>
                      <td className=" px-4 py-2 text-center  text-[#5C6F7F]">
                        {gbpRate?.sellRate}
                      </td>
                      <td className=" px-4 py-2 text-center text-[#e01]">
                        -2%
                      </td>
                    </tr>
                    <tr className="">
                      <td className=" px-4 py-2 text-left flex items-center gap-2 text-[#5C6F7F] text-lg ">
                        <img src={eurFlag} alt="" className="w-10 " />

                        <span className=" text-[#5C6F7F] font-bold text-lg">
                          EUR
                        </span>
                      </td>
                      <td className=" px-4 py-2 text-center  text-[#5C6F7F]">
                        {eurRate.buyRate}
                      </td>
                      <td className=" px-4 py-2 text-center  text-[#5C6F7F]">
                        {eurRate?.sellRate}
                      </td>
                      <td className=" px-4 py-2 text-center text-[#e01]">
                        -2%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* MOBILE DISPLAY */}
              <div className="flex md:hidden flex-col  items-center md:items-start gap-3 md:gap-0">
                <div className="w-full hidden md:flex items-center justify-between bg-white p-3">
                  <div className="">
                    <h1>Currency</h1>
                  </div>
                  <div className=" flex items-center gap-1  mr-28">
                    <h1>Last price(NgN)</h1>
                    <RiArrowDropDownLine size={28} />
                  </div>
                  <div className="flex items-center gap-1 ">
                    <h1>Changes</h1>
                    <RiArrowDropDownLine size={28} />
                  </div>
                </div>
                <div className=" bg-white border w-full flex flex-col p-3 rounded-md md:rounded-none">
                  <div className="flex items-center  justify-between md:justify-start md:gap-80 ">
                    <div className="currency flex items-center  gap-3">
                      <img src={usFlag} alt="US_Flag" className="w-10" />
                      <div className=" flex flex-col gap-2">
                        <h2 className="text-[#071827] font-medium">USD</h2>
                        <p className="text-[#071827] font-medium">
                          24hr Change{" "}
                          <span className="text-sm md:hidden  text-[#00D215] ml-auto ">
                            +5.29%
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-[#071827] self-center text-sm">
                        We Sell{" "}
                        <span className="text-[#0eb683] text-sm">
                          <span>&#8358;</span> {usdRate?.buyRate}
                        </span>{" "}
                      </p>
                      <p className="text-[#071827] self-center text-sm">
                        We Buy <span>&#8358;</span>
                        {usdRate?.sellRate}
                      </p>
                    </div>
                    <span className="text-xs hidden md:block text-[#00D215] ml-auto pr-4 ">
                      +5.29%
                    </span>
                  </div>
                </div>

                <div className=" bg-white border w-full flex flex-col p-3 rounded-md md:rounded-none">
                  <div className="flex items-center  justify-between md:justify-start md:gap-80 ">
                    <div className="currency flex items-center gap-3">
                      <img src={ukFlag} alt="Uk_Flag" className="w-10" />
                      <div className="flex flex-col gap-2">
                        <h2 className="text-[#071827]  font-medium">GBP</h2>
                        <p className="text-[#071827] font-medium">
                          24hr Change{" "}
                          <span className="text-sm md:hidden  text-[#d20000] ml-auto ">
                            -0.29%
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-[#071827] text-sm self-center">
                        We Sell{" "}
                        <span className="text-[#0eb683] text-sm">
                          {" "}
                          <span>&#8358;</span> {gbpRate?.buyRate}
                        </span>{" "}
                      </p>
                      <p className="text-[#071827] self-center text-sm">
                        We Buy <span>&#8358;</span> {gbpRate?.sellRate}
                      </p>
                    </div>
                    <span className="text-xs hidden md:block text-[#00D215] ml-auto pr-4 ">
                      +5.29%
                    </span>
                  </div>
                </div>

                <div className=" bg-white border w-full flex flex-col p-3 rounded-md md:rounded-none">
                  <div className="flex items-center  justify-between md:justify-start md:gap-80 ">
                    <div className="currency flex items-center gap-3">
                      <img src={eurFlag} alt="EU_Flag" className="w-10" />
                      <div className="flex flex-col gap-2">
                        <h2 className="text-[#071827] font-medium">
                          EUR (Euro)
                        </h2>
                        <p className="text-[#071827] font-medium">
                          24hr Change{" "}
                          <span className="text-sm md:hidden  text-[#E01A00] ml-auto ">
                            +5.29%
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-[#071827] self-center text-sm ">
                        We Sell{" "}
                        <span className="text-[#0eb683] text-sm">
                          <span>&#8358;</span>
                          {eurRate?.buyRate}
                        </span>{" "}
                      </p>
                      <p className="text-[#071827] self-center text-sm">
                        We Buy <span>&#8358;</span> {eurRate?.sellRate}
                      </p>
                    </div>
                    <span className="text-xs hidden md:block text-[#E01A00] ml-auto pr-4 ">
                      +5.29%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className=" md:hidden flex flex-col gap-3">
                <div className=" bg-white border w-full flex flex-col p-3 rounded-md md:rounded-none">
                  <div className="flex items-center  justify-between md:justify-start md:gap-80 ">
                    <div className="currency flex items-center gap-3">
                      <img src={usdc} alt="Uk_Flag" className="w-10" />
                      <div className="flex flex-col gap-2">
                        <h2 className="text-[#071827] font-medium">USDC</h2>
                        <p className="text-[#071827] font-medium">
                          24hr Change{" "}
                          <span className="text-sm md:hidden  text-[#00D215] ml-auto ">
                            +5.29%
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-[#071827] self-center text-sm">
                        We Sell{" "}
                        <span className="text-[#0eb683] text-sm">
                          N {usdcRate?.buyRate}
                        </span>{" "}
                      </p>
                      <p className="text-[#071827] self-center text-sm">
                        We Buy N {usdcRate?.sellRate}
                      </p>
                    </div>
                    <span className="text-xs hidden md:block text-[#00D215] ml-auto pr-4 ">
                      +5.29%
                    </span>
                  </div>
                </div>
                <div className=" bg-white border w-full flex flex-col p-3 rounded-md md:rounded-none">
                  <div className="flex items-center  justify-between md:justify-start md:gap-80 ">
                    <div className="currency flex items-center gap-3">
                      <img src={usdt} alt="Uk_Flag" className="w-10" />
                      <div className="flex flex-col gap-2">
                        <h2 className="text-[#071827] font-medium">
                          USDT (ERC-20)
                        </h2>
                        <p className="text-[#071827] font-medium">
                          24hr Change{" "}
                          <span className="text-sm md:hidden  text-[#00D215] ml-auto ">
                            +5.29%
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-[#071827] self-center text-sm">
                        We Sell{" "}
                        <span className="text-[#0eb683] text-sm">
                          N {usdtRate?.buyRate}
                        </span>{" "}
                      </p>
                      <p className="text-[#071827] self-center text-sm">
                        We Buy N {usdtRate?.sellRate}
                      </p>
                    </div>
                    <span className="text-xs hidden md:block text-[#00D215] ml-auto pr-4 ">
                      +5.29%
                    </span>
                  </div>
                </div>
              </div>

              {/* DESKTOP */}
              <div className="App hidden transition-transform duration-500 w-full h-full md:block   bg-[#f7fbfb]">
                <table className="table-auto w-full rounded-xl  bg-white ">
                  <thead>
                    <tr className="border-b border-slate-50">
                      <th className="px-4 py-2 text-left font-bold">
                        Currency
                      </th>
                      <th className="px-4 py-2 text-center font-bold">
                        We Sell{" "}
                      </th>
                      <th className="px-4 py-2 text-center font-bold">
                        We Buy
                      </th>
                      <th className="px-4 py-2 text-center font-bold">
                        24hrs Change
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b border-slate-50">
                      <td className=" px-4 py-2 text-left flex items-center gap-2">
                        <img src={usdc} alt="" className="w-10" />
                        <span className=" text-[#5C6F7F] font-bold text-lg">
                          USDC
                        </span>
                      </td>
                      <td className=" px-4 py-2 text-center  text-[#5C6F7F]">
                        {usdcRate?.buyRate}
                      </td>
                      <td className=" px-4 py-2 text-center  text-[#5C6F7F]">
                        {usdcRate?.sellRate}
                      </td>
                      <td className=" px-4 py-2 text-center text-[#00D215]">
                        +5%
                      </td>
                    </tr>
                    <tr className="">
                      <td className=" px-4 py-2 text-left flex items-center gap-2">
                        <img src={usdt} alt="" className="w-10" />

                        <span className=" text-[#5C6F7F] font-bold text-lg">
                          USDT (ERC-20)
                        </span>
                      </td>
                      <td className=" px-4 py-2 text-center  text-[#5C6F7F]">
                        {usdtRate?.buyRate}
                      </td>
                      <td className=" px-4 py-2 text-center  text-[#5C6F7F]">
                        {usdtRate?.sellRate}
                      </td>
                      <td className=" px-4 py-2 text-center text-[#e01]">
                        -2%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      ) : (
        <p className="flex items-center justify-center h-full">Trying to Fetch Rates...</p>
      )}
    </>
  );
};

export default Rates;
