import { create } from "zustand";

interface VerificationModal {
  isVerificationOpen: boolean;
  openVerification: () => void;
  closeVerification: () => void;
}

const useVerificationModalStore = create<VerificationModal>((set) => ({
  isVerificationOpen: false,
  openVerification: () => set({ isVerificationOpen: true }),
  closeVerification: () => set({ isVerificationOpen: false }),
}));

export default useVerificationModalStore;
