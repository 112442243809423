import React, { useState, useEffect } from "react";
import { useFundVerificationStore } from "../../store/card";

const CurrencyInput = ({
  currency = "$",
  label = "Amount",
  setInputtedValue,
}: any) => {
      const {
        isFundVerificationOpen,
        openFundVerificationModal,
        closeFundVerificationModal,
        setFundAmount,
      } = useFundVerificationStore();
  const [inputValue, setInputValue] = useState("");
  const [formattedValue, setFormattedValue] = useState("");

  const formatValue = (val: string) => {
    const number = parseFloat(val);
    if (isNaN(number)) return "";
    return number
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replace("$", ""); // Remove the default $ symbol
  };

  useEffect(() => {
    setFormattedValue(formatValue(inputValue));
      setInputtedValue(formatValue(inputValue));
      setFundAmount(Number(inputValue));
  }, [inputValue]);

  const handleChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    // Allow only numbers and a single decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      setInputValue(value);
    }
  };

  const handleBlur = () => {
    if (inputValue) {
      setInputValue(parseFloat(inputValue).toFixed(2));
    }
  };

  return (
    <div className="w-full">
      <label
        htmlFor="currency-input"
        className="block text-sm font-medium text-gray-700 mb-1"
      >
        {label}
      </label>
      <div className="relative">
        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
          {currency}
        </span>
        <input
          id="currency-input"
          type="text"
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          className="w-full pl-8 pr-3 py-2 rounded-md border border-[#E2E9F0] focus:outline-none focus:ring-1 focus:ring-[#E2E9F0] bg-[#F2FAF7] focus:border-transparent"
          placeholder="0.00"
        />
      </div>
      {inputValue && (
        <div className="mt-1 text-sm font-bold text-[#0EB683]">
          Formatted: {currency} {formattedValue}
        </div>
      )}
    </div>
  );
};

export default CurrencyInput;
