import TronIcon from "../../src/assets/icons/Tron";
import Solana from "../../src/assets/icons/Solana";
import Polygon from "../../src/assets/icons/Polygon";
import { ComponentType } from "react";

// WalletData interface
interface WalletData {
  id: string;
  userId: string;
  subAccountId: string;
  label: string;

  polygonUSDC: string;
  polygonUSDT: string;

  solUSDC: string;
  solUSDT: string;

  tronUSDC: string | null;
  tronUSDT: string | null;

  usdcBalance: string;
  usdtBalance: string;

  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

// Props for each balance card
type BalanceProps = {
  href: string;
  name: string;
  currency: string;
  amount: number;
  icon: ComponentType<{ className?: string }>;
  pattern: { y: number; squares: [number, number][] };
};

export type StableWalletCardProps = {
  data: BalanceProps[];
};

// Function to extract balances
export function extractBalances(
  walletData: WalletData | undefined,
  currency: "USDC" | "USDT"
): BalanceProps[] {
  // console.log(`Extracting balances for ${currency}`);
  // console.log("Wallet data:", JSON.stringify(walletData, null, 2));

  if (!walletData) {
    // console.error("walletData is undefined");
    return [];
  }

  const balances: BalanceProps[] = [];
  const networks = ["tron", "solana", "polygon"] as const; // Updated "sol" to "solana"

  networks.forEach((network) => {
    const balanceKey = `${network}${currency}` as keyof WalletData;
    const rawBalance = walletData[balanceKey];

    const amount =
      rawBalance && rawBalance !== "" ? parseFloat(rawBalance) : 0.0;

    console.log(`Adding ${network} ${currency} balance:`, amount);

    balances.push({
      href: `/${network}`,
      name: network,
      currency,
      amount,
      icon: getIcon(network),
      pattern: getPattern(network),
    });
  });

  console.log("Extracted balances:", balances);
  return balances;
}

// Function to get the correct icon based on the network
function getIcon(network: string): ComponentType<{ className?: string }> {
  switch (network) {
    case "tron":
      return TronIcon;
    case "solana":
      return Solana;
    case "polygon":
      return Polygon;
    default:
      return EmptyIcon; // Return a valid empty component
  }
}

// A valid empty icon component
const EmptyIcon: ComponentType<{ className?: string }> = () => (
  <div className="w-6 h-6" />
);

// Function to get the pattern based on the network
function getPattern(network: string): {
  y: number;
  squares: [number, number][];
} {
  switch (network) {
    case "tron":
      return {
        y: 16,
        squares: [
          [0, 1],
          [1, 3],
        ],
      };
    case "solana":
    case "polygon":
      return {
        y: -6,
        squares: [
          [-1, 2],
          [1, 3],
        ],
      };
    default:
      return { y: 0, squares: [] };
  }
}

export const capFirst = (str: string | null | undefined): string => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export const truncateAddress = (address: string): string => {
  if (!address) return '';

  if (address.length <= 12) return address;

  return `${address.slice(0,10)}...${address.slice(-10)}`;
};