import React, { ChangeEvent, useState, useEffect } from "react";
import { CgSoftwareUpload } from "react-icons/cg";
import tron from "../../assets/coins/tron.png";
import polygon from "../../assets/coins/Polygon.png";
import Solana from "../../assets/coins/solana.png";
import Ethereum from "../../assets/coins/eth.png";
import { useTabs } from "../widgets/TabWidget";
import { TabSelector } from "../widgets/TabSelector";
import { useStepperStore } from "../../store/useStableCoin";
import { useParams } from "react-router-dom";

interface UsdcBeneficiaryProps {
  onClick: () => void;
  prevStep: () => void;
  placeholder: string;
  onSaveBeneficiary: (details: any) => void;
}

// const NETWORK_OPTIONS = [
//   { id: "Tron", icon: tron, value: "trc20" },
//   { id: "Polygon", icon: polygon , value: "polygon" },
//   { id: "Solana", icon: Solana, value: "solana" },
// ] as const;

const NETWORK_OPTIONS = {
  USDC: [
    { id: "Solana", icon: Solana, value: "solana" },
    { id: "Polygon", icon: polygon, value: "polygon" },
    { id: "Ethereum", icon: Ethereum, value: "ethereum" },
  ],
  USDT: [
    { id: "Solana", icon: Solana, value: "solana" },
    { id: "Polygon", icon: polygon, value: "polygon" },
    { id: "Tron", icon: tron, value: "trc20" },
  ],
} as const;

const UsdcBeneficiary: React.FC<UsdcBeneficiaryProps> = ({
  onClick,
  prevStep,
  placeholder,
  onSaveBeneficiary,
}) => {
  const { beneficiaryDetails } = useStepperStore();
  const { currency } = useParams();
  const [formData, setFormData] = useState(() => ({
    beneficiaryName: "",
    alias: "",
    walletAddress: "",
  }));
  const [errors, setErrors] = useState({
    beneficiaryName: "",
    walletAddress: "",
  });
  const curr = currency?.toUpperCase() as string;
  // console.log(curr, "currcurr");
  const [selectedNetwork, setSelectedNetwork] = useTabs(
    //@ts-ignore
    NETWORK_OPTIONS?.[curr]?.map((opt: { value: any }) => opt.value)
  );

  // console.log(beneficiaryDetails, "beneficiaryDetails");

  useEffect(() => {
    // Clear errors dynamically when user types
    if (formData.beneficiaryName.trim())
      setErrors((prev) => ({ ...prev, beneficiaryName: "" }));
    if (formData.walletAddress.trim())
      setErrors((prev) => ({ ...prev, walletAddress: "" }));
  }, [formData]);

  const validateForm = () => {
    const newErrors: typeof errors = {
      beneficiaryName: "",
      walletAddress: "",
    };

    if (!formData.beneficiaryName.trim()) {
      newErrors.beneficiaryName = "Beneficiary name is required";
    }
    if (!formData.walletAddress.trim()) {
      newErrors.walletAddress = "Address is required";
    }

    setErrors(newErrors);

    // Return true if no errors
    return !Object.values(newErrors).some((err) => err);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSaveBeneficiary({
        ...formData,
        selectedNetwork: selectedNetwork,
      });
      onClick();
    }
  };
  useEffect(() => {
    if (formData) {
      setFormData(formData);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (performance.navigation.type !== 1) {
        useStepperStore.getState().resetStore();
      }
    };
  }, []);

  const isButtonDisabled =
    !formData.beneficiaryName.trim() || !formData.walletAddress.trim();

  const renderNetworkTab = (
    network: (typeof NETWORK_OPTIONS)["USDC"][number]
  ) => {
    const isActive = selectedNetwork === network.value;
    return (
      <TabSelector
        key={network.id}
        className={`flex items-center md:gap-2 cursor-pointer relative bg-transparent mr-4 md:mr-0 ${
          isActive
            ? "before:block before:w-5 before:h-5 before:bg-[#0eb683] before:rounded-full before:absolute before:left-1 before:bottom-1 before:self-center"
            : ""
        }`}
        isActive={isActive}
        //@ts-ignore
        onClick={() => setSelectedNetwork(network.value)}
      >
        <div className="flex items-center gap-2">
          <div className="bg-white rounded-full border border-[#0eb683] w-7 h-7"></div>
          <img
            src={network.icon}
            alt={`${network.id} icon`}
            className="w-7 hidden md:block"
          />
          <h1 className="text-[#5C6F7F] md:font-bold md:hidden">
            {network.id}
          </h1>
        </div>
        <h1 className="text-[#5C6F7F] md:font-bold hidden md:block">
          {network.id}
        </h1>
      </TabSelector>
    );
  };

  return (
    <div className="bg-[#f7fbfb] h-full w-full p-5">
      <div className="md:bg-white md:p-5 md:pt-5 md:max-w-lg">
        {/* Header Section */}
        <div className="flex items-center justify-between pt-5">
          <h1 className="text-[#071827] font-bold text-xl">
            Beneficiary Information
          </h1>
          <button className="text-[#0EB683] flex border border-[#0eb683] active:scale-90 items-center p-2 rounded-md shadow-md text-sm">
            <CgSoftwareUpload size={22} /> Share Form
          </button>
        </div>

        {/* Network Selection Tabs */}
        <nav className="flex items-center pt-4 justify-between">
          {/* @ts-ignore */}
          {NETWORK_OPTIONS?.[curr]?.map(renderNetworkTab)}
        </nav>

        {/* Form Section */}
        <form className="flex flex-col gap-4 pt-5">
          <MultiTypeInput
            name="placeholder"
            restrictSpecialChars={true}
            placeholder={placeholder}
            required
            className="md:bg-[#F2FAF7]"
            value={formData.walletAddress}
            onChange={(e) =>
              setFormData({ ...formData, walletAddress: e.target.value })
            }
            errorMessage={errors.walletAddress}
          />

          <MultiTypeInput
            name="beneficiaryName"
            placeholder="Beneficiary Name"
            required
            className="md:bg-[#F2FAF7]"
            value={formData.beneficiaryName}
            onChange={(e) =>
              setFormData({ ...formData, beneficiaryName: e.target.value })
            }
            errorMessage={errors.beneficiaryName}
          />

          <MultiTypeInput
            name="alias"
            placeholder="Alias"
            className="md:bg-[#F2FAF7]"
            value={formData.alias}
            onChange={(e) =>
              setFormData({ ...formData, alias: e.target.value })
            }
          />

          <div className="flex justify-between">
            <button
              type="button"
              onClick={prevStep}
              className="text-[#0eb683] p-3 px-6 rounded-md bg-white self-end transition-all md:mt-10 active:scale-90 border border-[#0eb683]"
            >
              Previous
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={isButtonDisabled}
              className={`p-3 px-6 rounded-md text-white self-end transition-all md:mt-10 active:scale-90 border ${
                isButtonDisabled
                  ? "disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none"
                  : "bg-[#0eb683] border-[#0eb683]"
              }`}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UsdcBeneficiary;

const MultiTypeInput = ({
  name,
  placeholder,
  required = false,
  className = "",
  value,
  onChange,
  errorMessage,
  restrictSpecialChars = false, // New prop
}: {
  name: string;
  placeholder: string;
  required?: boolean;
  className?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  restrictSpecialChars?: boolean; // Prop to restrict special characters
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    if (restrictSpecialChars) {
      // Remove special characters if the prop is true
      inputValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, "");
    }

    onChange({ ...e, target: { ...e.target, value: inputValue } });
  };

  const baseClassName =
    "py-4 px-4 w-full border border-[#E2E9F0] rounded-md outline-[#0eb683] placeholder:text-sm";

  return (
    <div className="mid relative">
      <input
        type="text"
        name={name}
        className={`${baseClassName} ${className}`}
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange} // Use the updated handler
        required={required}
      />
      {required && errorMessage && (
        <span className="text-red-500 text-xs">{errorMessage}</span>
      )}
    </div>
  );
};
