import { useState } from "react";

export const useErrorMessages = () => {
  const [errorMessages, setErrorMessages] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    dob: "",
    phone: "",
    idNumber: "",
    bvn: "",
    NIN: "",
    gender: "",
    address1: "",
    country: "",
    state: "",
    city: "",
    postCode: "",
    IDType: "",
    selfieData: "",
  });

  const setFieldError = (field: string, message: string) => {
    setErrorMessages((prev: any) => ({
      ...prev,
      [field]: message,
    }));
  };

  const clearFieldError = (field: string) => {
    setErrorMessages((prev: any) => ({
      ...prev,
      [field]: "",
    }));
  };

  return { errorMessages, setFieldError, clearFieldError };
};
