import React, { useEffect, useState } from "react";
import OTPInput from "../../components/widgets/OTPInput";
import { useCheckEmail, useRequestAccess } from "../../sevices/hooks/users";
import ReactLoading from "react-loading";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { Toaster, toast } from "sonner";


const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phoneNo: yup.string().required("Phone Number is required"),
  // .matches(/^\+\d{1,3}\d{5,13}$/, "Invalid phone number"),
});

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  referralCode?: string;
};

const RequestAccess = ({ setModal, setSuccess, setFailed }: any) => {
  const [loading, setLoading] = useState(false);

  const checkEmail = useCheckEmail();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const watchAllFields = watch();
  // console.log(errors, ";;");
  // console.log(watchAllFields, ";watchAllFields;");
  const requestAccess = useRequestAccess();
  const navigate = useNavigate();

  useEffect(() => {
    setSuccess(true);
  }, []);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    // Handle form submission here
    // onRequestAccess(data);
    validateEmail(data);
  };

  const validateEmail = (data: FormData) => {
    setLoading(true);
    checkEmail
      .mutateAsync({ email: watchAllFields.email })
      .then((res) => {
        if (res.data === true) {
          setLoading(false);
          toast.warning("User with this email already exist");
        } else if (res.data === false) {
          onRequestAccess(data);
          // setEmailExist(false)
        }
      })
      .catch((e) => {
        setLoading(false);
        // alert("Specified Email is already in use")s
        reset()
      });
  };

  const isButtonDisabled = !(
    watchAllFields.firstName &&
    watchAllFields.lastName &&
    watchAllFields.email &&
    watchAllFields.phoneNo
  );

  const onRequestAccess = (data: FormData) => {
    setLoading(true);
    data["referralCode"] = "5R4YB";
    requestAccess
      .mutateAsync(data)
      .then((res) => {
        setSuccess(true);
        setFailed(false);
        setModal(true);
        setLoading(false);
        reset()
      })
      .catch((err) => {
        setSuccess(false);
        setFailed(true);
        setModal(true);
        setLoading(false);
      });
  };

  return (
    <div className=" ">
      {/* <Toaster /> */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="input-field xs:py-4 xxs:py-0 "
      >
        <div className="flex flex-col md:flex-row items-center gap-3 pt-3 md:mb-3">
          <input
            type="text"
            // name="firstName"
            className={`border border-[#E2E9F0] bg-[#F2FAF7] w-full px-3 py-4 rounded-md outline-[#0eb683] ${
              errors.firstName && "border-red-500"
            }`}
            placeholder="First Name"
            {...register("firstName", { required: true })}
          />
          {errors.firstName && (
            <p className="text-red-500">{errors.firstName.message}</p>
          )}
          <input
            type="text"
            // name="lastName"
            className={`border bg-[#F2FAF7] border-[#E2E9F0] w-full px-3 py-4 rounded-md outline-[#0eb683]  ${
              errors.lastName && "border-red-500"
            }`}
            placeholder="Last Name"
            {...register("lastName", { required: true })}
          />
          {errors.lastName && (
            <p className="text-red-500">{errors.lastName.message}</p>
          )}
        </div>
        <input
          type="email"
          // name="email"
          className={`border border-[#E2E9F0] bg-[#F2FAF7] w-full px-3 py-4 outline-[#0eb683] rounded-md mt-3 md:mt-0 ${
            errors.email && "border-red-500"
          }`}
          placeholder="Email"
          {...register("email", {
            required: true,
          })}
        />
        {errors.email && (
          <p className="text-red-500 text-left">{errors.email.message}</p>
        )}
        <div className="my-3">
          <ReactPhoneInput
            containerStyle={{
              border: `0.5px solid #CBE0D6
      } `,
              padding: "0px",
              backgroundColor: "#F2FAF7",
              borderRadius: "4px",
              outline: "#0eb683",
            }}
            buttonStyle={{
              backgroundColor: "transparent",
              border: "none",
              borderRight: "1px solid #0EB683",
              margin: "10px 0px",
              padding: "0px 11px",
            }}
            inputStyle={{
              width: "100%",
              border: "1px solid #dcdfe4",
              padding: "0px 0px 0px 72px",
              backgroundColor: "#F2FAF7",
              height: 57,
            }}
            inputProps={{
              name: "phoneNo",
              required: true,
              autoFocus: true,
            }}
            enableAreaCodes={true}
            country="ng"
            placeholder="Enter your whatsapp number"
            value={watchAllFields.phoneNo}
            onChange={(phoneNo) =>
              setValue("phoneNo", phoneNo, { shouldValidate: true })
            }
          />
          {errors.phoneNo && (
            <p className="text-red-500">{errors.phoneNo.message}</p>
          )}

          <div className="flex items-center gap-1 mt-1 self-start">
            <span className="text-xs text-[#fff] md:text-gray-500 ">
              Provide{" "}
              <span className="inline font-semibold text-[#00A884]">
                WhatsApp{" "}
                <IoLogoWhatsapp fill="#00A884" className="inline-flex" />
              </span>{" "}
              number preferably
            </span>
          </div>
        </div>
        <button
          disabled={isButtonDisabled}
          type="submit"
          className=" bg-[#0eb683] p-3 mt-5 shadow-md shadow-gray-400/50  w-[100%] items-center rounded-[16px] font-medium text-white transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
        >
          {loading ? (
            <div className=" xxs:ml-[145px]  md:ml-[194px]">
              <ReactLoading
                type="spinningBubbles"
                color="#fff"
                height="27px"
                width="27px"
              />
            </div>
          ) : (
            "Request Access"
          )}
        </button>
      </form>

      <div className="flex flex-col justify-center items-center ">
        <small className="text-white md:text-[#000] text-center xxs:pt-1 xs:pt-3 xxs:mb-9 ">
          By clicking Continue, I agree to the website's
          <br />
          <button
            onClick={() => navigate("/terms-of-use", { replace: true })}
            className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
          >
            Terms of use
          </button>{" "}
          and{" "}
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/privacystatement", { replace: true });
            }}
            className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
          >
            Privacy statement
          </button>{" "}
        </small>
      </div>
    </div>
  );
  // ) : (
  //   <>
  //     <form className="refrence  " onSubmit={onSubmitForm}>
  //       <div className="otp-box xs:py-4 xxs:py-2 flex flex-col gap-5">
  //         <p className="text-white md:text-[#000] text-center">
  //           Enter your referee's referral code
  //         </p>
  //         <OTPInput
  //           autoFocus
  //           length={5}
  //           className="otpContainer flex gap-[8px] justify-center w-full xs:py-5 xxs:py-2 m-auto"
  //           inputClassName="otpInput w-12 h-12 md:w-[50px] md:h-[50px] bg-white border-2 border-[#0EB683] outline-[#0EB683] text-[#0EB683] text-2xl text-center font-bold rounded-lg"
  //           autoCapitalize={true}
  //           onChangeOTP={(otp) => setOtpValue(otp.toUpperCase())}
  //           onPaste={(otp) => setOtpValue(otp.toUpperCase())}
  //         />
  //         <button
  //           // onClick={onSubmitForm}
  //           type="submit"
  //           className=" bg-[#0eb683] p-3 xs:mt-5 xxs:mt-2  w-[100%] items-center rounded-md font-medium text-white transition-all active:scale-90"
  //         >
  //           {spin ? (
  //             <div className="xxs:ml-[140px] md:ml-[190px]">
  //               <ReactLoading
  //                 type="spinningBubbles"
  //                 color="#fff"
  //                 height="27px"
  //                 width="27px"
  //               />
  //             </div>
  //           ) : (
  //             "  Request Access"
  //           )}
  //         </button>
  //       </div>
  //     </form>
  //     <div className="flex justify-center items-center mt-3">
  //       <small className="text-white md:text-[#000] text-center xxs:pt-1 xs:pt-3 xxs:mb-9">
  //         By clicking Request Access, I agree to the website's
  //         <br />
  //         <button
  //           onClick={() => navigate("/terms-of-use", { replace: true })}
  //           className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
  //         >
  //           Terms of use
  //         </button>{" "}
  //         and{" "}
  //         <button
  //           onClick={(e) => {
  //             e.preventDefault();
  //             navigate("/privacystatement", { replace: true });
  //           }}
  //           className="text-[#0eb683] underline underline-offset-3 transition-all active:scale-90"
  //         >
  //           privacy statement
  //         </button>{" "}
  //       </small>
  //     </div>
  //   </>
  // );
};

export default RequestAccess;
