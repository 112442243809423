import { useEffect, useState } from "react";
import brandLogo from "../../assets/misc/design-top.svg";
import brandLogo2 from "../../assets/misc/design-bottom.svg";
import logo from "../../assets/misc/logo-on-dark.svg";
import { useRecoverPassword } from "../../sevices/hooks/users";
import { useNavigate } from "react-router-dom";
// import { useState } from "react";
import ReactLoading from "react-loading";
import { AnimatePresence, motion } from "framer-motion";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import { MdOutlinePassword } from "react-icons/md";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({ email: "" });
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();
  const forgetPassword = useRecoverPassword();
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    setLoading(true);
    forgetPassword
      .mutateAsync(values)
      .then((res) => {
        setLoading(false);
        navigate("/password", { replace: true });
      })
      .catch((e) => {
        setErrorMessage(e?.response?.data);
        setLoading(false);
        setModal(true);
      });
  };

  useEffect(() => {
    if (values.email) {
      setValues(values);
    }
  }, [!values.email]);

  return (
    <div>
      <img src={brandLogo} alt="" className="absolute w-28  md:w-44 " />
      <img
        src={brandLogo2}
        alt=""
        className="absolute w-28 md:w-44 right-0 bottom-0"
      />
      <div className=" flex items-center flex-col gap-5 bg-[#0C0D1F] h-screen justify-center ">
        <img src={logo} alt="" className=" w-60 inline" />

        <h1 className="text-white font-bold text-3xl pt-10">
          Forgot password?
        </h1>
        <small className="text-center text-white  ">
          Enter the email associated with your account,
          <br />
          we would send a link to reset your password
        </small>
        <form className="flex flex-col ">
          <input
            type="Email"
            value={values.email}
            name="email"
            id=""
            onChange={handleChange}
            placeholder="Email"
            className="p-3 outline-none active:border-[#0eb683] rounded-md border border-[#E2E9F0] w-[311px] md:w-[397px] mb-3 md:ml-1"
          />
          <div className="flex items-center md:justify-between xxs:gap-2 ">
            <button
              onClick={() => {
                navigate("/login", { replace: true });
              }}
              className="text-[#0eb683] p-3 md:px-12 md:ml-1 xxs:px-8  rounded-md bg-white border border-[#0eb683] transition-all active:scale-90"
            >
              Cancel
            </button>
            <button
              onClick={onSubmitForm}
              className="bg-[#0eb683] p-3 md:w-60 xxs:w-42 rounded-[16px] text-white border border-[#0eb683] xxs:px-16 transition-all active:scale-90 outline-none"
            >
              {loading ? (
                <div className=" xxs:px-[14px]  md:ml-[40px]">
                  <ReactLoading
                    type="spinningBubbles"
                    color="#fff"
                    height="27px"
                    width="27px"
                  />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
      {modal && (
        <AnimatePresence>
          <div>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="confirmation_modal bg-[#13111173] backdrop-blur-sm fixed w-full top-0 left-0 justify-center md:justify-end h-full items-center z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center "
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="desktop-card md:flex hidden justify-center flex-col gap-6 bg-white  w-full max-w-md h-[450px] rounded-md p-5 "
                >
                  <>
                    <div className="icon self-center">
                      <MdOutlinePassword size={50} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Password Reset Link
                    </h1>

                    <p className="text-sm text-[#5C6F7F] self-center text-center">
                      {errorMessage ?? "Email field is empty"}
                    </p>

                    <button
                      onClick={() => {
                        navigate("/login", { replace: true });
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                    >
                      Ok
                    </button>
                  </>
                </motion.div>
                {/* Mobile */}

                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="mobile-card flex md:hidden justify-center flex-col gap-4 bg-white  w-80 max-w-sm h-[400px] rounded-md p-5 "
                >
                  <>
                    <div className="icon self-center">
                      <MdOutlinePassword size={50} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Password Reset Link
                    </h1>

                    <p className="text-sm text-[#5C6F7F] self-center text-center">
                      {errorMessage +
                        "." +
                        " Please check the specified email and try again"}
                    </p>

                    <button
                      onClick={() => {
                        setModal(false);
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                    >
                      Retry
                    </button>
                  </>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default ForgotPassword;
