import React from "react";
import RefreshIcon from "../../assets/iconsComponents/RefreshIcon";
import WalletIcon from "../../assets/iconsComponents/WalletIcon";
import { useCardState } from "../../store";
import { numberWithCommas } from "../../pages/dashboard/Withdrawals";

const FiatWalletCard = ({
  height = "h-[100px]",
  mdHeight = "md:h-[104px]",
  refr,
  text,
  to,
  currency,
  amount,
  isBuy,
  buttonText,
  borderColor = "#0eb683",
  bgColor="#fff",
  ...props
}: any) => {
  const refresh = (props: any) => {
    props.refetch();
  };
  const { fundModal, toggleFundModal } = useCardState();
  return (
    <div>
      <div
        className={`flex items-center justify-between gap-4 xxs:bg-[#fff] md:bg-[#fff] md:py-6 md:pr-4 px-2 md:px-4 w-full border-2 border-[#0eb683] rounded-[16px] cursor-pointer relative ${height} ${mdHeight} md:w-[350px]`}
      >
        <div className="flex items-center gap-3">
          <div className="text-#98999cE bg-[#E7FCF5] p-3 rounded-full">
            <WalletIcon stroke="#0B0C0E" />
          </div>
          <div className="flex flex-col gap-3">
            <div>
              <div className="xxs:text-center md:text-left">
                <span className="xxs:text-[#5C6F7F] md:text-[#5C6F7F]">
                  {text}
                </span>
              </div>
              <p className="font-bold text-md xxs:text-[#0b0c0e] md:text-[#5C6F7F]">
                <span className="xxs:text-[#5C6F7F] md:text-[#5C6F7F] font-bold">
                  {currency}
                </span>
                <span>{amount}</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          {!refr && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                refresh(props);
              }}
              className="reload_icon border border-[#0eb683] text-[#0eb683] rounded-full p-1 transition-all active:scale-90 ml-6 absolute top-4 right-4 md:top-auto"
            >
              <RefreshIcon />
            </button>
          )}
          {isBuy && (
            <div>
              <div className="flex  ">
                <button
                  // disabled={disable}
                  onClick={() => {
                    toggleFundModal(true);
                    props.close();
                  }}
                  className={`bg-[${bgColor}] text-[${borderColor}] font-normal py-2 rounded-[9px] w-24 text-center transition-all active:scale-90 disabled:cursor-not-allowed whitespace-nowrap border-[${borderColor}] border hover:opacity-80`}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FiatWalletCard;
