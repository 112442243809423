import CryptoJS from "crypto-js";
import { Buffer } from "buffer";
import { JSEncrypt } from "jsencrypt";

interface EncryptionResult {
  dt: string;
  ky: string;
}

const generateRandomPassword = (length: number): string => {
  const charset: string =
    process.env.REACT_APP_CHARSET ||
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
  const randomValues = new Uint32Array(length);
  window.crypto.getRandomValues(randomValues);

  return Array.from(randomValues)
    .map((val) => charset[val % charset.length])
    .join("");
};

// export const rsaEncryption = async (
//   data: string,
//   publicKeyPem: string
// ): Promise<string> => {
//   if (!publicKeyPem) {
//     throw new Error("Public key is missing");
//   }

//   try {
//     // Add PEM headers if they're not present
//     const pemKey = publicKeyPem.includes("BEGIN PUBLIC KEY")
//       ? publicKeyPem
//       : `-----BEGIN PUBLIC KEY-----\n${publicKeyPem}\n-----END PUBLIC KEY-----`;

//     // Normalize the key
//     const normalizedKey = pemKey
//       .replace(/-----(BEGIN|END) PUBLIC KEY-----/g, "")
//       .replace(/\\n/g, "")
//       .replace(/\n/g, "")
//       .trim();

//     // Convert to binary
//     const binaryDer = Uint8Array.from(atob(normalizedKey), (c) =>
//       c.charCodeAt(0)
//     );

//     // Import the key
//     const publicKey = await window.crypto.subtle.importKey(
//       "spki",
//       binaryDer,
//       {
//         name: "RSA-OAEP",
//         hash: "SHA-256",
//       },
//       false,
//       ["encrypt"]
//     );

//     // Encrypt the data
//     const encoder = new TextEncoder();
//     const dataBuffer = encoder.encode(data);
//     const encrypted = await window.crypto.subtle.encrypt(
//       {
//         name: "RSA-OAEP",
//       },
//       publicKey,
//       dataBuffer
//     );

//     return Buffer.from(encrypted).toString("base64");
//   } catch (error) {
//     console.error("RSA Encryption error details:", error);
//     //@ts-ignore
//     throw new Error(`RSA encryption failed: ${error.message}`);
//   }
// };

export const rsaEncryption = (data: string, rsaPublicKey: string): string => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(rsaPublicKey);

  const encrypted = encrypt.encrypt(data);
  if (!encrypted) {
    throw new Error("RSA encryption failed");
  }

  return encrypted;
};

const generateKeyAndIV = () => {
  try {
    // Generate random 256-bit key
    const randomKey = CryptoJS.lib.WordArray.random(256 / 8);
    const keyBase64 = CryptoJS.enc.Base64.stringify(randomKey);

    // Generate random 128-bit IV
    const randomIV = CryptoJS.lib.WordArray.random(128 / 8);
    const ivBase64 = CryptoJS.enc.Base64.stringify(randomIV);

    // Join with specified separator
    const combinedString = `${keyBase64}**${ivBase64}`;

    return {
      combinedString,
      key: keyBase64,
      iv: ivBase64,
    };
  } catch (error) {
    // console.error("Key/IV generation error:", error);
    //@ts-ignore
    throw new Error(`Failed to generate key and IV: ${error.message}`);
  }
};

// export const aesEncryption = (data: string, key: string): string => {
//   try {
//     const salt = CryptoJS.lib.WordArray.random(128 / 8);
//     const derivedKey = CryptoJS.PBKDF2(key, salt, {
//       keySize: 256 / 32 + 128 / 32,
//       iterations: 10000,
//     });

//     const aesKey = CryptoJS.lib.WordArray.create(
//       derivedKey.words.slice(0, 256 / 32)
//     );
//     const iv = CryptoJS.lib.WordArray.create(derivedKey.words.slice(256 / 32));

//     const encrypted = CryptoJS.AES.encrypt(data, aesKey, {
//       iv: iv,
//       padding: CryptoJS.pad.Pkcs7,
//       mode: CryptoJS.mode.CBC,
//     });

//     const combined = salt.toString() + encrypted.toString();
//     return Buffer.from(combined, "hex").toString("base64");
//   } catch (error) {
//     console.error("AES Encryption error:", error);
//     //@ts-ignore
//     throw new Error(`AES encryption failed: ${error.message}`);
//   }
// };

export const aesEncryption = (
  data: string,
  keyBase64: string,
  ivBase64: string
): string => {
  try {
    // Convert base64 key and IV back to WordArray
    const key = CryptoJS.enc.Base64.parse(keyBase64);
    const iv = CryptoJS.enc.Base64.parse(ivBase64);

    // Encrypt the data
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    // Return the encrypted data in base64 format
    return encrypted.toString();
  } catch (error) {
    // console.error("AES Encryption error:", error);
    //@ts-ignore
    throw new Error(`AES encryption failed: ${error.message}`);
  }
};

export const generateAndEncryptData = async (
  data: string
): Promise<EncryptionResult> => {
  const { combinedString, key, iv } = generateKeyAndIV();
  try {
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;
    if (!publicKey) {
      throw new Error(
        "Public key is not configured in environment variables (REACT_APP_PUBLIC_KEY)"
      );
    }

    // Generate password and encrypt data
    const randomPassword = generateRandomPassword(32);
    // const encryptedData = aesEncryption(data, randomPassword);
    // console.log("Key:", key);
    // console.log("IV:", iv);
    const dt = aesEncryption(data, key, iv);
    // console.log("randomPassword:", randomPassword);
    // console.log("combinedString:", combinedString);
    // Encrypt the password with RSA
    // const encryptedKey = await rsaEncryption(randomPassword, publicKey);
    const ky = await rsaEncryption(combinedString, publicKey);

    return {
      dt,
      ky,
    };
  } catch (error) {
    // console.error("Full encryption error details:", error);
    throw error;
  }
};

// debugging
export const verifyEncryptionSetup = () => {
  // console.log("Charset available:", !!process.env.REACT_APP_CHARSET);
  // console.log("Public key available:", !!process.env.REACT_APP_PUBLIC_KEY);
  // console.log("Public key length:", process.env.REACT_APP_PUBLIC_KEY?.length);
  // console.log("Public key:", process.env.REACT_APP_PUBLIC_KEY);

  // const key = process.env.REACT_APP_PUBLIC_KEY || "";
  // console.log("Public key preview:", `${key.slice(0, 10)}...${key.slice(-10)}`);
};
