
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface WalletAddresses {
  polygon: {
    usdc: string;
    usdt: string;
  };
  solana: {
    usdc: string;
    usdt: string;
  };
  tron: {
    usdc: string;
    usdt: string;
  };
}

export interface WalletData {
  usdcBalance: string;
  usdtBalance: string;
  addresses: WalletAddresses;
}

interface WalletApiResponse {
  data: {
    createdAt: string;
    deletedAt: null | string;
    id: string;
    label: string;
    polygonUSDC: string;
    polygonUSDT: string;
    solUSDC: string;
    solUSDT: string;
    subAccountId: string;
    tronUSDC: string;
    tronUSDT: string;
    updatedAt: string;
    usdcBalance: string;
    usdtBalance: string;
    userId: string;
  };
  message: string;
  status: string;
}

interface WalletStore {
  wallet: WalletData | null;
  setWallet: (wallet: WalletData) => void;
  clearWallet: () => void;
}




export const useWalletStore = create<WalletStore>((set) => ({
  wallet: null,
  setWallet: (wallet: WalletData) => set({ wallet }),
  clearWallet: () => set({ wallet: null }),
}));


interface Step {
  name: string;
  label: string;
  description: string;
  completed: boolean;
}



interface Step {
  name: string;
  label: string;
  description: string;
  completed: boolean;
}

interface StepperStore {
  currentStep: number;
  steps: Step[];
  beneficiaryDetails: Record<string, any>;
  summaryData: Record<string, any>;
  formData: Record<string, any>; // New field to store form inputs
  setCurrentStep: (step: number) => void;
  nextStep: () => void;
  previousStep: () => void;
  updateStep: (index: number, data: Partial<Step>) => void;
  setBeneficiaryDetails: (details: Record<string, any>) => void;
  setSummaryData: (data: Record<string, any>) => void;
  updateFormData: (stepName: string, data: Record<string, any>) => void; // New method
  resetStore: () => void; // New method
}

const initialState = {
  currentStep: 0,
  steps: [
    {
      name: "Beneficiary",
      label: "Select Beneficiary",
      description: "Choose or add beneficiary",
      completed: false,
    },
    {
      name: "Details",
      label: "Transfer Details",
      description: "Enter transfer information",
      completed: false,
    },
    {
      name: "Payment",
      label: "Payment Instructions",
      description: "Review payment details",
      completed: false,
    },
    {
      name: "Summary",
      label: "Summary",
      description: "Confirm your transfer",
      completed: false,
    },
  ],
  beneficiaryDetails: {},
  summaryData: {},
  formData: {},
};

export const useStepperStore = create<StepperStore>()(
  persist(
    (set, get) => ({
      ...initialState,

      setCurrentStep: (step) => set({ currentStep: step }),

      nextStep: () =>
        set((state) => {
          const newSteps = [...state.steps];
          newSteps[state.currentStep].completed = true;

          return {
            currentStep: Math.min(
              state.currentStep + 1,
              state.steps.length - 1
            ),
            steps: newSteps,
          };
        }),

      previousStep: () =>
        set((state) => ({
          currentStep: Math.max(state.currentStep - 1, 0),
        })),

      updateStep: (index, data) =>
        set((state) => {
          const newSteps = [...state.steps];
          newSteps[index] = { ...newSteps[index], ...data };
          return { steps: newSteps };
        }),

      setBeneficiaryDetails: (details) => set({ beneficiaryDetails: details }),

      setSummaryData: (data) =>
        set((state) => ({
          summaryData: { ...state.summaryData, ...data },
        })),

      // New method to update form data for a specific step
      updateFormData: (stepName, data) =>
        set((state) => ({
          formData: {
            ...state.formData,
            [stepName]: data,
          },
        })),

      // New method to reset the store to initial state
      resetStore: () => set(initialState),
    }),
    {
      name: "stepper-storage", // unique name for localStorage key
      storage: createJSONStorage(() => sessionStorage), // use sessionStorage instead of localStorage
    }
  )
);




