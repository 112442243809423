/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import "../../index.css";
import { IoLogoWechat } from "react-icons/io5";
import { FcCurrencyExchange } from "react-icons/fc";
import hamburger from "../../assets/misc/hamburger-button.svg";
import BGImg from "../../assets/landing-asset/ezgif.com-animated-gif-maker.gif";
import Logo from "../../assets/misc/logo-on-light.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import image1 from "../../assets/landing-asset/lcy-1.png";
import image2 from "../../assets/landing-asset/Header Image 2.png";
import image3 from "../../assets/landing-asset/Header Image 3.png";
import { Toaster, toast } from "sonner";

const LandingPageHeader = () => {
  // Call to action text
  const [ctaText] = useState([
    { text: "Delivering   ## cross-border payments", keyword: "trusted" },
    {
      // text: "Swap your USDC & USDT Receive local currency at the best rates ###",
      // keyword: "instantly",
      text: "Swap local fiat ## receive foreign ###",
      keyword: "settlement",
    },
  ]);

  // State to track the current CTA
  const [currentCtaIndex, changeCtaIndex] = useState(0);

  // Function to move to next CTA
  const next = () => {
    changeCtaIndex(currentCtaIndex + 1);
    if (currentCtaIndex > 0) changeCtaIndex(0);
  };

  // Function to render CTA based on the current CTA index
  const renderCtaText = (index: number) => {
    let cta = ctaText[index];

    let splittedText = cta.text.split("##");
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    };

    return (
      <motion.div
        initial={{ y: "50%" }}
        animate={{ y: "0%" }}
        className=" max-w-[730px] relative flex flex-col items-center justify-center"
      >
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          className="h-60 flex items-end w-full pb-5"
        >
          <motion.h1 className="bold text-[#15112D] font-bold text-[38px] leading-[38px] md:text-[52px]  md:leading-[68px] 3xl:text-[73px] 3xl:leading-[90px] tracking-tight xxs:whitespace-nowrap md:whitespace-normal text-center">
            {splittedText[0]}{" "}
            <span
              className={`text-[#0EB683] ${
                cta?.keyword === "settlement" && "hidden"
              }`}
            >
              {cta?.keyword}
            </span>
            <br />{" "}
            <p className=" xxs:whitespace-normal md:whitespace-nowrap">
              {splittedText[1]}{" "}
              <span
                className={`text-[#0EB683] ${
                  cta?.keyword === "trusted" && "hidden"
                }`}
              >
                {cta?.keyword}
              </span>
            </p>
          </motion.h1>
        </motion.div>
        {/*
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          className="indicators flex gap-4 my-2 absolute bottom-1"
        >
          {ctaText.map((_, currentIndex) => (
            <motion.div
              key={currentIndex}
              initial="hidden"
              animate="visible"
              variants={variants}
              className={`indicator w-20 h-1 rounded-xl ${
                index === currentIndex ? "bg-[#0EB683]" : "bg-white"
              }  `}
            ></motion.div>
          ))}
        </motion.div> */}
      </motion.div>
    );
  };

  // Effect for Timing the CTA Sequence
  useEffect(() => {
    let timerId = setInterval(next, 5000);
    return () => clearInterval(timerId);
  }, [currentCtaIndex]);

  const handleClick = () => {
    window.open("https://links.ttfcx.com/connect", "_blank");
  };

  const handleGetRates = () => {
    window.open("https://links.ttfcx.com/chatus", "_blank");
  }

  const images = [image1, image2, image3];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000); // Change slide every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [currentSlide, images.length]);



  return (
    <header
      className="h-[100%] pt-5 md:px-[8%] px-5 py-2  "
      style={{
        backgroundImage: `url('${BGImg}')`,
        // background: "#014584",
        // background:
        //   "linear-gradient(264deg, rgba(68, 123, 175, 0.372) 1%, rgba(255, 255, 255, 1) 50%, rgba(68, 123, 175, 0.372) 81%)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Toaster position="top-right" richColors />
      <nav
        style={{
          backgroundImage: `url('${BGImg}')`,
          // background: "rgb(1,69,132)",
          // background:
          //   "linear-gradient(264deg, rgb(251, 253, 255) 1%, rgba(255, 255, 255, 1) 50%, rgb(238, 248, 255) 81%)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="flex items-center fixed top-0 left-0  right-0 z-50 px-5 md:px-[8%] py-4"
      >
        <a href="/" className="transition-all active:scale-90">
          <img
            src={Logo}
            alt=""
            className="md:w-36 md:h-16 3xl:w-40 3xl:h-20 "
          />
        </a>

        <div className="nav-options ml-auto flex items-center gap-5">
          <div
            onClick={handleGetRates}
            className="light bg-[#014584]  border border-[#014584] text-[#fff] shadow-sm   p-3 px-6 rounded-full  active:scale-90 transition-all duration-500 hover:opacity-80  3xl:text-[25px] 3xl:leading-[50px]  md:hidden flex"
          >
            Get Rates <FcCurrencyExchange fill={"#fff"} />
          </div>
          <div
            onClick={handleClick}
            className="light bg-[#fff]  border border-[#0eb683] text-[#0eb683] shadow-sm   p-3 px-6 rounded-full  active:scale-90 transition-all duration-500 hover:opacity-80  3xl:text-[25px] 3xl:leading-[50px] cursor-pointer hidden md:flex"
          >
            Talk to us <IoLogoWechat fill={"#0eb683"} />
          </div>
          <div
            onClick={handleGetRates}
            className="light text-[#fff] border-[#0eb683]  border bg-[#0eb683] shadow-sm   p-3 px-6 rounded-full  active:scale-90 transition-all duration-500 hover:opacity-80  3xl:text-[25px] 3xl:leading-[50px] cursor-pointer hidden md:flex"
          >
            Get Rates <FcCurrencyExchange fill={"#fff"} />
          </div>
        </div>
      </nav>
      <div className="w-full flex flex-col items-center justify-center">
        {/* <div className="text-center mt-14 mb-6">
          <h1
            // style={{
            //   // fontFamily: "DM Sans",
            //   fontFamily: "TomatoGrotesk-Bold",
            // }}
            className="bold text-[#15112D] font-bold text-[38px] leading-[38px] md:text-[52px]  md:leading-[68px] 3xl:text-[73px] 3xl:leading-[90px] tracking-tight xxs:whitespace-nowrap md:whitespace-normal"
          >
            Delivering{" "}
            <span
              // style={{
              //   // fontFamily: "DM Sans",
              //   fontFamily: "TomatoGrotesk-Bold",
              // }}
              className="bold text-[#0eb683] 3xl:text-[73px] 3xl:leading-[90px]"
            >
              trusted
            </span>{" "}
          </h1>
          <p
            // style={{
            //   // fontFamily: "DM Sans",
            //   fontFamily: "TomatoGrotesk-Bold",
            // }}
            className="bold text-[#15112D] font-bold text-[35px] leading-[40px] md:text-[52px]  md:leading-[68px] tracking-tight 3xl:text-[73px] 3xl:leading-[90px]"
          >
            cross-border payments
          </p>
        </div> */}

        <div className="">{renderCtaText(currentCtaIndex)}</div>
        <motion.p
          initial={{ x: "50%", opacity: 0 }}
          animate={{ x: "0%", opacity: 1 }}
          transition={{ delay: 1 }}
          // style={{
          //   // fontFamily: "DM Sans",
          //   fontFamily: "TomatoGrotesk-Light",
          // }}
          className="light text-[20px] text-[#393939] font-light leading-[30px]  text-center 3xl:text-[33px] 3xl:leading-[40px]"
        >
          Trusted Transfers securely enables cross-border payments between



          <br className="hidden md:inline" /> verified individuals, businesses and their global partners
        </motion.p>
        <motion.div
          initial={{ y: "50%", opacity: 0 }}
          animate={{ y: "0%", opacity: 1 }}
          transition={{ delay: 1 }}
          className="cta flex gap-3 md:gap-5 mt-5 "
        >
          <Link
            // style={{
            //   // fontFamily: "DM Sans",
            //   fontFamily: "TomatoGrotesk-Light",
            // }}
            to={"/request-access"}
            className="light bg-[#014584] border border-[#014584]  shadow-sm text-white p-4 px-8 rounded-full active:scale-90 transition-all duration-500 hover:opacity-80 3xl:text-[25px] 3xl:leading-[50px]"
          >
            Request Access
          </Link>
          <Link
            // style={{
            //   // fontFamily: "DM Sans",
            //   fontFamily: "TomatoGrotesk-Light",
            // }}
            to={"/login"}
            className="light bg-white border border-[#014584] text-[#014584]  p-4 px-8 rounded-full active:scale-90 transition-all duration-500 hover:opacity-80 shadow-sm 3xl:text-[25px] 3xl:leading-[50px]"
          >
            Login
          </Link>
        </motion.div>

        <div className="md:flex justify-center mt-14 space-x-5 w-[100%] hidden">
          <div className="w-3/4 bg-[#EAF2FD] px-10 rounded-[32px]  flex items-center ">
            <img
              src={image1}
              alt=" Image"
              className=" w-full  h-auto rounded-[32px]"
            />
          </div>
          <div className="w-3/4 bg-[#FCF2E8] px-10 rounded-[32px]  flex items-center">
            <img
              src={image2}
              alt=" Image"
              className="w-full h-auto rounded-[32px]"
            />
          </div>
          <div className="w-3/4 bg-[#ECFAF2] px-10 rounded-[32px]  flex items-center">
            <img
              src={image3}
              alt=" Image"
              className="w-full h-auto rounded-[32px]"
            />
          </div>
        </div>
        {/* mobile */}
      </div>
      <div className="block  md:hidden z-50">
        <Slider sliderImages={[]} />
      </div>
    </header>
  );
};

export default LandingPageHeader;

interface SliderProps {
  sliderImages: never[];
}

const dataSlider = [
  {
    id: 1,
    src: image1,
    name: "_img1",
    bg: "#EAF2FD",
  },
  {
    id: 2,
    src: image2,
    name: "_img2",
    bg: "#FCF2E8",
  },
  {
    id: 3,
    src: image3,
    name: "_img2",
    bg: "#ECFAF2",
  },
];

const Slider: React.FC<SliderProps> = ({ sliderImages }: SliderProps) => {
  const [slideIndex, setSlideIndex] = useState(1);

  const nextSlide = useCallback(() => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1);
    }
  }, [slideIndex]);

  const prevSlide = () => {
    setSlideIndex(
      ((slideIndex - 2 + dataSlider.length) % dataSlider.length) + 1
    );
  };

  useEffect(() => {
    const id = setInterval(nextSlide, 3000);
    return () => clearInterval(id);
  }, [nextSlide, slideIndex, sliderImages]);

  const moveDot = (index: number) => {
    setSlideIndex(index);
  };

  useEffect(() => {
    const scaleInImage = () => {
      const image = document.querySelector(".scale-in-image");
      if (image) {
        setTimeout(() => {
          image.classList.add("scale-in");
        }, 1000);
      }
    };

    scaleInImage();
  }, []);

  return (
    <div className="relative mt-8 ">
      <div className="w-full xxs:h-[40vh] overflow-hidden">
        {dataSlider.map((obj, index) => (
          <div
            key={obj.id}
            className={`w-full h-full absolute opacity-0 transition-opacity duration-400 bg-[${
              obj.bg
            }] rounded-md  ${
              slideIndex === index + 1 ? "active-anim opacity-100" : ""
            }`}
          >
            <img
              src={obj.src}
              alt={obj.name}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>

      <div className="absolute mx-auto left-[50%] -bottom-5 transform -translate-x-1/2 flex mt-4">
        {dataSlider.map((obj, index) => (
          <div
            key={obj.id}
            onClick={() => moveDot(index + 1)}
            className={`w-[6px] h-[6px]  border-3 rounded-full mr-2 ${
              slideIndex === index + 1
                ? "bg-[#0eb683] border-[#0eb683]"
                : "bg-gray-300 border-gray-300"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};
