import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FailedIcon from "../../assets/iconsComponents/FailedIcon";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import { useCheckEmail, useUsersInvitation } from "../../sevices/hooks/users";
import { useSidebarState } from "../../store";
import ReactLoading from "react-loading";
import * as yup from "yup";

import { IoMdClose } from "react-icons/io";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
});

type FormData = {
  firstName: string;
  lastName:string;
  email: string;

};


const InviteUsers = ({ data }: any) => {
  const invitationModalOpen = useSidebarState(
    (state) => state.invitationModalOpen
  );
  const setInvitationModalOpen = useSidebarState(
    (state) => state.setInvitationModalOpen
  );

  const usersInvitation = useUsersInvitation();
  const [invitationSuccess, setInvitationSuccess] = useState(false);
  const [invitationFailed, setInvitationFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const userDetails = data;
  const checkEmail = useCheckEmail();



  const [emailExist, setEmailExist] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const watchAllFields = watch();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    // Handle form submission here
   validateEmail(data);
  };



    const validateEmail = (data: { email: string; lastName: string; firstName: string; }) => {
    setLoading(true);
    checkEmail
      .mutateAsync({ email: watchAllFields.email })
      .then((res) => {
        if (res.data === true) {
          setLoading(false);
          setEmailExist(true);
        } else if (res.data === false) {
          inviteUser(data);
          // setEmailExist(false)
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const isButtonDisabled = !(
    watchAllFields.firstName &&
    watchAllFields.lastName &&
    watchAllFields.email
  );

  const inviteUser = (data: { email: string; lastName: string; firstName: string; } ) => {
    setLoading(true);
    usersInvitation
      .mutateAsync(data)
      .then((res: any) => {
        setLoading(false);
        reset()
        setInvitationSuccess(true);
      })
      .catch((e: any) => {
        setErrorMessage(e?.response?.data);
        setLoading(false);
        setInvitationFailed(true);
      });
  };



  const closeModal = () => {
    setInvitationModalOpen(false);

  };
  return (
    <>
      <AnimatePresence>
        {invitationModalOpen && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="modal fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
          >
            <motion.div
              animate={{
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="modal_overlay flex xxs:justify-center xxs:p-5 md:p-0 md:justify-end h-full cursor-pointer bg-[#13111173]"
            >
              <motion.div
                initial={{
                  x: 100,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 0,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="flex flex-col bg-white w-full max-w-sm md:max-w-sm max-h-full md:h-full relative xxs:rounded-md md:rounded-l-md p-5 md:px-7"
              >
                <div className="flex items-center justify-between mb-10">
                  <h2 className="text-xl font-semibold">Invite new users</h2>
                  <div
                    onClick={closeModal}
                    className="close flex items-center justify-center h-10 w-10 rounded-full hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center self-end"
                  >
                    <span className="hover:rotate-90 hover:transform transition duration-150 ease-in-out">
                      <IoMdClose size={29} className="" />
                    </span>
                  </div>
                </div>

                <p className="text-[#5C6F7F] text-sm font-medium whitespace-normal text-justify">
                  Enter your referee’s contact details and an invitation would
                  be sent to them. Any{" "}
                  <span className="font-extrabold text-md underline">BUY</span>{" "}
                  transaction your referee performs earns you up to
                  <span className="text-[#0eb683] font-bold">
                    {" "}
                    NGN1.00/$,£,€ commission
                  </span>
                </p>
                <div className="my-5">
                <p className="text-center text-[#5C6F7F] font-medium">
                  Your Referral Code:
                </p>
                <p className="ref text-[#5C6F7F] font-bold text-center text-3xl">
                  {userDetails?.invitationCode}
                </p>

                </div>
                <div className="flex flex-col items-center w-full gap-3  h-full">
                  {emailExist && (
                    <div className="text-[#e01a00] flex text-sm gap-1 items-center text-center">
                      <p className="flex gap-1">Email already exists.</p>
                    </div>
                  )}

                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col w-full gap-3 mt-5 flex-grow"
                  >
                    <input
                      type="text"
                      className={`border border-[#E2E9F0] bg-[#F2FAF7] w-full p-3 rounded-md outline-[#0eb683] ${
                        errors.firstName && "border-red-500"
                      }`}
                      placeholder="First Name"
                      {...register("firstName", { required: true })}
                    />
                    {errors.firstName && (
                      <p className="text-red-500">{errors.firstName.message}</p>
                    )}

                    <input
                      type="text"
                      className={`border bg-[#F2FAF7] border-[#E2E9F0] w-full p-3 rounded-md outline-[#0eb683] ${
                        errors.lastName && "border-red-500"
                      }`}
                      placeholder="Last Name"
                      {...register("lastName", { required: true })}
                    />
                    {errors.lastName && (
                      <p className="text-red-500">{errors.lastName.message}</p>
                    )}

                    <input
                      type="email"
                      className={`border border-[#E2E9F0] bg-[#F2FAF7] w-full p-3 outline-[#0eb683] rounded-md ${
                        errors.email && "border-red-500"
                      }`}
                      placeholder="Email"
                      {...register("email", {
                        required: true,
                        onBlur: () => setEmailExist(false),
                        onChange: () => setEmailExist(false),
                      })}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-left">
                        {errors.email.message}
                      </p>
                    )}

                    <button
                      type="submit"
                      className={`text-white w-full bg-[#0eb683] rounded-[16px] px-10 py-3.5 mt-auto transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 item-center justify-center flex ${
                        isButtonDisabled && "disabled"
                      }`}
                      disabled={isButtonDisabled}
                    >
                      {loading ? (
                        <div className="mx-auto">
                          <ReactLoading
                            type="spinningBubbles"
                            color="#fff"
                            height="27px"
                            width="27px"
                          />
                        </div>
                      ) : (
                        "Invite new user"
                      )}
                    </button>
                  </form>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {invitationSuccess && (
          <div>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 overflow-auto items-center justify-center gap-3"
                >
                  <div className="icon self-center">
                    <SuccessMobile width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-2xl  font-bold text-[#071827] self-center">
                    Invitation sent
                  </h1>

                  <p className="  text-sm text-[#5C6F7F] self-center text-center">
                    Your invitation was successfully sent
                  </p>

                  <button
                    onClick={() => {
                      closeModal();
                      setInvitationSuccess(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-[16px] px-20 py-3 self-center transition-all active:scale-90"
                  >
                    Done
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {invitationFailed && (
          <div>
            <motion.div
              // ref={modalRef}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50 "
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 overflow-auto items-center justify-center gap-3"
                >
                  <div className="icon self-center">
                    <FailedIcon width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                    Invitation failed
                  </h1>

                  <p className="  text-sm text-[#ED0000] self-center text-center">
                    {errorMessage ||
                      "We couldn't invite your user please retry"}
                  </p>

                  <button
                    onClick={() => {
                      setInvitationFailed(false);
                      setInvitationModalOpen(true);
                    }}
                    className="bg-[#0eb683] text-white rounded-[16px] px-20 py-3 self-center transition-all active:scale-90"
                  >
                    Retry
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default InviteUsers;
