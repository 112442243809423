import React, { useEffect, useMemo, useState } from "react";
import { useStepperStore } from "../../store/useStableCoin";
import { useSaveBeneficiaryStore } from "../../store/beneficiary";
import { StableCoinBeneficiary } from "../../types/Users";
import { useGetStableBeneficiaries } from "../../sevices/hooks/wallets";
import { useSearchParams } from "react-router-dom";

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string | React.ReactNode;
}

const CustomCheckbox = ({ checked, onChange, label }: CustomCheckboxProps) => {
  return (
    <label className="flex items-start gap-3 cursor-pointer group">
      <div className="relative flex items-center justify-center mt-1">
        <input
          type="checkbox"
          className="sr-only"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <div
          className={`h-5 w-5 rounded border  ${
            checked
              ? "bg-[#0eb683] border-[#0eb683]"
              : "border-gray-300 bg-white group-hover:border-[#0eb683]"
          }`}
        >
          {checked && (
            <svg
              className="w-5 h-5 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>
      </div>
      {label && <span className="text-[#5C6F7F]">{label}</span>}
    </label>
  );
};

interface TransferConfirmationProps {
  amount?: string;
  walletAddress?: string;
  beneficiaryName?: string;
  alias?: string;
}

const TransferConfirmation = ({
  currency,
  loading,
  prev,
  handleOTP,
}: {
  currency: string;
  loading: boolean;
  prev: () => void;
  handleOTP: () => void;
}) => {
  const [confirmations, setConfirmations] = useState({
    info: false,
    debit: false,
    terms: false,
    authorize: false,
    save: false,
  });

  const [searchParams] = useSearchParams();
  const Stablebeneficiaries = useGetStableBeneficiaries();
  const { beneficiaryDetails } = useStepperStore();
  const [initiateLoading, setInitiateLoading] = useState(false);
  const allChecked = Object.entries(confirmations)
    .filter(([key]) => key !== "save")
    .every(([, value]) => value);

  const handleInitiateTransfer = () => {
    if (allChecked) {
      setInitiateLoading(true);
      handleOTP();
      // console.log(beneficiaryDetails, "beneficiaryDetails");
    }
  };
  const { isLoading: StableisLoading, data: Stabledata } = Stablebeneficiaries;
  const beneficiaryId = searchParams.get("q");

  const filteredBeneficiary = useMemo<StableCoinBeneficiary | null>(() => {
    if (beneficiaryId && Stabledata?.data?.data) {
      return (
        Stabledata?.data?.data.find(
          (beneficiary) => beneficiary.id === beneficiaryId
        ) || null
      );
    }
    return null;
  }, [beneficiaryId, Stabledata]);

  // console.log(filteredBeneficiary, "filteredBeneficiary");
  // const transferDetails = {
  //   amount: beneficiaryDetails.formattedAmount,
  //   walletAddress: beneficiaryDetails.walletAddress,
  //   beneficiaryName: beneficiaryDetails.beneficiaryName,
  //   chain: beneficiaryDetails.selectedNetwork,
  //   alias: beneficiaryDetails.alias || "",
  // };

  const transferDetails = {
    amount: beneficiaryDetails.formattedAmount,

    walletAddress: filteredBeneficiary
      ? filteredBeneficiary.beneficiaryAddress ||
        beneficiaryDetails.walletAddress
      : beneficiaryDetails.walletAddress,

    beneficiaryName: filteredBeneficiary
      ? filteredBeneficiary.beneficiaryName ||
        beneficiaryDetails.beneficiaryName
      : beneficiaryDetails.beneficiaryName,

    chain: filteredBeneficiary
      ? filteredBeneficiary.chain || beneficiaryDetails.selectedNetwork
      : beneficiaryDetails.selectedNetwork,

    alias: filteredBeneficiary
      ? filteredBeneficiary.alias || beneficiaryDetails.alias || ""
      : beneficiaryDetails.alias || "",
  };
  useEffect(() => {
    return () => {
      if (performance.navigation.type !== 1) {
        useStepperStore.getState().resetStore();
      }
    };
  }, []);
  // console.log(beneficiaryDetails, "beneficiaryDetails");

  return (
    <div className="w-full p-4 md:p-10 bg-white rounded-2xl">
      <div className="flex flex-col md:flex-row gap-6">
        {/* Summary Section */}

        <TransferSummary
          confirmations={confirmations}
          //@ts-ignore
          setConfirmations={setConfirmations}
          {...transferDetails} // Spread in additional details
        />

        {/* Confirmation Section */}
        <div className="flex-1">
          <h2 className="text-xl font-semibold mb-6 text-[#071827]">
            Confirmation
          </h2>
          <p className="text-[#071827] mb-6">
            Transfers typically depend on the blockchain network
          </p>
          <p className="text-[#071827] mb-6">I confirm that:</p>
          <div className="space-y-5">
            <CustomCheckbox
              checked={confirmations.info}
              onChange={(checked) =>
                setConfirmations((prev) => ({ ...prev, info: checked }))
              }
              label="The information I have provided is correct"
            />

            <CustomCheckbox
              checked={confirmations.debit}
              onChange={(checked) =>
                setConfirmations((prev) => ({ ...prev, debit: checked }))
              }
              label="I agree my USDT wallet be debited along with all associated charges"
            />

            <CustomCheckbox
              checked={confirmations.terms}
              onChange={(checked) =>
                setConfirmations((prev) => ({ ...prev, terms: checked }))
              }
              label={
                <>
                  I agree to be bound by Trusted Transfer's Offshore Transfer{" "}
                  <a href="#" className="text-[#0eb683] hover:underline">
                    Terms of Service
                  </a>
                </>
              }
            />

            <CustomCheckbox
              checked={confirmations.authorize}
              onChange={(checked) =>
                setConfirmations((prev) => ({ ...prev, authorize: checked }))
              }
              label="By clicking the button below, Trusted Transfers is authorized to transfer the funds to the beneficiary account"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-8">
        <button
          onClick={prev}
          className="px-6 py-3 border border-[#0eb683] text-[#0eb683] rounded-xl "
        >
          Back
        </button>
        <button
          onClick={handleInitiateTransfer}
          disabled={!allChecked || loading}
          className={`px-6 py-3 rounded-xl text-white ${
            allChecked
              ? "bg-[#0eb683]  "
              : "disabled:bg-[#8cc2ad] disabled:cursor-not-allowed disabled:transition-none"
          }`}
        >
          {loading ? "Processing..." : "Initiate Transfer"}
        </button>
      </div>
    </div>
  );
};

export default TransferConfirmation;

const SummaryRow = ({
  label,
  value,
  className = "",
}: {
  label: string;
  value: string;
  className?: string;
}) => (
  <div className="w-full">
    <p className="text-[#5C6F7F] text-sm mb-1">{label}</p>
    <p className={`text-[#071827] font-medium break-words ${className}`}>
      {value}
    </p>
  </div>
);

interface TransferSummaryProps {
  amount: string;
  walletAddress: string;
  beneficiaryName: string;
  alias: string;
  chain: string;
  confirmations: {
    info: boolean;
    debit: boolean;
    terms: boolean;
    authorize: boolean;
    save: boolean;
  };
  setConfirmations: React.Dispatch<
    React.SetStateAction<{
      info: boolean;
      debit: boolean;
      terms: boolean;
      authorize: boolean;
      save: boolean;
    }>
  >;
}

const TransferSummary = ({
  amount,
  walletAddress,
  beneficiaryName,
  alias,
  confirmations,
  chain,
  setConfirmations,
}: TransferSummaryProps) => {
  const [searchParams] = useSearchParams();
  const { isSaveBeneficiary, setSaveBeneficiary } = useSaveBeneficiaryStore();
  const beneficiaryId = searchParams.get("q");

  // console.log(beneficiaryId, "beneficiaryId");
  return (
    <div className="flex-1 w-full md:w-[50%] md:pr-6">
      <h2 className="text-xl font-semibold mb-6 md:mb-0 text-[#071827]">
        Summary
      </h2>

      {/* Amount Section */}
      <div className="bg-white rounded-lg p-4 mb-4 shadow-sm">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <span className="text-[#0B0C0E] text-sm mb-1">You are sending:</span>
          <span className="text-[#0EB683] font-bold text-2xl">{amount}</span>
        </div>
      </div>

      {/* Details Card */}
      <div className="bg-emerald-50 rounded-lg p-4">
        <div className="space-y-6">
          {/* Mobile-optimized layout */}
          <div className="md:hidden space-y-4">
            <SummaryRow
              label="To"
              value={walletAddress}
              className="font-mono text-sm bg-white/80 p-2 rounded-md"
            />
            <SummaryRow
              label="Beneficiary Name"
              value={beneficiaryName}
              className="text-lg"
            />
            <SummaryRow label="Network" value={chain} className="text-lg" />
            <SummaryRow label="Alias" value={alias} className="text-lg" />
          </div>

          {/* Desktop layout */}
          <div className="hidden md:block space-y-5">
            <div className="flex items-center justify-between gap-4">
              <p className="text-[#5C6F7F] whitespace-nowrap">To:</p>
              <p className="font-mono text-[#071827] text-sm break-all">
                {walletAddress}
              </p>
            </div>
            <div className="flex items-center justify-between gap-4">
              <p className="text-[#5C6F7F] whitespace-nowrap">Chain:</p>
              <p className="font-mono text-[#071827] text-sm break-all">
                {chain}
              </p>
            </div>
            <div className="flex items-center justify-between gap-4">
              <p className="text-[#5C6F7F] whitespace-nowrap">
                Beneficiary Name:
              </p>
              <p className="text-[#071827] text-lg">{beneficiaryName}</p>
            </div>
            <div className="flex items-center justify-between gap-4">
              <p className="text-[#5C6F7F] whitespace-nowrap">Alias:</p>
              <p className="text-[#071827] text-lg">{alias}</p>
            </div>
          </div>

          {/* Save Beneficiary Section */}
          {!beneficiaryId && (
            <div className="flex items-center justify-between pt-2 border-t border-emerald-100">
              <p className="text-[#5C6F7F]">Save Beneficiary Details:</p>
              {/* <CustomCheckbox
              checked={confirmations.save}
              onChange={(checked) =>
                setConfirmations((prev) => ({ ...prev, save: checked }))
              }
            /> */}

              <CustomCheckbox
                checked={isSaveBeneficiary}
                onChange={(checked) => setSaveBeneficiary(checked)}
              />
            </div>
          )}
        </div>
      </div>

      {/* Warning Message */}
      <div className="mt-8 p-3 bg-red-50 rounded-lg">
        <p className="text-[#ED0000] text-sm flex items-center gap-2">
          <svg
            className="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          Please verify wallet address as transactions are non-refundable
        </p>
      </div>
    </div>
  );
};
