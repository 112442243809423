import React, { SVGProps } from 'react'

const TronIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
    return (
      <div>
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="60" height="60" rx="30" fill="#FF060A" />
          <path
            d="M44.7932 23.6404C43.2932 22.2554 41.2182 20.1404 39.5282 18.6404L39.4282 18.5704C39.2618 18.4368 39.0742 18.332 38.8732 18.2604C34.7982 17.5004 15.8332 13.9554 15.4632 14.0004C15.3595 14.0149 15.2604 14.0525 15.1732 14.1104L15.0782 14.1854C14.9612 14.3042 14.8724 14.4477 14.8182 14.6054L14.7932 14.6704V15.0254V15.0804C16.9282 21.0254 25.3582 40.5004 27.0182 45.0704C27.1182 45.3804 27.3082 45.9704 27.6632 46.0004H27.7432C27.9332 46.0004 28.7432 44.9304 28.7432 44.9304C28.7432 44.9304 43.2232 27.3704 44.6882 25.5004C44.8778 25.2701 45.0453 25.0223 45.1882 24.7604C45.2247 24.5555 45.2075 24.3447 45.1383 24.1484C45.069 23.9521 44.9502 23.7771 44.7932 23.6404ZM32.4582 25.6854L38.6382 20.5604L42.2632 23.9004L32.4582 25.6854ZM30.0582 25.3504L19.4182 16.6304L36.6332 19.8054L30.0582 25.3504ZM31.0182 27.6354L41.9082 25.8804L29.4582 40.8804L31.0182 27.6354ZM17.9732 17.5004L29.1682 27.0004L27.5482 40.8904L17.9732 17.5004Z"
            fill="white"
          />
        </svg>
      </div>
    );
};

export default TronIcon