import React from 'react'

const Polygon = () => {
  return (
    <div>
      <svg
        width="61"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.666626" width="60" height="60" rx="30" fill="#8247E5" />
        <g clip-path="url(#clip0_15879_44286)">
          <path
            d="M38.8749 23.4484C38.2916 23.0484 37.5416 23.0484 36.8749 23.4484L32.2083 26.7484L29.0416 28.8484L24.4583 32.1484C23.8749 32.5484 23.1249 32.5484 22.4583 32.1484L18.8749 29.5484C18.2916 29.1484 17.8749 28.3484 17.8749 27.4484V22.4484C17.8749 21.6484 18.2083 20.8484 18.8749 20.3484L22.4583 17.8484C23.0416 17.4484 23.7916 17.4484 24.4583 17.8484L28.0416 20.4484C28.6249 20.8484 29.0416 21.6484 29.0416 22.5484V25.8484L32.2083 23.6484V20.2484C32.2083 19.4484 31.8749 18.6484 31.2083 18.1484L24.5416 13.4484C23.9583 13.0484 23.2083 13.0484 22.5416 13.4484L15.7083 18.2484C15.0416 18.6484 14.7083 19.4484 14.7083 20.2484V29.6484C14.7083 30.4484 15.0416 31.2484 15.7083 31.7484L22.4583 36.4484C23.0416 36.8484 23.7916 36.8484 24.4583 36.4484L29.0416 33.2484L32.2083 31.0484L36.7916 27.8484C37.3749 27.4484 38.1249 27.4484 38.7916 27.8484L42.3749 30.3484C42.9583 30.7484 43.3749 31.5484 43.3749 32.4484V37.4484C43.3749 38.2484 43.0416 39.0484 42.3749 39.5484L38.8749 42.0484C38.2916 42.4484 37.5416 42.4484 36.8749 42.0484L33.2916 39.5484C32.7083 39.1484 32.2916 38.3484 32.2916 37.4484V34.2484L29.1249 36.4484V39.7484C29.1249 40.5484 29.4583 41.3484 30.1249 41.8484L36.8749 46.5484C37.4583 46.9484 38.2083 46.9484 38.8749 46.5484L45.6249 41.8484C46.2082 41.4484 46.6249 40.6484 46.6249 39.7484V30.2484C46.6249 29.4484 46.2916 28.6484 45.6249 28.1484L38.8749 23.4484Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_15879_44286">
            <rect
              width="31.9167"
              height="33.7"
              fill="white"
              transform="translate(14.7083 13.1484)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default Polygon