import React, { useCallback, useEffect, useMemo, useState } from "react";
import AppNumericInput from "../widgets/AppNumericInput";
import { AnimatePresence, motion } from "framer-motion";
import SendRadioInput from "../base-components/SendRadioInput";
import { BsChevronLeft } from "react-icons/bs";
import usdc from "../../assets/coins/usdc.png";
import usdt from "../../assets/coins/usdt.png";
import { numberWithCommas } from "../../pages/dashboard/Withdrawals";
import { IoMdClose } from "react-icons/io";
import usd from "../../assets/flags/usd.png";
import gbp from "../../assets/flags/pound.png";
import eur from "../../assets/flags/euro.png";
import CustomInputWithImage from "../widgets/customInput";
import CustomSelectComponent from "../widgets/customSelect";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { useWalletStore } from "../../store/useStableCoin";
import { useConvert, usePreview } from "../../sevices/hooks/wallets";
import { toast } from "sonner";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";

const baseCurrencyOptions = [
  {
    value: "USD",
    label: "USD",
    image: usd,
  },
  {
    value: "GBP",
    label: "GBP",
    image: gbp,
  },
  {
    value: "EUR",
    label: "EUR",
    image: eur,
  },
];

const ConvertModal = ({
  closeModal,
  currency,
  balance,
}: {
  closeModal: any;
  currency: string;
  balance?: any;
}) => {
  const [confirmation, setConfirmation] = useState(false);
  const [swapSuccess, setSwapSuccess] = useState(false);
  const [currencyOpt, setCurrencyOpt] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialPreviewFetched, setInitialPreviewFetched] = useState(false);
  const [isServiceFeeTaken, setIsServiceFeeTaken] = useState(true);
  const [convertAmount, setConvertAmount] = useState<any>();
  const overLayRef = React.useRef(null);
  const [previewDebounceTimeout, setPreviewDebounceTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const [response, setResponse] = useState(null);
  const convert = useConvert(currency);
  const wallet = useWalletStore((state) => state.wallet);

  // Update preview hook to use when currency changes
  const preview = usePreview(currencyOpt.toLowerCase());

  // Only fetch preview data when currency changes

  console.log(preview, "preview");
  const close = () => {
    closeModal(false);
  };

  useEffect(() => {
    const opposingCurrency =
      currency?.toUpperCase() === "USDT" ? "USDC" : "USDT";
    setCurrencyOpt(opposingCurrency);
  }, [currency]);

  const serviceFeePercentage = 0.5;
  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.target === overLayRef.current) {
      closeModal(false);
    }
  };

  const serviceFee = useMemo(() => {
    if (!convertAmount) return "0.00";

    // Remove commas and convert to number
    const amount = parseFloat(convertAmount.replace(/,/g, ""));

    // Calculate service fee
    const fee = amount * (serviceFeePercentage / 100);

    return fee.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [convertAmount]);

  const totalAmount = useMemo(() => {
    if (!convertAmount) return "0.00";

    // Remove commas and convert to number
    const amount = parseFloat(convertAmount.replace(/,/g, ""));

    // Calculate service fee
    const fee = amount * (serviceFeePercentage / 100);

    // If isServiceFeeTaken is true (YES), show the entered amount since fee is deducted from it
    // If isServiceFeeTaken is false (NO), show amount + fee since fee is debited separately
    const total = isServiceFeeTaken ? amount : amount + fee;

    return total.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [convertAmount, isServiceFeeTaken]);

  const insufficientBalanceDetails = useMemo(() => {
    if (!balance || parseFloat(balance) === 0)
      return {
        isInsufficient: true,
        message: "Top up your balance to continue",
      };

    // If no convert amount, no insufficiency
    if (!convertAmount)
      return {
        isInsufficient: false,
        message: "",
      };

    const amount = parseFloat(convertAmount.replace(/,/g, ""));
    const currentBalance = parseFloat(balance);

    const fee = amount * (serviceFeePercentage / 100);
    // Use the same logic as totalAmount
    const totalNeeded = isServiceFeeTaken ? amount : amount + fee;

    return {
      isInsufficient: totalNeeded > currentBalance,
      message:
        totalNeeded > currentBalance
          ? "Insufficient balance for this transaction"
          : "",
    };
  }, [convertAmount, balance, isServiceFeeTaken]);

  const convertedAmount = useMemo(() => {
    if (!convertAmount) return "0.00";

    // Remove commas and convert to number
    const amount = parseFloat(convertAmount.replace(/,/g, ""));

    // Calculate service fee
    const fee = amount * (serviceFeePercentage / 100);

    // If YES: user gets amount - fee (organization takes fee)
    // If NO: user gets full amount (fee taken from balance separately)
    const finalAmount = isServiceFeeTaken ? amount - fee : amount;

    return finalAmount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [convertAmount, isServiceFeeTaken]);

  let prefix;
  switch (currencyOpt) {
    case "USD":
      prefix = "$ ";
      break;
    case "NGN":
      prefix = "₦ ";
      break;
    case "EUR":
      prefix = "€ ";
      break;
    case "GBP":
      prefix = "£ ";
      break;
    case "USDT":
      prefix = "USDT ";
      break;
    case "USDC":
      prefix = "USDC ";
      break;
    default:
      prefix = "";
  }

  const isContinueDisabled = useMemo(() => {
    if (!balance || parseFloat(balance) === 0) return true;

    // If no convert amount, disable
    if (!convertAmount) return true;

    const amount = parseFloat(convertAmount.replace(/,/g, ""));
    const currentBalance = parseFloat(balance);

    const fee = amount * (serviceFeePercentage / 100);
    // Use the same logic as totalAmount
    const totalNeeded = isServiceFeeTaken ? amount : amount + fee;

    return totalNeeded > currentBalance || !currencyOpt;
  }, [convertAmount, balance, currencyOpt, isServiceFeeTaken]);

  const handleChange = (newValue: any, actionMeta: any) => {
    console.log("new value:", newValue);
    setCurrencyOpt(newValue.value);

    console.log("action meta:", actionMeta);
  };
  const confirmationDetails = useMemo(() => {
    // Add more robust checking
    if (!convertAmount || convertAmount === "0") return null;

    try {
      // Safely parse the amount, handling potential comma-separated values
      const amount = parseFloat(convertAmount.replace(/,/g, ""));

      // Ensure we have a valid number
      if (isNaN(amount)) return null;

      const fee = amount * (serviceFeePercentage / 100);
      const totalDebited = isServiceFeeTaken
        ? amount - fee // Deduct fee from entered amount
        : amount + fee; // Add fee to entered amount

      return {
        converting: `${numberWithCommas(amount)} ${currency.toUpperCase()}`,
        charges: `${numberWithCommas(fee)} ${currency.toUpperCase()}`,
        totalDebited: `${numberWithCommas(
          totalAmount
        )} ${currency.toUpperCase()}`,
        receivedAmount: `${numberWithCommas(convertedAmount)} ${currencyOpt}`,
        conversionText: `${numberWithCommas(
          amount
        )} ${currency.toUpperCase()} converts to ${numberWithCommas(
          totalAmount
        )} ${currencyOpt}`,
      };
    } catch (error) {
      console.error("Error calculating confirmation details:", error);
      return null;
    }
  }, [convertAmount, currencyOpt, currency, isServiceFeeTaken]);

  console.log(confirmationDetails, "confirmationDetails");

  // Debounced preview fetch function
  const fetchPreview = useCallback(
    (amount?: number) => {
      if (previewDebounceTimeout) {
        clearTimeout(previewDebounceTimeout);
      }

      if (!currencyOpt || !currency) return;

      const timeout = setTimeout(() => {
        try {
          preview
            .mutateAsync({
              from_currency: currency?.toUpperCase(),
              to_currency: currencyOpt?.toUpperCase(),
              from_amount: amount || 1,
              to_amount: amount || 1,
            })
            .then((res) => {
              //@ts-ignore
              setResponse(res);
            });
        } catch (error) {
          console.error("Error in preview fetch:", error);
        }
      }, 500); // 500ms debounce

      setPreviewDebounceTimeout(timeout);
    },
    [currency, currencyOpt, preview]
  );
  console.log("currency", currency, ".......currencyOpt:", currencyOpt);
  useEffect(() => {
    if (currencyOpt && currency) {
      fetchPreview();
    }
    return () => {
      if (previewDebounceTimeout) {
        clearTimeout(previewDebounceTimeout);
      }
    };
  }, [currencyOpt, currency]);

  useEffect(() => {
    if (!convertAmount) return;

    const amount = parseFloat(convertAmount.replace(/,/g, ""));
    if (!isNaN(amount)) {
      fetchPreview(amount);
    }
  }, [convertAmount, fetchPreview]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (previewDebounceTimeout) {
        clearTimeout(previewDebounceTimeout);
      }
    };
  }, []);

  const initiateConvert = () => {
    //  parse values
    const fromAmount = confirmationDetails?.totalDebited
      ? parseFloat(confirmationDetails.totalDebited.replace(/,/g, ""))
      : null;

    const toAmount = confirmationDetails?.receivedAmount
      ? parseFloat(confirmationDetails.receivedAmount.replace(/,/g, ""))
      : null;

    setLoading(true);
    convert
      .mutateAsync({
        from_currency: currency?.toUpperCase(),
        to_currency: currencyOpt?.toUpperCase(),
        from_amount: fromAmount as number,
        to_amount: toAmount as number,
      })
      .then((res: any) => {
        toast.success("Conversion successfully initiated!");
        setLoading(false);
        setConfirmation(false);
        setSwapSuccess(true);
      })
      .catch((e: any) => {
        toast.error(e?.response?.data);
        setConfirmation(false);
        setLoading(false);
      });
  };

  const closeSucccessModal = () => {
    close();
    setConfirmation(false);
    setSwapSuccess(false);
  };
  const currencyOptions = useMemo(() => {
    const options = [...baseCurrencyOptions];

    // Add USDC option if currency is USDT
    if (currency.toLowerCase() === "usdt") {
      options.unshift({
        value: "USDC",
        label: "USDC",
        image: usdc,
      });
    }

    // Add USDT option if currency is USDC
    if (currency.toLowerCase() === "usdc") {
      options.unshift({
        value: "USDT",
        label: "USDT",
        image: usdt,
      });
    }

    return options;
  }, [currency]);

  return (
    <AnimatePresence>
      <div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              // delay: 0.3,
              duration: 0.1,
            },
          }}
          ref={overLayRef}
          className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
          onClick={handleOverLayClick}
        >
          <motion.div
            initial={{
              x: 600,
              scale: 0,
            }}
            animate={{
              x: 0,
              scale: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              x: 600,
              scale: 0,
              transition: {
                delay: 0.3,
              },
            }}
            className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
          >
            <motion.div
              initial={{
                x: 600,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              }}
              exit={{
                x: 100,
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative xxs:rounded-md md:rounded-l-md  p-5 md:px-7 overflow-auto"
            >
              <div className="flex items-end justify-end ">
                <div
                  onClick={close}
                  className="close  flex items-center justify-center h-10 w-10  rounded-full
                    hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center "
                >
                  <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                    <IoMdClose size={29} className="" />
                  </span>
                </div>
              </div>

              {!confirmation && !swapSuccess && (
                <div>
                  <div className=" flex flex-col gap-3">
                    <h1 className="text-[#071827] text-2xl font-bold self-center ">
                      Convert {currency.toUpperCase()}
                    </h1>
                    <div className="flex flex-col gap-1">
                      <p className="text-[#5C6F7F] text-md  self-center">
                        Current Balance
                      </p>
                      <h2 className="text-[#0EB683] text-3xl font-bold self-center">
                        {numberWithCommas(balance)} {currency.toUpperCase()}
                      </h2>
                    </div>

                    <div className="mid relative mt-8">
                      <span className="text-[#071827] font-bold text-xl ">
                        Convert
                      </span>
                      <CustomInputWithImage
                        maxLength={20}
                        value={convertAmount}
                        onValueChange={(values: { value: any }) => {
                          setConvertAmount(values.value);
                        }}
                        thousandSeparator={true}
                        prefix=" "
                        style={{
                          borderColor: insufficientBalanceDetails.isInsufficient
                            ? "#e01"
                            : "#0EB683",
                        }}
                        labelStyle={{
                          color: "#000",
                          fontWeight: "bold",
                        }}
                        selectedCurrency={currency.toUpperCase()}
                        placeholder={`Enter amount you want to convert`}
                        isAllowed={(nValue: any) => nValue.value.length <= 11}
                      />
                      <span className="text-[#e01] absolute left-2 bottom-10">
                        *
                      </span>
                      {insufficientBalanceDetails.isInsufficient && (
                        <p className="text-[#e01] text-sm mt-1">
                          {insufficientBalanceDetails.message}
                        </p>
                      )}
                    </div>
                    <div className=" h-fit w-fit py-1 px-2 mx-auto my-4  mb-2 rounded-md">
                      <LiaExchangeAltSolid
                        className="rotate-90 font-bold "
                        fill="black"
                      />
                    </div>
                    <div className="mid  relative">
                      <CustomSelectComponent
                        label="To"
                        options={currencyOptions}
                        handleChange={handleChange}
                        defaultValue={currencyOptions[0]}
                        placeholder="Select a currency"
                      />
                      <span className="text-[#e01] absolute left-2 bottom-4">
                        *
                      </span>
                    </div>

                    <div className="mt-8">
                      <p className="text-[#5C6F7F] text-sm">
                        <span className="text-red-500 ">*</span>
                        Should service fee be debited from total amount?
                      </p>
                      <div className="flex my-4">
                        <SendRadioInput
                          label=""
                          getValue={(value: boolean) => {
                            setIsServiceFeeTaken(value);
                          }}
                          isRequired={false}
                          defaultValue={true}
                          positiveText="YES"
                          negativeText="NO"
                          revPos={true}
                        />
                      </div>
                      <div className="space-y-3">
                        <p className="text-[#2c2c2d] text-sm">
                          Service Fee @0.5% ({currency.toUpperCase()})
                        </p>
                        <h1 className="text-[#071827] font-bold text-xl">
                          {serviceFee} ({currency.toUpperCase()})
                        </h1>
                        <h2 className="text-[#071827] text-md flex items-center gap-4">
                          <span>Total Amount Debited:</span>
                          <span className="text-[#0eb683] font-bold ">
                            {totalAmount} ({currency.toUpperCase()})
                          </span>
                        </h2>
                      </div>
                    </div>

                    <AppNumericInput
                      value={convertedAmount}
                      onValueChange={() => {}}
                      disabled={true}
                      thousandSeparator={true}
                      prefix={prefix}
                      style={{
                        borderColor: "#EAEAEA",
                        backgroundColor: "#EAEAEA",
                      }}
                      labelStyle={{
                        color: "#000",
                        fontWeight: "bold",
                      }}
                      label={`Amount you would receive (${currencyOpt})`}
                      placeholder="Amount will be computed"
                      isAllowed={(nValue: any) => nValue.value.length <= 11}
                      infoStyle={{
                        textAlign: "end",
                        fontWeight: "bold",
                        padding: 0,
                      }}
                      isInsufficient={""}
                      insufficientLabel="Insufficient balance"
                    />

                    <button
                      onClick={() => setConfirmation(true)}
                      disabled={isContinueDisabled}
                      className="bg-[#0eb683] text-white p-3 rounded-md transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 mt-4"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              )}

              {confirmation && !swapSuccess && (
                <div>
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-4 absolute top-6">
                      <button
                        className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
                        onClick={() => {
                          setConfirmation(false);
                        }}
                      >
                        <BsChevronLeft />
                      </button>
                      <h1 className="text-lg font-bold text-[#071827] ">
                        Confirmation
                      </h1>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <h1 className="text-[#071827] text-xl font-bold self-center pb-4 ">
                      Conversion Details
                    </h1>

                    <div className="border border-[#0eb683] flex flex-col items-center justify-center w-full h-24 rounded-md">
                      <p className="text-[#5C6F7F] ">Current Balance</p>
                      <div className="flex items-center gap-4">
                        <h1 className="text-3xl text-[#071827] font-bold">
                          {numberWithCommas(balance)} {currency.toUpperCase()}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className=" flex flex-col gap-4 mt-6">
                    <div className="flex justify-between">
                      <h1 className="text-sm text-[#071827] font-medium">
                        You are converting:
                      </h1>
                      <div className="flex items-center gap-1">
                        <p className="text-sm text-[#0eb683] font-bold">
                          {confirmationDetails?.converting}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <h1 className="text-sm text-[#071827] font-medium">
                        Charges:
                      </h1>
                      <div className="flex items-center gap-1">
                        <img
                          src={currency === "usdc" ? usdc : usdt}
                          alt="{currency} "
                          className="w-4 "
                        />
                        <p className="text-sm text-[#0eb683] font-bold">
                          {confirmationDetails?.charges}
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <h1 className="text-sm text-[#071827] font-medium">
                        You would receive:
                      </h1>
                      <div className="flex items-center gap-1">
                        <p className="text-sm text-[#0eb683] font-bold">
                          {confirmationDetails?.receivedAmount}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <h1 className="text-sm text-[#071827] font-medium">
                        Total Debited Amount:
                      </h1>
                      <div className="flex items-center gap-1">
                        <p className="text-sm text-[#0eb683] font-bold">
                          {confirmationDetails?.totalDebited}
                        </p>
                      </div>
                    </div>

                    <p className="text-sm mt-7 text-[#071827] font-semibold text-center">
                      {confirmationDetails?.conversionText}
                    </p>

                    <h3 className="xxs:text-sm md:text-base text-center text-[#242628] md:mt-10">
                      By clicking the button below,
                      <br />I agree my {currency} wallet would be debited
                      <br /> and converted to {currencyOpt} immediately
                    </h3>

                    <button
                      onClick={() => {
                        // setSwapSuccess(true);
                        // setShowSwapConfirmation(false);
                        initiateConvert();
                      }}
                      className="bg-[#0eb683]  text-white p-3 rounded-xl transition-all active:scale-90 "
                    >
                      {loading ? "loading..." : "Confirm"}
                    </button>
                  </div>
                </div>
              )}

              {swapSuccess && (
                <div className=" flex items-center flex-col justify-center h-full gap-3">
                  <div className="icon self-center mb-4">
                    <SuccessMobile width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center mb-3 text-center">
                    Convert {currency.toUpperCase()} Order Placed!
                  </h1>
                  <p className="text-sm text-[#5C6F7F] self-center text-center">
                    Your {currency.toUpperCase()} to {currencyOpt.toUpperCase()}{" "}
                    conversion is now complete. <br />
                    Please check your {currencyOpt.toUpperCase()} balance
                  </p>

                  <button
                    onClick={closeSucccessModal}
                    className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90 mt-6"
                  >
                    Done
                  </button>
                </div>
              )}
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default ConvertModal;
