import React, { useMemo } from "react";

import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import EmptyState from "../../../components/base-components/EmptyState";
import {
  useAccActivity,
  useWalletAccount,
} from "../../../sevices/hooks/wallets";

import StableWalletCard from "../../../components/wallet-components/stable-coin-wallet-card";
import { Infor } from "./UsdcWallet";
import WalletAccountTable from "../../../components/wallet-components/WalletAccountTable";
import {
  useGetSingleFee,
  useGetSingleWallet,
  useGetWalletDetails,
} from "../../../sevices/hooks/users";
import { extractBalances } from "../../../helper";

const UsdtWallet = ({ currency = "usdt" }: { currency?: string }) => {
  const { data: walletdetails, error, isLoading } = useGetWalletDetails();
  const { data: fees } = useGetSingleFee("usdt");
  // const userActivities = useAccActivity();
  const wall = useGetSingleWallet("usdt");
  const navigate = useNavigate();
  // console.log(fees, "fees");
  // console.log(wall, "wall");
  //usdt wallet
  // const usdtAcc = useWalletAccount("usdt");
  // const usdtRates = useUsdtRates();

  // const usdtTransactions = useMemo(
  //   () =>
  //     userActivities?.data?.data?.data?.filter(
  //       (transaction: { currency: string }) =>
  //         transaction?.currency?.toUpperCase() === "USDT"
  //     ),
  //   [userActivities?.data?.data?.data]
  // );

  if (isLoading) {
    return <div>Loading wallet details...</div>;
  }

  //  if (error) {
  //    console.error("Error fetching wallet details:", error);
  //    return <div>Error loading wallet details. Please try again later.</div>;
  //  }

  const details = walletdetails?.data?.data;

  if (!details) {
    // console.error("Wallet details are undefined or null");
    return <div>No wallet details available.</div>;
  }

  const usdtDetails = extractBalances(details, "USDT");
  // console.log("usdt Details:", usdtDetails);

  return (
    <div className="bg-[#f7fbfb] h-full w-full md:pt-10 md:px-8 ">
      <div className="flex items-center gap-24 md:gap-4 bg-white md:bg-[#f7fbfb] px-5 py-10 md:py-0 md:px-0 ">
        <button
          className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
          onClick={() => {
            navigate(-1);
          }}
        >
          <BsChevronLeft size={24} />
        </button>
        <p className="text-[#071827] font-semibold">
          {currency.toUpperCase()} Wallet
        </p>
      </div>
      {/* <div className="py-6">
        <StableWalletCard data={usdtDetails} />
      </div> */}

      <div className="">
        {/* <Infor currency={currency} /> */}
      </div>

      {/* <div className="mt-10">
        {usdtTransactions?.length > 0 ? (
          <WalletAccountTable
            itemsPerPage={10}
            data={usdtTransactions}
            currency={currency}
          />
        ) : (
          <EmptyState />
        )}
      </div> */}
    </div>
  );
};

export default UsdtWallet;
