import React, { useMemo, useState } from "react";
import { Tooltip } from "../base-components/Tooltip";
import { Transaction } from "../../data/misc/mock";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useNavigate } from "react-router-dom";
import Pagination from "../transaction-component/Pagination";
// import { Container } from "../transaction-component/TransactionTable";
import GlobalFilter from "../transaction-component/GlobalFilter";
import { FaMinus, FaPlus } from "react-icons/fa";
import { TbRefresh } from "react-icons/tb";
import { GoArrowUpRight } from "react-icons/go";

export const dColumns: readonly Column<Transaction>[] = [
  {
    Header: "Transaction Type",
    accessor: "transactionType",
    Cell: ({ row }) => {
      const { transactionType, amount } = row.original;

      const getIcon = (type: string) => {
        switch (type) {
          case "Received":
            return <FaPlus className="text-[#0eb683]" />;
          case "Transferred":
            return <FaMinus className="text-red-500" />;
          case "Converted to NGN":
            return <TbRefresh className="text-[#0eb683]" />;
          default:
            return null;
        }
      };

      const getBgColor = (type: string) => {
        switch (type) {
          case "Received":
            return "bg-[#e2fef0]";
          case "Transferred":
            return "bg-[#ffe5e5]";
          case "Converted to NGN":
            return "bg-[#e2fef0]";
          default:
            return "bg-transparent";
        }
      };

      return (
        <div className="flex items-center space-x-2">
          <span
            className={`inline-block p-3 rounded-full ${getBgColor(
              transactionType
            )}`}
          >
            {getIcon(transactionType)}
          </span>
          <div className="flex flex-col">
            <span className="text-[#5C6F7F] font-light">{transactionType}</span>
            <span className="font-semibold text-lg">{amount}</span>
          </div>
        </div>
      );
    },
  },

  {
    Header: "Date",
    accessor: "date",
    Cell: ({ value }: { value: string }) => (
      <div>
        <span className="text-[#5C6F7F]">{value}</span>
      </div>
    ),
  },
  {
    Header: "Chain",
    accessor: "chain",

    Cell: ({ value }: { value: string }) => (
      <div className="">
        <span className="flex items-center whitespace-nowrap">{value}</span>
      </div>
    ),
  },
  {
    Header: "Transaction Reference",
    accessor: "transactionReference",
    Cell: ({ value }: { value: string }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip message={value}>
          <a
            className="truncate hover:underline cursor-pointer text-[#0eb683]"
            style={{ marginRight: "0.5rem" }}
          >
            {value.slice(0, 20)}
          </a>
        </Tooltip>
      </div>
    ),
  },

  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      const status = String(value);
      const { bg, color } = colorMap[status] || {};

      const stats = (status: string) => {
        return status.replace(/(^|\s|-)(\w)/g, (match, p1, p2) => {
          return p1 + p2.toUpperCase();
        });
      };

      return (
        <span
          className="inline-block px-3 py-1 rounded-full text-sm font-bold"
          style={{ backgroundColor: bg, color }}
        >
          {stats(status)}
        </span>
      );
    },
  },
];

interface ColorMap {
  [key: string]: { bg: string; color: string };
}

const colorMap: ColorMap = {
  Initiated: { bg: "#e5e5e5", color: "#5C6F7F" },
  "In-progress": { bg: "#fff2eb", color: "#FF7F36" },
  Completed: { bg: "#e2fef0", color: "#0EB683" },
  Failed: { bg: "#f8b0b0", color: "#f51505" },
  Cancelled: { bg: "#f51505", color: "#e2fef0" },
};

const StableCoinTable = ({ tableData }: { tableData: Transaction[] }) => {
  const columns = useMemo(() => dColumns, []);
  const data = useMemo(() => tableData, [tableData]);
  const navigate = useNavigate();

  const table = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    state,
    setGlobalFilter,
    prepareRow,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
    previousPage,
    pageOptions,
    canNextPage,
    canPreviousPage,
    footerGroups,
  } = table;

  const { globalFilter, pageIndex, pageSize } = state;
  return (
    <div>
      <div className="  flex flex-col bg-white mb-8 overflow-y-auto">
        <div className="overflow-x-auto sm:-mx-0 ">
          <div className="inline-block md:min-w-full xxs:w-full sm:px-6 lg:px-8 ">
            <div className="flex items-center gap-3 ">
              <div className="w-[100%]">
                <GlobalFilter
                  setFilter={setGlobalFilter}
                  filter={globalFilter}
                />
              </div>
            </div>
            <div className="overflow-x-auto xxs:hidden md:block">
              <table
                {...getTableProps()}
                className="appearance-none bg-white min-w-full  mb-6 "
                id="my-table"
              >
                <thead className="bg-[#fff] appearance-none">
                  {headerGroups.map(
                    (headerGroup: {
                      getHeaderGroupProps: () => {
                        [x: string]: any;
                        key: any;
                      };
                      headers: any[];
                    }) => {
                      const { key, ...restHeaderProps } =
                        headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key} {...restHeaderProps}>
                          {headerGroup.headers.map((column) => (
                            <th
                              className="font-normal text-sm text-primary py-4 text-left whitespace-nowrap px-4 border-r border-slate-50"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              key={column.id}
                            >
                              <div className="flex items-center">
                                <span className="text-[#5C6F7F] font-bold text-sm">
                                  {column.render("Header")}
                                </span>

                                {column.canSort === true && (
                                  <span className="ml-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10"
                                      height="12"
                                      viewBox="0 0 10 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.00016 2.83L8.17016 6L9.58016 4.59L5.00016 0L0.410156 4.59L1.83016 6L5.00016 2.83ZM5.00016 15.17L1.83016 12L0.420156 13.41L5.00016 18L9.59016 13.41L8.17016 12L5.00016 15.17Z"
                                        fill="#323232"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </th>
                          ))}
                        </tr>
                      );
                    }
                  )}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="mt-3 pt-3 w-full space-y-8 border-r border-slate-50"
                >
                  {page.map(
                    (row: {
                      getRowProps: () => JSX.IntrinsicAttributes &
                        React.ClassAttributes<HTMLTableRowElement> &
                        React.HTMLAttributes<HTMLTableRowElement>;
                      cells: any[];
                    }) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          className="appearance-none my-4 border border-slate-50 even:bg-white odd:bg-slate-100"
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="font-normal text-sm text-[#202223] py-4 px-4 border-r border-slate-50 text-left"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>

              {/* <Pagination
                // gotoPage={gotoPage}
                length={data.length}
                pageSize={pageSize}
                pageCount={pageCount}
                setPageSize={setPageSize}
              /> */}
            </div>
            <div className="xxs:block md:hidden w-full">
              <MobileView data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StableCoinTable;

interface IData {
  data: Transaction[];
}
export const MobileView = ({ data }: IData) => {
  let currentPage = 1;
  const [currentPageIndex, setCurrentPageIndex] = useState(currentPage);
  function chunkArray<T>(array: T[], size: number) {
    let result = [];
    for (let i = 0; i < array?.length; i += size) {
      let chunk = array?.slice(i, i + size);
      result.push(chunk);
    }
    return result;
  }

  return (
    <>
      {data ? (
        chunkArray(data, 5)[currentPageIndex - 1]?.map((trx, index) => {
          const transactionType = (type: string) => {
            let icon;

            switch (type) {
              case "Transferred":
                icon = <FaMinus color={"#FA0204"} />;
                break;
              case "Received":
                icon = <FaPlus color={"#0EB683"} />;
                break;
              default:
                icon = <TbRefresh color={"#0eb683"} />;
                break;
            }

            return icon;
          };
          const icon = transactionType(trx?.transactionType);

          const narration = (nar: string) => {
            return <span className=" truncate w-24 ">{nar}</span>;
          };
          const amount = trx?.amount;

          const currencySymbol = (currency: string, amount: string) => {
            let currencySymbol = "";

            switch (currency?.toUpperCase()) {
              case "NGN":
                currencySymbol = "₦ ";
                break;
              case "USD":
                currencySymbol = "$ ";
                break;
              default:
                break;
            }

            return `${currencySymbol}${amount}`;
          };
          //   const amount = currencySymbol(trx?.currency, amountF);
          //   const narrations = narration(trx?.narration);

          //   const myDate = date;
          //   const formatDate = date);
          const Cdate = trx?.date;

          //   const currency = trx?.currency;

          const mapping = (value: string) => {
            const status = value;
            //@ts-ignore
            const { bg, color } = colorMap[status] || {};

            const stats = (status: string) => {
              return status.replace(/(^|\s|-)(\w)/g, (match, p1, p2) => {
                return p1 + p2.toUpperCase();
              });
            };

            return (
              <span
                className="inline-block px-3 py-0.5 rounded-full text-xs  mt-2"
                style={{ backgroundColor: bg, color }}
              >
                {stats(status)}
              </span>
            );
          };
          const color = mapping(trx?.status);
          return (
            <div key={index + "1"} className="">
              <div className="  py-4 px-2 bg-[#fff] w-full border-y border-slate-100 flex items-center ">
                <div className="flex-1 mr-2">
                  <div
                    className={` h-[2rem] w-[2rem] border rounded-full flex items-center justify-center ${
                      trx?.transactionType === "Transferred"
                        ? "bg-[#FEECE2] border-[#FEECE2]"
                        : "bg-[#E2FEF0] border-[#E2FEF0]"
                    }`}
                  >
                    {icon}
                  </div>
                </div>
                <div className="flex-[5]">
                  <div className="flex items-center justify-between space-y-2 ">
                    <span className="text-[#5C6F7F] text-sm">
                      {trx?.transactionType}
                    </span>
                    <span className="text-[#5C6F7F] text-sm font-light">
                      {Cdate}
                    </span>
                  </div>

                  <div className="flex items-center justify-between space-x-2 ">
                    <span className="text-[#071827] mt-2 font-bold">
                      {amount}
                    </span>
                    <div className="flex flex-col items-end">
                      {/* <div className="flex ">
                        <span>{color}</span>
                      </div> */}
                      <div className="flex items-center text-[#0eb683] mt-2">
                        <GoArrowUpRight />
                        <span className="flex items-center gap-1  text-sm ">
                          View
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div>Loading</div>
      )}
    </>
  );
};
