import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { FiBell, FiSearch } from "react-icons/fi";
import { MdOutlineDateRange } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useUsers } from "../../sevices/hooks/users";
import Button from "../base-components/Button";
import { Toaster } from "sonner";

type HeaderProps = {
  requiresLayout: boolean;
  open: boolean;
  setOpen: any;
  showProfileDrop: boolean;
  setShowProfileDrop: React.Dispatch<React.SetStateAction<boolean>>;
};

const Nav = ({
  requiresLayout,
  setOpen,
  open,
  setShowProfileDrop,
  showProfileDrop,
}: HeaderProps) => {
  const [imagedata, setImageData] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  // const [userDetails] = useOutletContext<any>();

  //function to extract images
  const handleImage = (evt: any) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", (arg: any) => {
      setImageData(arg.target.result);
    });
    reader.readAsDataURL(evt.target.files[0]);
  };

  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );

  //submit function
  // const handleSubmit = (evt: any) => {
  //   evt.preventDefault()
  //   if(!imagedata) {
  //     return
  //     let newImage ={
  //       picture{
  //         medium:
  //       }
  //     }
  //   }
  // }

  return (
    <div>
      {/* {showSearch ? (
        <SearchBar setShowSearch={setShowSearch} />
      ) : ( */}

      <nav className="sticky top-0 left-0 right-0 bg-white p-2 px-[4%] w-full text-[#2E4457] border-b items-center justify-end pr-5 hidden md:block">
        <Toaster position="top-right" richColors />
        <div className="flex items-center  gap-2 justify-end relative">
          <div className="div flex gap-3 cursor-pointer border-r-2 border-l-gray-300 pr-3">
            <div
              onClick={() => {
                setOpen(!open);
              }}
              className="search-area flex items-center p-2 rounded-full gap-2  bg-[#F7FBFB]"
            >
              <FiSearch size={20} />
              {/* <input type="text" placeholder='Search...' className='bg-transparent outline-none hidden md:inline-block' /> */}
            </div>
            <div className="date_area flex items-center bg-[#F7FBFB] px-3 rounded-xl gap-1">
              <MdOutlineDateRange />
              <span className="font-medium">{new Date().toDateString()}</span>
            </div>
            <div className="flex items-center p-2 bg-[#F7FBFB] rounded-full mr-3 ">
              <FiBell size={20} className="  " />
            </div>
          </div>
          <div className="border border-gray-300 h-9 w-9 rounded-full flex  justify-center mr-4 ml-3 ">
            <figure className=" bg-gray-100 h-6 w-6 rounded-full self-center relative flex">
              <div
                className="w-full h-full rounded-full text-gray-300 flex items-center justify-center cursor-pointer absolute top-0 left-0 transition-all active:scale-95"
                onClick={() => setShowProfileDrop(!showProfileDrop)}
              >
                <FaUserAlt size={17} />
              </div>
            </figure>
          </div>
        </div>
      </nav>
      {/* )} */}
    </div>
  );
};

export default Nav;

const SearchBar = ({
  setShowSearch,
}: {
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [searchValue, setSearchValue] = useState("");
  const router = useParams();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchValue) {
      const value = searchValue.replaceAll(" ", "+");
      // router.push(`/search?q=${value.toLocaleLowerCase()}`);
    }
  };

  return (
    <div className="bg-white top-0 h-14 flex  justify-between items-center shadow-md fixed w-full px-3 z-10">
      <form className="min-w-full" onSubmit={(e) => onSubmit(e)}>
        <div className="flex items-center min-w-full">
          <label className="relative block my-8 w-[90%] mx-2">
            <span className="sr-only">Search</span>
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <input
              autoFocus
              className={`placeholder:text-slate-400 block bg-white w-full border-slate-300  py-4 pl-9 pr-3 shadow-sm focus:outline-none focus:border-primary focus:ring-1 sm:text-sm disabled:opacity-10 h-full`}
              placeholder="Search"
              type="text"
              name="search"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </label>
          <div className="flex h-10 items-center space-x-2">
            <Button type="submit">Go</Button>
            <Button>
              <AiOutlineClose
                onClick={() => setShowSearch(false)}
                size="25px"
              />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
