
import { useState, useEffect, useMemo } from "react";
import OnboardingFooter from "../../components/base-components/OnboardingFooter";
import AppSelectInput from "../../components/base-components/AppSelectInput";
import Input from "./comp/Input";
import {
  useAccount,
  useBankList,
  useResolveBankName,
  useUpdateUserOnboardingInfo,
} from "../../sevices/hooks/users";
import LoadingScreen from "../Notifications/LoadingScreen2";
export type IBank = {
  anchorId: string;
  bankName: string;
  cbnCode: string;
  createdAt: string;
  deletedAt: null | string;
  id: string;
  nipCode: string;
  updatedAt: string;
};

const ChooseBank = ({
  getDetails,
  backFn,
  nextFn,
  currentFlow,
  userDetail,
}: {
  getDetails: (data: any, isValid: boolean) => void;
  backFn: () => void;
  nextFn: () => void;
  currentFlow: string;
  userDetail: any;
}) => {
  const [bank, setBank] = useState<{
    label?: string;
    value?: string;
    bank?: IBank;
  }>({});

  const { accountType, id, userID, selfieData } = userDetail;
  const [loading, setLoading] = useState(false);
  const getBanks = useBankList();
  const [accountNumber, setAccountNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankId, setBankId] = useState("");
  const [accountName, setAccountName] = useState("");
  const resolveName = useResolveBankName();
  const [loadingMsg, setLoadingMsg] = useState("Validating your details...");
  const updateInfo = useUpdateUserOnboardingInfo(userID || id);
  const accountSetup = useAccount();
  const [errorMessages, setErrorMessages] = useState({
    accountNumber: "",
    bank: {},
  });

  // console.log(userDetail, "userDetail");

  useEffect(() => {
    setLoadingMsg(loadingMsg);
    updateInfo
      .mutateAsync({ images: selfieData })
      .then((res) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    accountSetup
      .mutateAsync({
        userId: id,
        enable: true,
        canWithdraw: true,
        canDeposit: true,
      })
      .then((res) => {})
      .catch((err) => {});
  }, []);

  const isValid = () => {
    const errors = {
      accountNumber: "",
      bank: {},
    };
    if (!accountNumber?.trim()) {
      errors.accountNumber = "Account Number is required";
    }
    if (!bank) {
      //@ts-ignore
      errors.bank = "Bank is required";
    }
    setErrorMessages(errors);

    return Object.values(errors).every((error) => error === "");
  };

  const bankOptions = useMemo(
    () =>
      getBanks?.data?.data?.map((bank: IBank) => ({
        label: bank.bankName,
        value: bank.nipCode,
        bank,
      })),
    [getBanks.data]
  );

  useEffect(() => {
    if (accountNumber.length === 10 && bank?.bank) {
      const id = bank.bank?.nipCode || "";
      setBankCode(bank.bank?.nipCode);
      setBankId(bank.bank?.anchorId);
      resolveName
        .mutateAsync({
          bankCode: id,
          accountNumber: accountNumber,
        })
        .then((data: any) => {
          setAccountName(data?.data?.data?.attributes?.accountName);
          // setBankCode()
          // setBankId()
          setErrorMessage("");
        })
        .catch((error: any) => {
          setErrorMessage("Invalid account details");
        });
    }
  }, [accountNumber, bank]);

  useEffect(() => {
    getDetails(
      {
        bank: bank?.bank?.bankName,
        accountNumber,
        accountName,
        bankId,
        bankCode,
      },
      true
    );
  }, [accountName, accountNumber, bank, bankId, bankCode]);

  const handleSubmitBank = () => {
    if (accountType !== "private") {
      nextFn();
    } else {
      setLoadingMsg("Verifying details, Please wait..");
      setLoading(true);
      accountName &&
        updateInfo
          .mutateAsync({
            userBankName: bank?.bank?.bankName,
            userAccountNumber: accountNumber,
            // accountName,
            userBankId: bankId,
            userBankCode: bankCode,
          })
          .then((res) => {
            nextFn();
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
    }
  };

  const handleAccountNumber = (newValue: string) => {
    setAccountNumber(newValue);
  };

  return (
    <LoadingScreen showLoading={loading} msg={loadingMsg} bg="#f2faf799">
      <div className=" py-10 xxs:px-4  md:px-6">
        <div className="text w-full ">
          <h1 className="text-[24px] leading-[36px] font-bold pb-1 text-[#071827]">
            Bank Details
          </h1>
          <p className=" text-[#5C6F7F] text-[16px] font-normal leading-[24px]">
            This is where we would remit commissions earned from your referee’s
            transactions:
          </p>
          {currentFlow !== "private" && (
            <p className="pb-4 text-[#5C6F7F] text-[16px] font-normal leading-[24px]">
              Please enter account details associated with this business
            </p>
          )}
        </div>

        <div className=" bg-none md:bg-white xxs:p-0 md:p-7 flex flex-col gap-5 rounded-md mt-5 md:max-w-lg">
          <div className="relative w-full">
            <AppSelectInput
              options={bankOptions || []}
              label=""
              handleChange={(bank: any) => setBank(bank)}
            />
            {/* <div className="text-red-500 text-xs">{errorMessages.bank}</div> */}
            <span className="text-red-600 absolute left-3 top-3">*</span>
          </div>
          <div className="relative w-full">
            <Input
              label="Account Number"
              type="text"
              pattern="\d*"
              name="accountNumber"
              required={true}
              // errorMessages={errorMessages}
              maxChar={10}
              onChange={handleAccountNumber}
            />
            {/* <span className="text-red-600 absolute left-3 top-4">*</span> */}
            <div className="text-red-500 text-xs">
              {errorMessages.accountNumber}
            </div>
          </div>
          <div className="text-md font-bold ">
            {resolveName.isLoading ? (
              <TypingDots />
            ) : accountName && accountNumber.length === 10 ? (
              <p className="text-md font-bold text-[#0eb683]">{accountName}</p>
            ) : (
              errorMessage
            )}
          </div>
        </div>
        <OnboardingFooter
          backFn={backFn}
          nextFn={handleSubmitBank}
          // nextDisabled={bankStepValid === false}
          nextDisabled={!accountName}
        />
      </div>
    </LoadingScreen>
  );
};

export default ChooseBank;

export const TypingDots = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots === "..." ? "" : prevDots + "."));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <p className="text-md font-bold text-[#0eb683]">Validating details{dots}</p>
  );
};
