import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdClose, IoIosArrowBack } from "react-icons/io";
import AppNumericInput from "../widgets/AppNumericInput";
import Notification from "./Notification";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../base-components/Button";
import { BsChevronLeft } from "react-icons/bs";
import { IFundProps } from "../../types/Fund";
import ReactLoading from "react-loading";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import { Tooltip } from "../base-components/Tooltip";
const FundModal: React.FC<IFundProps> = ({
  fundModal,
  confirm,
  loading,
  success,
  value,
  currency,
  valueFormatted,
  setConfirm,
  setSuccess,
  setValue,
  setFund,
}) => {
  const overLayRef = React.useRef(null);
  const closeModal = () => {
    setFund(false);
    setValue("");
    setConfirm(false);
    setSuccess(false);
  };
  const handleOverLayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.target === overLayRef.current && closeModal();
  };

  return (
    <div>
      <div className="FundModal">
        <AnimatePresence>
          {fundModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              onClick={handleOverLayClick}
              className="modal  fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                ref={overLayRef}
                className="modal_overlay  flex xxs:justify-center xxs:p-5 md:p-0  md:justify-end h-full cursor-pointer bg-[#13111173]"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="flex flex-col bg-white  w-full max-w-sm md:max-w-sm max-h-full  md:h-full relative    p-5 md:px-7 overflow-auto "
                >
                  {!success ? (
                    <>
                      <div className="flex items-end justify-end ">
                        <Tooltip message="close">
                          <div
                            onClick={closeModal}
                            className="close  flex items-center justify-center h-10 w-10 hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center      rounded-full"
                          >
                            <span className="hover:rotate-90 hover:transform  transition duration-150 ease-in-out">
                              <IoMdClose size={27} />
                            </span>
                          </div>
                        </Tooltip>
                      </div>

                      {confirm ? (
                        <>
                          <div className=" ">
                            <h1 className="font-bold text-lg text-[#071827]">
                              Fund your Account
                            </h1>
                            <p className="text-[#5C6F7F] text-sm pb-4 tracking-wide">
                              Fund your NGN balance via direct bank transfer
                            </p>
                          </div>
                          {/* <div className="">
                            <AppNumericInput
                              value={valueFormatted}
                              onValueChange={(value: any) => {
                                setValue(value);
                              }}
                              thousandSeparator={true}
                              prefix="₦ "
                              style={{ borderColor: "#0EB683" }}
                              labelStyle={{
                                color: "#071827",
                                fontWeight: "semibold",
                                // margin: "10px 0px",
                              }}
                              label="Amount you wish to transfer (NGN)"
                              placeholder="Enter the NGN amount"
                              isAllowed={(nValue: any) =>
                                nValue.value.length <= 11
                              }
                            />
                          </div> */}
                          <div className="xxs:pt-3 mb-6">
                            <Notification
                              info={"To fund your Naira balance, transfer to:"}
                            />
                          </div>
                          <button
                            // disabled={!value}
                            onClick={(e) => {
                              e.preventDefault();
                              // setFundModal(false);
                              setConfirm(true);
                            }}
                            className="bg-[#0eb683] rounded-[16px] px-10 py-3 transition-all active:scale-90  text-white disabled:bg-[#8fc0ad] disabled:cursor-not-allowed   "
                          >
                            Continue
                          </button>

                          {/* <Button
                            clasName=""
                            disable={false}
                            onClick={() => {
                              setConfirm(true);
                              // onStart();
                            }}
                          >
                            Continue
                          </Button> */}
                        </>
                      ) : (
                        <div>
                          <div className="flex gap-3 items-center pb-2 absolute top-6">
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                setConfirm(false);
                                // setFundModal(true);
                              }}
                              className="justify-center items-center bg-[#ECF6F3] p-2 h-10 w-10 rounded-full cursor-pointer transition-all active:scale-90 overflow-hidden flex"
                            >
                              <IoIosArrowBack size={27} />
                            </div>

                            <h1 className="font-bold text-xl text-[#071827]">
                              Account Details
                            </h1>
                          </div>
                          <p className="text-sm text-[#5C6F7F] mt-7">
                            Fund your NGN balance via direct bank transfer
                          </p>
                          <div className="flex items-center justify-between mt-4">
                            <h1 className="text-[#071827] font-bold ">
                              You are transferring to the account information
                              below:
                            </h1>
                            <p className="text-[#0EB683] font-bold text-xl">
                              {valueFormatted?.replace("₦ ", "NGN ")}
                            </p>
                          </div>
                          <div className="py-5 ">
                            <Notification
                              text={
                                "Once you have transferred the amount, click confirm below"
                              }
                              info={"To:"}
                            />
                          </div>

                          <div
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   // setShowConfirmationModal(false);
                            //   setSuccess(true);
                            // }}\
                            onClick={closeModal}
                            className="flex  justify-between flex-col "
                          >
                            <button className="border border-[#0eb683] text-[#0eb683] md:p-3 rounded-[16px]  cursor-pointer transition-all active:scale-90  py-2 px-8 hover:bg-[#0eb683] hover:text-white ">
                              {loading ? (
                                <div className="flex items-center justify-center">
                                  <ReactLoading
                                    type="spinningBubbles"
                                    color="#fff"
                                    height="27px"
                                    width="27px"
                                  />
                                </div>
                              ) : (
                                ` Confirm`
                              )}
                            </button>
                          </div>

                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex items-center justify-center flex-col h-full gap-3">
                      <>
                        <div className="icon self-center">
                          <SuccessMobile width={"200px"} height={"200px"} />
                        </div>
                        <h1 className="text-3xl font-bold text-[#071827] self-center">
                          Wallet Funded
                        </h1>

                        <p className="text-sm text-[#5C6F7F] self-center">
                          Your {currency.toUpperCase()} wallet has been
                          successfully funded
                        </p>

                        <button
                          onClick={closeModal}
                          className="bg-[#0eb683] text-white rounded-[16px] px-28 py-2 self-center transition-all active:scale-90 "
                        >
                          Done
                        </button>
                      </>
                    </div>
                  )}
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default FundModal;
