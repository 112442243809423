import React, { useContext, useMemo, useState } from "react";
import { BiRefresh } from "react-icons/bi";
import { BsChevronLeft } from "react-icons/bs";
import { Link, useNavigate, useOutletContext } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import SuccessMobile from "../../../assets/iconsComponents/SuccessMobile";

import Notification from "../../../components/dashboard-components/Notification";
import { AiOutlineClose } from "react-icons/ai";

import EmptyState from "../../../components/base-components/EmptyState";
// import { useRatesEur } from "../../../sevices/hooks/tags";
import FiatWalletCard from "../../../components/dashboard-components/FiatWalletCard";
import SwapModal from "../../../components/dashboard-components/SwapModal";
import { useAccActivity } from "../../../sevices/hooks/wallets";

import FundModal from "../../../components/dashboard-components/FundModal";
import { useRatesStore } from "../../../store/useRateStore";
import WalletAccountTable from "../../../components/wallet-components/WalletAccountTable";
import { numberWithCommas } from "../Withdrawals";
import TabContext from "../../../store/context/TabProvider";

const EurWallet = ({ currency = "eur" }: { currency?: string }) => {
  const userActivities = useAccActivity();
  const navigate = useNavigate();
  const rates = useRatesStore((state) => state.rates);
  const [userDetails] = useOutletContext<any>();
  const accountEur = numberWithCommas(userDetails?.balances?.eurBalance);
  const total = userActivities?.data?.data?.total
  const eurTransactions = useMemo(
    () =>
      userActivities?.data?.data?.data?.filter(
        (transaction: { currency: string }) =>
          transaction.currency.toUpperCase() === "EUR"
      ),
    [userActivities?.data?.data]
  );

  const ngnBalance = userDetails?.balances?.ngnBalance;
  // console.log(userDetails, "userDetails");

  //hooks for EUR operations
  const [showBuyEur, setShowBuyEur] = useState(false);

  const [amountValue, setAmountValue] = useState<any>();
  //@ts-ignore
  const { setTab, tab } = useContext(TabContext);
  const [confirmSellEur, setConfirmSellEur] = useState(false);
  const [eurSellSuccess, setEurSellSuccess] = useState(false);
  const [eurAmountValue, setEurAmountValue] = useState<any>();
  const [NGNAmountValue, setNGNAmountValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const fundNgn = () => setShowModal((prev) => !prev);
  //fund
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const eurRate = rates.EUR;

  const buyRate = eurRate?.buyRate;
  const sellRate = eurRate?.sellRate;

  function commas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const EurErr =
    tab === "buy"
      ? `Minimum amount is NGN ${commas(Number(buyRate) * Number(500))} `
      : `Minimum amount is USD ${commas(
          (Number(sellRate) * Number(10)) / Number(sellRate)
        )} `;

  return (
    <div className="md:bg-[#f7fbfb] h-full w-full px-5 py-5 md:p-5 md:pl-8">
      <div className="flex items-center gap-24 md:gap-4">
        <button
          className=" rounded-full bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer "
          onClick={() => {
            navigate(-1);
          }}
        >
          <BsChevronLeft />
        </button>
        <p className="font-semibold">EUR Wallet</p>
      </div>
      <div className="box bg-white flex flex-col md:p-5 mt-7 rounded-lg">
        <div className="flex flex-col items-start gap-3 mb-5 md:flex-row md:justify-between">
          <div className="w-full md:w-fit">
            <FiatWalletCard
              text={"EUR Balance"}
              currency={"€ "}
              icons={<BiRefresh />}
              amount={userDetails?.balances?.eurBalance}
            />
          </div>
          <div className="flex flex-col md:items-end">
            <div className="button flex gap-4 md:items-end ">
              <button
                onClick={() => {
                  setShowBuyEur(true);
                }}
                className="border border-[#0eb683] text-center  text-[#0eb683] w-full p-3 md:w-40 whitespace-nowrap bg-white rounded-[16px] transition-all active:scale-90 cursor-pointer disabled:cursor-not-allowed"
              >
                Buy | Sell EUR
              </button>
              <Link
                to={"/dashboard/wallet/transfer/eur"}
                className="bg-[#0eb683] w-full md:w-64 p-3 text-center border border-[#0eb683] text-white rounded-[16px] transition-all active:scale-90 cursor-pointer self-end"
              >
                Transfer FCY
              </Link>
            </div>
            {showBuyEur && (
              <SwapModal
                baseCurrency={"eur"}
                baseCurrencySymbol={"€ "}
                currency={"ngn"}
                currencySymbol={"₦ "}
                rate={buyRate}
                sellRate={sellRate}
                confirmation={confirmSellEur}
                setConfirmation={setConfirmSellEur}
                inputValue={
                  tab === "buy" ? NGNAmountValue?.value : eurAmountValue?.value
                }
                inputValueFormatted={NGNAmountValue?.formattedValue}
                inputValueFloat={NGNAmountValue?.floatValue}
                setInputValue={setNGNAmountValue}
                openModal={showBuyEur}
                loading={loading}
                setLoading={setLoading}
                setOpenModal={setShowBuyEur}
                balance={ngnBalance}
                baseBalance={accountEur}
                computedValue={
                  tab === "buy" ? eurAmountValue?.value : NGNAmountValue?.value
                }
                computedValueFloat={eurAmountValue?.floatValue}
                computedValueFormatted={eurAmountValue?.formattedValue}
                setComputedValue={setEurAmountValue}
                errorMessage={EurErr}
                success={eurSellSuccess}
                setSuccess={setEurSellSuccess}
                // initiateBuy={() => {}}
                setFundModal={() => {
                  setShowModal(true);
                }}
                // refetchRates={refetch}
              />
            )}

            <div className=" md:text-right mt-8">
              <p className="text-sm md:hidden text-[#5C6F7F]">
                Securely make transfers to permitted regions with a fee of a
                0.25% + &#8364;50 Offshore Charge
                <br />
                Transfers typically typically take 3 business days All funds are
                secured
              </p>

              <p className="text-sm hidden md:flex items-end flex-col  font-medium text-[#5C6F7F] md:text-right">
                Securely make transfers to permitted regions with a fee of a
                0.25% + &#8364;50 Offshore Charge
                <br />
                <span className="md:text-right">
                  {" "}
                  Transfers typically typically take 3 business days
                </span>{" "}
                <span className=""> All funds are secured</span>
              </p>
            </div>
          </div>
        </div>

        {showModal && (
          <FundModal
            fundModal={showModal}
            confirm={showConfirmationModal}
            setConfirm={setShowConfirmationModal}
            loading={loading}
            success={showSuccess}
            setValue={setAmountValue}
            setSuccess={setShowSuccess}
            value={amountValue?.value}
            currency={"ngn"}
            valueFormatted={amountValue?.formattedValue}
            setFund={fundNgn}
          />
        )}
      </div>

      <div className="mt-10">
        {total > 0 ? (
          <WalletAccountTable
            // itemsPerPage={10}
            data={eurTransactions}
            currency={currency}
          />
        ) : (
          <EmptyState />
        )}
      </div>

      <div
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <AnimatePresence>
          {showConfirmationModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="confirmation_modal bg-[#13111173] backdrop-blur-sm fixed w-full top-0 left-0 justify-center h-full items-center z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center md:justify-end "
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="card flex flex-col bg-white max-w-sm w-full md:max-w-md max-h-full  md:rounded-l-3xl p-5 md:h-full "
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowConfirmationModal(false);
                    }}
                    className="hover:bg-[#f2faf7] rounded-full p-2  self-end cursor-pointer"
                  >
                    <AiOutlineClose size={28} />
                  </button>
                  <div className="flex gap-3 items-center pb-2">
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmationModal(false);
                        setShowModal(true);
                      }}
                      className="bg-[#f7fbfb] p-2 rounded-full cursor-pointer transition-all active:scale-90"
                    >
                      <BsChevronLeft />
                    </div>

                    <h1 className="font-bold text-xl text-[#071827]">
                      Confirmation
                    </h1>
                  </div>
                  <p className="text-sm text-[#5C6F7F] mb-9">
                    Fund your NGN wallet via direct bank transfer
                  </p>
                  <div className="flex items-center justify-between">
                    <h1 className="text-[#071827] font-bold">
                      You are transferring:
                    </h1>
                    <p className="text-[#0EB683] font-bold text-xl">NGN 0.00</p>
                  </div>
                  <div className="py-5">
                    <Notification
                      text={
                        "Once you have transferred the amount, click confirm below"
                      }
                      info={"To:"}
                    />
                  </div>

                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setShowConfirmationModal(false);
                      setShowSuccess(true);
                    }}
                    className="flex flex-row justify-between md:flex-col mb-4"
                  >
                    <button className="border border-[#0eb683] text-[#0eb683] md:p-3 rounded-md md:mb-4 cursor-pointer transition-all active:scale-90  py-3 px-8 hover:bg-[#0eb683] hover:text-white ">
                      Confirm
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <AnimatePresence>
          {showSuccess && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.1,
                },
              }}
              className="confirmation_modal bg-[#13111173] backdrop-blur-sm fixed w-full top-0 left-0 justify-center h-full items-center z-50"
            >
              <motion.div
                initial={{
                  x: 600,
                  scale: 0,
                }}
                animate={{
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 600,
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="flex h-screen items-center justify-center md:justify-end"
              >
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="desktop-card md:flex hidden justify-center flex-col gap-8 bg-white w-[448px]  h-full  md:rounded-l-3xl p-5 "
                >
                  <div className="icon self-center">
                    <SuccessMobile width={"200px"} height={"200px"} />
                  </div>
                  <h1 className="text-3xl font-bold text-[#071827] self-center">
                    Wallet Funded
                  </h1>

                  <p className="text-sm text-[#5C6F7F] self-center">
                    Your wallet has been successfully funded
                  </p>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSuccess(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-28 py-3 self-center transition-all active:scale-90 "
                  >
                    Done
                  </button>
                </motion.div>
                <motion.div
                  initial={{
                    x: 600,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      // scale:1,
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 1,
                    transition: {
                      // scale:10,
                      duration: 0.3,
                    },
                  }}
                  className="mobile-card flex md:hidden justify-center flex-col gap-8 bg-white w-full max-w-sm h-[450px] rounded-3xl p-5 "
                >
                  <div className="icon self-center">
                    <SuccessMobile width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-3xl font-bold text-[#071827] self-center">
                    Wallet Funded
                  </h1>

                  {/* <CheckIcon/> */}
                  <p className="text-sm text-[#5C6F7F] self-center">
                    Your wallet has been successfully funded
                  </p>

                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowSuccess(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-16 py-3 self-center transition-all active:scale-90 "
                  >
                    Done
                  </button>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default EurWallet;
