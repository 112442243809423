import React, { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import "./styles/standard.css";
import Select, { components, SingleValueProps } from "react-select";
import { useLocation } from "react-router-dom";

const useContainsStablecoin = (): boolean => {
  const location = useLocation();
  return location.pathname.toLowerCase().includes("stablecoin");
};

const customStyles = {
  menu: (base: any) => ({ ...base, width: "100px" }),
  control: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    width: "max-content",
    height: "50px",
    padding: 0,
    borderRadius: "0.375rem 0.375rem 0.375rem 0.375rem",
    fontSize: "12px",
    border: state.isFocused ? "1px solid #0eb683" : "1px solid #0eb683",
    boxShadow: "none",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "15px",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: "0 5px 0 0",
    color: "black",
  }),
};

interface AppNumericInputProps {
  name?: string;
  label?: string;
  info?: string;
  min?: number;
  max?: number;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  infoStyle?: React.CSSProperties;
  rightLabel?: string | boolean;
  rightLabelStyle?: React.CSSProperties;
  isInsufficient?: boolean | string;
  insufficientLabel?: string;
  className?: string;
  currencies?: any[];
  selectedCurrency?: string;
  setSelectedCurrency?: (currency: string) => void;
  handleCurrencyChange?: (option: any) => void;
  placeholder?: string;
  placeholderStyle?: React.CSSProperties;
  limit?: number;
  [key: string]: any;
  prefix: any;
}

interface Currency {
  value: string;
  label: string;
  image: string;
}
const CurrencyIcon: React.FC<{ src: string; alt: string }> = React.memo(
  ({ src, alt }) => (
    <img
      src={src}
      alt={alt}
      style={{
        width: "20px",
        height: "20px",
        marginRight: "5px",
      }}
    />
  )
);
const AppNumericInput: React.FC<AppNumericInputProps> = ({
  name,
  label,
  info,
  min,
  max,
  style,
  labelStyle,
  infoStyle,
  rightLabel,
  rightLabelStyle,
  isInsufficient,
  insufficientLabel,
  className,
  currencies,
  selectedCurrency,
  setSelectedCurrency,
  handleCurrencyChange,
  placeholder,
  placeholderStyle,
  limit,
  ...props
}) => {
   const hasStablecoinInRoute = useContainsStablecoin();
  const [exceedsLimit, setExceedsLimit] = useState(false);



  const currencyValue = useMemo(() => {
    return currencies?.find(
      (item: Currency) => item.value === selectedCurrency?.toUpperCase()
    );
  }, [currencies, selectedCurrency]);



  const customSingleValue = React.memo<React.FC<SingleValueProps<any>>>(
    (props) => (
      //@ts-ignore
      <components.SingleValue {...props}>
        <div className="flex items-center">
          {" "}
          {/* @ts-ignore */}
          <CurrencyIcon src={props.data.image} alt="currency" />
          <span className="text-[16px] leading-[21px] text-[#5C6F7F]">
            {/* @ts-ignore */}
            {props.data.value}
          </span>
        </div>
      </components.SingleValue>
    )
  );

  const CustomOption = React.memo((props: any) => (
    <components.Option {...props}>
      <div className="flex items-center gap-1">
        <CurrencyIcon src={props.data.image} alt="currency" />
        <span className="text-[16px] leading-[21px] text-[#5C6F7F]">
          {props.data.value}
        </span>
      </div>
    </components.Option>
  ));

  // const isValueAllowed = ({ value }: { value: string }) => {
  //   if (value === "") {
  //     setExceedsLimit(false);
  //     return true;
  //   }

  //   if (limit !== undefined) {
  //     const numValue = parseFloat(value.replace(/,/g, ""));
  //     if (numValue > limit) {
  //       setExceedsLimit(true);
  //       return false;
  //     }
  //     setExceedsLimit(false);
  //   }

  //   if (value.startsWith("0")) {
  //     const regex = /^0(\.\d{0,2})?$/;
  //     return regex.test(value);
  //   }

  //   return true;
  // };

  const isValueAllowed = ({ value }: { value: string }) => {
    // Check for negative sign
    if (value.includes("-")) {
      return false;
    }

    if (value === "") {
      setExceedsLimit(false);
      return true;
    }

    if (limit !== undefined) {
      const numValue = parseFloat(value.replace(/,/g, ""));
      if (numValue > limit) {
        setExceedsLimit(true);
        return false;
      }
      setExceedsLimit(false);
    }

    if (value.startsWith("0")) {
      const regex = /^0(\.\d{0,2})?$/;
      return regex.test(value);
    }

    return true;
  };

  const computedStyle: React.CSSProperties = {
    ...(isInsufficient || exceedsLimit ? style : {}),
  };

  // console.log(hasStablecoinInRoute, "hasStablecoinInRoute");

  return (
    <div className="app-special-input">
      <div className="flex justify-between label-group">
        <p className="label text-left" style={labelStyle}>
          {label}
        </p>
      </div>
      <div>
        <div className="flex items-center gap-3">
          {currencies && (
            <div>
              <Select
                options={currencies}
                value={currencyValue}
                styles={customStyles}
                isSearchable={false}
                onChange={handleCurrencyChange}
                components={{
                  //@ts-ignore
                  SingleValue: customSingleValue,
                  Option: CustomOption,
                }}
              />
            </div>
          )}
          <div className={`app-input`} style={computedStyle}>
            <div
              className="insufficient"
              hidden={!isInsufficient && !exceedsLimit}
            >
              {exceedsLimit
                ? `(max limit: ${props.prefix} ${limit?.toLocaleString()})`
                : insufficientLabel ?? "Insufficient"}
            </div>
            <NumberFormat
              {...props}
              placeholder={placeholder}
              style={placeholderStyle}
              allowLeadingZeros={true}
              isAllowed={isValueAllowed}
            />
          </div>
        </div>
      </div>
      <p className="info" style={infoStyle}>
        <span className="text-[#0eb683]">{info}</span>
      </p>
    </div>
  );
};

export default AppNumericInput;
