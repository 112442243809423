import { create } from "zustand";

interface ModalState {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const useModalStore = create<ModalState>((set) => ({
  isOpen: false,
  openModal: () => set({ isOpen: true }),
  closeModal: () => set({ isOpen: false }),
}));

export default useModalStore;

interface FundVerificationState {
  isFundVerificationOpen: boolean;
  fundAmount: number | null;
  setFundAmount: (amount: number) => void;
  openFundVerificationModal: () => void;
  closeFundVerificationModal: () => void;
}

export const useFundVerificationStore = create<FundVerificationState>(
  (set) => ({
    isFundVerificationOpen: false,
    fundAmount: null,
    setFundAmount: (amount: number) =>
      set((state: any) => ({ fundAmount: amount })),
    openFundVerificationModal: () => set({ isFundVerificationOpen: true }),
    closeFundVerificationModal: () => set({ isFundVerificationOpen: false }),
  })
);
