import React from "react";

interface IOFooter {
  backFn: () => void;
  nextFn: () => void;
  backBtnLabel?: string;
  nextBtnLabel?: string;
  nextDisabled?: boolean;
  successScreen?: () => void;
  color?: any;
}

const OnboardingFooter = ({
  backFn,
  nextFn,
  backBtnLabel,
  nextBtnLabel,
  nextDisabled,
  color,
}: IOFooter) => {
  // console.log(color);
  return (
    <footer
      className={`w-full max-w-lg  py-5 flex items-center justify-between ${
         color ?? "md:bg-[#F7FBFB]"
      }   xxs:mb-10`}
    >
      <button
        onClick={backFn}
        className="bg-white border border-[#0EB683] text-[#0EB683] p-3 px-7 rounded-lg active:scale-90 transition-all duration-500 hover:opacity-80"
      >
        {backBtnLabel ?? "Back"}
      </button>
      <button
        disabled={nextDisabled || false}
        className="bg-[#0EB683] text-white p-3 px-7 rounded-lg active:scale-90 transition-all duration-500 hover:opacity-80 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed"
        onClick={nextFn}
      >
        {nextBtnLabel ?? "Save and Proceed"}
      </button>
    </footer>
  );
};

export default OnboardingFooter;
