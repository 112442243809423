import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import { useSidebarState } from "../../store";
import ReactLoading from "react-loading";
import FiatWalletCard from "../dashboard-components/FiatWalletCard";
import CopyButton from "../base-components/copy";
import { useCardById, useCardPrivateDetails } from "../../sevices/hooks/cards";
import { useParams } from "react-router-dom";
type Props = {};

const CardDetails = (props: Props) => {
  const { card_id } = useParams();
  const cardInfos = useCardPrivateDetails(card_id as string);
  const setDetails = useSidebarState((state) => state.setShowDetails);
  const [check, setCheck] = useState(false);
  const showDetails = useSidebarState((state) => state.showDetails);
  const { data: details, isLoading } = useCardById(card_id as string);
  const handleInputChange = (name: string) => (newValue: string) => {};
  const handleInputFocus = (evt: { target: { name: any } }) => {};

  const [loading, setLoading] = useState(false);

  const cardDetails = details?.data?.data;
  // console.log(cardInfos, "cardInfos");
  const closeModal = () => {
    setDetails(false);
  };
  return (
    <>
      <AnimatePresence>
        {showDetails && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                // delay: 0.3,
                duration: 0.1,
              },
            }}
            className="modal fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
          >
            <motion.div
              animate={{
                scale: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                scale: 0,
                transition: {
                  delay: 0.3,
                },
              }}
              className="modal_overlay flex xxs:justify-center xxs:p-5 md:p-0 md:justify-end h-full cursor-pointer bg-[#13111173]"
            >
              <motion.div
                initial={{
                  x: 100,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 100,
                  opacity: 0,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="flex flex-col bg-white w-full max-w-sm md:max-w-sm max-h-full md:h-full relative xxs:rounded-md md:rounded-l-md p-5 md:px-7"
              >
                <div className="flex items-center justify-between mb-10">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 ">
                      Card Details
                    </h2>
                  </div>
                  <div
                    onClick={closeModal}
                    className="close flex items-center justify-center h-10 w-10 rounded-full hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center self-end"
                  >
                    <span className="hover:rotate-90 hover:transform transition duration-150 ease-in-out">
                      <IoMdClose size={29} className="" />
                    </span>
                  </div>
                </div>
                {/* <div className="mb-10">
                  <p>
                    You are required to first empty your card before deleting
                  </p>
                </div> */}

                <div className="flex flex-col  w-full gap-3  h-full">
                  <div className="space-y-8">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-gray-500 text-sm">Cardholder name</p>
                        <p className="text-gray-800 font-medium">
                          {cardDetails?.card_name}
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <CopyButton textToCopy={"cardholderName"} />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <p className="text-gray-500 text-sm">Card number</p>
                        <p className="text-gray-800 font-medium">
                          {cardDetails?.card_name}
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <CopyButton textToCopy={"cardNumber"} />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <p className="text-gray-500 text-sm">Expiry date</p>
                        <p className="text-gray-800 font-medium">
                          {"expiryDate"}
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <CopyButton textToCopy={"expiryDate"} />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <p className="text-gray-500 text-sm">Security code</p>
                        <p className="text-gray-800 font-medium">
                          {"securityCode"}
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <CopyButton textToCopy={"securityCode"} />
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <p className="text-gray-500 text-sm">Billing address</p>
                        <p className="text-gray-800 font-medium">
                          {"billingAddress"}
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <CopyButton textToCopy={"securityCode"} />
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  onClick={closeModal}
                  className="text-white w-full bg-[#0eb683] rounded-[16px] px-10 py-3.5 mt-auto transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 item-center justify-center flex disabled"
                >
                  {loading ? (
                    <div className="mx-auto">
                      <ReactLoading
                        type="spinningBubbles"
                        color="#fff"
                        height="27px"
                        width="27px"
                      />
                    </div>
                  ) : (
                    "Done"
                  )}
                </button>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default CardDetails;
