import { motion } from "framer-motion";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";

interface ConfirmationModalProps {
  onDone: () => void; // Callback function for "Done" button click
  message?: string; // Optional custom message
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onDone,
  message = "Your recipient will receive value within 1-3 business days",
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      className="fixed inset-0 bg-[#13111173] backdrop-blur-sm flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1, transition: { duration: 0.3 } }}
        exit={{ scale: 0.9, opacity: 0, transition: { duration: 0.2 } }}
        className="flex flex-col gap-6 bg-white w-full max-w-sm md:max-w-md rounded-3xl p-5 shadow-lg"
      >
        <div className="icon self-center">
          {/* Render the success icon */}
          <SuccessMobile width="150px" height="150px" />
        </div>
        <h1 className="text-2xl font-bold text-[#071827] text-center">
          Transfer initiated
        </h1>
        <p className="text-sm text-[#5C6F7F] text-center">{message}</p>
        <button
          onClick={onDone}
          className="bg-[#0eb683] w-full text-white rounded-xl px-10 py-3 self-center transition-all active:scale-90"
        >
          Done
        </button>
      </motion.div>
    </motion.div>
  );
};

export default ConfirmationModal;
