import React, { useRef, useState } from "react";
import { useNgnBeneficiaryState } from "../../store/useNgnBeneficiary";
import { NgnBeneficiaryType } from "../../types/NgnBeneficiary";
import { capitalize } from "../../pages/dashboard/Transactions";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FiTrash } from "react-icons/fi";
import { useOutsideAlerter } from "../../hooks/OutsideAlerter";
import { FaMoneyBillTransfer } from "react-icons/fa6";

export function createAcronym(str: string): string {
  if (!str) return "";

  const words: string[] = str.split(/\s+/);

  const ftw: string[] = words.slice(0, 2);
  const acronym: string = ftw.reduce(
    (acc, word) => acc + word[0].toUpperCase(),
    ""
  );

  return acronym;
}
type CardProps = {
  b: any;
  delete: () => void;
};
const NgnBeneficiaryCard: React.FC<CardProps> = ({
  b,

  delete: deleteAction,
}) => {
  const [popup, setPopup] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(popupRef, popup, () => setPopup(false));
  const setSelectedNgnBeneficiaryId = useNgnBeneficiaryState(
    (state) => state.setSelectedBeneficiaryId
  );
  const setShowNgnBeneficiaryModal = useNgnBeneficiaryState(
    (state) => state.setShowBeneficiaryDetails
  );

  const { accountName, id, bankName, accountNumber } = b;

  const acronym = createAcronym(accountName);

  return (

    <div
      key={id}
      onClick={() => {
        setSelectedNgnBeneficiaryId(id);
        setShowNgnBeneficiaryModal(true);
      }}
      className=""
    >
      <div className="border border-[#0eb683] gap-4 bg-white w-full h-28  rounded-[16px] flex md:flex-col items-center md:h-48 p-5 transition-all active:scale-90 cursor-pointer xxs:relative ">
        <div className="flex justify-center items-center">
          <div className="bg-[#E5F2EA] flex justify-center align-middle items-center p-3 h-12 w-12  text-center text-[#0eb683] font-extrabold rounded-full self-center  ">
            <p className="font-bold text-lg">{acronym}</p>
          </div>
        </div>
        <div className="name flex flex-col items-start md:items-center">
          <h1 className="text-[text-[#071827] font-semibold">
            {b?.accountName}
          </h1>
          <p className="text-sm text-[#5C6F7F]">
            {bankName} - {accountNumber}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NgnBeneficiaryCard;
