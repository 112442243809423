import React, { useState, useEffect } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddFiatComponent from "../../../components/beneficiary-component/AddFiatComponent";
import AddStableCoinComponent from "../../../components/beneficiary-component/AddStableCoinComponent";

import { useSidebarState } from "../../../store";

const AddBeneficiary = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize current tab from query params, default to 'fiat'
  const [currentTab, setCurrentTab] = useState(() => {
    return searchParams.get("tab") || "fiat";
  });



  // Update query params when tab changes
  useEffect(() => {
    setSearchParams({ tab: currentTab }, { replace: true });
  }, [currentTab, setSearchParams]);

  // Tab change handler
  const handleTabChange = (tab: React.SetStateAction<string>) => {
    setCurrentTab(tab);
  };

  return (
    <div className="bg-[#f7fbfb] h-screen w-full p-5 md:p-0 pt-5 md:pt-5 md:pl-8">
      <div className="div flex items-center gap-24">
        <div className="flex items-center xxs:gap-16 md:gap-4 md:mb-5">
          <button
            className="rounded-full mb-4 bg-[#ECF6F3] p-2 flex transition-all active:scale-90 cursor-pointer"
            onClick={() => {
              navigate("/dashboard/beneficiaries", { replace: true });
            }}
          >
            <BsChevronLeft />
          </button>
          <p className="font-semibold md:mr-2 pb-4 md:pb-3">Add Beneficiary</p>
        </div>
        <div className="toggle md:max-w-[240px] w-full h-12 md:h-10 border border-[#0eb683] xxs:mb-6 self-center bg-white justify-between rounded-[32px] cursor-pointer p-1 md:mb-8 md:flex hidden">
          <div
            className={`px-2 flex-1 flex items-center justify-center transition-all duration-500 rounded-[32px] ${
              currentTab === "fiat" ? "bg-[#0eb683] text-white" : ""
            }`}
            onClick={() => handleTabChange("fiat")}
          >
            Fiat
          </div>
          <div
            className={`px-2 flex-1 flex items-center justify-center transition-all duration-500 rounded-[32px] ${
              currentTab === "stable" ? "bg-[#0eb683] text-white" : ""
            }`}
            onClick={() => handleTabChange("stable")}
          >
            Stablecoin
          </div>
        </div>
      </div>
      <div className="div bg-[#f7fbfb] z-10 py-5">
        <div className="toggle md:hidden md:max-w-[320px] w-full h-10 md:h-10 flex border border-[#0eb683] mb-6 md:mb-8 self-center justify-between rounded-[32px] cursor-pointer p-1 md:mt:10 bg-white">
          <div
            className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-[32px] ${
              currentTab === "fiat" ? "bg-[#0eb683] text-white" : ""
            }`}
            onClick={() => handleTabChange("fiat")}
          >
            Fiat
          </div>
          <div
            className={`px-2 flex-1 whitespace-nowrap flex items-center justify-center transition-all duration-500 rounded-[32px] ${
              currentTab === "stable" ? "bg-[#0eb683] text-white" : ""
            }`}
            onClick={() => handleTabChange("stable")}
          >
            Stablecoins
          </div>
        </div>
        {currentTab === "fiat" ? (
          <AddFiatComponent onClick={() => {}} />
        ) : (
          <AddStableCoinComponent />
        )}
      </div>
    </div>
  );
};

export default AddBeneficiary;
