import React, { useState } from "react";
import phone from "../../assets/landing-asset/Human on landing page - mobile.png";
import { FiCheck } from "react-icons/fi";

const LandingPageSection = () => {
  // const imageWidth = partnerData.length / 120;
  //   const [leftPosition, setLeftPosition] = useState(0);

  //   const handleLeftClick = () => {
  //     setLeftPosition(leftPosition + 120);
  //   };

  //   const handleRightClick = () => {
  //     setLeftPosition(leftPosition - 120);
  //   };

  return (
    <>
      <section className="  px-4 md:pt-10 md:px-[8%]">
        <div className="bg-[#fff] lg:flex lg:items-center border-2 border-[#0eb683] justify-between rounded-[32px] shadow-black">
          <div className="text p-5 md:px-[3%] md:py-10 pb-8 w-full md:w-[60%]">
            <h1 className="bold text-xl lg:text-left  font-bold md:text-[45px] leading-[56.52px] md:font-semibold md:px-5 md:py-3 3xl:text-[73px] 3xl:leading-[90px]">
              Make <span className="bold text-[#0EB683]"> payments </span> with
              us
            </h1>
            <p className="light text-[#393939]   md:pb-4 md:pl-5 3xl:text-[33px] 3xl:leading-[40px] md:text-[20px] text-[14px] md:leading-[30px] leading-[19.6px]">
              We are committed to transferring your funds timely and securely
            </p>
            <div className="md:px-5 pt-8 space-y-4">
              {data.map((item, index) => (
                <div className="flex items-start gap-4">
                  {/* Check Mark */}
                  <div className="points h-6 w-6 rounded-full bg-[#0EB686] text-[#fff] flex items-center justify-center  mt-1">
                    {" "}
                    <FiCheck size={13} />{" "}
                  </div>
                  {/* Header and Subtext */}
                  <div className="w-full">
                    <h2 className="bold text-[#000] font-bold 3xl:text-[33px] 3xl:leading-[40px] md:text-[24px] text-base md:leading-[32px]  text-start">
                      {item.header}
                    </h2>
                    <p className="light  pt-3 pb-5 text-[#393939]   md:text-[20px] text-[14px] md:leading-[25px] leading-[19.6px]">
                      {item.subtext}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="phone p-[3%] h-full w-full  md:w-[40%] mb-4 md:mb-0">
            <img src={phone} className="object-contain" alt="img" />
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingPageSection;

const data = [
  {
    header: "Constant liquidity",
    subtext: "24/7 Liquidity. Get value exchanged to your position instantly",
  },
  {
    header: "Funds security",
    subtext: `Best in class security for your account or funds and end-to-end encryption during transactions`,
  },
  {
    header: "Instant settlement",
    subtext: "Receive value in local or foreign currency instantly",
  },
  {
    header: "Guaranteed remittance",
    subtext: "Make transfers and payments same day or within 3 business days",
  },
];
