import { create } from "zustand";
import { persist } from "zustand/middleware";

interface SaveBeneficiaryState {
  isSaveBeneficiary: boolean;
  setSaveBeneficiary: (save: boolean) => void;
  resetSaveBeneficiary: () => void;
}

export const useSaveBeneficiaryStore = create<SaveBeneficiaryState>()(
  persist(
    (set) => ({
      isSaveBeneficiary: false,
      setSaveBeneficiary: (save) => set({ isSaveBeneficiary: save }),
      resetSaveBeneficiary: () => set({ isSaveBeneficiary: false }),
    }),
    {
      name: "save-beneficiary-storage",
      getStorage: () => localStorage,
    }
  )
);
