import React, { useState, useEffect, useRef } from "react";
import WalletWidget, { Ixprops as WalletWidgetProps } from "./WalletWidget";

interface IProps {
  widgets: WalletWidgetProps[];
}

const WalletScroll: React.FC<IProps> = ({ widgets }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);


  const scrollToCard = (index: number) => {
    if (scrollContainerRef.current) {
      const scrollPosition = index * scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
      setCurrentIndex(index);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const index = Math.round(
          scrollContainerRef.current.scrollLeft /
            scrollContainerRef.current.clientWidth
        );
        setCurrentIndex(index);
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const getDotClassName = (index: number) => {
    return currentIndex === index ? "bg-[#0eb683]" : "bg-gray-400";
  };

  return (
    <div className="pb-4 md:py-8">
      <div className="relative flex items-center">
        <div
          ref={scrollContainerRef}
          className="flex gap-4 overflow-auto scrollbar-hide w-full"
        >
          {widgets.map((widget, index) => (
            <div key={index} className="w-full">
              <WalletWidget {...widget} />
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-8">
        {widgets.map((_, index) => (
          <div
            key={index}
            className={`h-2 w-2 rounded-full mx-1 cursor-pointer ${getDotClassName(
              index
            )}`}
            onClick={() => scrollToCard(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default WalletScroll;
