import React, { useState, useCallback, useEffect } from "react";
import { motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import ReactLoading from "react-loading";
import OTPInput from "../widgets/OTPInput";
import useVerificationModalStore from "../../store/cardVerification";

interface IOtp {
  loading: boolean;
  verify: boolean;
  setOtp: (otp: string) => void;
  withdraw: (otp: string) => void;
  onClose?: () => void;
  setVerification?: (state: boolean) => void;
}

const OTP_LENGTH = 5;

const OtpModal: React.FC<IOtp> = ({
  loading,
  setOtp,
  withdraw,
  onClose,
  setVerification,
}) => {
  const { closeVerification } = useVerificationModalStore();
  const [localOtp, setLocalOtp] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  // Validate OTP format
  const validateOtp = useCallback((otp: string): boolean => {
    return otp.length === OTP_LENGTH && /^[A-Z0-9]{5}$/.test(otp);
  }, []);

  // Handle OTP change with validation
  const handleOtpChange = useCallback(
    (otpValue: string) => {
      const formattedOtp = otpValue.toUpperCase();
      setLocalOtp(formattedOtp);
      setIsValid(validateOtp(formattedOtp));
      setError("");
      setOtp(formattedOtp); // Update parent component
    },
    [setOtp, validateOtp]
  );

  // Handle OTP completion
  const handleOtpComplete = useCallback(
    (otpValue: string) => {
      const formattedOtp = otpValue.toUpperCase();
      if (validateOtp(formattedOtp)) {
        setLocalOtp(formattedOtp);
        setIsValid(true);
        setError("");
        setOtp(formattedOtp);
      } else {
        setError("Please enter a valid OTP");
        setIsValid(false);
      }
    },
    [setOtp, validateOtp]
  );

  // Handle form submission
  const handleSubmit = useCallback(() => {
    if (!loading && isValid) {
      try {
        withdraw(localOtp);
      } catch (err) {
        setError("Failed to process OTP. Please try again.");
      }
    }
  }, [loading, isValid, localOtp, withdraw]);

  // Handle modal close
  const handleClose = useCallback(() => {
    setLocalOtp("");
    setError("");
    setIsValid(false);
    setVerification?.(false);
    onClose?.();
    closeVerification?.();
  }, [setVerification, onClose, closeVerification]);

  // Handle keyboard events
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "Enter" && isValid && !loading) {
        handleSubmit();
      } else if (e.key === "Escape") {
        handleClose();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [isValid, loading, handleSubmit, handleClose]);

  const modalVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.3 },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.1 },
    },
  };

  const contentVariants = {
    hidden: { x: 600, scale: 0 },
    visible: {
      x: 0,
      scale: 1,
      transition: { duration: 0.3 },
    },
    exit: {
      x: 600,
      scale: 0,
      transition: { delay: 0.3 },
    },
  };

  return (
    <motion.div
      variants={modalVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="fixed inset-0 flex justify-center md:justify-end items-center z-50 backdrop-blur-md bg-[#13111137]"
    >
      <motion.div
        variants={contentVariants}
        className="flex h-screen items-end md:items-center md:justify-center w-full"
      >
        <div className="bg-white h-[350px] w-full max-w-md rounded-t-3xl md:rounded-3xl p-10 relative">
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 p-2 hover:bg-red-200 hover:text-red-600 rounded-full transition-all duration-150 ease-in-out"
            aria-label="Close modal"
          >
            <IoMdClose size={28} />
          </button>

          <div className="flex flex-col gap-6 h-full">
            <div className="text-center">
              <h1 className="text-3xl text-[#0eb683] font-extrabold">
                Verification
              </h1>
              <p className="text-[#5C6F7F] text-sm mt-2">
                Enter the 5-digit code sent to your email
              </p>
            </div>

            <div className="flex-grow">
              <OTPInput
                autoFocus
                length={OTP_LENGTH}
                className="flex justify-between w-full py-3"
                inputClassName={`w-12 h-12 md:w-[50px] md:h-[50px] border text-2xl text-center font-bold rounded-lg
                  ${
                    error
                      ? "border-red-500 text-red-500"
                      : "border-[#0EB683] text-[#0EB683]"
                  }
                  outline-[#0EB683] bg-white`}
                autoCapitalize={true}
                onChangeOTP={handleOtpChange}
                onComplete={handleOtpComplete}
                // value={localOtp}
              />
              {error && (
                <p className="text-red-500 text-sm mt-2 text-center">{error}</p>
              )}
            </div>

            <div className="flex items-center justify-between gap-4">
              <button
                onClick={handleClose}
                className="flex items-center justify-center px-4 py-3 text-[#0eb683] border border-[#0eb683] rounded-[16px] transition-all active:scale-95 flex-1"
              >
                <MdOutlineKeyboardBackspace className="mr-2" />
                Back
              </button>

              <button
                onClick={handleSubmit}
                disabled={loading || !isValid}
                className="flex-1 px-4 py-3 bg-[#0eb683] text-white rounded-[16px] transition-all active:scale-95 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:active:scale-100"
              >
                {loading ? (
                  <div className="flex justify-center">
                    <ReactLoading
                      type="spinningBubbles"
                      color="#fff"
                      height={27}
                      width={27}
                    />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default OtpModal;
