// ChainSelector.tsx
import React, { useState, useEffect, useRef } from "react";


export interface Chain {
  id: string;
  name: string;
  icon: string;
  address: string;
}

interface ChainSelectorProps {
  chains: Chain[];
  selectedChain: Chain;
  onChainSelect: (chain: Chain) => void;
}

const ChainSelector: React.FC<ChainSelectorProps> = ({
  chains,
  selectedChain,
  onChainSelect,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        event.target instanceof Node &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (chain: Chain): void => {
    onChainSelect(chain);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 bg-white border border-[#0EB683] rounded-full px-2 py-2 w-full hover:border-gray-300 transition-colors"
      >
        <div className="flex items-center gap-2 flex-1">
          <img
            src={selectedChain.icon}
            alt={`${selectedChain.name} icon`}
            className="w-6 h-6 rounded-full"
          />
          <span className="text-[#5C6F7F]">{selectedChain.name}</span>
        </div>
        <svg
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg py-1  z-10">
          {chains.map((chain) => (
            <button
              key={chain.id}
              type="button"
              onClick={() => handleSelect(chain)}
              className="flex items-center gap-2 w-full px-2 py-2 hover:bg-gray-50 transition-colors"
            >
              <img
                src={chain.icon}
                alt={`${chain.name} icon`}
                className="w-10 h-10 rounded-full"
              />
              <span className="text-sm text-gray-700 ">{chain.name}</span>
              {selectedChain.id === chain.id && (
                <svg
                  className="w-4 h-4 ml-auto text-green-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChainSelector;
