import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Column } from "react-table";
import { Tooltip } from "../base-components/Tooltip";
import { useNavigate } from "react-router-dom";
import { AiOutlineMinus } from "react-icons/ai";
import { MdAdd } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import { IData } from "../transaction-component/TransactionTable";
import { numberWithCommas } from "../../pages/dashboard/Withdrawals";
const moment = require("moment");

export type Transaction = {
  currency: string;
  serialNumber: any;
  createdAt: string;
  transactionRef: string;
  type: string;
  amount: string;
  narration: string;
  status: string;

  transactionPayload?: any;
};

export interface IColumn {
  serialNumber: any;
  createdAt: string;
  transactionRef: string;
  type: string;
  amount: string;
  narration: string;
  status: string;
  currency: string;
}
type ContainerProps = {
  data: Transaction[];
};
export const MobileView = ({ data }: ContainerProps) => {
  let currentPage = 1;
  const [currentPageIndex, setCurrentPageIndex] = useState(currentPage);
  function chunkArray<T>(array: T[], size: number) {
    let result = [];
    for (let i = 0; i < array?.length; i += size) {
      let chunk = array?.slice(i, i + size);
      result.push(chunk);
    }
    return result;
  }

  return (
    <>
      {data ? (
        chunkArray(data, 5)[currentPageIndex - 1]?.map((trx, index) => {
          const transactionType = (type: string) => {
            let icon;

            switch (type) {
              case "debit":
                icon = (
                  <AiOutlineMinus
                    size={30}
                    color={"#FA0204"}
                    className="font-extrabold"
                  />
                );
                break;
              case "credit":
                icon = (
                  <MdAdd
                    size={30}
                    color={"#0EB683"}
                    className="font-extrabold"
                  />
                );
                break;
              default:
                icon = (
                  <FiSend
                    size={30}
                    color={"#0eb683"}
                    className="font-extrabold"
                  />
                );
                break;
            }

            return icon;
          };
          const icon = transactionType(trx?.type);

          const narration = (nar: string) => {
            return <span className=" truncate w-24 ">{nar}</span>;
          };
          const amountF = numberWithCommas(Number(trx?.amount));

          const currencySymbol = (currency: string, amount: string) => {
            let currencySymbol = "";

            switch (currency?.toUpperCase()) {
              case "NGN": // Nigerian Naira
                currencySymbol = "₦ ";
                break;
              case "USD": // US Dollar
                currencySymbol = "$ ";
                break;
              case "EUR": // Euro
                currencySymbol = "€ ";
                break;
              case "GBP": // British Pound Sterling
                currencySymbol = "£ ";
                break;
              default:
                break;
            }

            return `${currencySymbol}${amount}`;
          };
          const amount = currencySymbol(trx?.currency, amountF);
          const narrations = narration(trx?.narration);

          const myDate = new Date(trx?.createdAt);
          const createdDate = new Date(trx?.createdAt); // No need to multiply by 1000
          const now = new Date();
          const hoursSinceCreation =
            (now.getTime() - createdDate.getTime()) / (1000 * 60 * 60); // Convert milliseconds to hours
          const isNew = hoursSinceCreation <= 70;
          const formatDate = moment(myDate);
          const Cdate = formatDate.format("lll");

          const currency = trx?.currency;

          const mapping = (value: string) => {
            const colorMap = {
              processing: { bg: "#e5e5e5", color: "#5C6F7F" },
              pending: { bg: "#fff2eb", color: "#FF7F36" },
              successful: { bg: "#e2fef0", color: "#0EB683" },
              cancelled: { bg: "#e2fef0", color: "#f51505" },
              failed: { bg: "#f51505", color: "#e2fef0" },
            };

            const status = value?.toLowerCase();
            //@ts-ignore
            const { bg, color } = colorMap[status] || {};

            const stats = (status: string) => {
              return status.replace(/(^|\s|-)(\w)/g, (match, p1, p2) => {
                return p1 + p2.toUpperCase();
              });
            };

            return (
              <span
                className="inline-block px-3 py-1 rounded-full text-sm font-bold mt-2"
                style={{ backgroundColor: bg, color }}
              >
                {stats(status)}
              </span>
            );
          };
          const color = mapping(trx?.status);
          return (
            <div key={index + "1"} className="">
              <div className="  py-4 px-2 bg-[#fff] w-full border-y border-slate-100 flex items-center ">
                <div className="flex-1 mr-2">
                  <div
                    className={`text-lg h-[3rem] w-[3rem] border rounded-full flex items-center justify-center ${
                      trx?.type === "debit"
                        ? "bg-[#FEECE2] border-[#FEECE2]"
                        : "bg-[#E2FEF0] border-[#E2FEF0]"
                    }`}
                  >
                    {icon}
                  </div>
                </div>
                <div className="flex-[5]">
                  <div className="flex items-center justify-between space-y-3">
                    <div className="flex items-center gap-3">
                      <span className="text-[#5C6F7F]">
                        {" "}
                        {currency} {trx?.type.charAt(0).toUpperCase()}
                        {trx?.type.slice(1)}
                      </span>
                      {isNew && (
                        <div className="bg-[#E2FEF0] text-xs px-2 rounded-full text-[#0EB683]">
                          New
                        </div>
                      )}
                    </div>
                    <span className="text-[#071827] font-semibold">
                      {amount}
                    </span>
                  </div>

                  <div className="flex items-center justify-between space-y-3">
                    <span>{Cdate}</span>
                    <span className="text-sm text-[#0eb683]">View</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div>Loading</div>
      )}
    </>
  );
};

export const dColumns: readonly Column<Transaction>[] = [
  {
    Header: "SN",
    accessor: "serialNumber",
    //@ts-ignore
    Cell: ({ value, row }) => {
      const index = row?.index;
      return (index !== undefined ? index + 1 : "").toString();
    },
  },
  {
    Header: "TIMESTAMP (GMT+1)",
    accessor: "createdAt",
    Cell: ({ value }) => {
      const myDate = new Date(value);
      const formatDate = moment(myDate);
      const Cdate = formatDate.format("lll");
      return Cdate;
    },
  },
  {
    Header: "TRANSACTION REF",
    accessor: "transactionRef",
    Cell: ({ value }: { value: string }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip message={value}>
          <a
            className="truncate hover:underline cursor-pointer text-[#0eb683]"
            style={{ marginRight: "0.5rem" }}
          >
            {value.slice(0, 20)}
          </a>
        </Tooltip>
      </div>
    ),
  },
  {
    Header: "TYPE",
    accessor: (row) =>
      `${
        typeof row?.currency === "string"
          ? row?.currency.toUpperCase()
          : row?.currency || "USD"
      } ${row?.type.charAt(0).toUpperCase()}${row?.type.slice(1)} `,

    Cell: ({ value }: { value: string }) => (
      <div className="">
        <span className="flex items-center whitespace-nowrap">{value}</span>
      </div>
    ),
  },
  {
    Header: "SENT",
    accessor: (row) => {
      let currencySymbol = "";
      const formatter = new Intl.NumberFormat();
      const amount = Number(row?.transactionPayload?.fromAmount);
      const formattedAmount = isNaN(amount) ? "" : amount.toFixed(2);

      switch (row?.transactionPayload?.fromCurrency?.toUpperCase()) {
        case "NGN": // Nigerian Naira
          currencySymbol = "₦ ";
          break;
        case "USD": // US Dollar
          currencySymbol = "$ ";
          break;
        case "EUR": // Euro
          currencySymbol = "€ ";
          break;
        case "GBP": // British Pound Sterling
          currencySymbol = "£ ";
          break;
        default:
          currencySymbol = "$ ";
          break;
      }

      return (
        <div className="whitespace-nowrap">{`${currencySymbol}${numberWithCommas(
          Number(formattedAmount)
        )}`}</div>
      );
    },
  },
  {
    Header: "RECEIVED",
    accessor: (row) => {
      let currencySymbol = "";
      const formatter = new Intl.NumberFormat();
      const amount = Number(row?.transactionPayload?.toAmount);
      const formattedAmount = isNaN(amount) ? "0.00" : amount.toFixed(2);

      switch (row?.transactionPayload?.toCurrency?.toUpperCase()) {
        case "NGN": // Nigerian Naira
          currencySymbol = "₦ ";
          break;
        case "USD": // US Dollar
          currencySymbol = "$ ";
          break;
        case "EUR": // Euro
          currencySymbol = "€ ";
          break;
        case "GBP": // British Pound Sterling
          currencySymbol = "£ ";
          break;
        default:
          currencySymbol = "$ ";
          break;
      }

      return (
        <div className="whitespace-nowrap">{`${currencySymbol}${numberWithCommas(
          Number(formattedAmount)
        )}`}</div>
      );
    },
  },

  {
    Header: "NARRATION",
    accessor: "narration",
    Cell: ({ value }) => (
      <div className="flex items-left">
        <Tooltip message={value}>
          <div className="truncate w-40 cursor-pointer">{value}</div>
        </Tooltip>
      </div>
    ),
  },

  {
    Header: "STATUS",
    accessor: "status",
    Cell: ({ value }) => {
      const colorMap = {
        processing: { bg: "#FFF2EB", color: "#FF7F36" },
        pending: { bg: "#E9E9E9", color: "#5C6F7F" },
        successful: { bg: "#E2FEF0", color: "#0EB683" },
        cancelled: { bg: "#e2fef0", color: "#f51505" },
        failed: { bg: "#e6c1bd", color: "#f51505" },
      };

      const status = value?.toLowerCase();
      //@ts-ignore
      const { bg, color } = colorMap[status] || {};

      const stats = (status: string) => {
        return status.replace(/(^|\s|-)(\w)/g, (match, p1, p2) => {
          return p1 + p2.toUpperCase();
        });
      };

      return (
        <span
          className="inline-block px-3 py-1 rounded-full text-sm font-bold"
          style={{ backgroundColor: bg, color }}
        >
          {stats(status)}
        </span>
      );
    },
  },
];

const DashboardTable = ({ tableData }: { tableData: Transaction[] }) => {
  const columns = useMemo(() => dColumns, []);
  const data = useMemo(() => tableData, [tableData]);

  const navigate = useNavigate();

  const table = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    state,
    setGlobalFilter,
    prepareRow,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
    previousPage,
    pageOptions,
    canNextPage,
    canPreviousPage,
    footerGroups,
  } = table;

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div>
      <div className=" rounded-[16px] flex flex-col bg-white ">
        <div className="overflow-x-auto sm:-mx-6 ">
          <div className="inline-block md:min-w-full xxs:w-full sm:px-6 lg:px-8">
            <div className="overflow-x-auto md:min-w-full w-full">
              <header className="flex justify-between px-4 py-2 border-b border-slate-50 ">
                <h1 className=" text-gray-600 text-base font-semibold">
                  Recent Activities
                </h1>
                <p
                  onClick={() => {
                    navigate("/dashboard/transactions", { replace: true });
                  }}
                  className="text-[#0eb683] font-medium hover:underline cursor-pointer transition-all active:scale-90 text-sm"
                >
                  View all
                </p>
              </header>

              <div className="xxs:hidden md:block">
                <table
                  {...getTableProps()}
                  className="appearance-none bg-white min-w-full  mb-6 "
                  id="my-table"
                >
                  <thead className="bg-[#fff] appearance-none">
                    {headerGroups.map(
                      (headerGroup: {
                        getHeaderGroupProps: () => {
                          [x: string]: any;
                          key: any;
                        };
                        headers: any[];
                      }) => {
                        const { key, ...restHeaderProps } =
                          headerGroup.getHeaderGroupProps();
                        return (
                          <tr key={key} {...restHeaderProps}>
                            {headerGroup.headers.map((column) => (
                              <th
                                className="font-normal text-sm text-primary py-4 text-left whitespace-nowrap px-4 border-r border-slate-50"
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                                key={column.id}
                              >
                                <div className="flex items-center">
                                  <span className="text-[#5C6F7F] font-bold text-sm">
                                    {column.render("Header")}
                                  </span>

                                  {column.canSort === true && (
                                    <span className="ml-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="12"
                                        viewBox="0 0 10 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.00016 2.83L8.17016 6L9.58016 4.59L5.00016 0L0.410156 4.59L1.83016 6L5.00016 2.83ZM5.00016 15.17L1.83016 12L0.420156 13.41L5.00016 18L9.59016 13.41L8.17016 12L5.00016 15.17Z"
                                          fill="#323232"
                                        />
                                      </svg>
                                    </span>
                                  )}
                                </div>
                              </th>
                            ))}
                          </tr>
                        );
                      }
                    )}
                  </thead>
                  <tbody
                    {...getTableBodyProps()}
                    className="mt-3 pt-3 w-full space-y-8 border-r border-slate-50"
                  >
                    {page.map(
                      (row: {
                        getRowProps: () => JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLTableRowElement> &
                          React.HTMLAttributes<HTMLTableRowElement>;
                        cells: any[];
                      }) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            className="appearance-none my-4 border border-slate-50 even:bg-white odd:bg-slate-100"
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="font-normal text-sm text-[#202223] py-4 px-4 border-r border-slate-50 text-left"
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              <div className=" xxs:block md:hidden w-full">
                <MobileView data={tableData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTable;
