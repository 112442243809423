import React from "react";
import { BeneficiaryType } from "../../sevices/hooks/wallets";
import { useBeneficiariesState } from "../../store/useBeneficiaries";
import { StableCoinBeneficiary } from "../../types/Users";
import { truncateAddress } from "../../helper";

const BeneficiaryCard = ({ beneficiary }: { beneficiary: BeneficiaryType }) => {
  const setShowBeneficiaryModal = useBeneficiariesState(
    (state) => state.setShowBeneficiaryDetails
  );
  const setSelectedBeneficiaryId = useBeneficiariesState(
    (state) => state.setSelectedBeneficiaryId
  );

  const setDBeneficiaryId = useBeneficiariesState(
    (state) => state.setDBeneficiaryId
  );
  // props: { beneficiary: BeneficiaryType }

  const {
    iban,
    name,
    accountNumber,
    bank,
    destinationType,
    beneficiaryId,
    id,
  } = beneficiary;

  let character = name
    ?.charAt(0)
    .toUpperCase()
    ?.match(/\b(\w)/g);

  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  return (
    <>
      <div
        key={beneficiary.id}
        onClick={() => {
          setSelectedBeneficiaryId(beneficiaryId);
          setShowBeneficiaryModal(true);
          setDBeneficiaryId(id);
        }}
        className=" border border-[#0eb683] gap-4 bg-white w-full h-28  rounded-[16px] flex md:flex-col items-center md:h-48 p-5 transition-all active:scale-90 cursor-pointer  "
      >
        <div className="">
          <div className="bg-[#E5F2EA] flex justify-center align-middle items-center p-3 h-14 w-14  text-center text-[#0eb683] font-extrabold rounded-full self-center  ">
            <p className="font-bold text-lg">{character || "?"} </p>
          </div>
        </div>
        <div className="name  ">
          <h1 className="text-[#071827] font-bold  text-xs whitespace-nowrap md:text-center ">
            {truncateString(name?.toUpperCase(), 30) || "undefined"}
          </h1>
          <div>
            <div className="">
              <p className="text-[#5C6F7F] font-bold md:text-center text-sm">
                {truncateString(bank, 22)}
              </p>
            </div>
            <div className="md:block flex justify-between">
              <p className=" text-xs font-bold   md:text-center text-[#071827]">
                {" "}
                {accountNumber || iban}
              </p>
              <div className="hidden md:text-center md:block mr-5">
                <h1 className="font-bold text-[#0eb683] text-sm md:ml-4 ">
                  {destinationType === "usa"
                    ? "ABA"
                    : destinationType === "noneIban"
                    ? "SWIFT / BIC"
                    : destinationType?.toUpperCase()}
                </h1>
              </div>
            </div>
            <div className=" md:text-center md:hidden mr-5">
              <h1 className="font-bold text-[#0eb683] text-sm md:ml-4 ">
                {destinationType === "usa"
                  ? "ABA"
                  : destinationType === "noneIban"
                  ? "SWIFT / BIC"
                  : destinationType?.toUpperCase()}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiaryCard;

export const StableBeneficiaryCard: React.FC<{
  beneficiary: StableCoinBeneficiary;
}> = ({ beneficiary }) => {
  const setShowBeneficiaryModal = useBeneficiariesState(
    (state) => state.setShowBeneficiaryDetails
  );
  const setSelectedBeneficiaryId = useBeneficiariesState(
    (state) => state.setSelectedBeneficiaryId
  );
  const setDBeneficiaryId = useBeneficiariesState(
    (state) => state.setDBeneficiaryId
  );

  const { alias, coin, chain, beneficiaryName, beneficiaryAddress, id, user } =
    beneficiary;

  let character = beneficiaryName.charAt(0).toUpperCase();

  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  return (
    <div
      key={id}
      onClick={() => {
        setSelectedBeneficiaryId(id);
        setShowBeneficiaryModal(true);
        setDBeneficiaryId(id);
      }}
      className="border-2 border-[#0eb683] gap-4 bg-white w-full h-28 rounded-[16px] flex md:flex-col items-center md:h-48 p-5 transition-all active:scale-90 cursor-pointer"
    >
      <div>
        <div className="bg-[#E5F2EA] flex justify-center align-middle items-center p-3 h-14 w-14 text-center text-[#0eb683] font-extrabold rounded-full self-center">
          <p className="font-bold text-lg">{character || "?"}</p>
        </div>
      </div>
      <div className="name">
        <h1 className="text-[#071827] font-bold text-xs whitespace-nowrap md:text-center">
          {truncateString(beneficiaryName.toUpperCase(), 30) || "undefined"}
        </h1>
        <div>
          <div>
            <p className="text-[#5C6F7F] font-bold md:text-center text-sm">
              {truncateString(user.userBankName, 22)}
            </p>
          </div>
          <div className="md:block flex justify-between">
            <p className="text-xs font-bold md:text-center text-[#071827]">
              {truncateAddress(beneficiaryAddress)}
            </p>
            <div className="hidden md:text-center md:block mr-5">
              <h1 className="font-bold text-[#0eb683] text-sm md:ml-4">
                {coin.toUpperCase()} - [{chain.toUpperCase()}]
              </h1>
            </div>
          </div>
          <div className="md:text-center md:hidden mr-5">
            <h1 className="font-bold text-[#0eb683] text-sm md:ml-4">
              {coin.toUpperCase()} - [{chain.toUpperCase()}]
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};
