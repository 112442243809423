import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";

import { useSidebarState } from "../../store";
import ReactLoading from "react-loading";

import { IoMdClose } from "react-icons/io";
import Input from "../../pages/onboarding/comp/Input";
import AppCheckBox from "../widgets/AppCheckBox";
import { useCardRequest } from "../../sevices/hooks/cards";
import { useOutletContext } from "react-router-dom";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
};

interface AddCreditCardModalProps {
  refetch: () => void;
}

const AddCreditCardModal: React.FC<AddCreditCardModalProps> = ({ refetch }) => {
  const createCardModal = useSidebarState((state) => state.createCardModal);
  const [check, setCheck] = useState(false);
  const [userDetails] = useOutletContext<any>();
  const [userName, setUserName] = useState("");
  const { firstName, lastName } = userDetails;
  const registerCard = useCardRequest();
  const setCreateCardModal = useSidebarState(
    (state) => state.setCreateCardModal
  );
  const [cardDetails, setCardDetails] = useState({
    cardLabel: "",
    cardPin: "",
    confirmCardPin: "",
    number: "",
  });
  const handleInputChange = (label: string) => (newValue: string) => {
    setCardDetails((prevDetails) => ({
      ...prevDetails,
      number: "53",
    }));
    if (label === "cardLabel") {
      // Combine firstName and lastName
      const combinedName = `${userDetails.firstName} ${userDetails.lastName}`;
      // Truncate combinedName if it exceeds 20 characters
     let  updatedValue =
        combinedName.length > 20
          ? combinedName.substring(0, 20) + "..."
          : combinedName;
      setUserName(updatedValue);
    }

    setCardDetails((prevDetails) => {
      // if (label === "cardLabel" && newValue.length > 20) {
      //   newValue = newValue.substring(0, 20) + "...";
      // }

      return {
        ...prevDetails,
        [label]: newValue,
      };
    });
  };

  // const handleInputChange = (label: string) => (newValue: string) => {
  //   setCardDetails((prevDetails) => ({
  //     ...prevDetails,
  //     number: "53",
  //   }));
  //   setCardDetails((prevDetails) => {
  //     let updatedValue = newValue;

  //     if (label === "cardLabel") {
  //       // Combine firstName and lastName
  //       const combinedName = `${userDetails.firstName} ${userDetails.lastName}`;
  //       // Truncate combinedName if it exceeds 20 characters
  //       updatedValue =
  //         combinedName.length > 20
  //           ? combinedName.substring(0, 20) + "..."
  //           : combinedName;
  //     }

  //     return {
  //       ...prevDetails,
  //       [label]: updatedValue,
  //     };
  //   });
  // };

  const handleInputFocus = (evt: { target: { name: any } }) => {
    setCardDetails((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<FormData>({});
  const watchAllFields = watch();
  const closeModal = () => {
    setCreateCardModal(false);
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    setLoading(true);
    // console.log("Form Data:", data);
    // console.log("Card Details:", cardDetails);
    registerCard
      .mutateAsync({
        pin: cardDetails.cardPin,
        card_label: cardDetails.cardLabel,
      })
      .then((res) => {
        // console.log(res);
        setLoading(false);
        refetch();
        setCreateCardModal(false);
      })
      .catch((err) => {
        // console.error(err);
        setLoading(false);
      });
  };

  const isButtonDisabled =
    !cardDetails.cardLabel ||
    !cardDetails.cardPin ||
    cardDetails.cardPin.length !== 4 ||
    cardDetails.cardPin !== cardDetails.confirmCardPin ||
    !check ||
    loading;

  return (
    <>
      <AnimatePresence>
        {createCardModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
            className="modal fixed w-full h-full top-0 left-0 flex justify-center items-center z-50 bg-[#13111173]"
          >
            <motion.div
              animate={{ scale: 1, transition: { duration: 0.3 } }}
              exit={{ scale: 0, transition: { delay: 0.3 } }}
              className="modal_overlay flex xxs:justify-center xxs:p-5 md:p-0 md:justify-end h-full w-full"
            >
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { delay: 0.3, duration: 0.3 },
                }}
                exit={{ x: 100, opacity: 0, transition: { duration: 0.3 } }}
                className="flex flex-col bg-white w-full max-w-sm md:max-w-sm h-full md:h-full relative xxs:rounded-md md:rounded-l-md p-5 md:px-7 overflow-y-auto"
              >
                <div className="flex items-center justify-between mb-10">
                  <h2 className="text-xl font-semibold">
                    Create new virtual card
                  </h2>
                  <div
                    onClick={closeModal}
                    className="close flex items-center justify-center h-10 w-10 rounded-full hover:bg-[#F7FBFB] p-3 hover:rounded-full hover:text-center self-end"
                  >
                    <span className="hover:rotate-90 hover:transform transition duration-150 ease-in-out">
                      <IoMdClose size={29} className="" />
                    </span>
                  </div>
                </div>
                <div className="">
                  {/* <Cards
                    number={cardDetails.number}
                    name={cardDetails.cardLabel}
                    expiry={""}
                    cvc={cardDetails.cardPin}
                    focused="number"
                  /> */}

                  <Cards
                    number={cardDetails.number}
                    name={userName}
                    expiry={""}
                    cvc={cardDetails.cardPin}
                    focused="number"
                  />
                </div>
                <div className="flex flex-col items-center w-full gap-3  h-full mt-8">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-[#5C6F7F] text-[16px] leading-[24px]">
                      Cardholder Name
                    </p>
                    <p className="text-[#071827] text-[16px] leading-[24px] truncate w-[140px] text-left flex items-end justify-end">
                      {firstName} {lastName}
                    </p>
                  </div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col w-full gap-4  flex-grow"
                  >
                    <div className="relative w-full">
                      <Input
                        label="Card Label"
                        type="text"
                        // pattern="\d*"
                        name="cardLabel"
                        required={true}
                        // errorMessages={errorMessages}
                        // maxChar={10}
                        onChange={handleInputChange("cardLabel")}
                        onFocus={handleInputFocus}
                      />
                      {/* <span className="text-red-600 absolute left-3 top-4">*</span> */}
                      <div className="text-red-500 text-xs">
                        {/* {errorMessages.accountNumber} */}
                      </div>
                    </div>

                    <div className="relative w-full">
                      <Input
                        label="Create 4-digit PIN"
                        type="password"
                        // pattern="\d*"
                        name="cardPin"
                        required={true}
                        // errorMessages={errorMessages}
                        maxChar={4}
                        onChange={handleInputChange("cardPin")}
                        onFocus={handleInputFocus}
                      />
                      {/* <span className="text-red-600 absolute left-3 top-4">*</span> */}
                      <div className="text-red-500 text-xs">
                        {/* {errorMessages.accountNumber} */}
                      </div>
                    </div>

                    <div className="relative w-full">
                      <Input
                        label="Confirm 4-digit PIN"
                        type="password"
                        // pattern="\d*"
                        name="confirmCardPin"
                        required={true}
                        // errorMessages={errorMessages}
                        maxChar={4}
                        onChange={handleInputChange("confirmCardPin")}
                        onFocus={handleInputFocus}
                      />
                      {/* <span className="text-red-600 absolute left-3 top-4">*</span> */}
                      <div className="text-red-500 text-xs">
                        {/* {errorMessages.accountNumber} */}
                      </div>
                    </div>
                    <div className="flex gap-4 ">
                      <div className="mt-1">
                        <AppCheckBox
                          getChecked={(value: boolean) => {
                            setCheck(value);
                          }}
                        />
                      </div>
                      <div>
                        <small className=" xxs:text-left  md:text-sm ">
                          By creating your virtual USD card, you accept our {" "}
                          <span className="text-[#0eb683] hover:underline">
                            terms and conditions.
                          </span>{" "}
                          The card is created by TTFCX Enterprises LLC, pursuant
                          to license from its issuing partner at Mastercard Inc.
                        </small>
                      </div>
                    </div>
                    {/* <button
                      type="submit"
                      className={`text-white w-full bg-[#0eb683] rounded-[16px] px-10 py-3.5 mt-auto transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 item-center justify-center flex ${
                        isButtonDisabled && "disabled"
                      }`}
                      disabled={isButtonDisabled}
                    >
                      {loading ? (
                        <div className="mx-auto">
                          <ReactLoading
                            type="spinningBubbles"
                            color="#fff"
                            height="27px"
                            width="27px"
                          />
                        </div>
                      ) : (
                        "Create Card"
                      )}
                    </button> */}

                    <button
                      type="submit"
                      className={`text-white w-full bg-[#0eb683] rounded-[16px] px-10 py-3.5 mt-auto transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90 item-center justify-center flex`}
                      disabled={isButtonDisabled} // disable the button based on the conditions
                    >
                      {loading ? (
                        <div className="mx-auto">
                          <ReactLoading
                            type="spinningBubbles"
                            color="#fff"
                            height="27px"
                            width="27px"
                          />
                        </div>
                      ) : (
                        "Create Card"
                      )}
                    </button>
                  </form>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AddCreditCardModal;
