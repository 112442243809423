import React from "react";
import { motion } from "framer-motion";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
// import { IRequestAccess, useRequest } from "../../store/useRequestAccess";
import { useNavigate } from "react-router-dom";
import FailedIcon from "../../assets/iconsComponents/FailedIcon";
interface IRequestAccess {
  setModal: (arg: boolean) => void;
  showModal: boolean;
  success: boolean;
  failed: boolean;
}
const RequestAccessModal = ({
  setModal,
  showModal,
  success,
  failed,
}: IRequestAccess) => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              // delay: 0.3,
              duration: 0.1,
            },
          }}
          className="modal  fixed w-full h-full top-0 left-0 justify-center items-center  duration-300  "
        >
          <motion.div
            initial={{
              x: 600,
              scale: 0,
            }}
            animate={{
              x: 0,
              scale: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              x: 600,
              scale: 0,
              transition: {
                delay: 0.3,
              },
            }}
            className="modal_overlay  flex justify-center xxs:p-5 md:p-0   h-screen items-center cursor-pointer bg-[#13111173] "
          >
            <motion.div
              initial={{
                x: 600,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              }}
              exit={{
                x: 100,
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              className=" md:w-5/12 w-[90%] bg-[#fff] rounded-xl shadow-lg p-6 md:h-min h-[90%]  flex items-center justify-center  "
            >
              {success && (
                <div className="flex bg-[#fff] w-full flex-col gap-4 ">
                  <div className="icon self-center">
                    <SuccessMobile width={"10px"} height={"10px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                    Request submitted
                  </h1>

                  <p className="  text-sm text-[#5C6F7F] self-center text-center">
                    Your request for access has been submitted
                    <br />
                    Please check your email
                  </p>

                  <button
                    onClick={() => {
                      setModal(false);
                      navigate("/");
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                  >
                    Done
                  </button>
                </div>
              )}

              {failed && (
                <div className="flex flex-col gap-4 ">
                  <div className="icon self-center">
                    <FailedIcon width={"100px"} height={"100px"} />
                  </div>
                  <h1 className="text-2xl font-bold text-[#071827] self-center">
                    Error Occurred
                  </h1>

                  <p className="  text-sm text-[#5C6F7F] self-center text-center">
                    Please check your values <br />
                    and try again
                  </p>

                  <button
                    onClick={() => {
                      setModal(false);
                    }}
                    className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                  >
                    Retry
                  </button>
                </div>
              )}
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default RequestAccessModal;
