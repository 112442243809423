// useRateStore.ts
import create from "zustand";

interface Rate {
  buyRate: number;
  buyMinValue: string;
  sellRate: number;
  sellMinValue: string;
}

interface RatesState {
  rates: { [key: string]: Rate }; 
  setRate: (currency: string, rate: Rate) => void;
}

export const useRatesStore = create<RatesState>((set) => ({
  rates: {},
  setRate: (currency, rate) =>
    set((state) => ({
      rates: {
        ...state.rates,
        [currency]: rate,
      },
    })),
}));
