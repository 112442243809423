import React, { useState, InputHTMLAttributes } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label: string;
  name: string;
  type: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  errorMessages?: any;
  maxChar?: number;
  onChange: (newValue: string) => void;
  bgColor?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  type,
  required,
  disabled,
  errorMessages,
  bgColor,
  value,
  maxChar,
  onChange,
  onFocus,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    // Check if the type is "number" and allow only digits
    if (type === "number") {
      newValue = newValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    }

    // Ensure the value does not exceed max characters if maxChar is set
    if (maxChar && newValue.length > maxChar) {
      newValue = newValue.slice(0, maxChar);
    }

    onChange(newValue);
  };

  return (
    <div className="relative z-0 w-full">
      <input
        {...rest}
        id={name}
        type={type === "password" && showPassword ? "text" : type}
        disabled={disabled}
        placeholder=" "
        value={value}
        onFocus={onFocus}
        autoFocus={false}
        onChange={handleChange}
        required={required}
        className={`pt-3.5 pb-2.5 block w-full px-2.5 mt-0 bg-white ${
          bgColor ? "md:bg-[#fff]" : "md:bg-[#F2FAF7]"
        } border rounded-[4px] ${
          errorMessages?.[name]
            ? "border-[#E01A00]"
            : "appearance-none focus:outline-none outline-[#0eb683] focus:ring-0 focus:border-[#0eb683] border-gray-200"
        }`}
      />
      <label
        htmlFor={name}
        className="absolute duration-300 top-3 -z-1 origin-0 text-[#6F798B] text-[14px] leading-[21px] font-normal pl-3"
      >
        {required && <span className="text-[#E01A00]">*</span>}
        {label}
      </label>
      {type === "password" && (
        <span
          onClick={handleTogglePassword}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
        >
          {showPassword ? (
            <AiFillEyeInvisible size={24} />
          ) : (
            <AiFillEye size={24} />
          )}
        </span>
      )}
    </div>
  );
};

export default Input;
