import card from "../../assets/landing-asset/Card services-2.png";
import mobile from "../../assets/landing-asset/Mobile app-3.png";
import API from "../../assets/landing-asset/API Access.png";
import rate from "../../assets/landing-asset/Free Dynamic Live Rates.png";
import multiCurrency from "../../assets/landing-asset/lcy-2.png";
import swaps from "../../assets/landing-asset/lcy-3.png";
import international from "../../assets/landing-asset/International Transfers.png";
import compliance from "../../assets/landing-asset/Extended Compliance.png";
import security from "../../assets/landing-asset/Authentication & Security.png";
import { PiStarFourFill } from "react-icons/pi";
import LandingContact from "./LandingContact";

const LandingMid = () => {
  return (
    <div className=" pt-10 px-4 md:pt-20 md:px-[8%]">
      <ResponsiveBoxes image={rate} />
      <Box2 image={card} />
      {/* <Box2 image={multiCurrency} /> */}
      <Box3 image={swaps} />
      <Box4 image={international} />
      <Box5 image={security} />
      <Box6 image={compliance} />
      <div>
        <LandingContact />
      </div>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 justify-center my-14 w-full">
          <div className="col-span-1 h-[100%] bg-[#fff] p-4 rounded-[32px] flex flex-col items-center justify-items-center border border-[#CBE0D6]">
            <div className="flex items-center justify-between w-full pb-2">
              <p className="bold text-[#071827] text-base font-semibold leading-tight tracking-tight 3xl:text-[20px] 3xl:leading-[30px]">
                Multi-Currency Accounts
              </p>
              <p className="light text-[#FD784C] text-center text-xs font-normal leading-6 bg-[#FFEEE9] rounded-full px-2 3xl:text-[20px] 3xl:leading-[30px]">
                Coming Soon
              </p>
            </div>
            <img
              src={multiCurrency}
              alt=" NEWImage"
              className="w-full h-full object-center rounded-lg"
            />
          </div>
          <div className="col-span-1 bg-[#fff] p-4 rounded-[32px] flex flex-col items-center border border-[#CBE0D6] h-[100%]">
            <div className="flex items-center justify-between w-full pb-2">
              <p className="bold text-[#071827] text-base font-semibold leading-tight tracking-tight 3xl:text-[20px] 3xl:leading-[30px]">
                Mobile App
              </p>
              <p className="light text-[#FD784C] text-center text-xs font-normal leading-6 bg-[#FFEEE9] rounded-full px-2 3xl:text-[20px] 3xl:leading-[30px]">
                Coming Soon
              </p>
            </div>
            <img
              src={mobile}
              alt="mOBILE_Image"
              className="w-[80%] h-[90%] object-center rounded-lg"
            />
          </div>
          <div className="col-span-1 h-[100%] bg-[#fff] p-4 rounded-[32px] flex flex-col justify-normal items-center border border-[#CBE0D6]">
            <div className="flex items-center justify-between w-full pb-2">
              <p className="bold text-[#071827] text-base font-semibold leading-tight tracking-tight 3xl:text-[20px] 3xl:leading-[30px]">
                API Access
              </p>
              <p className="light text-[#FD784C] text-center text-xs font-normal leading-6 bg-[#FFEEE9] rounded-full px-2 3xl:text-[20px] 3xl:leading-[30px]">
                Coming Soon
              </p>
            </div>
            <img
              src={API}
              alt="API_Image"
              className="h-full w-full rounded-lg object-cover"
            />
          </div>
        </div>

        {/* mobile */}
        {/* <div className="space-y-4 mt-8 md:hidden block">
          <div className="w-full bg-[#fff] p-4 rounded-[32px]  flex flex-col items-center justify-items-center border border-[#CBE0D6]">
            <div className="flex items-center justify-between w-full pb-2">
              <p className="bold text-[#071827] text-xs font-semibold leading-tight tracking-tight 3xl:text-[20px] 3xl:leading-[30px]">
                Card Services
              </p>
              <p className="light text-[#FD784C] text-center text-xs font-normal leading-6 bg-[#FFEEE9] rounded-full px-2 3xl:text-[20px] 3xl:leading-[30px]">
                Coming Soon
              </p>
            </div>
            <img
              src={card}
              alt=" Image"
              className=" w-full  h-auto rounded-lg"
            />
          </div>
          <div className="w-full bg-[#fff] p-4 rounded-[32px]  flex flex-col justify-normal items-center border border-[#CBE0D6]">
            <div className="flex items-center justify-between w-full pb-2">
              <p className="bold text-[#071827] text-xs font-semibold leading-tight tracking-tight 3xl:text-[20px] 3xl:leading-[30px]">
                API Access
              </p>
              <p className="light text-[#FD784C] text-center text-xs font-normal leading-6 bg-[#FFEEE9] rounded-full px-2 3xl:text-[20px] 3xl:leading-[30px]">
                Coming Soon
              </p>
            </div>
            <img src={API} alt=" Image" className="w-full h-auto rounded-lg" />
          </div>
          <div className="w-full bg-[#fff] p-4 rounded-[32px]  flex flex-col items-center border border-[#CBE0D6]">
            <div className="flex items-center justify-between w-full pb-2">
              <p className="bold text-[#071827] text-xs font-semibold leading-tight tracking-tight 3xl:text-[20px] 3xl:leading-[30px]">
                Mobile App
              </p>
              <p className="light text-[#FD784C] text-center text-xs font-normal leading-6 bg-[#FFEEE9] rounded-full px-2 3xl:text-[20px] 3xl:leading-[30px]">
                Coming Soon
              </p>
            </div>
            <img
              src={mobile}
              alt=" Image"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default LandingMid;

// import React from 'react';

const ResponsiveBoxes = ({ image }: { image: string }) => {
  return (
    <div className="flex flex-col md:flex-row">
      {/* Box with Text */}
      <div className="md:w-1/2  py-8  flex rounded-[32px] items-center">
        <div className="text-left ">
          <h2 className="bold md:text-3xl text-[20px] leading-[28px] text-[#15112D] font-bold 3xl:text-[73px] 3xl:leading-[90px]">
            Free Dynamic Live Rates
          </h2>
          <p className="light md:text-[20px] text-[14px] text-[#393939] font-light md:leading-[30px] leading-[19.6px] text-left mt-3 3xl:text-[33px] 3xl:leading-[40px]">
            Get the best rates for available currencies
          </p>
        </div>
      </div>

      {/* Box with Image */}
      <div className="md:w-1/2 bg-[#EAF2FD] p-8 md:p-16 md:h-full flex items-center justify-center rounded-[32px]">
        <img
          className="max-w-full max-h-full md:max-w-full md:max-h-full"
          src={image}
          alt="YourImage"
        />
      </div>
    </div>
  );
};
const Box2 = ({ image }: { image: string }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:w-1/2  py-8  flex rounded-[32px] items-center md:hidden">
        <div className="text-left ">
          <h2 className="bold md:text-3xl text-[20px] leading-[28px] text-[#15112D] font-bold ">
            Card Services
          </h2>
          <p className="light md:text-[20px] text-[14px] text-[#393939] font-light md:leading-[30px] leading-[19.6px] text-left mt-3 ">
            Secure virtual cards for seamless global transactions
          </p>
        </div>
      </div>
      <div className="md:w-1/2 bg-[#FCF2E8] p-8 md:p-16 md:h-full flex items-center justify-center rounded-[32px]">
        <img
          className="max-w-full max-h-full md:max-w-full md:max-h-full"
          src={image}
          alt="YouImage"
        />
      </div>
      {/* Box with Text */}
      <div className="md:w-1/2  p-16  md:flex rounded-[32px] items-center justify-center hidden">
        <div className="text-left ">
          <h2 className="bold text-3xl font-bold 3xl:text-[73px] 3xl:leading-[90px]">
            Card Services
          </h2>
          <p className="light text-[20px] text-[#393939] font-light leading-[30px] text-left mt-4 3xl:text-[33px] 3xl:leading-[40px]">
            Secure virtual cards for seamless global transactions
          </p>
        </div>
      </div>
    </div>
  );
};
const Box3 = ({ image }: { image: string }) => {
  return (
    <div className="flex flex-col md:flex-row">
      {/* Box with Text */}
      <div className="md:w-1/2  md:py-16 py-8  flex rounded-[32px] items-center  ">
        <div className="text-left ">
          <h2 className=" bold md:text-3xl text-[20px] leading-[28px] text-[#15112D] font-bold 3xl:text-[73px] 3xl:leading-[90px]">
            Currency Swaps & Conversions
          </h2>
          <div className="text-[20px] text-[#393939] font-light leading-[30px] text-left mt-4    space-y-3">
            <div className="flex items-center gap-5">
              <span>
                <PiStarFourFill size={10} color={"#0eb683"} />
              </span>
              <p className="light md:text-[20px] text-[14px] text-[#393939] font-light md:leading-[30px] leading-[19.6px] text-left 3xl:text-[33px] 3xl:leading-[40px]">
                Local Fiat Swaps for Foreign Currency
              </p>
            </div>
            <div className="flex items-center gap-5">
              <span>
                <PiStarFourFill size={10} color={"#0eb683"} />
              </span>
              <p className="light md:text-[20px] text-[14px] text-[#393939] font-light md:leading-[30px] leading-[19.6px] text-left 3xl:text-[33px] 3xl:leading-[40px]">
                Foreign Currency Swaps for Local Fiat
              </p>
            </div>
            <div className="flex items-center gap-5">
              <span>
                <PiStarFourFill size={10} color={"#0eb683"} />
              </span>
              <p className="light md:text-[20px] text-[14px] text-[#393939] font-light md:leading-[30px] leading-[19.6px] text-left 3xl:text-[33px] 3xl:leading-[40px]">
                Swaps between foreign currencies
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-1/2 bg-[#ECFAF2] p-8 md:p-16 md:h-full flex items-center justify-center rounded-[32px]">
        <img
          className="max-w-full max-h-full md:max-w-full md:max-h-full"
          src={image}
          alt="UImage"
        />
      </div>
    </div>
  );
};
const Box4 = ({ image }: { image: string }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:w-1/2  py-8  flex rounded-[32px] items-center md:hidden">
        <div className="text-left ">
          <h2 className="bold md:text-3xl text-[20px] leading-[28px] text-[#15112D] font-bold ">
            International Transfers
          </h2>
          <p className="light md:text-[20px] text-[14px] text-[#393939] font-light md:leading-[30px] leading-[19.6px] text-left mt-3">
            Access to international payment rails allowing you make global
            payments
          </p>
        </div>
      </div>

      <div className="md:w-1/2 bg-[#EAF2FD] p-8 md:p-16 md:h-full flex items-center justify-center rounded-[32px]">
        <img
          className="max-w-full max-h-full md:max-w-full md:max-h-full"
          src={image}
          alt="Ymage"
        />
      </div>
      {/* Box with Text */}
      <div className="md:w-1/2  p-16  md:flex rounded-[32px] items-center justify-center hidden">
        <div className="text-left ">
          <h2 className="bold text-3xl font-bold 3xl:text-[73px] 3xl:leading-[90px]">
            International Transfers
          </h2>
          <p className="light text-[20px] text-[#393939] font-light leading-[30px] text-left mt-4 3xl:text-[33px] 3xl:leading-[40px]">
            Access to international payment rails allowing you make global
            payments
          </p>
        </div>
      </div>
    </div>
  );
};

const Box5 = ({ image }: { image: string }) => {
  return (
    <div className="flex flex-col md:flex-row">
      {/* Box with Text */}
      <div className="md:w-1/2  md:py-16 py-8  flex rounded-[32px] items-center  ">
        <div className="text-left ">
          <h2 className="bold md:text-3xl text-[20px] leading-[28px] text-[#15112D] font-bold 3xl:text-[73px] 3xl:leading-[90px]">
            Authentication & Security
          </h2>
          <div className="text-[20px] text-[#393939] font-light leading-[30px] text-left mt-4    space-y-3">
            <div className="flex items-center gap-5">
              <span>
                <PiStarFourFill size={10} color={"#0eb683"} />
              </span>
              <p className="light md:text-[20px] text-[14px] text-[#393939] font-light md:leading-[30px] leading-[19.6px] text-left 3xl:text-[33px] 3xl:leading-[40px]">
                2 - Factor Authentication
              </p>
            </div>
            <div className="flex items-center gap-5">
              <span>
                <PiStarFourFill size={10} color={"#0eb683"} />
              </span>
              <p className="light md:text-[20px] text-[14px] text-[#393939] font-light md:leading-[30px] leading-[19.6px] text-left 3xl:text-[33px] 3xl:leading-[40px]">
                256 - Bit Encryption
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-1/2 bg-[#FCF2E8] p-8 md:p-16 md:h-full flex items-center justify-center rounded-[32px]">
        <img
          className="max-w-full max-h-full md:max-w-full md:max-h-full"
          src={image}
          alt="Ymage"
        />
      </div>
    </div>
  );
};
const Box6 = ({ image }: { image: string }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:w-1/2  py-8  flex rounded-[32px] items-center md:hidden">
        <div className="text-left ">
          <h2 className="bold md:text-3xl text-[20px] leading-[28px] text-[#15112D] font-bold ">
            Extended Compliance
          </h2>
          <p className="light md:text-[20px] text-[14px] text-[#393939] font-light md:leading-[30px] leading-[19.6px] text-left mt-3">
            Enhanced KYC, KYB & Transaction Monitoring
          </p>
        </div>
      </div>
      <div className="md:w-1/2 bg-[#ECFAF2] p-8 md:p-16 md:h-full flex items-center justify-center rounded-[32px]">
        <img
          className="max-w-full max-h-full md:max-w-full md:max-h-full"
          src={image}
          alt="Yomage"
        />
      </div>
      {/* Box with Text */}
      <div className="md:w-1/2  p-16  md:flex rounded-[32px] items-center justify-center hidden">
        <div className="text-left ">
          <h2 className="bold text-3xl font-bold 3xl:text-[73px] 3xl:leading-[90px]">
            Extended Compliance
          </h2>
          <p className="light text-[20px] text-[#393939] font-light leading-[30px] text-left mt-4 3xl:text-[33px] 3xl:leading-[40px]">
            Enhanced KYC, KYB & Transaction Monitoring
          </p>
        </div>
      </div>
    </div>
  );
};

// export default ResponsiveBoxes;
