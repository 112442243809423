import React, { SVGProps } from "react";

const USDTIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <div>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="60" height="60" rx="30" fill="white" />
        <g clip-path="url(#clip0_15881_53473)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0564 10.1965L8.03365 27.8075C8.00313 27.873 7.99345 27.947 8.00605 28.0186C8.01864 28.0903 8.05284 28.1558 8.10365 28.2057L29.7712 49.9038C29.8327 49.9655 29.9147 50 30 50C30.0852 50 30.1672 49.9655 30.2287 49.9038L51.8962 28.2071C51.9471 28.1572 51.9813 28.0916 51.9939 28.02C52.0064 27.9483 51.9968 27.8743 51.9662 27.8088L43.9435 10.1978C43.9176 10.1387 43.876 10.0886 43.8236 10.0535C43.7711 10.0184 43.7102 9.99985 43.6479 10H16.3545C16.292 9.99921 16.2306 10.0173 16.1777 10.0522C16.1248 10.087 16.0827 10.1371 16.0564 10.1965Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.7838 29.6152C32.6283 29.6274 31.8246 29.6776 30.0318 29.6776C28.6059 29.6776 27.5935 29.6328 27.2383 29.6152C21.7277 29.3619 17.6146 28.3594 17.6146 27.1592C17.6146 25.9589 21.7277 24.9578 27.2383 24.7004V28.6168C27.5986 28.6439 28.6305 28.7076 30.0564 28.7076C31.7675 28.7076 32.6244 28.6331 32.7786 28.6182V24.7031C38.2775 24.9592 42.3816 25.9616 42.3816 27.1592C42.3816 28.3567 38.2788 29.3592 32.7786 29.6139L32.7838 29.6152ZM32.7838 24.2981V20.7935H40.4579V15.4492H19.5642V20.7935H27.237V24.2967C21.0005 24.5961 16.3105 25.8871 16.3105 27.4342C16.3105 28.9812 21.0005 30.2709 27.237 30.5716V41.802H32.7825V30.5676C39.0047 30.2682 43.6869 28.9785 43.6869 27.4328C43.6869 25.8871 39.0086 24.5975 32.7825 24.2967L32.7838 24.2981Z"
            fill="#50AF95"
          />
        </g>
        <defs>
          <clipPath id="clip0_15881_53473">
            <rect
              width="44"
              height="40"
              fill="white"
              transform="translate(8 10)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default USDTIcon;
