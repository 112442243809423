import { ReactNode } from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { IoLogoWechat } from "react-icons/io5";
import { Link } from "react-router-dom";

interface IStep {
  icon: () => ReactNode;
  title: string;
  description: string;
  showAngle?: boolean;
}

const Step = ({ icon, title, description, showAngle = true }: IStep) => {
  return (
    <div className="step flex md:flex-col items-center w-full gap-4 md:gap-2 relative">
      <div className="icon w-12 h-12 rounded-full bg-[#0EB683] text-white flex justify-center items-center shrink-0 mb-10 md:mb-0">
        {icon()}
      </div>

      <div className="md:text-center">
        <h3 className="text-lg font-semibold ">{title}</h3>
        <p className="">{description}</p>
      </div>
      {showAngle && (
        <AiOutlineDoubleRight className="absolute top-[5%] -right-5 hidden md:inline-block" />
      )}
    </div>
  );
};
 const handleClick = () => {
   window.open("https://links.ttfcx.com/connect", "_blank");
 };
const LandingPageMid = () => {
  return (
    <div className="md:px-[8%] px-4 md:py-20 py-10 ">
      <div className="bg-[#014584] py-10  text-center px-3 rounded-[32px]">
        <div className="text  ">
          <h1 className="bold text-[#fff] text-[24px] leading-[33.5px] md:text-5xl font-bold pb-7 md:p-10 3xl:text-[73px] 3xl:leading-[90px]">
            Join 100s of customers <br /> trusting us
          </h1>
          <p className="light text-[#fff] text-[14px] leading-[19px] md:text-[20px]  3xl:text-[33px] 3xl:leading-[40px]">
            Gain access to constantly deliver your business payments within 3
            business days
          </p>
        </div>
        <div className="flex flex-col items-center justify-center gap-4 md:flex-row md:hidden mt-8">
          <Link
            to="/request-access"
            className="light text-[#fff] bg-[#0eb683] p-4 rounded-full px-8 font-semibold shadow-md w-[200px] md:mr-4 3xl:text-[25px] 3xl:leading-[50px] border border-[#0eb683] "
          >
            Request Access
          </Link>

          <Link
            to={"#"}
            onClick={handleClick}
            className="light bg-[#fff] text-[#0eb683] p-4 rounded-full px-8 font-semibold shadow-md w-[200px] md:ml-4 3xl:text-[25px] 3xl:leading-[50px] border border-[#0eb683] flex items-center gap-1 justify-center"
          >
            <span>Talk to us</span> <IoLogoWechat fill={"#0eb683"} />
          </Link>
        </div>
        <div className="hidden flex-col items-center justify-center gap-3 md:gap-5 md:flex-row md:flex py-10">
          <Link
            to="/request-access"
            className="light text-[#fff] bg-[#0eb683] p-4 rounded-full px-8 font-semibold shadow-md  3xl:text-[25px] 3xl:leading-[50px] border border-[#0eb683] "
          >
            Request Access
          </Link>

          <Link
            to="/login"
            className="light bg-[#fff] text-[#0eb683] p-4 rounded-full px-8 font-semibold shadow-md 3xl:text-[25px] 3xl:leading-[50px] border border-[#0eb683] "
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPageMid;
