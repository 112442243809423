import React, { useState } from "react";
//
import { Link } from "react-router-dom";


const WalletCard = ({ icon, title, button, amount, currency, to }: any) => {


  return (
    <Link
      to={to}
      className="card flex flex-col gap-4 bg-white w-[310px] h-52    border border-[#CBE0D6] rounded-lg shrink-0 p-3 hover:shadow-md  relative mix-blend-normal overflow-hidden"
    >
      <div className="flex items-center my-auto  ">
        <div>
          <figure className="w-20 p-5">
            <img src={icon} alt="" />
          </figure>
        </div>
        <div>
          <h1 className="text-[#5C6F7F] text-lg"> {title}</h1>
          <p className="text-lg">
            {amount} <span className="font-bold">{currency}</span>{" "}
          </p>
        </div>

      </div>
      {/* <div className="absolute right-[-8px] top-[125px]">
        <div className="bg-slate-300 text-[#0eb683] transform rotate-45 origin-bottom-right absolute bottom-0 right-0 py-1 px-2 whitespace-nowrap w-44 text-center font-semibold  shadow-lg">
          Coming Soon
        </div>
      </div> */}
    </Link>
  );
};

export default WalletCard;
