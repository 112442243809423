import React, { useEffect, useLayoutEffect } from "react";
import LandingPageMid from "../../components/base-components/LandingPageMid";
import LandingPageSection from "../../components/base-components/LandingPageSection";
import LandingPageFooter from "../../components/base-components/LandingPageFooter";
import LandingPageHeader from "../../components/base-components/LandingPageHeader";
import LandingMid from "../../components/base-components/LandingMid";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  useEffect(() => {
    // Use a slight delay to ensure this runs after any automatic focusing
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 10); // 10ms delay; adjust as needed
  }, []);
  return (
    <div>
      <LandingPageHeader />
      <LandingMid />
      <LandingPageSection />
      <LandingPageMid />
      <LandingPageFooter />
    </div>
  );
};

export default LandingPage;
