import { useState } from "react";
import logoLight from "../assets/misc/logo-on-light.svg";
import Logo from "../assets/misc/logo-on-dark.svg";
import img1 from "../assets/images/onboarding/Onboarding1.png";
import img2 from "../assets/images/onboarding/onboarding2.png";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import Login from "../pages/authentication/Login";
import RequestAccess from "../pages/authentication/RequestAccess";
import { useRequest } from "../store/useRequestAccess";
import RequestAccessModal from "../pages/authentication/RequestAccessModal";
import { ToastContainer } from "react-toastify";

const AuthLayout = ({ isLogin }: { isLogin: boolean }) => {
  const setRequestModal = useRequest((state) => state.setShowRequestModal);
  const showRequestModal = useRequest((state) => state.showRequestModal);
  const success = useRequest((state) => state.isSuccess);
  const failed = useRequest((state) => state.isFailed);
  const setSuccess = useRequest((state) => state.setIsSuccess);
  const setFailed = useRequest((state) => state.setIsFailed);
  return (
    <>
      {/* <ToastContainer /> */}
      <div className="auth-layout lg:grid grid-cols-[1fr_auto] h-screen bg-[#0c0d1f] md:bg-white">
        <main className="flex flex-col p-5  md:px-[10%] h-full overflow-hidden">
          <a href="/">
            <img src={Logo} alt="" className="md:hidden w-40  m-auto  pb-5" />
            <img src={logoLight} className="w-32 hidden md:block" alt="" />
          </a>

          <div className="auth-wrapper max-w-md w-full transition-all self-center justify-self-center">
            <h1 className="text-3xl text-center font-semibold text-[#fff]  md:text-[#0eb683] pt-6">
              Welcome
            </h1>

            <div className="auth-box mt-8">
              <div className="tabs flex relative">
                <Link
                  to={"/login"}
                  className={`flex items-center justify-center text-md md:text-lg font-semibold flex-1 p-4 ${
                    !isLogin ? "text-[#aabecf]" : "text-[#0EB683]"
                  }`}
                >
                  Login
                </Link>
                <Link
                  to={"/request-access"}
                  className={`flex items-center justify-center text-sm  md:text-lg font-semibold flex-1 p-1 md:p-4 ${
                    isLogin ? "text-[#aabecf]" : "text-[#0EB683]"
                  }`}
                >
                  Request Access
                </Link>
                <div
                  className={`indicator h-[2px] w-1/2 bg-[#0EB683] transition-all duration-500 absolute bottom-0 ${
                    isLogin ? "left-0" : "left-[50%]"
                  } rounded-xl`}
                ></div>
              </div>

              <div className="tabs-content flex">
                <div
                  className={`login-wrapper max-w-md w-full transition-all duration-1000 shrink-0 ${
                    isLogin
                      ? "translate-x-0 opacity-100"
                      : "-translate-x-full opacity-0"
                  }`}
                >
                  <Login />
                </div>
                <div
                  className={`request-access-wrapper w-full transition-all duration-1000 shrink-0 ${
                    !isLogin
                      ? "-translate-x-full opacity-100 "
                      : "translate-x-100 opacity-0 hidden"
                  }`}
                >
                  {/* <Login /> */}
                  {/* request */}
                  <RequestAccess
                    setModal={setRequestModal}
                    setSuccess={setSuccess}
                    setFailed={setFailed}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        <figure className="bg-[#F7FBFB] w-[500px] xl:w-[600px] hidden lg:block transition-all duration-1000 text-center">
          <img src={img1} alt="" className="pt-[30px]  ml-10" />
          <div className="design flex justify-center items-center pt-5 pl-10">
            <div className=" h-[2px] w-40 bg-[#0eb683] rounded-md  "></div>
            <BsDot fill="#0eb683" size={30} />
            <div className=" h-[2px] w-40 bg-[#0eb683]  rounded-md "></div>
          </div>

          <div className="flex justify-end px-10">
            <img src={img2} alt="" className=" mt-1 " />
          </div>
        </figure>
      </div>
      {showRequestModal && (
        <RequestAccessModal
          showModal={showRequestModal}
          setModal={setRequestModal}
          success={success}
          failed={failed}

        />
      )}
    </>
  );
};

export default AuthLayout;
