import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useUsers } from "../../sevices/hooks/users";
interface Props {
  setShowProfileDrop: React.Dispatch<React.SetStateAction<boolean>>;
  showProfileDrop: boolean;
}
const PopOver = ({ showProfileDrop, setShowProfileDrop }: Props) => {
  const getUserDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );
  return (
    <div>
      {showProfileDrop && (
        <div className="bg-white w-60 h-fit border absolute top-10 right-[-1px] mt-1 flex flex-col rounded-xl  pt-1 shadow-md ">
          <button
            onClick={() => setShowProfileDrop(false)}
            className="self-end hover:bg-slate-200 cursor-pointer rounded-full mt-1 mr-3 "
          >
            <AiOutlineClose size={24} />
          </button>

          <div className="border-2 border-[#201f1f] h-12 w-12 rounded-full flex  justify-center self-center mb-3">
            <figure className=" bg-[#3a3737] h-10 w-10 rounded-full self-center relative flex">
              <label
                htmlFor="file_input"
                className="w-full h-full rounded-full text-gray-300 flex items-center justify-center cursor-pointer absolute top-0 left-0 transition-all active:scale-90"
              >
                <FaUserAlt size={28} />
              </label>
              {/* <input type="file" name="file" accept="image/*" id="file_input" className="hidden" onChange={handleImage} />
          <img src= {imagedata} alt="" className="w-full rounded-full self-center" /> */}
            </figure>
          </div>
          <div className="self-center">
            <h2 className="font-medium text-[#000] text-xl ">
              {getUserDetails?.data?.data?.firstName}{" "}
              {getUserDetails?.data?.data?.lastName}
            </h2>
            <p className="email text-sm text-center pb-3">
              {getUserDetails?.data?.data?.email}
            </p>
          </div>
          <button className="border border-[#0eb683] p-2 self-center rounded-[16px] transition-all active:scale-90 text-[#0eb683]">
            Tap to change image
          </button>
          <Link
            to={"/dashboard/settings"}
            className="settings font-bold flex items-center pl-8 gap-3 pt-4 transition-all active:scale-90"
          >
            <FiSettings size={20} />
            <h1 onClick={() => setShowProfileDrop(false)}>Settings</h1>
          </Link>
          <Link
            onClick={() => window.localStorage.removeItem("accessToken")}
            to={"/login"}
            className="font-bold logout flex items- pl-8 gap-3 items-center text-red-500 py-4 cursor-pointer transition-all active-scale-90"
          >
            <FiLogOut size={20} />
            <p>Log Out</p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default PopOver;
