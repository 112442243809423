/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import logo from "../../assets/misc/FaviconAsset_ttfcx.svg";
import hamburger from "../../assets/misc/hamburger-button.svg";
import { TabSelector } from "../../components/widgets/TabSelector";
import { TabPanel, useTabs } from "../../components/widgets/TabWidget";
import { useSidebarState } from "../../store";
import { useNavigate, useSearchParams } from "react-router-dom";
import BeneficiaryEmptyState from "../../components/beneficiary-component/BeneficiaryEmptyState";
import BeneficiaryCard, {
  StableBeneficiaryCard,
} from "../../components/beneficiary-component/BeneficiaryCard";
import {
  BeneficiaryType,
  useBeneficiaries,
  useGetStableBeneficiaries,
} from "../../sevices/hooks/wallets";
import { useBeneficiariesState } from "../../store/useBeneficiaries";
import BeneficiaryDetailsModal from "../../components/beneficiary-component/BeneficiaryDetailsModal";
import { BsSearch } from "react-icons/bs";
import { IoPersonAdd } from "react-icons/io5";
import { StableCoinBeneficiary } from "../../types/Users";

const Beneficiaries = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("tab") || "Fiat"
  );
  const toggleSideBarOpen = useSidebarState((state) => state.toggleSidebar);
  const navigate = useNavigate();
  const beneficiaries = useBeneficiaries();
  const Stablebeneficiaries = useGetStableBeneficiaries();
  const setBeneficiaries = useBeneficiariesState(
    (state) => state.setBeneficiaries
  );
  const showBeneficiaryDetails = useBeneficiariesState(
    (state) => state.showBeneficiaryDetails
  );
  const { isLoading, data } = beneficiaries;

  const {
    isLoading: StableisLoading,
    data: Stabledata,
    refetch,
  } = Stablebeneficiaries;
  const stableCoinBeneficiaryList = Stabledata?.data;
  const hasData = !!data?.data?.length || !!Stabledata?.data;
  const changeTab = () => {
    navigate("/dashboard/beneficiaries/add-beneficiary", { replace: true });
  };
  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    // Update query params to preserve tab selection
    setSearchParams({ tab });
  };
  useEffect(() => {
    setBeneficiaries(beneficiaries?.data?.data || []);
  }, [beneficiaries?.data?.data]);
  const [query, setQuery] = useState("");

  const searchTerm = ["beneficiaryName", "chain", "coin"];

  // console.log(stableCoinBeneficiaryList);
  return (
    <div className="h-screen w-full bg-[#f7fbfb] p-5 md:pr-5 md:pl-8 ">
      <div className="w-16 ml-auto md:hidden">
        <img src={logo} alt="logo" />
      </div>
      <button
        className=" rounded-m  block md:hidden"
        onClick={() => toggleSideBarOpen()}
      >
        <img src={hamburger} alt="logo" className="w-10" />
      </button>

      <div className=" flex justify-between xxs:items-center md:mr-3">
        <div>
          <h1 className="text-2xl pl-1 font-bold text-[#071827] ">
            Beneficiaries
          </h1>
        </div>
        {/* button */}
        <div className="w-62  mt-2">
          <button
            className="hidden md:flex items-center text-white font-medium bg-[#0eb683] p-3 rounded-[16px] gap-2 w-[215px]  transition-all active:scale-90 cursor-pointer justify-center active:bg-#6bf3ca"
            onClick={changeTab}
          >
            <IoPersonAdd size={23} />
            <span className="text-base text-#fff pt-1">Add Beneficiary</span>
          </button>
        </div>
        {/* mobile */}

        <div className="md:hidden  ">
          <button
            className="block md:hidden items-center bg-[#0eb683] p-3 px-10 rounded-[16px] shadow-lg  transition-all active:scale-90 cursor-pointer  text-white  "
            onClick={changeTab}
          >
            {" "}
            <p className="flex items-center font-extrabold gap-2">
              <IoPersonAdd />
              Add
            </p>
          </button>
          {}
        </div>
      </div>

      <nav className=" border-b-2 border-[#E8E9EB]  md:px-0 pb-4 flex gap-6 md:justify-start  md:mt-5 ">
        <TabSelector
          className={` cursor-pointer relative bg-transparent flex items-center gap-1 text-[#5C6F7F] ${
            selectedTab === "Fiat"
              ? "text-[#0eb684] font-semibold before:block  before:h-[2px] before:w-full before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
              : ""
          } `}
          isActive={selectedTab === "Fiat"}
          onClick={() => handleTabChange("Fiat")}
        >
          <span>Fiat</span>
        </TabSelector>

        <TabSelector
          className={` cursor-pointer relative bg-transparent text-[#5C6F7F] ${
            selectedTab === "Stablecoins"
              ? "text-[#0eb684] font-semibold before:block before:w-full before:h-[2px] before:bg-[#0eb683] before:absolute before:bottom-[-18px] before:transition-all"
              : ""
          } `}
          isActive={selectedTab === "Stablecoins"}
          onClick={() => handleTabChange("Stablecoins")}
        >
          Stablecoin
        </TabSelector>
      </nav>
      <div className="md:mt-5 xxs:mt-1">
        <div>
          <header className=" py-4 md:py-0">
            <div className="search flex items-center bg-[#fff] text-[#5C6F7F] p-1 px-4 gap-1 rounded-md">
              <BsSearch />
              <input
                type="text"
                placeholder="Search for a beneficiary"
                className="flex-1 p-2 bg-transparent outline-none w-full text-[#5C6F7F]"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </header>
        </div>
      </div>
      <div className=" ">
        <TabPanel
          hidden={selectedTab !== "Fiat"}
          className="flex flex-col gap-3 "
        >
          <div
            className="
           grid sm:grid-cols-2 xxs:gap-3 md:gap-2 lg:gap-2 md:grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] xxs:mt-1 md:mt-5"
          ></div>
        </TabPanel>
        <TabPanel
          hidden={selectedTab !== "Stablecoins"}
          className="flex flex-col gap-3 mt-5"
        >
          <div
            className="
           grid sm:grid-cols-2 xxs:gap-3 md:gap-2 lg:gap-2 md:grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] xxs:mt-1 md:mt-5"
          >
            {/* @ts-ignore */}
            {stableCoinBeneficiaryList?.data
              .filter((b: StableCoinBeneficiary) =>
                // @ts-ignore
                searchTerm.some((key: StableCoinBeneficiary) =>
                  // @ts-ignore
                  b[key]?.toLowerCase().includes(query)
                )
              )
              .map((beneficiary, index) => {
                return (
                  <StableBeneficiaryCard
                    beneficiary={beneficiary}
                    key={index + "beneficiary1"}
                  />
                );
              })}
          </div>
          {/* StableBeneficiaryCard */}
        </TabPanel>
      </div>
      {!hasData && !isLoading && <BeneficiaryEmptyState />}

      {isLoading && !hasData && <div>Loading Please wait...</div>}

      {showBeneficiaryDetails && (
        <BeneficiaryDetailsModal selectedTab={selectedTab} refetch={refetch} />
      )}
    </div>
  );
};

export default Beneficiaries;
