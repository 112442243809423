import React, { useEffect, useState } from "react";
import { BsCheck2, BsChevronLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

// Import existing components
import BeneficiaryLayout from "../../../components/beneficiary-component/BeneficiaryLayout";
import TransferBeneficiaryFlow from "../../../components/wallet-components/TransferBeneficiaryFlow";
import PaymentInstruction from "../../../components/wallet-components/PaymentInstruction";
import Summary from "../../../components/wallet-components/Summary";

// Import stablecoin components

import { useStepperStore } from "../../../store/useStableCoin";
import StableCoinBeneficiaryLayout from "../../../components/beneficiary-component/StableCoinBeneficiaryLayout";
import UsdcBeneficiary from "../../../components/wallet-components/UsdcBeneficiary";
import UsdcTransactionDetails from "../../../components/wallet-components/UsdcTransactionDetails";
import UsdcSummary from "../../../components/wallet-components/UsdcSummary";
import TransferConfirmation from "../../../components/wallet-components/stableCoiSummary";
import VerificationModal from "../../../components/base-components/VerificationComponent";
import {
  useRequestOtp,
  useTransferFunds,
  useWithdraw,
} from "../../../sevices/hooks/users";
import ConfirmationModal from "../../../components/base-components/successModal";
import { toast } from "sonner";
import { useSaveBeneficiaryStore } from "../../../store/beneficiary";
import BeneficiaryDetailsModal from "../../../components/beneficiary-component/BeneficiaryDetailsModal";
import { useBeneficiariesState } from "../../../store/useBeneficiaries";

interface StepIndicatorProps {
  position: number;
  completed: boolean;
  active: boolean;
  label: string;
  description: string;
  isMobile: boolean;
  totalSteps: number; // Added to know when we're on the last step
}

const StepIndicator: React.FC<StepIndicatorProps> = ({
  position,
  completed,
  active,
  label,
  description,
  isMobile,
  totalSteps,
}) => {
  const indicatorClasses = `
    w-7 h-7 rounded-full flex justify-center items-center shrink-0
    transition-all duration-300 md:border md:mb-1
    ${completed ? "bg-[#0eb683]" : "bg-[#F6FCFA] md:bg-white"}
    ${active && !completed ? "border-[#0EB683]" : ""}
  `;

  const labelClasses = `
    ${active ? "text-white md:text-[#0EB683] text-sm" : "text-xs"}
    md:text-lg md:font-semibold transition-all duration-300
  `;

  const ConnectorLine = () => {
    if (isMobile || position === totalSteps - 1) return null;

    return (
      <div
        className={`absolute left-full top-1/2 transform -translate-y-1/2 h-0.5
        ${completed ? "bg-[#0EB683]" : "bg-gray-300"}
        w-full`}
      ></div>
    );
  };

  return (
    <div
      className={`
        p-2 px-2 rounded-full md:rounded-md items-center
        transition-all duration-300 gap-3 md:gap-5 relative flex-1 justify-center
        flex ${isMobile ? "flex-row" : "md:flex-col"}
        cursor-pointer shrink-0
        ${active ? "bg-[#0EB683] md:bg-transparent" : "bg-transparent"}
      `}
    >
      <div className="relative flex items-center justify-center w-full">
        <div className={indicatorClasses}>
          {completed ? (
            <BsCheck2 className="text-white" />
          ) : (
            <>
              <span
                className={`${
                  completed ? "text-white" : "text-[#0EB683]"
                } md:hidden`}
              >
                {position + 1}
              </span>
              {active && (
                <div className="hidden md:block rounded-full h-2 w-2 bg-[#0EB683]" />
              )}
            </>
          )}
        </div>
        <ConnectorLine />
      </div>

      <div className="flex flex-col items-center text-left">
        <span className={labelClasses}>{label}</span>
        <span className="text-xs text-gray-500 hidden md:inline">
          {description}
        </span>
      </div>
    </div>
  );
};
const WalletTransfer: React.FC = () => {
  const navigate = useNavigate();
  const { isSaveBeneficiary, setSaveBeneficiary } = useSaveBeneficiaryStore();
  const showBeneficiaryDetails = useBeneficiariesState(
    (state) => state.showBeneficiaryDetails
  );
  const [verification, setVerification] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initiateLoading, setInitiateLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const { currency } = useParams();
  const withdraw = useTransferFunds(currency as string);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const getOtp = useRequestOtp(
    //@ts-ignore
    localStorage.getItem("user_id")
  );

  const {
    currentStep,
    steps,
    nextStep,
    previousStep,
    beneficiaryDetails,
    setBeneficiaryDetails,
    summaryData,
    setSummaryData,
    setCurrentStep,
  } = useStepperStore();

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isStableCoin = React.useMemo(() => {
    return (
      currency?.toUpperCase() === "USDT" || currency?.toUpperCase() === "USDC"
    );
  }, [currency]);

  const handleClick = async () => {
    setInitiateLoading(true);
    try {
      await getOtp.mutateAsync({});
      setVerification(true);
      // setWithdrawalModal(false);
      // setShowModal(false);
      setInitiateLoading(false);
    } catch (error) {
      //@ts-ignore
      toast.error(error?.response?.data);
      // console.error("Error requesting OTP:", error);
    }
  };

  // Memoize regular step components
  const regularStepComponents = React.useMemo(
    () => [
      {
        content: (
          <BeneficiaryLayout
            onNext={() => {
              nextStep();
            }}
          />
        ),
      },
      {
        content: (
          <TransferBeneficiaryFlow
            onNext={() => {
              nextStep();
            }}
            onSaveBeneficiary={(details) => {
              setBeneficiaryDetails(details);
            }}
          />
        ),
      },
      {
        content: (
          <PaymentInstruction
            onNext={() => {
              nextStep();
            }}
            onPrevious={() => {
              previousStep();
            }}
            beneficiaryDetails={beneficiaryDetails}
            onUpdateSummary={(data) => {
              setSummaryData(data);
            }}
          />
        ),
      },
      {
        content: (
          <Summary
            onPrevious={() => {
              previousStep();
            }}
            summaryData={summaryData}
          />
        ),
      },
    ],
    [
      nextStep,
      previousStep,
      setBeneficiaryDetails,
      setSummaryData,
      beneficiaryDetails,
      summaryData,
    ]
  );

  // Memoize stablecoin step components
  const stableCoinStepComponents = React.useMemo(
    () => [
      {
        content: (
          <StableCoinBeneficiaryLayout
            prevStep={previousStep}
            onClick={nextStep}
          />
        ),
      },
      {
        content: (
          <UsdcBeneficiary
            prevStep={previousStep}
            onClick={nextStep}
            onSaveBeneficiary={(details) => {
              setBeneficiaryDetails(details);
            }}
            placeholder={`Beneficiary ${currency?.toUpperCase()} Address`}
          />
        ),
      },
      {
        content: (
          <UsdcTransactionDetails
            onClick={nextStep}
            currency={currency?.toUpperCase() ?? ""}
            prev={previousStep}
            onSaveBeneficiary={(details) => {
              setBeneficiaryDetails(details);
            }}
          />
        ),
      },
      {
        content: (
          <TransferConfirmation
            loading={initiateLoading}
            currency={currency?.toUpperCase() ?? ""}
            prev={previousStep}
            handleOTP={handleClick}
          />
        ),
      },
    ],
    [currency, nextStep, previousStep]
  );

  const currentStepComponents = isStableCoin
    ? stableCoinStepComponents
    : regularStepComponents;

  const handleNavigateBack = () => {
    setCurrentStep(0);
    isStableCoin
      ? navigate(-1)
      : navigate(`/dashboard/wallet/${currency}`, { replace: true });
    setConfirmation(false);
  };
  // console.log(beneficiaryDetails, "beneficiaryDetails");

  const initiateTransfer = () => {
    setLoading(true);
    withdraw
      .mutateAsync({
        saveBeneficiary: isSaveBeneficiary,
        alias: beneficiaryDetails.alias,
        beneficiaryName: beneficiaryDetails.beneficiaryName,
        verificationCode: otp,
        amount: Number(beneficiaryDetails.amount),
        network: beneficiaryDetails.selectedNetwork,
        address: beneficiaryDetails.walletAddress,
      })
      .then((response) => {
        // console.log(response, "response");
        setConfirmation(true);
        setLoading(false);
      })
      .catch((error) => {
        //@ts-ignore
        toast.error(error?.response?.data);
        // console.error("Error initiating transfer:", error);
        setLoading(false);
      });
  };

  return (
    <div className="md:bg-[#f7fbfb]">
      <div className="h-full w-full px-5 pt-5">
        <header className="flex items-center gap-24 md:gap-4 md:ml-3">
          <button
            onClick={handleNavigateBack}
            className="bg-[#ECF6F3] rounded-full p-2 flex transition-all active:scale-90"
          >
            <BsChevronLeft />
          </button>
          <p className="font-semibold text-lg">
            Transfer {currency?.toUpperCase()}
          </p>
        </header>

        <nav className="relative mt-6 pt-3 border-t-2 border-[#f7fbfb] md:border-0">
          {/* <div className="hidden md:block absolute w-[760px] h-[2px] bg-slate-200 top-[33px] left-1/2 -translate-x-1/2" /> */}

          <div className="flex overflow-auto md:justify-center md:gap-48">
            {steps.map((step, index) => (
              <StepIndicator
                key={index}
                position={index}
                totalSteps={steps.length}
                completed={index < currentStep}
                active={index === currentStep}
                label={step.label}
                description={step.description}
                isMobile={isMobile}
              />
            ))}
          </div>
        </nav>
        {verification && (
          <VerificationModal
            showVerification={verification}
            setShowVerification={setVerification}
            setOtp={setOtp}
            // setConfirmationModal={setConfirmation}
            withdrawNgn={initiateTransfer}
            loading={loading}
          />
        )}

        {confirmation && (
          <ConfirmationModal
            onDone={handleNavigateBack}
            message="Your recipient will receive value shortly."
          />
        )}

        {showBeneficiaryDetails && <BeneficiaryDetailsModal />}
      </div>

      <main className="md:ml-5">
        {currentStepComponents[currentStep].content}
      </main>
    </div>
  );
};

export default WalletTransfer;
