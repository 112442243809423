import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import Logo from "../../assets/misc/logo-on-light.svg";
import logoWhite from "../../assets/misc/logo-on-dark.svg";
import UsFlag from "../../assets/flags/usd.png";
import UkFlag from "../../assets/flags/pound.png";
import EuFlag from "../../assets/flags/euro.png";
import { useSidebarState } from "../../store";
import DashboardIcon from "../../assets/iconsComponents/DashboardIcon";
import { LiaCreditCardSolid } from "react-icons/lia";
import WalletIcon from "../../assets/iconsComponents/WalletIcon";
import BeneficiaryIcon from "../../assets/iconsComponents/BeneficiaryIcon";
import TransactionsIcon from "../../assets/iconsComponents/TransactionsIcon";
import WithdrawalIcon from "../../assets/iconsComponents/WithdrawalIcon";
import RatesIcon from "../../assets/iconsComponents/RatesIcon";
import SettingsIcon from "../../assets/iconsComponents/SettingsIcon";
import LogoutIcon from "../../assets/iconsComponents/LogoutIcon";
import {
  useUserLogin,
  useUsers,
  useUsersInvitation,
} from "../../sevices/hooks/users";
import FailedIcon from "../../assets/iconsComponents/FailedIcon";
import SuccessMobile from "../../assets/iconsComponents/SuccessMobile";
import { motion, AnimatePresence } from "framer-motion";

// import { useGbpRates, useRates, useRatesEur } from "../../sevices/hooks/tags";
import { AiOutlineClose } from "react-icons/ai";


import { useRatesStore } from "../../store/useRateStore";
import CurrencyRatesComponent from "../currencyRate";

const links = [
  {
    id: 0,
    name: "Dashboard",
    url: "/dashboard/",
    icon: () => <DashboardIcon width={"24px"} height={"24px"} />,
  },
  // {
  //   id: 1,
  //   name: "Wallets",
  //   url: "/dashboard/wallets",
  //   icon: () => <WalletIcon width={"24px"} height={"24px"} />,
  // },
  {
    id: 1,
    name: "Cards",
    url: "/dashboard/cards",
    icon: () => <LiaCreditCardSolid size={28}/>,
  },
  {
    id: 2,
    name: "Beneficiaries",
    url: "/dashboard/beneficiaries",
    icon: () => <BeneficiaryIcon width={"24px"} height={"24px"} />,
  },
  {
    id: 3,
    name: "Transactions",
    url: "/dashboard/transactions",
    icon: () => (
      <TransactionsIcon width={"24px"} height={"24px"} stroke="#5C6F7F" />
    ),
  },
  {
    id: 4,
    name: "Withdrawals",
    url: "/dashboard/withdrawals",
    icon: () => <WithdrawalIcon width={"24px"} height={"24px"} />,
  },
  {
    id: 5,
    name: "Rates",
    url: "/dashboard/rates",
    icon: () => <RatesIcon width={"24px"} height={"24px"} />,
  },
  {
    id: 6,
    name: "Settings",
    url: "/dashboard/settings",
    icon: () => <SettingsIcon width={"24px"} height={"24px"} />,
  },
];

const Aside = () => {
  const rates = useRatesStore((state) => state.rates);
  const userDetails = useUsers(
    //@ts-ignore
    localStorage.getItem("user_id"),
    localStorage.getItem("accessToken")
  );
  const navigate = useNavigate();
  const sideBarOpen = useSidebarState((state) => state.sideBarOpen);
  const setSideBarOpen = useSidebarState((state) => state.toggleSidebar);
  const setInvitationModalOpen = useSidebarState(
    (state) => state.setInvitationModalOpen
  );

  const [showInviteUserModal, setShowInviteUserModal] = useState(false);

  const [invitationSuccess, setInvitationSuccess] = useState(false);
  const usersInvitation = useUsersInvitation();

  const [invitationFailed, setInvitationFailed] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [fields, setFields] = useState({
    email: "",
    lastName: "",
    firstName: "",
  });

  const usdRate = rates.NGN?.buyRate;
  const eurRate = rates.EUR?.buyRate;
  const gbpRate = rates.GBP?.buyRate;

  const activeLink = ` xxs:border-0 md:border-[#0eb683] focus:bg-[#f7fbfb]   text-[#0eb684] md:bg-[#F7FBFB] md:border-l-4 md:border-[#0eb683] xxs:bg-inherit md:pl-7 flex items-center gap-3 text-[#0eb683] py-3 font-bold`;

  const normalLink = ` hover:bg-[#f7fbfb] hover:border-l-4 hover:border-[#0eb683] text-[#5C6F7F] flex items-center py-3 md:pl-7 gap-3 border-l-4 border-transparent`;

  // flex md:focus:border-l-4 items-center  md:focus:border-[#0eb683] md:focus:bg-[#F7FBFB] focus:text-[#0eb683]  hover:text-[#0eb683]  md:hover:bg-[#f7fbfb]  md:pl-7 py-3 gap-6

  const onFieldChange = ({ name, value }: { value: string; name: string }) => {
    setFields({ ...fields, [name]: value });
  };

  const inviteUser = (e: any) => {
    e.preventDefault();
    usersInvitation
      .mutateAsync(fields)
      .then((res: any) => {
        setInvitationSuccess(true);
      })
      .catch((e: any) => {
        setInvitationFailed(true);
      });
  };

  const validateField = () => {
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (regEx.test(fields.email)) {
      setDisabled(false);
    } else if (!regEx.test(fields.email) && fields.email !== "") {
      setDisabled(true);
    }
  };
  const openModal = () => {
    setShowInviteUserModal(true);
  };
  const closeModal = () => {
    setShowInviteUserModal(false);
  };

  // useEffect(() => {}, [userDetails?.data?.data?.invitationCode]);

  return (
    <>
      <CurrencyRatesComponent />
      <aside
        className={`md:flex fixed md:relative top-0 left-0 z-40 bg-[#0c0d1f] md:bg-white w-60  text-gray-200 md:text-[#071827]  h-screen flex-col    p-7 md:p-0 transition-all duration-500 ${
          !sideBarOpen ? "-translate-x-full md:translate-x-0" : ""
        }`}
      >
        <div className="logo md:p-2.5 md:px-8">
          <a href="/dashboard">
            <img src={Logo} alt="" className=" w-28 hidden md:block " />
          </a>
          <a href="/dashboard">
            <img src={logoWhite} alt="" className="w-28 block md:hidden" />
          </a>
        </div>
        <button
          onClick={() => {
            setSideBarOpen(false);
            navigate("/dashboard/beneficiaries", { replace: true });
          }}
          className="md:hidden bg-[#0eb683] text-white p-2 text-lg px-4 w-full mt-12 mb-4 rounded-[16px] transition-all active:scale-90"
        >
          Initiate Transfer
        </button>
        <AnimatePresence>
          {showInviteUserModal && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  // delay: 0.3,
                  duration: 0.1,
                },
              }}
              className="modal_backdrop backdrop-blur-sm bg-[#13111173] fixed w-full h-full top-0 left-0 justify-center items-center transition-opacity duration-300 z-50"
            >
              <motion.div
                initial={{
                  scale: 0,
                }}
                animate={{
                  scale: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  scale: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                className="flex flex-col h-screen items-center justify-center md:items-end"
              >
                <motion.div
                  initial={{
                    x: 100,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 100,
                    opacity: 0,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  className="card bg-white md:h-full shrink-0 w-full max-w-sm   md:rounded-l-3xl p-8 flex flex-col gap-3 md:max-w-md "
                >
                  <button
                    onClick={closeModal}
                    className=" hover:bg-[#F2FAF7] rounded-full p-2  self-end text-[#1E3448] transition-all active:scale-90"
                  >
                    <AiOutlineClose size={28} />
                  </button>
                  <h2 className="text-xl font-semibold text-black">s</h2>
                  <p className=" text-[#5C6F7F] text-sm font-medium whitespace-normal ">
                    Enter your referee’s contact details and an invitation would
                    be sent to them. Any transaction your referee performs earns
                    you up to{" "}
                    <span className="text-[#0eb683]">
                      NGN1.00/$,£,€ commission
                    </span>
                  </p>
                  <p className="text-center text-black font-medium">
                    Your Referral Code:
                  </p>
                  <p className="ref text-black font-bold text-center text-3xl ">
                    {userDetails?.data?.data?.invitationCode}
                  </p>
                  <div className=" flex flex-col items-center w-full gap-3 mt-5">
                    <input
                      type="text"
                      name="firstName"
                      // value={firstname}
                      className="border border-[#E2E9F0] bg-[#F2FAF7] w-full p-3 rounded-md outline-[#0eb683] text-black"
                      placeholder="First Name"
                      onChange={(e) => {
                        onFieldChange({
                          name: "firstName",
                          value: e.target.value,
                        });
                      }}
                    />
                    <input
                      type="text"
                      name="lastName"
                      // value={lastname}
                      className="border bg-[#F2FAF7] border-[#E2E9F0] w-full p-3 rounded-md outline-[#0eb683] text-black"
                      placeholder="Last Name"
                      onChange={(e) => {
                        onFieldChange({
                          name: "lastName",
                          value: e.target.value,
                        });
                      }}
                    />
                    <input
                      type="email"
                      name="email"
                      value={fields.email}
                      className="border border-[#E2E9F0] bg-[#F2FAF7] w-full p-3  outline-[#0eb683] rounded-md text-black"
                      placeholder="Email"
                      onPaste={validateField}
                      onChange={(e) => {
                        onFieldChange({ name: "email", value: e.target.value });
                        validateField();
                      }}
                    />

                    <button
                      disabled={disabled}
                      onClick={inviteUser}
                      className="text-white w-full bg-[#0eb683] rounded-[16px] px-10 py-3 mt-20 transition-all active:scale-90 disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                    >
                      Invite new user
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {invitationSuccess && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="confirmation_modal backdrop-blur-sm
           bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex h-screen items-center justify-center md:justify-end "
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5 "
                  >
                    <div className="icon self-center">
                      <SuccessMobile width={"100px"} height={"100px"} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Invitation sent
                    </h1>

                    <p className="  text-sm text-[#5C6F7F] self-center text-center">
                      Your invitation was succesfully sent
                    </p>

                    <button
                      onClick={() => {
                        setInvitationSuccess(false);
                        setShowInviteUserModal(false);
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                    >
                      Done
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {invitationFailed && (
            <div>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    // delay: 0.3,
                    duration: 0.1,
                  },
                }}
                className="confirmation_modal backdrop-blur-sm
           bg-[#13111137] fixed w-full top-0 left-0 justify-center h-full items-center z-50"
              >
                <motion.div
                  initial={{
                    x: 600,
                    scale: 0,
                  }}
                  animate={{
                    x: 0,
                    scale: 1,
                    transition: {
                      duration: 0.3,
                    },
                  }}
                  exit={{
                    x: 600,
                    scale: 0,
                    transition: {
                      delay: 0.3,
                    },
                  }}
                  className="flex h-screen items-center justify-center md:justify-end "
                >
                  <motion.div
                    initial={{
                      x: 600,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    }}
                    exit={{
                      x: 100,
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                      },
                    }}
                    className="flex  justify-center flex-col gap-8 bg-white md:h-full w-full h-[450px] md:w-[448px] rounded-3xl md:rounded-l-3xl p-5 "
                  >
                    <div className="icon self-center">
                      <FailedIcon width={"100px"} height={"100px"} />
                    </div>
                    <h1 className="text-2xl font-bold text-[#071827] self-center">
                      Invitation failed
                    </h1>

                    <p className="  text-sm text-[#5C6F7F] self-center text-center">
                      We couldn't invite your user please retry
                    </p>

                    <button
                      onClick={() => {
                        setInvitationFailed(false);
                        setShowInviteUserModal(true);
                      }}
                      className="bg-[#0eb683] text-white rounded-md px-20 py-3 self-center transition-all active:scale-90"
                    >
                      Retry
                    </button>
                  </motion.div>
                </motion.div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        <div className="aside">
          <div className="flex flex-col ">
            {links.map((link) => (
              <NavLink
                to={link.url}
                key={link.id}
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
                onClick={() => setSideBarOpen(false)}
              >
                <div>{link.icon()}</div>

                <p>{link.name}</p>
              </NavLink>
            ))}
          </div>

          <div className="flex flex-col xxs:gap-7 xxs:mt-5 xs:gap-9 xs:mt-8 md:gap-10 md:mt-10 ">
            <div className="hidden md:flex flex-col md:pl-7 gap-4">
              <div className="figure flex items-center gap-6">
                <figure className=" rounded-full w-7">
                  <img src={UsFlag} alt="" />
                </figure>
                <small className="text-[#5C6F7F] md:text-base md:font-medium">
                  $1/N{usdRate}
                </small>
              </div>
              <div className="figure flex items-center gap-6">
                <figure className=" rounded-full w-7">
                  <img src={UkFlag} alt="" />
                </figure>
                <small className="text-[#5C6F7F] md:text-base md:font-medium">
                  £1/N{gbpRate}
                </small>
              </div>
              <div className="figure flex items-center gap-6">
                <figure className=" rounded-full w-7">
                  <img src={EuFlag} alt="" />
                </figure>
                <small className="text-[#5C6F7F] md:text-base md:font-medium">
                  €1/N{eurRate}
                </small>
              </div>
            </div>

            <div className="md:pl-7">
              <button
                onClick={() => setInvitationModalOpen(true)}
                className="whitespace-nowrap text-base  xxs:text-[#0eb683] md:text-[#fff] p-3 xxs:bg-[#fff] b md:bg-[#0eb683] rounded-[16px] transition-all active:scale-90 xxs:px-4 md:px-0 xxs:w-full md:w-44 flex text-center items-center gap-2 justify-center  "
              >
                Invite new user
              </button>
            </div>

            <div className="md:ml-4">
              <Link
                onClick={() => window.localStorage.removeItem("accessToken")}
                to={"/login"}
                className="flex items-center md:p-4 text-white md:text-[red] cursor-pointer gap-4 "
              >
                <LogoutIcon />
                <p className="text-white md:text-[#5C6F7F] md:hover:text-[red] md:hover:font-semibold">
                  Log Out
                </p>
              </Link>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Aside;
