import React, { useContext, useEffect, useMemo, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  Props,
  WalletIcon,
} from "../../../components/wallet-components/stable-coin-wallet-card";
import USDCIcon from "../../../assets/icons/USDCIcon";
import USDTIcon from "../../../assets/icons/USDTIcon";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { TbArrowsDoubleNeSw } from "react-icons/tb";
import { PiArrowsClockwiseFill } from "react-icons/pi";
import { GoArrowUpRight } from "react-icons/go";
import QRCode from "qrcode.react";
import {
  useGenerateWallet,
  useGetSingleFee,
  useGetSingleWallet,
  useGetWalletDetails,
  useUsers,
} from "../../../sevices/hooks/users";
import Polygon from "../../../assets/coins/Polygon.png";
import Solana from "../../../assets/coins/solana.png";
import Ethereum from "../../../assets/coins/eth.png";

import tronIcon from "../../../assets/coins/tron.png";
import { AiOutlineCopy } from "react-icons/ai";
import SwapModal from "../../../components/dashboard-components/SwapModal";
import FundModal from "../../../components/dashboard-components/FundModal";
import ConvertModal from "../../../components/wallet-components/ConvertModal";
import _ from "lodash";
import { Infor } from "./UsdcWallet";
import { useRatesStore } from "../../../store/useRateStore";
import { numberWithCommas } from "../Withdrawals";
import TabContext from "../../../store/context/TabProvider";
import ChainSelector from "../../../components/chainSelector";
import { useStepperStore, useWalletStore } from "../../../store/useStableCoin";
import { useAccActivity } from "../../../sevices/hooks/wallets";
import WalletAccountTable from "../../../components/wallet-components/WalletAccountTable";
import EmptyState from "../../../components/base-components/EmptyState";

interface Network {
  deposits_enabled: boolean;
  id: string;
  name: string;
  withdraws_enabled: boolean;
  network_address?: string;
}

interface Chain {
  id: string;
  name: string;
  icon: string;
  address: string;
}
interface WalletData {
  createdAt: string;
  id: string;
  label: string;
  polygonUSDC: string;
  polygonUSDT: string;
  solUSDC: string;
  solUSDT: string;
  tronUSDC: string;
  tronUSDT: string;
  ethUSDC: string;
  ethUSDT: string;
  usdcBalance: string;
  usdtBalance: string;
  userId: string;
}

const getNetworkAddress = (
  networkId: string,
  currency: string,
  walletData: WalletData
): string => {
  switch (networkId.toLowerCase()) {
    case "solana":
      return currency === "USDC" ? walletData.solUSDC : walletData.solUSDT;
    case "polygon":
      return currency === "USDC"
        ? walletData.polygonUSDC
        : walletData.polygonUSDT;
    case "tron":
      return currency === "USDC" ? walletData.tronUSDC : walletData.tronUSDT;
    case "ethereum":
      return currency === "USDC" ? walletData.ethUSDC : "";
    default:
      return "";
  }
};

const getNetworkIcon = (networkId: string) => {
  switch (networkId) {
    case "tron":
      return tronIcon;
    case "polygon":
      return Polygon;
    case "solana":
      return Solana;
    case "ethereum":
      return Ethereum;
    default:
      return "";
  }
};

const StableWallet = () => {
  const { currency, chain } = useParams();
  const rates = useRatesStore((state) => state.rates);
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [BuyModal, setBuyModal] = useState(false);
  const [userDetails, refetch] = useOutletContext<any>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const wallet = useWalletStore((state) => state.wallet);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [amountValue, setAmountValue] = useState<any>({ value: 0 });
  const [isQRLoading, setIsQRLoading] = useState(false);

  const [showBuyCurrency, setShowBuyCurrency] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const usdcRate = rates.USDC;
  const { data: walletdetails } = useGetWalletDetails();
  const { data: fees } = useGetSingleFee(currency as string);
  const { data: ddd, isLoading } = useGetSingleWallet(currency as string);
  const userActivities = useAccActivity({
    page_size: 10,
    page: 1,
    currency: currency?.toUpperCase(),
  });

  //BuyUsd
  const [baseAmountValue, setBaseAmountValue] = useState<any>();
  const [NGNAmountValue, setNGNAmountValue] = useState<any>();

  //@ts-ignore
  const { setTab, tab } = useContext(TabContext);

  // State for wallet data
  const [walletBalance, setWalletBalance] = useState<string | undefined>("0.0");
  const [chains, setChains] = useState<Chain[]>([]);
  const [selectedChain, setSelectedChain] = useState<Chain | null>(null);
  const { data: xwallet } = useGenerateWallet();

  //fund
  const [fundModal, setFundModal] = useState(false);

  const stableCoinTransactions = useMemo(
    () => userActivities?.data?.data?.data,
    [userActivities?.data?.data?.data]
  );

  // console.log(usdcRate, "usdcRate");

  const [copyStatus, setCopyStatus] = useState("");

  const [showConvert, setShowConvert] = useState(false);

  // console.log(chains, "chains");
  // console.log(currency, "currency");

  const fetchWalletDetails = (
    currency: string,
    setWalletBalance: (balance: string) => void,
    setChains: (chains: Chain[]) => void,
    setSelectedChain: (chain: Chain) => void
  ) => {
    // console.log(currency, "currencycurrency");
    try {
      const response = walletdetails;

      // console.log(response, "responseresponse");

      if (response && response.data.data) {
        const walletData: WalletData = response.data.data;

        setWalletBalance(
          currency === "USDC" ? walletData.usdcBalance : walletData.usdtBalance
        );

        let availableChains: Chain[];

        // console.log(walletData, "walletData");

        if (currency === "USDC") {
          availableChains = [
            {
              id: "solana",
              name: "Solana",
              icon: getNetworkIcon("solana"),
              address: getNetworkAddress("solana", currency, walletData),
            },
            {
              id: "polygon",
              name: "Polygon",
              icon: getNetworkIcon("polygon"),
              address: getNetworkAddress("polygon", currency, walletData),
            },
            {
              id: "ethereum",
              name: "Ethereum",
              icon: getNetworkIcon("ethereum"),
              address: getNetworkAddress("ethereum", currency, walletData),
            },
          ];
        } else {
          availableChains = [
            {
              id: "solana",
              name: "Solana",
              icon: getNetworkIcon("solana"),
              address: getNetworkAddress("solana", currency, walletData),
            },
            {
              id: "polygon",
              name: "Polygon",
              icon: getNetworkIcon("polygon"),
              address: getNetworkAddress("polygon", currency, walletData),
            },
            {
              id: "tron",
              name: "Tron",
              icon: getNetworkIcon("tron"),
              address: getNetworkAddress("tron", currency, walletData),
            },
          ];
        }

        setChains(availableChains);

        // Set initial selected chain
        if (availableChains.length > 0) {
          setSelectedChain(availableChains[0]);
        }
      }
    } catch (error) {
      // console.error("Error fetching wallet details:", error);
    }
  };
  useEffect(() => {
    return () => {
      if (performance.navigation.type !== 1) {
        useStepperStore.getState().resetStore();
      }
    };
  }, []);

  // Usage in useEffect
  useEffect(() => {
    const upperCurrency = currency?.toUpperCase();

    if (upperCurrency === "USDC" || upperCurrency === "USDT") {
      fetchWalletDetails(
        //@ts-ignore
        upperCurrency,
        setWalletBalance,
        setChains,
        setSelectedChain
      );
    } else {
      // console.error("Invalid currency value:", currency);
    }
  }, [ddd?.data?.data, currency]);

  const copyToClipBoard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyStatus("Wallet address copied to clipboard");
    let timeout = setTimeout(() => {
      setCopyStatus("");
      clearTimeout(timeout);
    }, 1500);
  };
  const handleChainSelect = async (chain: Chain) => {
    setIsQRLoading(true);
    setSelectedChain(chain);
    // Simulate network delay for better UX
    await new Promise((resolve) => setTimeout(resolve, 800));
    setIsQRLoading(false);
  };

  const [showAmount, setShowAmount] = useState(true);
  const currentIcon = (currency: string) => {
    switch (currency) {
      case "usdc":
        return () => <USDCIcon />;
      case "usdt":
        return () => <USDTIcon />;
      default:
        return () => null;
    }
  };

  const toggleShowAmount = () => {
    setShowAmount((prevState) => !prevState);
  };

  const handleBuySell = () => {
    setShowBuyCurrency(true);
  };

  const handleConvert = () => {
    setShowConvert(true);
  };

  const handleTransfer = () => {
    navigate(`/dashboard/wallet/transfer/${currency}`);
  };

  useEffect(() => {
    setWalletBalance(
      currency === "usdc" ? wallet?.usdcBalance : wallet?.usdtBalance
    );
  }, [currency, wallet]);

  const amountErr =
    tab === "buy"
      ? `Minimum amount is NGN ${commas(
          Number(usdcRate?.buyRate) * Number(500)
        )} `
      : `Minimum amount is USD ${commas(
          (Number(usdcRate?.sellRate) * Number(10)) / Number(usdcRate?.sellRate)
        )} `;

  const fundNgn = () => setFundModal((prevState) => !prevState);

  // console.log(walletBalance, "walletBalance");

  var shade = `flex flex-col md:flex-row col-span-3 h-[270px] md:h-[370px] ${
    currency === "usdc" ? "bg-[#2775CA]" : "bg-[#50AF95]"
  } p-4 px-6 rounded-2xl shadow-md`;

  return (
    <div className="bg-[#f7fbfb] h-full w-full  md:pt-10 md:px-8 ">
      <div className="flex items-center gap-24 md:gap-4 px-5 py-10 md:px-0 md:py-0 bg-white md:bg-[#f7fbfb] shadow-sm md:shadow-none">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className=" bg-[#ECF6F3] rounded-full p-2 flex transition-all active:scale-90 text-slate-70"
        >
          <BsChevronLeft size={24} />
        </button>
        <p className="text-[#071827] font-semibold">{`${
          currency && currency?.toUpperCase()
        } ${_.capitalize(selectedChain?.name)} Wallet`}</p>
      </div>
      <div className="py-6 px-4 md:px-0 grid grid-cols-1 md:grid-cols-5 gap-6  ">
        <div className={shade}>
          <div className="w-full mb-4 md:mb-0 h-full flex flex-col justify-normal md:justify-between">
            <div className="flex flex-row gap-2 md:gap-0 md:flex-col justify-normal md:justify-between h-[100%] md:h-[60%]">
              <div className="pt-4">
                <WalletIcon icon={currentIcon(currency as string)} />
              </div>
              <div className="md:block hidden">
                <h4 className="text-[#fff] mt-4">Wallet Balance</h4>
              </div>

              <div>
                <div className="md:hidden block">
                  <h4 className="text-[#fff] mt-4">Wallet Balance</h4>
                </div>

                <div className="flex items-center space-x-2 mt-2 h-">
                  <span className="text-[#fff] text-2xl">
                    {showAmount ? (
                      <p className="text-[#fff] text-lg md:text-3xl font-bold">
                        {" "}
                        {numberWithCommas(walletBalance)}{" "}
                        {currency && currency?.toUpperCase()}
                      </p>
                    ) : (
                      <p className="text-2xl md:text-4xl font-bold ">{`******************`}</p>
                    )}
                  </span>
                  <button
                    className="text-white hover:text-white focus:outline-none text-xl"
                    onClick={toggleShowAmount}
                  >
                    {showAmount ? <FiEye /> : <FiEyeOff />}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div className="grid grid-cols-3 gap-4 md:pt-4 w-full pt-0 md:w-[80%]">
                <Button
                  label={`Buy | Sell ${currency && currency?.toUpperCase()}`}
                  icon={TbArrowsDoubleNeSw}
                  onClick={handleBuySell}
                />
                <Button
                  label={`Convert ${currency && currency?.toUpperCase()}`}
                  icon={PiArrowsClockwiseFill}
                  onClick={handleConvert}
                />
                <Button
                  label={`Transfer
                  ${currency && currency?.toUpperCase()}`}
                  icon={GoArrowUpRight}
                  onClick={handleTransfer}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-3 md:col-span-2 bg-white rounded-2xl h-[370px] shadow-md p-4 px-5">
          <div className="mb-2 flex items-center justify-between">
            <div>
              <h1 className="md:text-xl text-base text-[#071827]">
                {/* Receive {currency && currency?.toUpperCase()} */}
                Receive {currency?.toUpperCase()} on {selectedChain?.name}
              </h1>
              <span className="text-[#5C6F7F] text-sm">Select chain</span>
            </div>
            {chains.length > 0 && selectedChain && (
              <ChainSelector
                chains={chains}
                selectedChain={selectedChain}
                onChainSelect={handleChainSelect}
              />
            )}
          </div>

          <div className="mx-auto flex items-center justify-center">
            <div className="flex flex-col gap-3 relative w-fit h-fit">
              <div className="qrcode_wrapper flex items-center justify-center w-48 h-48 border-4 border-[#0eb683] rounded-2xl relative">
                {isQRLoading || isLoading ? (
                  <div className="absolute inset-0 bg-white/80 flex items-center justify-center">
                    <div className="w-8 h-8 border-4 border-[#0eb683] border-t-transparent rounded-full animate-spin" />
                  </div>
                ) : selectedChain?.address ? (
                  <QRCode value={selectedChain.address} size={160} />
                ) : (
                  <div className="text-center text-gray-500">
                    No address available
                  </div>
                )}
              </div>
              <div className="blockage">
                <div className="h-3 md:h-2 w-32 bg-white mb-1 absolute -top-1 right-8"></div>
                <div className="w-3 h-32 md:w-2 bg-white mb-1 absolute top-8 -left-1"></div>
                <div className="w-3 h-32 md:w-2 bg-white mb-1 absolute -right-1 md:right-0 top-8"></div>
                <div className="h-3 md:h-2 w-32 bg-white mb-1 absolute bottom-1 md:bottom-2 right-8"></div>
              </div>
            </div>
          </div>

          {selectedChain?.address && (
            <div>
              <p className="text-[#071827] text-center mt-2 font-semibold">
                Scan to copy wallet address
              </p>
              <div className="flex items-center justify-center gap-2 mt-2">
                <div className="bg-[#DAE9F6] w-fit md:max-w-full p-1 rounded-md text-xs px-3 text-slate-700 overflow-hidden">
                  <p className="truncate">
                    {selectedChain && selectedChain.address}
                  </p>
                </div>

                <div
                  onClick={() =>
                    copyToClipBoard(selectedChain?.address as string)
                  }
                  className="flex items-center gap-1 border border-[#0eb683] p-1 px-3 rounded-md bg-white transition-all active:scale-90 cursor-pointer"
                >
                  <div className="text-[#0eb683]">
                    <AiOutlineCopy />
                  </div>
                  <p className="text-xs text-[rgb(14,182,131)]">Copy</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div className="px-4 md:px-0">
        <Infor
          currency={currency as string}
          fee={fees?.data?.data?.fee || ""}
          type={fees?.data?.data?.type || ""}
        />
      </div> */}
      {showBuyCurrency && (
        <SwapModal
          baseCurrency={currency as string}
          baseCurrencySymbol={`${currency?.toUpperCase()} `}
          currency={"ngn"}
          currencySymbol={"₦ "}
          rate={usdcRate?.buyRate}
          confirmation={showConfirmation}
          setConfirmation={setShowConfirmation}
          inputValue={
            tab === "buy" ? NGNAmountValue?.value : baseAmountValue?.value
          }
          inputValueFormatted={NGNAmountValue?.formattedValue}
          inputValueFloat={NGNAmountValue?.floatValue}
          setInputValue={setNGNAmountValue}
          openModal={showBuyCurrency}
          loading={loading}
          setLoading={setLoading}
          setOpenModal={setShowBuyCurrency}
          balance={userDetails?.balances?.ngnBalance}
          baseBalance={walletBalance}
          computedValue={
            tab === "buy" ? baseAmountValue?.value : NGNAmountValue?.value
          }
          sellRate={usdcRate?.sellRate}
          computedValueFloat={baseAmountValue?.floatValue}
          computedValueFormatted={baseAmountValue?.formattedValue}
          setComputedValue={setBaseAmountValue}
          errorMessage={amountErr}
          success={showSuccessModal}
          setSuccess={setShowSuccessModal}
          // refetchRates={refetch}
          refetch={() => refetch()}
          // initiateBuy={purchaseUsd}
          setFundModal={fundNgn}
        />
      )}

      {showModal && (
        <FundModal
          fundModal={showModal}
          confirm={showConfirmationModal}
          setConfirm={setShowConfirmationModal}
          loading={loading}
          success={showSuccess}
          setValue={setAmountValue}
          setSuccess={setShowSuccess}
          value={amountValue?.value}
          currency={"ngn"}
          valueFormatted={amountValue?.formattedValue}
          setFund={setShowModal}
        />
      )}

      {showConvert && (
        <ConvertModal
          closeModal={setShowConvert}
          balance={walletBalance}
          currency={currency as string}
        />
      )}

      <div className="">
        {stableCoinTransactions?.length > 0 ? (
          <WalletAccountTable
            // itemsPerPage={10}
            data={stableCoinTransactions}
            currency={currency?.toUpperCase()}
          />
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

export default StableWallet;

interface ButtonProps {
  label: string;
  icon: React.ElementType;
  onClick: () => void;
  bgColor?: string;
  borderColor?: string;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  icon: Icon,
  onClick,
  bgColor = "#fff",
  borderColor = "#0eb683",
}) => {
  return (
    <div className="w-full">
      <button
        className={`w-full py-4 rounded-xl md:rounded-lg flex items-center justify-center space-x-2 shadow-md transition-all active:scale-90`}
        onClick={onClick}
        style={{
          backgroundColor: bgColor,
          borderColor: borderColor,
          color: borderColor,
        }}
      >
        <Icon className="md:w-4 md:h-4 w-5 h-5 font-tomato-bold" />
        <span className="text-sm md:block hidden">{label}</span>
      </button>
      <span
        className="text-sm text-center block md:hidden font-bold pt-2"
        style={{ color: bgColor }}
      >
        {/(USDT|USDC)/i.test(label)
          ? label.replace(/USDT|USDC/gi, "").trim()
          : label}
      </span>
    </div>
  );
};

export function CurrentWalletIcon({ icon: Icon }: { icon: Props["icon"] }) {
  return (
    <div className="flex h-5 w-5 items-center gap-2 justify-center rounded-full bg-zinc-900/5 ring-1 ring-zinc-900/25 backdrop-blur-[2px] transition duration-300 group-hover:bg-white/50 group-hover:ring-zinc-900/25 dark:bg-white/7.5 dark:ring-white/15 dark:group-hover:bg-indigo-300/10 dark:group-hover:ring-indigo-400">
      {Icon && (
        <>
          <Icon className="h-5 w-5 fill-zinc-700/10 stroke-zinc-700 transition-colors duration-300 group-hover:stroke-zinc-900 dark:fill-white/10 dark:stroke-zinc-400 dark:group-hover:fill-indigo-300/10 dark:group-hover:stroke-indigo-400" />
          <p>Tron</p>
        </>
      )}
    </div>
  );
}
function commas(x: any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
