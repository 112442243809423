import React from "react";
import illustration from "../../assets/misc/WalletIMG.svg";

const EmptyState = () => {
  return (
    <>
      {/* <div>
        <h1 className="font-medium py-5 text-[#071827] text-xl">
          Recent Activity
        </h1>
      </div> */}
      <div className=" mt-16 flex flex-col items-center justify-center">
        <img src={illustration} alt="" />
        <h1 className="font-bold text-lg md:text-2xl text-[#071827] text-center">
          You have no recent transactions
        </h1>
        <p className=" text-[#5C6F7F] text-sm text-center">
          You have no recent transactions, click on any of your wallets to begin
        </p>
      </div>
    </>
  );
};

export default EmptyState;
