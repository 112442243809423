import AuthLayout from "./layouts/AuthLayout";
import LandingPage from "./pages/landing/LandingPage";
import DashBoardLayout from "./layouts/DashBoardLayout";
import Onboarding from "./pages/onboarding/Onboarding";
import OnboardingSuccess from "./pages/onboarding/OnboardingSuccess";
import PasswordReset from "./pages/onboarding/PasswordReset";
import DashboardPage from "./pages/dashboard/DashboardPage";
import NotFound from "./pages/NotFound";
import Wallets from "./pages/dashboard/Wallets";
import Beneficiaries from "./pages/dashboard/Beneficiaries";
import Transactions from "./pages/dashboard/Transactions";
import Withdrawals from "./pages/dashboard/Withdrawals";
import Rates from "./pages/dashboard/Rates";
import Settings from "./pages/dashboard/Settings";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import NewPassword from "./pages/authentication/NewPassword";
import PasswordEmailScreen from "./pages/authentication/PasswordEmailScreen";
import Invites from "./pages/authentication/Invites";
import Withdraw from "./pages/dashboard/withdrawals_screen/Withdraw";

import AddBeneficiary from "./pages/dashboard/beneficiaryScreen/AddBeneficiary";
import { Route, Routes } from "react-router-dom";
import WalletView from "./pages/dashboard/walletScreens/WalletView";
import WalletTransfer from "./pages/dashboard/walletScreens/WalletTransfer";
import BeneficaryTransfer from "./components/beneficiary-component/BeneficaryTransfer";
import ExternalBeneficiaryForm from "./pages/external/ExternalBeneficiaryForm";
import SignUpVerify from "./pages/onboarding/SignUpVerify";
import TermsOfUse from "./components/pdf/TermsOfUse";
import TermsOfService from "./components/pdf/TermsOfService";
import OnboardingTermsOfService from "./pages/onboarding/terms/OnboardingTermsOfService";
import OnboardingTermsOfUse from "./pages/onboarding/terms/OnboardingTermsOfUse";
import PrivacyStatement from "./components/pdf/PrivacyStatement";
import StableCoinWallets from "./pages/dashboard/StableCoinWallets";
import BusinessOnboardingPreview from "./pages/onboarding/BusinessOnboardingPreview";
import StepLayout from "./pages/onboarding/refresh-initiative/StepLayout";
import Refresh from "./pages/onboarding/refresh-initiative";
import RefreshSuccess from "./pages/onboarding/refresh-initiative/RefreshSuccess";
import OnboardingBusinessSuccess from "./pages/onboarding/OnboardingBusinessSuccess";
import StableWallet from "./pages/dashboard/walletScreens/StableWallet";
import CardPage from "./pages/dashboard/card";
import SingleCard from "./pages/dashboard/card/card";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/invitations" element={<Invites />} />
        <Route path="/refresh" element={<Refresh />} />
        <Route path="/onboarding">
          <Route index element={<Onboarding />} />
          <Route path="/onboarding/:step" element={<Onboarding />} />
        </Route>
        <Route path="/refresh-initiated">
          <Route index element={<StepLayout />} />
          <Route path="/refresh-initiated" element={<StepLayout />} />
        </Route>
        <Route path="/login" element={<AuthLayout isLogin={true} />} />
        <Route
          path="/request-access"
          element={<AuthLayout isLogin={false} />}
        />
        {/* External Route */}
        <Route
          path="external/beneficiary"
          element={<ExternalBeneficiaryForm />}
        />

        <Route path="/success" element={<OnboardingSuccess />} />
        <Route
          path="/business_success"
          element={<OnboardingBusinessSuccess />}
        />
        <Route path="/refresh-success" element={<RefreshSuccess />} />

        <Route path="/reset" element={<PasswordReset />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/verification-signup" element={<SignUpVerify />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/privacystatement" element={<PrivacyStatement />} />
        <Route path="/verification-password-reset" element={<NewPassword />} />
        <Route path="password" element={<PasswordEmailScreen />} />
        <Route
          path="/onboarding/terms-Of-Service"
          element={<OnboardingTermsOfService />}
        />
        <Route
          path="/onboarding/terms-Of-Use"
          element={<OnboardingTermsOfUse />}
        />
        <Route
          path="/onboarding/business-preview"
          element={<BusinessOnboardingPreview />}
        />
        <Route path="/dashboard" element={<DashBoardLayout />}>
          <Route index element={<DashboardPage />} />
          <Route
            path={`/dashboard/wallet/:currency`}
            element={<WalletView />}
          />
          <Route
            path={`/dashboard/wallet/:currency/stablecoin`}
            element={<StableWallet />}
          />
          <Route path={`/dashboard/cards`} element={<CardPage />} />
          <Route path={`/dashboard/cards/:card_id`} element={<SingleCard />} />
          <Route path={`/dashboard/wallets`} element={<Wallets />} />
          <Route
            path={`/dashboard/wallets/stablecoin`}
            element={<StableCoinWallets />}
          />


          <Route
            path="/dashboard/wallet/transfer/:currency"
            element={<WalletTransfer />}
          />
          <Route
            path="/dashboard/wallet/transfer/beneficiary/:beneficiaryId"
            element={<BeneficaryTransfer />}
          />
         
          <Route path="/dashboard/beneficiaries" element={<Beneficiaries />} />
          <Route
            path="/dashboard/beneficiaries/add-beneficiary"
            element={<AddBeneficiary />}
          />
          <Route path="/dashboard/transactions" element={<Transactions />} />
          <Route path="/dashboard/withdrawals" element={<Withdrawals />} />
          <Route
            path="/dashboard/withdrawals/withdraw"
            element={<Withdraw />}
          />
          <Route path="/dashboard/rates" element={<Rates />} />
          <Route path="/dashboard/settings" element={<Settings />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
