import React, { useState } from "react";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import ReactLoading from "react-loading";
import OTPInput from "../widgets/OTPInput";

interface VerificationModalProps {
  showVerification: boolean;
  setShowVerification: (show: boolean) => void;
  setAmountValue?: (value: string) => void;
  setOtp: (otp: string) => void;
  setConfirmationModal?: (show: boolean) => void;
  withdrawNgn: (e: any) => void;
  loading: boolean;
}

const VerificationModal: React.FC<VerificationModalProps> = ({
  showVerification,
  setShowVerification,
  setAmountValue,
  setOtp,
  setConfirmationModal,
  withdrawNgn,
  loading,
}) => {
  // Add state to track if OTP is complete (all 5 digits filled)
  const [isOtpComplete, setIsOtpComplete] = useState(false);

  const backdropVariants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
  };

  const containerVariants = {
    initial: {
      x: 600,
      scale: 0,
    },
    animate: {
      x: 0,
      scale: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      x: 600,
      scale: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  const contentVariants = {
    initial: {
      x: 600,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 0.3,
        duration: 0.3,
      },
    },
    exit: {
      x: 100,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };

  const handleClose = (): void => {
    setShowVerification(false);
    setAmountValue && setAmountValue("");
  };

  const handleBack = (): void => {
    setShowVerification(false);
    setConfirmationModal && setConfirmationModal(true);
  };

  const handleOTPChange = (value: string): void => {
    // Check if all OTP fields are filled
    setIsOtpComplete(value.length === 5);
    setOtp(value.toUpperCase());
  };

  return (
    <div>
      <motion.div
        variants={backdropVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="confirmation_modal backdrop-blur-sm bg-[#13111137] fixed w-full h-full top-0 left-0 justify-center md:justify-end items-center z-50"
      >
        <motion.div
          variants={containerVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          className="flex h-screen items-end md:items-center md:justify-center"
        >
          <motion.div
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="card flex flex-col bg-white h-[350px] w-full max-w-md md:max-w-md md:max-h-full rounded-t-3xl md:rounded-3xl p-10"
          >
            <div className="flex flex-col gap-3 relative my-auto">
              <button
                onClick={handleClose}
                className="text-right hover:bg-red-200 hover:text-red-600 rounded-full hover:rounded-full flex items-end justify-end md:text-slate-600 md:bg-transparent odd:absolute xxs:top-[-45px] xxs:right-[-30px] xxs:bg-red-200 xxs:text-red-600 md:top-[-61px] md:right-[-33px] p-2 hover:rotate-90 hover:transform transition duration-150 ease-in-out"
                type="button"
              >
                <AiOutlineClose size={28} />
              </button>
              <div>
                <h1 className="text-3xl text-[#0eb683] font-extrabold self-center text-center">
                  Verification
                </h1>
              </div>
              <div className="mt-1 md:mt-0">
                <p className="text-[#5C6F7F] text-sm text-center">
                  Enter the 5-digit code sent to your email
                </p>
                <OTPInput
                  autoFocus
                  length={5}
                  className="otpContainer flex justify-between w-full py-3 mt-4 md:mt-0"
                  inputClassName="otpInput w-12 h-12 md:w-[50px] md:h-[50px] bg-white border border-[#0EB683] outline-[#0EB683] text-[#0EB683] text-2xl text-center font-bold rounded-lg"
                  autoCapitalize={true}
                  onChangeOTP={handleOTPChange}
                  onPaste={handleOTPChange}
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  onClick={handleBack}
                  type="button"
                  className="text-[#0eb683] active:scale-90 border border-[#0eb683] md:p-3 rounded-2xl transition-all md:w-40 xxs:w-32 xxs:p-[17px] flex items-center justify-center"
                >
                  <span>
                    <MdOutlineKeyboardBackspace />
                  </span>
                  <span className="w-14">Back</span>
                </button>
                <button
                  onClick={withdrawNgn}
                  disabled={!isOtpComplete || loading}
                  type="button"
                  className={`bg-[#0eb683] self-center justify-center md:w-48 border border-[#0eb683] text-white md:p-3 rounded-2xl transition-all active:scale-90 md:mt-0 ${
                    !isOtpComplete || loading
                      ? "disabled:bg-[#8fc0ad] disabled:cursor-not-allowed disabled:transition-none disabled:active:scale-90"
                      : ""
                  } xxs:w-40 xxs:p-[17px]`}
                >
                  {loading ? (
                    <div className="xxs:ml-14 md:ml-[68px]">
                      <ReactLoading
                        type="spinningBubbles"
                        color="#fff"
                        height="27px"
                        width="27px"
                      />
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default VerificationModal;
