import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import "./styles/standard.css";
import usdt from "../../assets/coins/usdt.png";
import usdc from "../../assets/coins/usdc.png";
import Select, { components, SingleValueProps } from "react-select";
const CustomInputWithImage = ({
  name,
  label,
  info,
  min,
  max,
  style,
  labelStyle,
  infoStyle,
  rightLabel,
  rightLabelStyle,
  isInsufficient,
  insufficientLabel,
  className,
  selectedCurrency,
  placeholder,
  placeholderStyle,
  ...props
}: any) => {

 const getImageSource = () => {
   switch (selectedCurrency) {
     case "USDT":
       return usdt;
     case "USDC":
       return usdc;

     default:
       return "";
   }
 };
  return (
    <div className="app-special-input">
      <div
        className="label-group"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <p className="label" style={{ textAlign: "left", ...labelStyle }}>
          {label}
        </p>
      </div>
      <div>
        <div className="flex items-center">
          <div
            className={`app-input flex-1 pl-4 pr-12 relative`}
            style={isInsufficient ? style : null}
          >
            <div
              className="insufficient absolute right-3 top-1/2 -translate-y-1/2"
              hidden={!isInsufficient}
            >
              {insufficientLabel ?? "Insufficient"}
            </div>
            <NumberFormat
              {...props}
              placeholder={placeholder}
              style={placeholderStyle}
              allowLeadingZeros={true}
              isAllowed={({ value }) => {
                // Allow empty input
                if (value === "") {
                  return true;
                }

                // Enforce two decimal places only if the value starts with "0"
                if (value.startsWith("0")) {
                  const regex = /^0(\.\d{0,2})?$/;
                  return regex.test(value);
                }

                // Allow any other values
                return true;
              }}
            />
            {selectedCurrency && (
              <div className="">
                <img
                  src={getImageSource()}
                  alt={selectedCurrency}
                  className="max-w-8 max-h-8 md:max-w-10 md:max-h-10 mr-2"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <p style={rightLabelStyle}>{rightLabel}</p>
      <p className="info" style={{ ...infoStyle }}>
        <span className="text-[#0eb683]">{info}</span>
      </p>
    </div>
  );
};

export default CustomInputWithImage;
