import React, { useState } from "react";
import { GoCheck } from "react-icons/go";
import { MdOutlineCopyAll } from "react-icons/md";


interface CopyButtonProps {
  textToCopy: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);

      // Reset the copied state after 2 seconds
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <button
      onClick={handleCopy}
      className="flex items-center space-x-1 text-green-500 hover:text-green-600 focus:outline-none"
    >
      {copied ? (
        <>
          <GoCheck />
          {/* <span>Copied</span> */}
        </>
      ) : (
        <>
          <MdOutlineCopyAll />
          {/* <span>Copy</span> */}
        </>
      )}
    </button>
  );
};

export default CopyButton;
